<div class="filter-container">
  <label>Filter by Role:</label>
  <mat-button-toggle-group (change)="onRoleFilterChange($event.value)">
    <mat-button-toggle value="">All</mat-button-toggle>
    <mat-button-toggle value="ADMIN">Admin</mat-button-toggle>
    <mat-button-toggle value="EDITOR">Editor</mat-button-toggle>
    <mat-button-toggle value="OPERATOR_CP">Operator CP</mat-button-toggle>
    <mat-button-toggle value="OPERATOR_MCP">Operator MCP</mat-button-toggle>
    <mat-button-toggle value="COMPANY_SIGN_CERT">Company Sign Cert</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<table mat-table [dataSource]="filteredProjectAccessList$ | async">
  <ng-container *ngFor="let role of signatureRoles$ | async" [matColumnDef]="roleToColumn(role)">
    <th mat-header-cell *matHeaderCellDef class="vertical-header">{{ roleToColumn(role) }}</th>
    <td mat-cell *matCellDef="let projectAccess" [ngClass]="{'blue-cell': hasSignatureRole(projectAccess, role)}">
      <button mat-icon-button (click)="addSignatureRole(projectAccess, role)" *ngIf="!hasSignatureRole(projectAccess, role)">
        <mat-icon>radio_button_unchecked</mat-icon>
      </button>
      <button mat-icon-button (click)="removeSignatureRole(projectAccess, role)" *ngIf="hasSignatureRole(projectAccess, role)">
        <mat-icon> check_circle</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef >Username</th>
    <td mat-cell *matCellDef="let projectAccess">{{ projectAccess.user.userName }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async"></tr>
</table>
