import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CheckSheetState } from '../reducers/check-sheet';

const getCheckSheetFeatureState = createFeatureSelector<CheckSheetState>('checkSheet');

export const getCurrentCsa = createSelector(
  getCheckSheetFeatureState,
  (state: CheckSheetState) => state.currentCsa
);

export const getCurrentCsaId = createSelector(
  getCheckSheetFeatureState,
  (state: CheckSheetState) => state.currentCsaId
);

export const getCurrentCsItems = createSelector(
  getCheckSheetFeatureState,
  (state: CheckSheetState) => state.currentCsItems
);

