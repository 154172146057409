import { NamedEntity } from './named-entity';

export interface Site extends NamedEntity {
  id: number;
  siteNumber: string;
}

export function bySiteNumber(a: Site, b: Site) {
  return a.siteNumber.localeCompare(b.siteNumber);
}
