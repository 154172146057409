import { Company, SignatureMatrixTemplates, SignatureRole, Site, User } from '.';
import { NamedEntity } from './named-entity';

export interface Project extends NamedEntity {
  id: number;
  config: ProjectConfig[];
  secureHandoverImageUrl?: string;
  secureCustomerLogoUrl?: string;
  signatureMatrixTemplates: SignatureMatrixTemplates;
}

export interface TreeNode {
  id: number;
  parentId: number;
  number: string;
  name: string;
  nodeType: TreeNodeType;
  status?: string;
  site?: Site;
  children: TreeNode[];
}

export interface ProjectAccess {
  user?: User,
  project?: Project,
  site?: Site;
  companies: Company[];
  role: string;
  signatureRoles: SignatureRole[];
}

export interface ProjectConfig {
  id: number;
  projectId?: number;
  name: string;
  value: string;
  description?: string;
  updateStamp?: string;
}

export enum ProjectAccessRoles {
  READ_ONLY = 'READ_ONLY',
  COMPANY_SIGN_CERT = 'COMPANY_SIGN_CERT',
  OPERATOR_MCP = 'OPERATOR_MCP',
  OPERATOR_CP = 'OPERATOR_CP',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN'
}

export enum TreeNodeType {
  TASK = 'task',
  SYSTEM = 'system',
  SUBSYSTEM = 'subsystem',
  CP = 'cp',
  MCP = 'mcp'
}

export interface MipsCheckSheetItemValue {
  CheckStatusId: number;
  CheckstatusItemId: number;
  CheckStatusItemNo: number;
  CheckstatusItemValueId: number;
  CheckstatusItemValueSequence: number;
  CheckstatusItemValueName: string;
  CheckstatusItemValue: string;
  CheckstatusItemValueReference: string;
  CheckstatusItemValueComment: string;
  CheckstatusItemValueOk: number;
  CheckstatusItemValueDate: string;
  CheckstatusItemvalueEnteredBy: string;
}
