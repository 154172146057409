import { HandoverTemplateAction, HandoverTemplateActionTypes, HandoverTemplateDelete } from '@completion/actions';
import { HandoverTemplate } from '@completion/models';

export const initialState: HandoverTemplateState = {
  handoverTemplate: null,
  selectedSection: null
};

export interface HandoverTemplateState {
  handoverTemplate: HandoverTemplate;
  selectedSection: HandoverTemplate;
}

export function handoverTemplateReducer(
  state: HandoverTemplateState = initialState,
  action: HandoverTemplateAction
): HandoverTemplateState {
  switch (action.type) {
    case HandoverTemplateActionTypes.HandoverTemplateLoadSuccess:
    case HandoverTemplateActionTypes.HandoverTemplateUpdateSuccess: {
      const handoverTemplate: HandoverTemplate = action.handoverTemplate as HandoverTemplate;
      return {
        ...state,
        handoverTemplate,
        selectedSection: handoverTemplate
      };
    }

    case HandoverTemplateActionTypes.HandoverTemplateUnload:
      return initialState;

    default:
      return state;
  }
}
