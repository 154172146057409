import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import {
  GetDossierStatus,
  GetPreviewDossierStatus,
  HandoverDossierActionTypes,
  LoadDossierFileSuccess,
  LoadPreviewDossierFileSuccess
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentHandoverId, getCurrentProject } from '@completion/selectors';
import { HandoverDossierService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class HandoverDossierEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly handoverDossierService: HandoverDossierService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, handoverDossierService, store, toastr);
  }

  @Effect()
  startCompileDossier$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.CompileDossierStart),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('startCompileDossier', GetDossierStatus, ([_, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId
    ])
  );

  @Effect()
  startPreviewDossier$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.PreviewDossierStart),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('startPreviewDossier', GetPreviewDossierStatus, ([_, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId
    ])
  );

  @Effect()
  getDossier$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.LoadDossierFile),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('getDossier', LoadDossierFileSuccess, ([_, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId
    ])
  );

  @Effect()
  getPreviewDossier$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.LoadPreviewDossierFile),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('getPreviewDossier', LoadPreviewDossierFileSuccess, ([_, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId
    ])
  );

  @Effect()
  deleteDossier$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.DossierDelete),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('deleteDossier', null, ([_, currentProject, cpId, handoverId]) => [currentProject.id, cpId, handoverId])
  );

  @Effect()
  getJobStatus$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.RequestJobStatus),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('getJobStatus', GetDossierStatus, ([_, currentProject, cpId, handoverId]) => [currentProject.id, cpId, handoverId])
  );

  @Effect()
  getPreviewJobStatus$: Observable<Action> = this.actions$.pipe(
    ofType(HandoverDossierActionTypes.RequestPreviewJobStatus),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('getPreviewJobStatus', GetPreviewDossierStatus, ([_, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId
    ])
  );
}
