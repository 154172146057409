import { ProjectAccessAction, ProjectAccessActionTypes } from '@completion/actions';
import { ProjectAccess, ProjectAccessRoles } from '@completion/models';

export const initialState: ProjectAccessState = {
  projectAccess: null,
  role: ProjectAccessRoles.READ_ONLY,
  projectAccessList: null
};

export interface ProjectAccessState {
  projectAccess: ProjectAccess;
  role: ProjectAccessRoles;
  projectAccessList?: Array<ProjectAccess>;
}

export function projectAccessReducer(state: ProjectAccessState = initialState, action: ProjectAccessAction): ProjectAccessState {
  switch (action.type) {
    case ProjectAccessActionTypes.ProjectAccessLoadSuccess:
      return {
        ...state,
        projectAccess: action.projectsAccess,
        role: ProjectAccessRoles[action.projectsAccess.role]
      };
      case ProjectAccessActionTypes.ProjectAccessListLoadSuccess:
      return {
        ...state,
        projectAccessList: action.projectsAccessList
      };
    default:
      return state;
  }
}
