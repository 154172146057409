import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { Task } from '@completion/models';
import { getCurrentTask, getTasks } from '@completion/selectors';
import * as taskAction from '../../core/store/actions/task.action';
import { TaskState } from '../../core/store/reducers/task';

@Component({
  selector: 'app-task-selector',
  templateUrl: './task-selector.component.html',
  styleUrls: ['./task-selector.component.scss']
})
export class TaskSelectorComponent implements OnInit, OnDestroy {
  filteredTasks: Observable<Task[]>;
  filteredTaskStrings: Observable<string[]>;
  currentTask: Task = null;
  currentTaskString: string = '';
  private readonly taskList: Observable<Task[]>;

  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private readonly store: Store<TaskState>, private readonly router: Router) {
    this.taskList = this.store.pipe(select(getTasks));
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.populateOptions();
    this.store.pipe(takeUntil(this.destroy$), select(getCurrentTask)).subscribe(task => {
      this.currentTask = task;
      if (!this.currentTask) {
        this.store.dispatch(new taskAction.ClearCurrentTask());
      }
    });
  }

  onSelectedTask(taskString: string): void {

    const task = this.taskList.pipe(
      map(tasks => tasks.find(t => this.displayValue(t) === taskString))
    );
    task.subscribe(task => {
      if (task && task.id) {
        this.store.dispatch(new taskAction.CurrentTask(task));
      } else {
        this.store.dispatch(new taskAction.ClearCurrentTask());
      }
      this.router.navigate(['dashboard']);
    });


  }

  clearTaskHandler(event): void {
    this.onSelectedTask(null);
    this.currentTask = null;
    this.currentTaskString = '';
    this.populateOptions();
    event.stopPropagation();
  }

  displayValue(option: any): string {
    if (typeof option === 'string') {
      return option;
    }

    return option ? `${option.number} - ${option.name}` : '';
  }

  onChange(value: string): void {
    if (value === '') {
      this.store.dispatch(new taskAction.ClearCurrentTask());
    }
    else {
      this.currentTaskString = value;
    }
    this.populateOptions();
  }

  populateOptions(): void {
    this.filteredTasks = this.taskList.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(tasks => this.filterOptions(tasks))
    );

    this.filteredTaskStrings = this.filteredTasks.pipe(
      map(tasks => tasks.map(task => this.displayValue(task)))
    );
  }

  getTitle(task: Task): string {
    return task.number + ' - ' + task.name;
  }

  private filterOptions(values: Task[]): Task[] {
    if (!this.currentTaskString || this.currentTask !== null) {
      return values.slice(0, 50);
    }

    return values
      .filter(task => {
        return this.displayValue(task).toLowerCase().includes(this.currentTaskString.toLowerCase());
      })
      .slice(0, 50);
  }
}
