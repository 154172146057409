<div class="">
  <h3>Disclaimer: Reports are beta-functionality. Use with caution.</h3>
  <mat-tab-group >
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>assignment</mat-icon>
        Punches
      </ng-template>
        <div class="action-buttons mat-elevation-z3">
          <button class="action-button" mat-icon-button color="primary" (click)="loadPunchData()"> Load punch data
            <mat-icon>dataset</mat-icon>
          </button>
        </div>

        <div class="reports-container mat-elevation-z3 ">
          <h2>Punch burn down</h2>
          <h3 *ngIf="!currentTask && !isPunchesLoading"> Select task to see burn down</h3>
          <div class="filter-container">
            <form [formGroup]="punchFilterForm" *ngIf="punches && punches.length > 0" >
              <div class="filter-wrapper">
                <mat-form-field appearance="fill" class="filter-form-field">
                  <mat-label>Punch Filter Parameter</mat-label>
                  <mat-select [(value)]="selectedPunchFilter" (selectionChange)="populatePunchFilterValues()">
                    <mat-option *ngFor="let filter of filters" [value]="filter">{{filter}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="filter-form-field">
                  <mat-label>Punch Filter String</mat-label>
                  <mat-select [(value)]="punchFilterStringValue" [formControl]="punchFilterString">
                    <mat-option *ngFor="let filterValue of punchFilterValues" [value]="filterValue">
                      {{filterValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </div>
          <div class="flex">
            <div class="chart" #parentRef>

              <mat-spinner *ngIf="isPunchesLoading && (punches && punches.length === 0)"></mat-spinner>
              <h3 *ngIf="!isPunchesLoading && punches.length === 0"> No punch data available</h3>
              <!--  <ngx-charts-line-chart
                *ngIf="punchChartData  && !isPunchesLoading"
                [view]="[parentWidth, parentHeight]"
                [scheme]="colorScheme"
                [results]="punchChartData"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="true"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [xAxisLabel]="'Date'"
                [yAxisLabel]="'Punches'"
                [autoScale]="false"
                [yScaleMin]="yScaleMin">
              </ngx-charts-line-chart> -->

              <ngx-charts-line-chart
                *ngIf="punchWeeklyChartData  && !isPunchesLoading"
                [view]="[parentWidth, parentHeight]"
                [scheme]="colorScheme"
                [results]="punchWeeklyChartData"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="true"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [xAxisLabel]="'Date'"
                [yAxisLabel]="'Punches'"
                [autoScale]="false"
                [yScaleMin]="yScaleMin">
              </ngx-charts-line-chart>
              <ng-template #selectTask>
                <div>
                  <p>Please select task to see Punch Burndown</p>
                </div>
              </ng-template>
            </div>
            <div class="punches-actions">
              <button mat-icon-button (click)="refreshPunchData()" [matTooltip]="'Update data from server'">
                <mat-icon>refresh</mat-icon>
              </button>
              <button mat-icon-button (click)="downloadPunches()" [matTooltip]="'Download selection as excel file'">
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </div>
          <div>
            bvarr
            <ngx-charts-bar-vertical-2d
              [scheme]="weeklyColorScheme"
              [results]="weeklyChartData"
              [gradient]="true"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxisLabel]="'Week'"
              [yAxisLabel]="'Count'"
              [legendTitle]="'Punches'"
              [roundEdges]="false">
            </ngx-charts-bar-vertical-2d>
          </div>
          <div class="flex cake-diagrams" *ngIf="punchTypeDataNotAccepted">
            <ngx-charts-pie-chart
              [view]="[400, 300]"
              [scheme]="punchCakeColorScheme"
              [results]="punchTypeDataNotAccepted"
              [doughnut]="false"
              [tooltipDisabled]="false"
              [legendTitle]="'Not accepted punch by type'"
              [legendPosition]="legendPosition"
              [animations]="true"
              [labels]="true"
              [legend]="true"
              [gradient]="true"
              (select)="onSelectNotAccepted($event)"
            >
            </ngx-charts-pie-chart>
            <ngx-charts-pie-chart
              [view]="[400, 300]"
              [scheme]="punchCakeColorScheme"
              [results]="punchTypeDataNotCleared"
              [doughnut]="false"
              [tooltipDisabled]="false"
              [legendTitle]="'Not cleared punch by type'"
              [legendPosition]="legendPosition"
              [animations]="true"
              [labels]="true"
              [legend]="true"
              [gradient]="true"
              (select)="onSelectNotCleared($event)"
            >
            </ngx-charts-pie-chart>
          </div>
        </div>


    </mat-tab>


      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>assignment</mat-icon>
          Check sheet.
        </ng-template>

        <div class="action-buttons " >
          <button class="action-button" mat-icon-button color="primary" (click)="loadCsaData()"> Load check sheet data
            <mat-icon>dataset</mat-icon>
          </button>
          <button class="action-button" mat-icon-button color="primary" (click)="parseToGetCommPackageData()" [disabled]="isCheckSheetAssignmentsEmpty()"> Parse comm package readiness data
            <mat-icon>dataset</mat-icon>
          </button>
        </div>

        <div class="csa-report-container"
             *ngIf="checkSheetAssignmentChartData  && !isCsaLoading">
          <div class="filter-container">
            <form [formGroup]="filterForm">
              <div class="filter-container">
                <mat-form-field appearance="fill" class="filter-form-field">
                  <mat-label>Filter Parameter</mat-label>
                  <mat-select [(value)]="selectedFilter" (selectionChange)="onFilterChange($event)">
                    <mat-option *ngFor="let filter of filters" [value]="filter">{{filter}}</mat-option>
                    <!-- Add more options as needed -->
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="filter-form-field">
                  <mat-label>Filter String</mat-label>
                  <mat-select [(value)]="filterStringValue" [formControl]="filterString">
                    <mat-option *ngFor="let filterValue of filterValues" [value]="filterValue">
                      {{filterValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </div>

          <div class="flex">
            <div class="chart" >
              <h2>Check sheet burndown</h2>
              <ngx-charts-line-chart

                [view]="[parentWidth, parentHeight]"
                [scheme]="colorScheme"
                [results]="checkSheetAssignmentChartData"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="true"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [xAxisLabel]="'Date'"
                [yAxisLabel]="'CheckSheetAssignments'"
                [autoScale]="false"
                [yScaleMin]="yScaleMin">
              </ngx-charts-line-chart>
            </div>
            <div class="checksheet-actions">
              <button mat-icon-button (click)="refreshCsaData()" [matTooltip]="'Update data from server'">
                <mat-icon>refresh</mat-icon>
              </button>
              <button mat-icon-button (click)="downloadCsas()" [matTooltip]="'Download selection as excel file'">
                <mat-icon>download</mat-icon>
              </button>
            </div>

          </div>
          <div class="flex cake-diagrams" *ngIf="csaMcCertificateData">
            <ngx-charts-pie-chart
              [view]="[400, 300]"
              [scheme]="certificateCakeColorScheme"
              [results]="csaMcCertificateData"
              [doughnut]="false"
              [tooltipDisabled]="false"
              [legendTitle]="'Check Status MC certificate status'"
              [legendPosition]="legendPosition"
              [animations]="true"
              [labels]="true"
              [legend]="true"
              [gradient]="true"

            >
            </ngx-charts-pie-chart>
          </div>
        </div>

        <div class="paginator flex" *ngIf="cpChartData && cpChartData.length">
          <mat-paginator [length]="cpPreparedData.length"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[50, 100, 200]"
                         (page)="onPageChange($event)">
          </mat-paginator>
        </div>
        <div class="flex cp-chart"  #stackWidthRef>
          <div *ngIf="cpChartData && cpChartData.length">
            <ngx-charts-bar-horizontal-stacked
              [view]="[stackWidth, 1500]"
              [scheme]="horizontalStackScheme"
              [results]="cpChartData"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxisLabel]="'Count'"
              [yAxisLabel]="'Commissioning Packages'"
              [barPadding]="5"
              [animations]="true"
              (select)="onBarClick($event)"
            >
            </ngx-charts-bar-horizontal-stacked>
          </div>



        </div>

    </mat-tab>

  </mat-tab-group>
</div>








