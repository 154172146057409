<div class="apd-dark-theme">
  <mat-form-field class="item-selector no-underline" floatLabel="never">
    <mat-label>Select project</mat-label>
    <mat-select aria-label="Project selector" (selectionChange)="onSelectedProject($event.value)" [value]="selectedProject$ | async">
      <mat-option *ngFor="let project of projects$ | async" [attr.aria-label]="project.name" [value]="project">
        {{ project.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
