import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CheckSheetType, ResourceType } from '@completion/enums';
import { CheckSheet, Company, Phase, SignatureRole, Site } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OptionsService extends BaseService {
  private readonly url = '/api/';
  private readonly projectUrl = '/api/projects';

  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.GetSites)
  getSites(projectId: number): Observable<Site[]> {
    const getSitestUrl = `${this.projectUrl}/${projectId}/sites`;
    return this.http.get<Site[]>(getSitestUrl);
  }

  @Resource(ResourceType.GetMcSites)
  getMcSites(projectId: number): Observable<Site[]> {
    const getSitestUrl = `${this.projectUrl}/${projectId}/mcSites`;
    return this.http.get<Site[]>(getSitestUrl);
  }

  @Resource(ResourceType.GetSites)
  getCompanies(projectId: number, category?: string): Observable<Company[]> {
    const params = category
      ? new HttpParams().set('projectId', projectId.toString()).set('category', category)
      : new HttpParams().set('projectId', projectId.toString());
    return this.http.get<Company[]>(`${this.url}companies`, { params });
  }

  @Resource(ResourceType.GetPhases)
  getPhases(projectId: number): Observable<Phase[]> {
    const getPhasesUrl = `${this.projectUrl}/${projectId}/phases`;
    return this.http.get<Phase[]>(getPhasesUrl);
  }

  @Resource(ResourceType.GetCheckSheets)
  getCheckSheets(csType: CheckSheetType, projectId: number): Observable<CheckSheet[]> {
    const params = new HttpParams().set('csType', csType);
    return this.http.get<CheckSheet[]>(`${this.url}projects/${projectId}/checkSheets`, { params });
  }

  @Resource(ResourceType.GetSignatureRoles)
  getSignatureRoles(projectId: number): Observable<SignatureRole[]> {
    return this.http.get<SignatureRole[]>(`${this.projectUrl}/${projectId}/signatureRoles`);
  }

  @Resource(ResourceType.UpdateSignatureRole)
  updateSignatureRole(projectId: number, signatureRole: SignatureRole): Observable<SignatureRole[]> {
    return this.http.put<SignatureRole[]>(`${this.projectUrl}/${projectId}/signatureRoles`, signatureRole);
  }

  @Resource(ResourceType.AddSignatureRole)
  addSignatureRole(projectId: number, signatureRole: SignatureRole): Observable<SignatureRole[]> {
    return this.http.post<SignatureRole[]>(`${this.projectUrl}/${projectId}/signatureRoles`, signatureRole);
  }

  @Resource(ResourceType.DeleteSignatureRole)
  deleteSignatureRole(projectId: number, signatureRoleId: number): Observable<SignatureRole[]> {
    return this.http.delete<SignatureRole[]>(`${this.projectUrl}/${projectId}/signatureRoles/${signatureRoleId}`);
  }
}
