import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, share, takeUntil } from 'rxjs/operators';

import { LoaderService } from './loader/loader.service';
import { LoaderConfig, defaultLoaderConfig } from './loader/loader.config';
import { Store, select } from '@ngrx/store';
import { ChangeProject, ProjectsLoad } from '../core/store/actions/project.action';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { getProjects } from '../core/store/selectors/project.selector';
import { Project } from '../shared/models/project';
import { ProjectAccessLoad } from '../core/store/actions/project-access.action';
import { CurrentUserLoad } from "@completion/actions";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('{{ delay }}')], {
        delay: '0.1s 3000ms ease-in-out'
      })
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public animationTime = '0.1s 3000ms ease-in-out';

  @Input()
  apdTitle = '';

  @Input()
  titleClickUrl = '';

  @Input()
  hideUserLogin = false;

  @Input()
  logoClickUrl = 'https://www.akersolutions.com/';

  @HostBinding('class.app-header')
  classAppHeader = true;
  private loaderConfig: LoaderConfig = defaultLoaderConfig
  public isLoading: Observable<boolean>;

  private readonly authCompelete$ = new BehaviorSubject<boolean>(false);
  private authComplete: boolean = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly authService: MsalService,
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    private store: Store

  ) {
    this.isLoading = this.loaderService.isLoadingStream.pipe(share());
  }
  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async ngOnInit(): Promise<void> {

    this.isLoading = this.loaderService.isLoadingStream.pipe(share());
    if (this.loaderConfig && this.loaderConfig.delayLoadbar != null) {
      this.setDelayForLoadbar(this.loaderConfig.delayLoadbar);
    }

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
      this.authService.instance.handleRedirectPromise();
      this.authComplete = true;
      this.authCompelete$.next(true);
    });

    await this.authService.instance.handleRedirectPromise();
    const accounts = this.authService.instance.getAllAccounts();
    if (!accounts.length) {
      await this.authService.instance.loginRedirect();
    } else {
      const account = accounts[0];
      this.store.dispatch(new CurrentUserLoad());
    }

    if (this.loaderConfig && this.loaderConfig.delayLoadbar != null) {
      this.setDelayForLoadbar(this.loaderConfig.delayLoadbar);
    }
    this.authCompelete$.subscribe(async (complete) => {
      if (complete) {
        this.store
          .pipe(
            select(getProjects),
            takeUntil(this.destroy$)
          )
          .subscribe(projects => {
            let project: Project;
            if (projects.length) {
              if (window.localStorage.getItem('projectId')) {
                project = projects.find(p => p.id === +window.localStorage.getItem('projectId'));
              }
              if (!project) {
                project = projects[0];
              }
              this.store.dispatch(new ChangeProject(project));
              this.store.dispatch(new ProjectAccessLoad(project));
            }
            else {
              this.store.dispatch(new ProjectsLoad());
            }
          });
      }
    });

  }



  private setDelayForLoadbar(delay: number): void {
    this.animationTime = `0.1s ${delay}ms ease-in-out`;
  }
}
