import {punches} from "../testing";
import {Punch} from "./punch";
import {CheckRecordValue} from "./check-sheet-assignment";

export class cpReportData {
  cpId: number;
  cpName: string;
  cpStatus: string;
  cpProgress: number;
  mcps: mcpReportData[];
}

export class mcpReportData {
  mcpId: number;
  mcpName: string;
  mcpStatus: string;
  mcpProgress: number;
  csa: csaReportData[];
}

export class  csaReportData {
  csaId: number;
  csaName: string;
  csaStatus: string;
  csaProgress: number;
  csi: csiReportData[];
}

export class csiReportData {
  csiId: number;
  csiName: string;
  csiStatus: string;
  csiProgress: number;
  punches: Punch[];
  checkStatusItemValues: CheckRecordValue[];
}

export class CertificateOverviewReport {
  taskNumber: string;
  taskName: string;
  systemNo: string;
  systemName: string;
  subsystemNo: string;
  subsystemName: string;
  commissioningPackageNo: string;
  commissioningPackageName: string;
  csaCertificateState: CheckSheetAssignmentCertificateState;
  signedRfcc: number;
  totalRfcc: number;
  signedRfoc: number;
  totalRfoc: number;
  signedMcc: number;
  totalMcc: number;
}

export class CheckSheetAssignmentCertificateState {
  nonMcc: number;
  totalMcCsa: number;
  nonRfoc: number;
  totalCpCsa: number;
  nonRfcc: number;
}

