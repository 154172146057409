import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonStatus } from '@completion/enums';

@Component({
  selector: 'app-commit-button',
  templateUrl: './commit-button.component.html',
  styleUrls: ['./commit-button.component.scss']
})
export class CommitButtonComponent {
  @Input() idleLabel = 'Save';
  @Input() successLabel = 'Saved';
  @Input() buttonStatus: ButtonStatus;
  @Input() isRoundButton = false;
  @Input() isStrokedButton = false;
  @Input() disabled = false;
  @Input() buttonTestId: string;
  @Output() commitButtonClicked = new EventEmitter<boolean>();

  get idle(): boolean {
    return this.buttonStatus === ButtonStatus.IDLE;
  }

  get inprogress(): boolean {
    return this.buttonStatus === ButtonStatus.IN_PROGRESS;
  }

  get success(): boolean {
    return this.buttonStatus === ButtonStatus.COMPLETED;
  }

  onClick() {
    this.commitButtonClicked.emit(true);
  }
}
