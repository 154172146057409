<div class="signature-matrix-container mat-elevation-z2">
  <h3>{{ convertSignatureTypeToName(type) }}</h3>
  <div class="spinner-container" *ngIf="isLoading(); else loaded">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>
  <ng-template #loaded>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="ordinal">
        <th mat-header-cell *matHeaderCellDef>Ordinal</th>
        <td mat-cell class="ordinal" *matCellDef="let element">{{ element.ordinal }}</td>
      </ng-container>
      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef>Organisation</th>
        <td mat-cell class="verified" *matCellDef="let element">{{ element.verified || element.signatureRole?.description || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>ROLE</th>
        <td mat-cell class="role" *matCellDef="let element">{{ element.role || element.signatureRole?.name || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="signee">
        <th mat-header-cell *matHeaderCellDef>Signee</th>
        <td mat-cell class="signee" *matCellDef="let element">{{ element.signee?.name || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="signatureRole">
        <th mat-header-cell *matHeaderCellDef>Signature Role</th>
        <td mat-cell class="signatureRole" *matCellDef="let element">{{ element.signatureRole?.name || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell class="edit" *matCellDef="let element">
          <button class="edit-button" (click)="editSignatureMatrix(element)" mat-flat-button><mat-icon>edit</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell class="delete" *matCellDef="let element">
          <button class="edit-button" (click)="deleteSignatureMatrix(element)" mat-flat-button><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-template>
  <div>
    <button mat-button (click)="addSignatureMatrix()">Add Signature Matrix Row</button>
  </div>
</div>
