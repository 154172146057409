import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OfflineState } from '../reducers/offline';

const getOfflineFeatureState = createFeatureSelector<OfflineState>('offline');

export const getOfflineState = createSelector(
  getOfflineFeatureState,
  (state: OfflineState) => {
    return state.applicationMode;
  }
);
