export enum ResourceType {
  CertificateGetCertificateDetails = 'certificateGetCertificateDetails',
  CertificateGetCertificates = 'certificateGetCertificates',

  CertificateGetMccIssueOptions = 'certificateGetMccIssueOptions',
  CertificateGetRfccIssueOptions = 'certificateGetRfccIssueOptions',
  CertificateGetRfocIssueOptions = 'certificateGetRfocIssueOptions',

  CertificateIssueMccs = 'certificateIssueMccs',
  CertificateIssueRfcc = 'certificateIssueRfcc',
  CertificateIssueRfoc = 'certificateIssueRfoc',

  CertificateSaveCertificateComment = 'certificateSaveCertificateComment',
  SaveSignatureMatrix = 'SaveSignatureMatrix',

  CertificateUpdateMccSignature = 'certificateUpdateMccSignature',
  CertificateUpdateMccAccepted = 'certificateUpdateMccAccepted',
  CertificateUpdateRfccSignature = 'certificateUpdateRfccSignature',
  CertificateUpdateRfocSignature = 'certificateUpdateRfocSignature',

  DeleteCheckSheetAssignment = 'deleteCheckSheetAssignment',
  MoveCheckSheetAssignment = 'moveCheckSheetAssignment',
  AddCheckedTags = 'addCheckedTags',

  LoadDashboardCps = 'dashboardCpList',
  LoadDashboardMcps = 'dashboardMcpList',
  LoadDashboardCheckSheetAssignmentStatus = 'DashboardCheckSheetAssignmentStatus',
  LoadIntegrationStatus = 'LoadIntegrationStatus',
  LoadIntegrationHeartBeat = 'LoadIntegrationHeartBeat',

  HandoverCreateSection = 'handoverCreateSection',
  HandoverFileDelete = 'handoverFileDelete',
  HandoverFileUpload = 'handoverFileUpload',
  HandoverGetHandover = 'handoverGetHandover',
  HandoverGetHandoverSectionTemplates = 'handoverGetHandoverSectionTemplates',
  HandoverUpdateRow = 'handoverUpdateRow',
  HandoverUpdateSection = 'handoverUpdateSection',
  HandoverUpdateSections = 'handoverUpdateSections',
  HandoverDeleteSection = 'handoverDeleteSection',
  HandoverUpdateSignature = 'handoverUpdateSignature',
  HandoverUpdateFrontPage = 'HandoverUpdateFrontPage',

  HandoverDocsGetMcsi = 'handoverDocsGetMcsi',
  HandoverDocsGetCpsi = 'handoverDocsGetCpsi',
  HandoverDocsGetCppl = 'handoverDocsGetCppl',

  HandoverTemplateGetHandoverTemplate = 'handoverTemplateGetHandoverTemplate',
  HandoverTemplateUpdateOrdinals = 'updateHandoverTemplateOrdinals',
  HandoverTemplateDeleteHandoverTemplate = 'handoverTemplateDeleteHandoverTemplate',
  HandoverTemplateAddHandoverTemplate = 'handoverTemplateAddHandoverTemplate',
  HandoverTemplateUpdateHandoverTemplate = 'handoverTemplateUpdateHandoverTemplate',

  StartCompileDossier = 'startCompileDossier',
  StartPreviewDossier = 'startPreviewDossier',

  GetJobStatus = 'getJobStatus',
  GetDossier = 'getDossier',
  PreviewDossier = 'previewDossier',

  PreviewJobStatus = 'previewJobStatus',
  DeleteDossier = 'deleteDossier',

  OfflineDownloadSOW = 'offlineDownloadSOW',
  OfflineGetDownloads = 'offlineGetDownloads',
  OfflineReleaseDownloads = 'offlineReleaseDownloads',

  ProjectConfigUpdate = 'projectConfigUpdate',
  ProjectGetProjectTree = 'projectGetProjectTree',
  ProjectImageUpload = 'projectImageUpload',
  GetProjects = 'getProjects',
  ProjectImageDelete = 'projectImageDelete',
  GetTasks = 'getTasks',
  GetDeliveries = 'getDeliveries',

  GetProjectAccess = 'getProjectAccess',
  GetProjectAccessList = 'getProjectAccessList',
  UpdateProjectAccess = 'updateProjectAccess',

  TagsGetCpAvailableTags = 'tagsGetCpAvailableTags',
  TagsGetMcpAvailableTags = 'tagsGetMcpAvailableTags',
  TagSetSerialNumberTags = "tagsSetSerialNumberTags",
  TagSearchTag = 'tagSearchTags',

  UserGetCurrentUser = 'userGetCurrentUser',
  UserGetUsers = 'userGetUsers',

  GetCPComponent = 'getCPComponent',
  CreateCommPkg = 'createCommPkg',
  GetCommPkgs = 'getCommPkgs',
  UpdateCp = 'updateCp',
  GetCp = 'getCp',
  DeleteCp = 'deleteCp',

  GetAreas = 'getAreas',
  GetDisciplines = 'getDisciplines',
  GetSites = 'getSites',
  GetMcSites = 'getMcSites',
  GetCompanies = 'getCompanies',
  GetPhases = 'getPhases',
  GetCheckSheets = 'getCheckSheets',

  GetCheckedTags = 'getCheckedTags',
  GetCheckSheetAssignment = 'getCheckSheetAssignment',
  UpdateCheckListItem = 'updateCheckListItem',
  UpdateCheckSheetAssignment = 'updateCheckSheetAssignment',
  UpdateRecordValue = 'updateRecordValue',
  AddRecordValue = 'addRecordValue',
  DeleteRecordValue = "deleteRecordValue",
  DuplicateTags = 'duplicateTags',
  UpdateCpCsaStatuses = 'updateCpCsaStatuses',
  RefetchCpData = 'refetchCpData',

  GetAllMcps = 'getAllMcps',
  CreateMcps = 'createMcps',
  GetMcp = 'getMcp',
  UpdateMcp = 'updateMcp',

  GetMcpPunches = 'getMcpPunches',
  GetCpPunches = 'getCpPunches',
  GetCsaPunches = 'getCsaPunches',
  CreateCpPunch = 'createCpPunch',
  CreateMcpPunch = 'createMcpPunch',
  UpdatePunch = 'updatePunch',
  DeletePunch = 'deletePunch',
  UploadAttachments = 'uploadAttachments',
  GetAttachment = 'getAttachment',
  DeleteAttachment = 'deleteAttachment',
  LoadUserPunches = "LoadUserPunches",
  LoadUserSignatureAssignments = "LoadUserSignatureAssignments",
  InjectCSItemValues = "InjectCSItemValues",
  FetchSignaturesFromHistory = "FetchSignaturesFromHistory",
  LoadUnacceptedPunches = "LoadUnacceptedPunches",
  FetchDefaultSignatureMatrixces = "FetchDefaultSignatureMatrixces",
  DeleteSignatureMatrixTemplate = "DeleteSignatureMatrixTemplate",
  UpdateSignatureMatrixTemplate = "UpdateSignatureMatrixTemplate",
  GetSignatureRoles = "GetSignatureRoles",
  AddSignatureMatrixTemplate = "AddSignatureMatrixTemplate",
  UpdateSignatureRole = "UpdateSignatureRole",
  AddSignatureRole = "AddSignatureRole",
  DeleteSignatureRole = "DeleteSignatureRole",
  SignCpCsaSignatureEntry = "SignCpCsaSignatureEntry",
  SignMcpCsaSigntureEntry = "SignMcpCsaSigntureEntry",
  InsertAndSignMcpCsaSigntureEntry = "InsertAndSignMcpCsaSigntureEntry",
  AddUnsignedSignatureMatrices = "AddUnsignedSignatureMatrices",
  DeleteSignatureMatrix = "DeleteSignatureMatrix",
  AdminRevokeAccepted = "AdminRevokeAccepted",
  InsertAndSignSignatureMatrix = "InsertAndSignSignatureMatrix",
  ToggleSignSignatureMatrix = "ToggleSignSignatureMatrix",
  UploadCertificateAttachment = "UploadCertificateAttachment",
  CertificateDeleteFile = "CertificateDeleteFile",
  UploadCpCsaFile = "UploadCpCsaFile",
  UploadMcpCsaFile = "UploadMcpCsaFile",
  DeleteMcpCsaAttachment = "DeleteMcpCsaAttachment",
  DeleteCpCsaAttachment = "DeleteCpCsaAttachment",
  DeleteMccAttachment = "DeleteMccAttachment",
  PublishPunchesToMips = "PublishPunchesToMips",
  GetProjectPunches = "GetProjectPunches",
  GetCheckSheetAssignmentByTask = "GetCheckSheetAssignmentByTask",
  GetCheckSheetAssignmentByProject = "GetCheckSheetAssignmentByProject",
  GetProjectPunchesByTask = "GetProjectPunchesByTask",
  FetchCpItemStatusesFromHistory = "FetchCpItemStatusesFromHistory",
  FetchMcItemStatusesFromHistory = "FetchMcItemStatusesFromHistory",
  ResetCpItems = "ResetCpItems",


}
