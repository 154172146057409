import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TagState } from '../reducers/tag';

const getTagFeatureState = createFeatureSelector<TagState>('tag');

export const getAvailableTags = createSelector(
  getTagFeatureState,
  (state: TagState) => state.availableTags
);

export const getSearchTags = createSelector(
  getTagFeatureState,
  (state: TagState) => state.searchTags
);
