<div class="button-container" *ngIf="inprogress">
  <div class="spinner-container">
    <mat-spinner diameter="24" class="white-spinner"></mat-spinner>
  </div>
  <button
    [attr.data-testid]="buttonTestId"
    mat-button
    [class.mat-raised-button]="!isRoundButton && !isStrokedButton"
    [class.mat-stroked-button]="isStrokedButton"
    (click)="onClick()"
    [class.round]="isRoundButton"
    disabled
  >
    &nbsp;
  </button>
</div>

<button
  [attr.data-testid]="buttonTestId"
  mat-button
  [class.mat-raised-button]="!isRoundButton && !isStrokedButton"
  [class.mat-stroked-button]="isStrokedButton"
  (click)="onClick()"
  [class.round]="isRoundButton"
  [disabled]="disabled"
  *ngIf="idle"
>
  {{ idleLabel }}
</button>

<span *ngIf="success">{{ successLabel }} ✔</span>
