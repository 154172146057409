import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

import { SiteSelectorComponent } from './site-selector.component';

@NgModule({
  imports: [CommonModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatButtonModule, FormsModule],
  declarations: [SiteSelectorComponent],
  exports: [SiteSelectorComponent]
})
export class SiteSelectorModule { }
