import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureAssignmentsComponent } from './signature-assignments.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';




@NgModule({
  declarations: [SignatureAssignmentsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatBadgeModule
  ],
  exports: [SignatureAssignmentsComponent]
})
export class SignatureAssignmentsModule { }
