import { NamedEntity } from './named-entity';

export interface Task extends NamedEntity {
  id: number;
  number: string;
}

export function byTaskNumber(a: Task, b: Task) {
  return a.number.localeCompare(b.number);
}
