import { HandoverDocsAction, HandoverDocsActionTypes } from '@completion/actions';
import { Mcpl } from '@completion/models';

export const initialState: Mcpl = null;

export function mcplReducer(state: Mcpl = initialState, action: HandoverDocsAction): Mcpl {
  switch (action.type) {
    case HandoverDocsActionTypes.McplLoadSuccess: {
      return action.mcpl;
    }

    default:
      return state;
  }
}
