import { CheckSheetAssignment } from '@completion/models';

export const sortByTag = (a: CheckSheetAssignment, b: CheckSheetAssignment) => {
  return a.tag.tagNumber.localeCompare(b.tag.tagNumber);
};

export const sortByCheckSheet = (a: CheckSheetAssignment, b: CheckSheetAssignment) => {
  return a.checkSheet.csNumber.localeCompare(b.checkSheet.csNumber);
};

export const sortByProperty = (property: string) => (a: any, b: any) => {
  if (!a[property] || !b[property]) {
    return 0;
  }

  return a[property].toString().localeCompare(b[property].toString());
};

export const compare = (a: number | string, b: number | string, isAsc: boolean) => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
