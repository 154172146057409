import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { CheckListItem, CheckRecordValue, CheckSheetAssignment, CommissioningPackage, McPackage, MoveCheckSheetAssignement } from '@completion/models';
import { getSignVerifyValue } from '@completion/utils';
import { BaseService, Resource } from './base.service';
import { SignatureMatrixState } from '../store/reducers/signature-matrix';
import {Reset} from "../../shared/models/reset";


@Injectable({
  providedIn: 'root'
})
export class CpService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.GetCommPkgs)
  getCommPkgs(projectId: number, taskId?: number): Observable<CommissioningPackage[]> {
    let packagesUrl = `${this.baseUrl}/projects/${projectId}/cps`;

    if (taskId) {
      packagesUrl += `?taskId=${taskId}`;
    }
    return this.http.get<CommissioningPackage[]>(packagesUrl);
  }

  @Resource(ResourceType.UpdateCp)
  updateCp(projectId: number, updatedCp: CommissioningPackage): Observable<CommissioningPackage> {
    return this.http.put<CommissioningPackage>(`${this.baseUrl}/projects/${projectId}/cps/${updatedCp.id}`, updatedCp);
  }

  @Resource(ResourceType.GetCheckedTags)
  getCheckedTags(projectId: number, cpId: number): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags`);
  }

  @Resource(ResourceType.GetCheckSheetAssignment)
  getCheckSheetAssignment(projectId: number, cpId: number, csaId: number): Observable<CheckSheetAssignment> {
    return this.http.get<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}`);
  }

  @Resource(ResourceType.GetCp)
  getCp(projectId: number, cpId: number): Observable<CommissioningPackage> {
    return this.http.get<CommissioningPackage>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}`);
  }

  @Resource(ResourceType.DeleteCp)
  deleteCp(projectId: number, cpId: number): Observable<CommissioningPackage> {
    return this.http.delete<CommissioningPackage>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}`);
  }

  @Resource(ResourceType.UpdateCheckListItem)
  updateCheckListItem(projectId: number, cpId: number, csaId: number, changedItem: CheckListItem): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/csItems/${changedItem.number}`,
      changedItem
    );
  }

  @Resource(ResourceType.AddCheckedTags)
  addCheckedTags(projectId: number, cpId: number, checkSheetAssignment: CheckSheetAssignment[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags`, checkSheetAssignment, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.UpdateCheckSheetAssignment)
  updateCheckSheetAssignment(
    projectId: number,
    cpId: number,
    assignment: CheckSheetAssignment,
    doSignOrVerify?: string
  ): Observable<CheckSheetAssignment> {
    const value = getSignVerifyValue(doSignOrVerify, assignment);
    const params = doSignOrVerify ? { params: new HttpParams().set(doSignOrVerify, value.toString()) } : {};
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${assignment.id}`,
      assignment,
      params
    );
  }

  @Resource(ResourceType.GetAllMcps)
  getAllMcps(projectId: number, cpId: number): Observable<McPackage[]> {
    return this.http.get<McPackage[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/mcPackages`);
  }

  @Resource(ResourceType.CreateMcps)
  createMcps(projectId: number, cpId: number, mcPackages: McPackage[]): Observable<McPackage[]> {
    return this.http.post<McPackage[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/mcPackages`, mcPackages);
  }

  @Resource(ResourceType.UpdateRecordValue)
  updateCpRecordValue(
    projectId: number,
    cpId: number,
    csaId: number,
    csiNum: number,
    recordValue: CheckRecordValue
  ): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/csItems/${csiNum}` +
      `/recordValues/${recordValue.sequenceNumber}`,
      recordValue
    );
  }

  @Resource(ResourceType.AddRecordValue)
  addCpRecordValue(
    projectId: number,
    cpId: number,
    csaId: number,
    csiNum: number,
    recordValue: CheckRecordValue
  ): Observable<CheckSheetAssignment> {
    return this.http.post<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/csItems/${csiNum}` +
      `/recordValues/${recordValue.sequenceNumber}`,
      recordValue
    );
  }

  @Resource(ResourceType.DeleteRecordValue)
  deleteCpRecordValue(
    projectId: number,
    cpId: number,
    csaId: number,
    csiNum: number,
    recordValue: CheckRecordValue
  ): Observable<CheckSheetAssignment> {
    return this.http.delete<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/csItems/${csiNum}` +
      `/recordValues/${recordValue.id}`
    );
  }

  @Resource(ResourceType.DuplicateTags)
  duplicateTags(projectId: number, cpId: number, checkSheetAssignment: CheckSheetAssignment[]): Observable<HttpResponse<object>> {
    return this.http.post(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/duplicateTags`, checkSheetAssignment, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.MoveCheckSheetAssignment)
  moveTags(projectId: number, cpId: number, csas: MoveCheckSheetAssignement): Observable<HttpResponse<object>> {
    return this.http.post(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/moveTags`, csas, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.DeleteCheckSheetAssignment)
  deleteCpCheckSheetAssignment(projectId: number, cpId: number, ids: number[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags`, options);
  }

  @Resource(ResourceType.UpdateCpCsaStatuses)
  updateCpCsaStatuses(projectId: number, cpId: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: [cpId]
    };
    return this.http.put(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/updateCsaStatuses`, options);
  }

  @Resource(ResourceType.RefetchCpData)
  refetchCpData(projectId: number, cpId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/refetchCpData`);
  }

  @Resource(ResourceType.FetchSignaturesFromHistory)
  fetchCpSignaturesFromHistory(projectId: number, cpId: number, csaId: number): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/signatures`, {});
  }

  @Resource(ResourceType.FetchCpItemStatusesFromHistory)
  fetchCpItemStatusesFromHistory(projectId: number, cpId: number, csaId: number): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/recoverItemStatuses`, {});
  }

  @Resource(ResourceType.SignCpCsaSignatureEntry)
  signCpCsaSigntureEntry(projectId: number, cpId: number, csaId: number, signatureEntryId: number): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/signatures/${signatureEntryId}`, {}
    );
  }

  @Resource(ResourceType.SignCpCsaSignatureEntry)
  insertAndSignCpCsaSigntureEntry(projectId: number, cpId: number, csaId: number, sign: boolean, entry: SignatureMatrixState[]): Observable<CheckSheetAssignment> {
    return this.http.post<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/signatures/add/${sign ? 'sign' : 'unsign'}`, entry
    );
  }

  @Resource(ResourceType.UploadCpCsaFile)
  uploadCpCsaFile(projectId: number, cpId: number, csaId: number, file: File): Observable<CheckSheetAssignment> {
    const formData = new FormData();
    formData.append('attachmentFiles', file, file.name);
    return this.http.post<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/files`, formData);
  }

  @Resource(ResourceType.DeleteCpCsaAttachment)
  deleteCpCsaAttachment(projectId: number, cpId: number, csaId: number, attachmentId: number): Observable<CheckSheetAssignment> {
    return this.http.delete<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/checkedTags/${csaId}/files/${attachmentId}`);
  }

  @Resource(ResourceType.ResetCpItems)
  resetCpItems(projectId: number, cpId: number, reset:Reset): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/reset/${reset.level}`, reset);
  }

}



