import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CertificateState } from '../reducers/certificate';

const getCertificateFeatureState = createFeatureSelector<CertificateState>('certificate');

export const getCertificates = createSelector(
  getCertificateFeatureState,
  (state: CertificateState) => state.certificates
);

export const getCertificateMccIssueOptions = createSelector(
  getCertificateFeatureState,
  (state: CertificateState) => state.mccIssueOptions
);

export const getCertificateRfccIssueOptions = createSelector(
  getCertificateFeatureState,
  (state: CertificateState) => state.rfccIssueOptions
);

export const getCertificateRfocIssueOptions = createSelector(
  getCertificateFeatureState,
  (state: CertificateState) => state.rfocIssueOptions
);

export const getCertificateDetails = createSelector(
  getCertificateFeatureState,
  (state: CertificateState) => state.certificateDetails
);
