import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';
import 'zone.js/dist/long-stack-trace-zone.js';

import { AppModule } from './app/app.module';
import { env } from './environments/env';

if (env.get().production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
