import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ProjectAccessState } from 'src/app/core/store/reducers/project-access';

import { ClearError, CompanyLoad, SignatureRoleLoad, SiteLoad, UpdateProjectAccess } from '@completion/actions';
import { ButtonStatus, CompanyCategory, ResourceStatus, ResourceType } from '@completion/enums';
import { Company, ProjectAccess, ProjectAccessRoles, SignatureRole, Site } from '@completion/models';
import { getCompanies, getResourceState, getSignatureRoles, getSites } from '@completion/selectors';
import { hasSameId } from '@completion/utils';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {
  hasSameIds = hasSameId;
  roles = Object.values(ProjectAccessRoles);
  buttonStatus = ButtonStatus.IDLE;
  errorMsg: string | null;
  form: FormGroup;

  projectAccess: ProjectAccess | undefined;
  private readonly destroy$: Subject<void> = new Subject();

  sites$: Observable<Site[]> = this.store.pipe(
    select(getSites),
    takeUntil(this.destroy$)
  );
  companies$: Observable<Company[]> = this.store.pipe(
    select(getCompanies),
    takeUntil(this.destroy$)
  );

  signatureRoles$: Observable<SignatureRole[]> = this.store.pipe(
    select(getSignatureRoles),
    takeUntil(this.destroy$)
  );

  constructor(private readonly store: Store<ProjectAccessState>, private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserEditDialogComponent>, @Inject(MAT_DIALOG_DATA) readonly data = null) {
    this.store.dispatch(new SignatureRoleLoad());
    this.store.dispatch(new SiteLoad());
    this.store.dispatch(new CompanyLoad(CompanyCategory.M));
    this.projectAccess = { ...this.data.projectAccess };
    this.store
      .pipe(
        select(getResourceState, ResourceType.UpdateProjectAccess),
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        if (state.status === ResourceStatus.InProgress) {
          this.buttonStatus = ButtonStatus.IN_PROGRESS;
        }

        if (state.status === ResourceStatus.Failure) {
          this.buttonStatus = ButtonStatus.IDLE;
        }

        this.errorMsg = state.lastError;

        if (state.status === ResourceStatus.Success) {
          this.buttonStatus = ButtonStatus.COMPLETED;
          setTimeout(() => {
            this.buttonStatus = ButtonStatus.IDLE;
            this.dialogRef.close();
          }, 1000);

        }
      });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      site: [this.projectAccess.site],
      companies: [this.projectAccess.companies],
      role: [this.projectAccess.role],
      signatureRoles: [this.projectAccess.signatureRoles]
    });
  }

  onSave(): void {
    this.form.markAllAsTouched();
    this.errorMsg = null;
    this.store.dispatch(new ClearError());

    if (this.form.valid) {
      this.buttonStatus = ButtonStatus.IN_PROGRESS;

      this.store.dispatch(new UpdateProjectAccess(this.projectAccess));

      if (!this.errorMsg || this.errorMsg.length === 0) {
        this.buttonStatus = ButtonStatus.COMPLETED;
        setTimeout(() => this.buttonStatus = ButtonStatus.IDLE, 2000);
      }
    }
  }

}
