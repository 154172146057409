import { HandoverDocsAction, HandoverDocsActionTypes } from '@completion/actions';
import { Cppl } from '@completion/models';

export const initialState: Cppl = null;

export function cpplReducer(state: Cppl = initialState, action: HandoverDocsAction): Cppl {
  switch (action.type) {
    case HandoverDocsActionTypes.CpplLoadSuccess: {
      return action.cppl;
    }

    default:
      return state;
  }
}
