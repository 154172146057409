<div class="apd-dark-theme">
  <div class="row-direction">
    <mat-form-field class="item-selector" floatLabel="never">
      <mat-label>Select task</mat-label>
      <input
        matInput
        name="task"
        type="text"
        placeholder="Task"
        aria-label="Task selector"
        [(ngModel)]="currentTaskString"
        [matAutocomplete]="taskAutocomplete"
        (ngModelChange)="populateOptions()"
        (keyup)="onChange($event.target.value)"
      />
      <button mat-icon-button matSuffix type="button">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-autocomplete
        autoActiveFirstOption
        #taskAutocomplete="matAutocomplete"
        (optionSelected)="onSelectedTask($event.option.value)"
        [displayWith]="displayValue"
      >
        <mat-option *ngFor="let taskString of filteredTaskStrings | async" [value]="taskString" [matTooltip]="taskString">
          {{ taskString }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button matSuffix mat-icon-button aria-label="Clear" type="reset" (click)="clearTaskHandler($event)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
