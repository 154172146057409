import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CheckSheetType, ResourceType } from '@completion/enums';
import {
  CheckSheet, CheckSheetAssignment,
  Company, MCCDetails,
  MCCertificate,
  Phase, RFCCDetails,
  RFCCertificate, RFOCDetails,
  RFOCertificate,
  SignatureRole,
  Site
} from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ResetService extends BaseService {
  private readonly url = this.baseUrl + '/';
  private readonly projectUrl = this.baseUrl +'/projects';

  constructor(readonly http: HttpClient) {
    super(http);
  }


  @Resource(ResourceType.GetResetRfocs)
  getResetRfocs(projectId: number, cpId): Observable<RFOCDetails[]> {
    return this.http.get<RFOCDetails[]>(`${this.projectUrl}/${projectId}/cpId/${cpId}/reset/options/rfoc`);
  }

  @Resource(ResourceType.GetResetRfccs)
  getResetRfccs(projectId: number, cpId): Observable<RFCCDetails[]> {
    return this.http.get<RFCCDetails[]>(`${this.projectUrl}/${projectId}/cpId/${cpId}/reset/options/rfcc`);
  }

  @Resource(ResourceType.GetResetMccs)
  getResetMccs(projectId: number, cpId): Observable<MCCDetails[]> {
    return this.http.get<MCCDetails[]>(`${this.projectUrl}/${projectId}/cpId/${cpId}/reset/options/mcc`);
  }

  @Resource(ResourceType.GetResetCpCsas)
  getResetCpCsas(projectId: number, cpId): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.projectUrl}/${projectId}/cpId/${cpId}/reset/options/cpCsa`);
  }

  @Resource(ResourceType.GetResetMcCsas)
  getResetMcCsas(projectId: number, mcId): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.projectUrl}/${projectId}/cpId/${mcId}/reset/options/mcCsa`);
  }
}
