import { Injectable } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

import { CheckSheetAssignment, OfflineDownloadScopeCp, OfflineDownloadScopeItem, OfflineDownloadScopeMcp } from '@completion/models';
import { OfflineDialogComponent } from '../../shared/offline-dialog/offline-dialog.component';
import { OfflineDataService } from './offline-data.service';
import { OfflineDownloadsService } from './offline-downloads.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineValidityService {
  constructor(
    private readonly offlineDownloadsService: OfflineDownloadsService,
    private readonly offlineDataService: OfflineDataService,
    private readonly dialog: MatDialog
  ) {}

  checkValidityOfOfflineScopes(projectId: number) {
    if (!navigator.onLine) {
      return;
    }

    const remoteSowsMap = new Map<number, OfflineDownloadScopeItem>();
    const localSowsMap = new Map<number, CheckSheetAssignment>();

    this.offlineDownloadsService.getOfflineDownloads(projectId).subscribe(downloads => {
      OfflineValidityService.addRemoteScopesToMap(downloads.cps, remoteSowsMap);
      OfflineValidityService.addRemoteScopesToMap(downloads.mcps, remoteSowsMap);

      this.offlineDataService.fetchCsasByProject(projectId).then(localCsas => {
        for (const assignment of localCsas) {
          localSowsMap.set(assignment.id, assignment.csa);
        }

        const lockNotFoundInRemote: CheckSheetAssignment[] = [];
        const notFoundInLocal: OfflineDownloadScopeItem[] = [];

        OfflineValidityService.buildNotFound(remoteSowsMap, localSowsMap, notFoundInLocal);
        OfflineValidityService.buildNotFound(localSowsMap, remoteSowsMap, lockNotFoundInRemote);

        // If scopes are found in local storage while the offline lock is not existing for the current user (possibly released by 'Admin').
        // Notify the user.
        // Delete scope(s) in local storage
        if (lockNotFoundInRemote.length > 0) {
          this.scopesNotLockedRemotely(lockNotFoundInRemote);
        }

        // If scopes are not found in local storage while the offline lock is activated for the current user.
        // Request the user to release lock on scope(s)
        if (notFoundInLocal.length > 0) {
          this.scopesNotFoundLocally(notFoundInLocal);
        }
      });
    });
  }

  private static addRemoteScopesToMap(
    packages: OfflineDownloadScopeCp[] | OfflineDownloadScopeMcp[],
    map: Map<number, OfflineDownloadScopeItem>
  ) {
    for (const pkg of packages) {
      for (const assignment of pkg.assignments) {
        map.set(assignment.id, assignment);
      }
    }
  }

  private static buildNotFound(findFrom: Map<number, any>, findIn: Map<number, any>, notFound: any[]) {
    for (const remoteCsaId of findFrom.keys()) {
      if (!findIn.has(remoteCsaId)) {
        notFound.push(findFrom.get(remoteCsaId));
      }
    }
  }

  private scopesNotFoundLocally(csas: OfflineDownloadScopeItem[]) {
    let notifyText =
      'Following scope(s) are not existing in local offline storage.<br>Please release these scope(s) to unlock them from the server.<ul>';
    csas.forEach(csa => {
      notifyText += `<li> <strong>Check sheet: ${csa.checkSheetNumber} / ${csa.tagNumber} / ${csa.phaseNumber} / ${csa.companyNumber}</strong></li> `;
    });
    notifyText += '</ul>';
    this.notifyUser('', notifyText);
  }

  private scopesNotLockedRemotely(csas: CheckSheetAssignment[]) {
    let notifyText =
      'Following scope(s) are found in local offline storage, but the lock(s) on these scope(s) are released from the server.<br>These scope(s) will be therefore deleted from your local offline storage.<ul>';
    csas.forEach(async csa => {
      notifyText += `<li> <strong>Check sheet: ${csa.checkSheet.csNumber} / ${csa.tag.tagNumber} / ${csa.site.siteNumber} / ${csa.company.compNumber}</strong></li>`;
      // Delete scope from offline local storage
      await this.offlineDataService.deleteScope(csa.id);
    });
    notifyText += '</ul>';
    this.notifyUser('', notifyText);
  }

  private notifyUser(title: string, msg: string) {
    this.dialog.open(OfflineDialogComponent, {
      data: {
        title,
        icon: 'info',
        warning: msg,
        confirmationTile: 'OK'
      }
    });
  }
}
