import { Component, OnDestroy, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Subscription as RxSubscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { NetworkStatusService } from '@completion/services';
import { OfflineDataService } from '@completion/services';
import { OfflineState } from '@completion/reducers';

import { SetOnline } from '@completion/actions';
import { Confirmation } from '@completion/models';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-offline-toolbar',
  templateUrl: './offline-toolbar.component.html',
  styleUrls: ['./offline-toolbar.component.scss']
})
export class OfflineToolbarComponent implements OnInit, OnDestroy {
  online = true;
  offlineChanges = 0;
  syncChannel: BroadcastChannel;
  subscriptions: RxSubscription;
  changeSubscription: Subscription;

  constructor(
    private readonly networkStatusService: NetworkStatusService,
    private readonly store: Store<OfflineState>,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly offlineDataService: OfflineDataService
  ) {
    this.syncChannel = new BroadcastChannel('completion-sync');
    this.subscriptions = new RxSubscription();
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      this.networkStatusService.isOnline().subscribe(online => {
        this.online = online;
      })
    );

    this.syncChannel.addEventListener('message', this.onSyncMessage.bind(this));

    this.offlineDataService.changeEventsChanged.subscribe(async () => {
      await this.fetchPendingCount();
    });

    await this.fetchPendingCount();
  }

  ngOnDestroy(): void {
    this.syncChannel.removeEventListener('message', this.onSyncMessage);
    this.subscriptions.unsubscribe();
  }

  onSyncMessage() {
    this.fetchPendingCount();
  }

  async fetchPendingCount() {
    this.offlineChanges = await this.offlineDataService.fetchPendingChanges();
  }

  switchToOnline() {
    const title = 'Switching back to online mode';
    const confirmationMessage = 'Remember to ' + '<strong>release scopes</strong> when back online.<br/>' + 'Other users might need them.';

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        confirmationMessage,
        isCancelDisplay: false,
        confirmationTile: 'Got it'
      } as Confirmation
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }

        // redirect to online mode
        this.store.dispatch(new SetOnline());
        this.router.navigateByUrl('/');
      });
  }
}
