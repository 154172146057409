<h1 mat-dialog-title>Edit Signature Matrix Type</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Signature Matrix Type</mat-label>
    <input matInput [(ngModel)]="data.checkSheet.signatureMatrixType" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let type of data.signatureMatrixTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</div>
