<div class="grid-container">
  <!-- Line 1 -->
  <div class="grid-item done text-at-base">ENTERED</div>
  <div class="grid-item done">&nbsp;</div>
  <div class="grid-item done text-at-base content-stacked" *ngIf="punch.clearedBy?.user || (showClearAccepted() && punch.clearedBy) ; else showClearButton">
    <button
      mat-icon-button
      name="unClearBtn"
      *ngIf="!punch.acceptedBy && showUnClear"
      title="Undo clearing"
      aria-label="Undo clearing"
      (click)="onUndoClear()"
    >
      <mat-icon>undo</mat-icon>
    </button>
    CLEARED
  </div>
  <ng-template #showClearButton>
    <button
      [disabled]="clearDisabled()"
      mat-button
      class="grid-item"
      aria-label="Clear punch"
      name="clearBtn"
      (click)="punch.clearedBy = clearedByForm.value"
    >
      CLEAR
    </button>
  </ng-template>
  <div class="grid-item">&nbsp;</div>
  <div class="grid-item done text-at-base" *ngIf="punch.acceptedBy || !punch.clearedBy?.user; else showAcceptButton">
    <button
      mat-icon-button
      name="unAcceptBtn"
      *ngIf="punch.acceptedBy && showClearAccepted()"
      title="Undo accept"
      aria-label="Undo clearing"
      (click)="clearAccepted()"
    >
      <mat-icon>undo</mat-icon></button
    >ACCEPTED
  </div>
  <ng-template #showAcceptButton>
    <button
      mat-button
      class="grid-item"
      name="acceptBtn"
      aria-label="Accept punch"
      (click)="punch.acceptedBy = acceptedByForm.value"
      [disabled]="acceptDisabled()"
    >
      ACCEPT
    </button>
  </ng-template>

  <!-- Line 2 -->
  <div class="grid-item line on-the-right done"><mat-icon>check_circle</mat-icon></div>
  <div class="grid-item line full-line done"></div>
  <div class="grid-item line on-both-sides" [class.done]="punch.clearedBy?.user">
    <mat-icon *ngIf="punch.clearedBy?.user">check_circle</mat-icon>
    <mat-icon *ngIf="!punch.clearedBy?.user">radio_button_unchecked</mat-icon>
  </div>
  <div class="grid-item line full-line" [class.done]="punch.clearedBy?.user"></div>
  <div class="grid-item line on-the-left" [class.done]="punch.acceptedBy">
    <mat-icon *ngIf="punch.acceptedBy">check_circle</mat-icon>
    <mat-icon *ngIf="!punch.acceptedBy">radio_button_unchecked</mat-icon>
  </div>

  <!-- Line 3 -->
  <div class="grid-item assignment-container">
    <div>
      <app-user-assignment *ngIf="punch.enteredBy" [userAssignment]="punch.enteredBy"></app-user-assignment>
      <mat-form-field class="no-underline hidden">
        <mat-select>
          <mat-select-trigger> </mat-select-trigger>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="grid-item"></div>
  <div class="grid-item">
    <div>
      <app-user-assignment [userAssignment]="clearedByForm.value"></app-user-assignment>
      <mat-form-field class="no-underline" [class.hidden]="punch.clearedBy?.user">
        <mat-select [formControl]="clearedByForm" [compareWith]="hasSameUserIds" name="clearSelector">
          <mat-select-trigger> </mat-select-trigger>
          <mat-option *ngFor="let user of users$ | async" [value]="user">{{ user.user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="grid-item"></div>
  <div class="grid-item">
    <div>
      <app-user-assignment [userAssignment]="acceptedByForm.value"> </app-user-assignment>
      <mat-form-field class="no-underline" [class.hidden]="punch.acceptedBy">
        <mat-select [formControl]="acceptedByForm" [compareWith]="hasSameUserIds" name="acceptSelector">
          <mat-select-trigger> </mat-select-trigger>
          <mat-option *ngFor="let user of users$ | async" [value]="user">{{ user.user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
