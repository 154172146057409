import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import {
  CpplLoad,
  CpplLoadSuccess,
  CpsiLoad,
  CpsiLoadSuccess,
  HandoverDocsActionTypes,
  McplLoad,
  McplLoadSuccess,
  McsiLoad,
  McsiLoadSuccess
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentProject } from '@completion/selectors';
import { HandoverDocsService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class HandoverDocsEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly handoverDocsService: HandoverDocsService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, handoverDocsService, store, toastr);
  }

  @Effect()
  loadCppl$: Observable<Action> = this.actions$.pipe(
    ofType<CpplLoad>(HandoverDocsActionTypes.CpplLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('getCppl', CpplLoadSuccess, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.handoverId])
  );

  @Effect()
  loadMcpl$: Observable<Action> = this.actions$.pipe(
    ofType<McplLoad>(HandoverDocsActionTypes.McplLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('getMcpl', McplLoadSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.handoverId,
      action.mccId
    ])
  );

  @Effect()
  loadMcsi$: Observable<Action> = this.actions$.pipe(
    ofType<McsiLoad>(HandoverDocsActionTypes.McsiLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('getMcsi', McsiLoadSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.handoverId,
      action.mccId
    ])
  );

  @Effect()
  loadCpsi$: Observable<Action> = this.actions$.pipe(
    ofType<CpsiLoad>(HandoverDocsActionTypes.CpsiLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('getCpsi', CpsiLoadSuccess, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.handoverId])
  );
}
