import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { StatusCircleModule } from '../../shared/status-circle/status-circle.module';
import { UserAssignmentModule } from '../../shared/user-assignment/user-assignment.module';
import { OfflineDownloadsComponent } from './offline-downloads.component';

@NgModule({
  declarations: [OfflineDownloadsComponent],
  imports: [
    CommonModule,
    StatusCircleModule,
    RouterModule,
    UserAssignmentModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule],
  exports: [OfflineDownloadsComponent]
})
export class OfflineDownloadsModule {}
