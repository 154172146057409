import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import {
  AddSignatureRole,
  CheckSheetLoad,
  CheckSheetLoadSuccess, CheckSheetSignatureTypeLoadSuccess,
  CompanyLoad,
  CompanyLoadSuccess, CompanySignatureTypeLoadSuccess,
  DeleteSignatureRole, LoadRfocSuccess, McSiteLoad,
  OptionsActionTypes,
  PhaseLoad,
  PhaseLoadSuccess, ResetActionTypes,
  SignatureRoleLoad,
  SignatureRoleLoadSuccess,
  SiteLoad,
  SiteLoadSuccess, UpdateCompany,
  UpdateSignatureRole
} from '@completion/actions';
import { State } from '@completion/reducers';
import {getCurrentCpId, getCurrentProject} from '@completion/selectors';
import { OptionsService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class OptionsEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly optionsService: OptionsService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, optionsService, store, toastr);
  }

  @Effect()
  loadSites$: Observable<Action> = this.actions$.pipe(
    ofType<SiteLoad>(OptionsActionTypes.SiteLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getSites', SiteLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadMcSites$: Observable<Action> = this.actions$.pipe(
    ofType<McSiteLoad>(OptionsActionTypes.McSiteLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getMcSites', SiteLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadCompanies$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyLoad>(OptionsActionTypes.CompanyLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getCompanies', CompanyLoadSuccess, ([action, currentProject]) => [currentProject.id, action.category])
  );

  @Effect()
  loadPhases$: Observable<Action> = this.actions$.pipe(
    ofType<PhaseLoad>(OptionsActionTypes.PhaseLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getPhases', PhaseLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadCheckSheets$: Observable<Action> = this.actions$.pipe(
    ofType<CheckSheetLoad>(OptionsActionTypes.CheckSheetLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getCheckSheets', CheckSheetLoadSuccess, ([action, currentProject]) => [action.csType, currentProject.id])
  );

  @Effect()
  loadCheckSheetSignatureTypes$: Observable<Action> = this.actions$.pipe(
    ofType<CheckSheetLoad>(OptionsActionTypes.CheckSheetSignatureTypeLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getCheckSheetSignatureTypes', CheckSheetSignatureTypeLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadSignatureRoles$: Observable<Action> = this.actions$.pipe(
    ofType<SignatureRoleLoad>(OptionsActionTypes.SignatureRoleLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getSignatureRoles', SignatureRoleLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  updateSignatureRole$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateSignatureRole>(OptionsActionTypes.UpdateSignatureRole),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateSignatureRole', SignatureRoleLoadSuccess, ([action, currentProject]) => [currentProject.id, action.signatureRole])
  );

  @Effect()
  addSignatureRole$: Observable<Action> = this.actions$.pipe(
    ofType<AddSignatureRole>(OptionsActionTypes.AddSignatureRole),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('addSignatureRole', SignatureRoleLoadSuccess, ([action, currentProject]) => [currentProject.id, action.signatureRole])
  );

  @Effect()
  deleteSignatureRole$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteSignatureRole>(OptionsActionTypes.DeleteSignatureRole),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('deleteSignatureRole', SignatureRoleLoadSuccess, ([action, currentProject]) => [currentProject.id, action.signatureRole.id])
  );

  @Effect()
  companySignatureTypeLoad$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyLoad>(OptionsActionTypes.CompanySignatureTypeLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getCompanySignatureTypes', CompanySignatureTypeLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  updateCompany$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateCompany>(OptionsActionTypes.UpdateCompany),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateCompany', CompanyLoadSuccess, ([action, currentProject]) => [currentProject.id, action.company])
  );


}
