import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddSignatureMatrixTemplate, AddSignatureRole, UpdateSignatureMatrixTemplate, UpdateSignatureRole } from '@completion/actions';
import { ButtonStatus, ResourceStatus, ResourceType } from '@completion/enums';
import { SignatureRole } from '@completion/models';
import { getResourceState } from '@completion/selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-signature-role-edit-dialog',
  templateUrl: './signature-role-edit-dialog.component.html',
  styleUrls: ['./signature-role-edit-dialog.component.scss']
})
export class SignatureRoleEditDialogComponent implements OnInit {

  signatureRole: SignatureRole;

  buttonStatus = ButtonStatus.IDLE;
  errorMsg: string | null;
  form: FormGroup;

  constructor(private readonly store: Store,
    public dialogRef: MatDialogRef<SignatureRoleEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data = null,
    private readonly formBuilder: FormBuilder,
  ) {
    this.signatureRole = { ...this.data.signatureRole };
    this.store.select(getResourceState, ResourceType.UpdateSignatureRole).subscribe(
      state => {
        if (state.status === ResourceStatus.Failure) {
          this.errorMsg = state.lastError;
        }
      }
    );
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.signatureRole.name, Validators.required],
      description: [this.signatureRole.description, Validators.required],
      groupEmail: [this.signatureRole.groupEmail]
    });
  }

  onSave() {
    if (this.form.valid) {
      this.buttonStatus = ButtonStatus.IN_PROGRESS;
      this.errorMsg = null;
      if (this.signatureRole.id === null) {
        this.store.dispatch(new AddSignatureRole(this.signatureRole));
      }
      else {
        this.store.dispatch(new UpdateSignatureRole(this.signatureRole));
      }

      if (!this.errorMsg || this.errorMsg.length === 0) {
        this.buttonStatus = ButtonStatus.COMPLETED;
        setTimeout(() => this.buttonStatus = ButtonStatus.IDLE, 2000);
        this.dialogRef.close();
      }
    }
  }

}
