import { PunchAction, PunchActionTypes } from '@completion/actions';
import { Attachment, Punch, punchItemSort } from '@completion/models';
import { act } from '@ngrx/effects';

export const initialState: PunchState = {
  punches: [],
  userPunches: [],
  unacceptedPunches: [],
  punch: null,
  deleteStatus: null,
  attachments: null,
  selectedAttachment: null
};

export interface PunchState {
  punches: Punch[];
  userPunches: Punch[];
  unacceptedPunches: Punch[];
  punch: Punch;
  deleteStatus: number;
  selectedAttachment: Blob;
  attachments: Attachment[];
}

export function punchReducer(state: PunchState = initialState, action: PunchAction): PunchState {
  switch (action.type) {
    case PunchActionTypes.LoadPunchesSuccess:
      return {
        ...state,
        punches: [...action.punches].sort(punchItemSort)
      };
    case PunchActionTypes.CreatePunchSuccess:
      return {
        ...state,
        punch: action.punch
      };
    case PunchActionTypes.ClearPunch:
      return {
        ...state,
        punch: null,
        deleteStatus: null,
        selectedAttachment: null,
        attachments: null
      };
    case PunchActionTypes.DeletePunchSuccess:
      return {
        ...state,
        deleteStatus: action.deleteStatus
      };
    case PunchActionTypes.LoadSelectedAttachmentSuccess:
      return {
        ...state,
        selectedAttachment: action.attachment
      };
    case PunchActionTypes.ClearSelectedAttachment:
      return {
        ...state,
        selectedAttachment: null
      };
    case PunchActionTypes.UploadAttachmentsSuccess:
      return {
        ...state,
        attachments: action.attachments
      };
    case PunchActionTypes.DeleteAttachmentSuccess:
      return {
        ...state,
        punches: state.punches.map(p =>
          p.id === action.punchId ? getUpdatedPunch(action.punchId, action.attachmentId, state.punches) : p
        )
      };
    case PunchActionTypes.LoadUserPunchesSuccess:
      return {
        ...state,
        userPunches: [...action.punches].sort(punchItemSort)
      };
    case PunchActionTypes.LoadUnacceptedPunchesSuccess:
      return {
        ...state,
        unacceptedPunches: [...action.punches]
      };
    default:
      return state;
  }
}

export function getUpdatedPunch(punchId: number, attachmentId: number, punches: Punch[]) {
  const punch = punches.find(p => p.id === punchId);
  const index = punch.attachments.indexOf(punch.attachments.find(a => a.id === attachmentId));
  return {
    ...punches.find(p => p.id === punchId),
    attachments: [...punch.attachments.slice(0, index), ...punch.attachments.slice(index + 1)]
  };
}
