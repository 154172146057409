import { Action } from '@ngrx/store';

import { Permission } from '@completion/models';

export enum TestingUtilityActionTypes {
  AddTestPermissions = 'Add Test Permissions'
}

export class AddTestPermissions implements Action {
  readonly type = TestingUtilityActionTypes.AddTestPermissions;

  constructor(public permissions: Permission[]) {}
}

export type TestingUtilityActions = AddTestPermissions;
