import { NamedEntity } from './named-entity';
import { Site } from './site';

export interface Company extends NamedEntity {
  id: number;
  compNumber: string;
  sites?: Site[];
  signatureMatrixType?: string;
}

export function byCompanyNumber(a: Company, b: Company) {
  return a.compNumber?.localeCompare(b.compNumber);
}
