import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import {CompanyLoad, UpdateCompany} from '@completion/actions';
import { getCompanies } from '@completion/selectors';
import { Company } from '@completion/models';
import { MatTableDataSource } from '@angular/material/table';
import {CompanyEditorDialogComponent} from "./company-editor-dialog/company-editor-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-company-editor',
  templateUrl: './company-editor.component.html',
  styleUrls: ['./company-editor.component.scss']
})
export class CompanyEditorComponent implements OnInit, OnDestroy {
  companies: Company[] = [];
  dataSource = new MatTableDataSource<Company>();
  displayedColumns: string[] = ['name', 'companyNumber', 'signatureMatrixType', 'edit'];
  filterString: string = '';
  destroy$: Subject<void> = new Subject();

  constructor(private store: Store, private dialog: MatDialog) {
    this.store.dispatch(new CompanyLoad('M'));
  }

  ngOnInit(): void {
    this.store.select(getCompanies).pipe(takeUntil(this.destroy$)).subscribe((companies) => {
      if (companies) {
        this.companies = companies;
        this.dataSource.data = companies;
      }
    });
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterString.trim().toLowerCase();
  }

openEditDialog(company: Company): void {
  const distinctSignatureMatrixTypes = [...new Set(this.companies.map(c => c.signatureMatrixType))];
  const dialogRef = this.dialog.open(CompanyEditorDialogComponent, {
    width: '400px',
    data: { company, signatureMatrixTypes: distinctSignatureMatrixTypes }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      const updatedCompany = { ...company, signatureMatrixType: result.signatureMatrixType };
      this.store.dispatch(new UpdateCompany(updatedCompany));
    }
  });
}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
