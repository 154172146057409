import { User } from './user';
import {Attachment} from "./attachment";

export interface Certificates {
  mcc: MCCertificateGrouping[];
  rfcc: RFCCertificate[];
  rfoc: RFOCertificate[];
}

export interface MCCertificateGrouping {
  mcpId: number;
  tagsTotal: number;
  partHandover: boolean;
  certificates: MCCertificate[];
}

export interface MCCertificate {
  id: number;
  documentNumber: string;
  signedBy?: User;
  signedDate?: string;
  tagsIssued: number;
  attachments?: Attachment[];
}

export interface RFCCertificate {
  id: number;
  partNumber?: string;
  signed: boolean;
  signedDate: string;
  numberOfSignees: number;
  numberOfSigned: number;
}

export interface RFOCertificate {
  id: number;
  partNumber?: string;
  signed: boolean;
  signedDate: string;
  numberOfSignees: number;
  numberOfSigned: number;
}
