import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";

import { CommitButtonModule } from '../commit-button/commit-button.module';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploaderDirective } from './file-uploader.directive';

@NgModule({
    declarations: [FileUploaderComponent, FileUploaderDirective],
    imports: [CommitButtonModule, CommonModule, SpinnerModule, MatButtonModule, ConfirmationDialogModule, ErrorMessageModule],
    exports: [FileUploaderComponent]
})
export class FileUploaderModule {}
