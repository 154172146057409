import { FileDocumentType, JobStatus, SystemDocumentType } from '@completion/enums';
import { SignatureMatrix } from './signature-matrix';

export interface HandoverItem {
  id: number;
  title: string;
  uri: string;
}

export interface HandoverFileGeneric {
  id: number;
  documentTitle: string;
  uri: string;
}

export interface HandoverFileDrawing extends HandoverFileGeneric {
  date: string;
  documentNumber: string;
  processStatus: string;
  revisionNumber: number;
}

export interface HandoverFileProcedure extends HandoverFileGeneric {
  documentNumber: string;
  revisionNumber: number;
}

export type HandoverFile = HandoverFileGeneric | HandoverFileDrawing | HandoverFileProcedure;

export type HandoverFileUploadRequest = Partial<HandoverFile> & { file: File };

export interface HandoverRow {
  ordinal: number,
  attachments: HandoverFile[];
  contentType: SystemDocumentType | FileDocumentType;
  id: number;
  included: boolean;
  items?: HandoverItem[];
  processCode: ProcessCode;
  sectionId: number;
  status: string;
  title: string;
}

export interface HandoverRowTemplate {
  ordinal: number;
  id: number;
  title: string;
  contentType: SystemDocumentType | FileDocumentType;
  processCode: ProcessCode;
  hasAttachments: boolean;
}

export interface HandoverRowUpdate {
  handoverId: number;
  rowId: number;
  sectionId: number;
  row: HandoverRow;
}

export interface HandoverSection {
  title: string;
  id: number;
  rows: HandoverRow[];
}

export interface HandoverSectionTemplate extends Omit<HandoverSection, 'rows'> {
  rows: HandoverRowTemplate[];
}

export interface HandoverPart {
  id: number;
  partNumber: number;
  sections: HandoverSection[];
  signatureMatrix: SignatureMatrix;
  refNumber?: string;
  dossierName?: string;
  dossierGeneratedDate?: Date;
  lockedForAdmin?: boolean;
  lockedForUser?: boolean;
  hasRfoc?: boolean;
  processState?: string;
}

export interface HandoverTemplate {
  id: number;
  projectId: number;
  children?: HandoverTemplate[];
  templateName: string;
  parentId: number;
  ordinal: number;
  type?: string;
  contentType?: string;
  processCode?: string;
}

export interface DossierStatus {
  handoverId?: number;
  created?: Date;
  error?: string;
  status: JobStatus;
  signedPath?: string;
}

export interface DossierUrl {
  url: string;
}

export enum ProcessCode {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D'
}

export const ProcessCodeDictionary: Record<ProcessCode, string> = {
  [ProcessCode.A]: 'Before Installation',
  [ProcessCode.B]: 'Before RFCC',
  [ProcessCode.C]: 'Before RFOC',
  [ProcessCode.D]: 'After RFOC'
};
