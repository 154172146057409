import { ResourceAction, ResourceActionTypes } from '@completion/actions';
import { ResourceStatus, ResourceType } from '@completion/enums';
import { ResourceDescriptor, ResourceState } from '@completion/models';

// --- Initial state ----------------------------

export type ResourceMapState = Record<ResourceType, ResourceState>;

export const initialResourceState: ResourceState = {
  status: ResourceStatus.Idle,
  lastError: ''
};

export const initialState: ResourceMapState = {} as ResourceMapState;

// --- Helpers ----------------------------------

export function getResourceName(resourceDescriptor: ResourceDescriptor | string) {
  if (typeof resourceDescriptor === 'string') {
    return resourceDescriptor;
  }

  let name: string = resourceDescriptor.type;
  const identifiers = resourceDescriptor.identifiers || {};

  Object.keys(identifiers)
    .sort()
    .forEach(key => {
      name = `${name}/${key}=${identifiers[key]}`;
    });

  return name;
}

// --- Reducer ----------------------------------

export function resourceReducer(state: ResourceMapState = initialState, action: ResourceAction): ResourceMapState {
  switch (action.type) {
    case ResourceActionTypes.Start: {
      const resourceName = getResourceName(action.resourceDescriptor);
      return {
        ...state,
        [resourceName]: {
          status: ResourceStatus.InProgress,
          lastError: ''
        }
      };
    }
    case ResourceActionTypes.Success: {
      const resourceName = getResourceName(action.resourceDescriptor);
      return {
        ...state,
        [resourceName]: {
          status: ResourceStatus.Success,
          lastError: ''
        }
      };
    }
    case ResourceActionTypes.Cancel:
    case ResourceActionTypes.Reset: {
      const resourceName = getResourceName(action.resourceDescriptor);
      return {
        ...state,
        [resourceName]: {
          status: ResourceStatus.Idle,
          lastError: ''
        }
      };
    }
    case ResourceActionTypes.Failure: {
      const resourceName = getResourceName(action.resourceDescriptor);
      return {
        ...state,
        [resourceName]: {
          status: ResourceStatus.Failure,
          lastError: action.error
        }
      };
    }
    default:
      return state;
  }
}
