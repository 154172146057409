<div class="apd-dark-theme">
  <div class="row-direction">
    <mat-form-field class="item-selector" floatLabel="never">
      <mat-label>Select site</mat-label>
      <input
        matInput
        name="site"
        type="text"
        placeholder="Site"
        aria-label="site selector"
        #site="ngModel"
        [matAutocomplete]="siteAutocomplete"
        [(ngModel)]="currentSite"
      />
      <button mat-icon-button matSuffix type="button">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-autocomplete
        autoActiveFirstOption
        #siteAutocomplete="matAutocomplete"
        (optionSelected)="onSelectedSite($event.option.value)"
        [displayWith]="displayValue"
      >
        <mat-option *ngFor="let site of siteList | async" [value]="site"> {{ site.siteNumber }} - {{ site.name?.toString() }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button matSuffix mat-icon-button aria-label="Clear" type="reset" (click)="clearSiteHandler($event)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
