<div class="dialog-container">
  <div mat-dialog-content>
    <h1>Search result</h1>
    <div>
      <mat-list>
        <mat-list-item class="header">
          <span class="cp-number">CommPkg</span>
          <span class="mcp-number">McPackage</span>
          <span class="tag-number">Tag Number</span>
          <span class="check-sheet-number">Check Sheet</span>
          <span class="status-circle">Status</span>
          <span class="signed-by">Signed</span>
          <span class="verified-by">Verified</span>
          <span class="status-circle">Locked</span>
        </mat-list-item>
        <mat-list-item *ngFor="let item of data.tags">
          <span class="cp-number link" (click)="navToCp(item)">{{ item.tag.commissioningPackage?.cpNumber }}</span>
          <span class="mcp-number link" (click)="navToMcp(item)">{{ item.tag.mcPackage?.mcpNumber }}</span>
          <span class="tag-number link" (click)="navToCsa(item)">{{ item.tag.tagNumber }}</span>
          <span class="check-sheet-number link" (click)="navToCsa(item)">{{ item.checkSheet.csNumber }}</span>
          <span class="status-circle"><app-status-circle [status]="getCompletionStatus(item.status)"></app-status-circle></span>
          <span class="signed-by">{{ item.signedBy?.user.name }}</span>
          <span class="verified-by">{{ item.verifiedBy?.user.name }}</span>
          <span class="status-circle"><mat-icon *ngIf="item.certified">locked</mat-icon></span>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-stroked-button *ngIf="data.isCancelDisplay" mat-dialog-close data-testid="dialog-cancel">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="true" name="confirm-btn" color="primary" cdkFocusInitial data-testid="dialog-confirm">
      {{ data.confirmationTile }}
    </button>
  </div>
</div>
