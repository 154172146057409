<div
  class="image-div"
  appFileUploader
  (fileDropped)="onSelectedFile($event)"
  [class.is-file-available]="filePreviewURL"
  [style.height]="height"
>
  <app-spinner *ngIf="processingImage" diameter="30"></app-spinner>

  <div class="inside-div" *ngIf="!filePreviewURL">
    <span>Drop file here</span>
    <span>or</span>
    <button mat-raised-button color="primary" (click)="fileInput.click()" [disabled]="processingImage">upload file</button>
  </div>

  <img [src]="filePreviewURL" *ngIf="filePreviewURL" (error)="noImage()" (load)="loadingImage()" />
</div>
<div *ngIf="filePreviewURL">
  <button mat-button mat-stroked-button (click)="remove()" [disabled]="processingImage">Remove</button>

  <app-commit-button
    class="change"
    name="Change"
    [disabled]="processingImage"
    [buttonStatus]="buttonStatus"
    (commitButtonClicked)="fileInput.click()"
    [idleLabel]="'Change'"
  ></app-commit-button>
</div>

<app-error-message *ngIf="errorMessage" [errorMessage]="errorMessage"></app-error-message>

<input type="file" class="hidden" (change)="onSelectedFile($event.target.files)" [accept]="acceptFileFormat" #fileInput />
