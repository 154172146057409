export class Delivery {
  id: number;
  deliveryNumber: string;
  description: string;
  facility?: string;
}

export function byDeliveryNumber(a: Delivery, b: Delivery) {
  return a.deliveryNumber.localeCompare(b.deliveryNumber);
}
