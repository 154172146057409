import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationMode } from 'src/app/shared/enums/application-mode';
import { HttpRequestMethod } from 'src/app/shared/enums/http-request-method';

import { getCurrentCpId, getCurrentCsaId, getCurrentMcpId, getOfflineState } from '@completion/selectors';
import { OfflineDataService } from '@completion/services';
import { OfflineState } from '../store/reducers/offline';

export enum Request {
  getProject = 'getProject',
  getProjectTreeNodes = 'getTreeNodes',
  getCpCsa = 'getCpCsa',
  getCpCsas = 'getCpCsas',
  getMcpCsa = 'getMcpCsa',
  getMcpCsas = 'getMcpCsas',
  getCpPunches = 'getCpPunches',
  getMcpPunches = 'getMcpPunches',
  getCp = 'getCp',
  getCps = 'getCps',
  getMcp = 'getMcp',
  getSOW = 'getSOW',
  getTasks = 'getTasks',
  getCompanies = 'getCompanies',
  getUsers = 'getUsers',
  getAttachment = 'getAttachment',
  postAttachment = 'postAttachment',
  deleteAttachment = 'deleteAttachment',
  putChecksheetItemStatus = 'putChecksheetItemStatus',
  putSetRecordValue = 'putSetRecordValue',
  putSignOrVerifyChecksheet = 'putSignOrVerifyChecksheet',
  putAddOrEditComment = 'putAddOrEditComment',
  postAddPunch = 'postAddPunch',
  putUpdatePunch = 'putUpdatePunch',
  deletePunch = 'deletePunch',
  addCheckStatusItemValue = 'addCheckStatusItemValue',
  updateCheckStatusItemValue = 'updateCheckStatusItemValue',
  deleteCheckStatusItemValue = 'deleteCheckStatusItemValue',
  addCheckCheetAttachment = 'addCheckCheetAttachment',
  addSignatureMatrixEntry = 'addSignatureMatrixEntry',
  getProjectAccess = 'getProjectAccess',
  unhandled = 'unhandled'
}

@Injectable()
export class HttpInterceptorClient implements HttpInterceptor {
  applicationMode = ApplicationMode.ONLINE;
  private cpId: number;
  private mcpId: number;
  private csaId: number;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly store: Store<OfflineState>, private readonly offlineDataService: OfflineDataService) {
    this.store
      .pipe(
        select(getOfflineState),
        takeUntil(this.destroy$)
      )
      .subscribe(mode => {
        this.applicationMode = mode;
      });

    this.store
      .pipe(
        select(getCurrentCpId),
        takeUntil(this.destroy$)
      )
      .subscribe(cpId => {
        this.cpId = cpId;
      });
    this.store
      .pipe(
        select(getCurrentMcpId),
        takeUntil(this.destroy$)
      )
      .subscribe(mcpId => {
        this.mcpId = mcpId;
      });

    this.store
      .pipe(
        select(getCurrentCsaId),
        takeUntil(this.destroy$)
      )
      .subscribe(csaId => {
        this.csaId = csaId;
      });
  }

  isOnlineRequest(request: HttpRequest<any>): boolean {
    if (this.applicationMode === ApplicationMode.ONLINE) {
      return true;
    }
    else if (!!request.body && !!request.body.offlineBy) {
      return true;
    }
    else if (!!(request.body as FormData)) {
      if (request.body.has && request.body.has('offlineBy')) {
        return true;
      }
    }
    else if( request.params.has('offlineById')){
      return true;
    }
    return false;
  };

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isOnlineRequest(request)) {
      return next.handle(request);
    } else {
      return this.fetchLocalData(request);
    }
  }

  private fetchLocalData(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const requestType: Request = this.parseRequestUrl(request);
    switch (requestType) {
      case Request.getProject: {
        return this.offlineDataService.getProjects();
      }
      case Request.getTasks: {
        return this.offlineDataService.getTasks();
      }
      case Request.getProjectTreeNodes: {
        return this.offlineDataService.getTree();
      }
      case Request.getCps: {
        return this.offlineDataService.getCps();
      }
      case Request.getMcpCsa:
      case Request.getCpCsa: {
        return this.offlineDataService.fetchCSA(this.csaId);
      }
      case Request.getCpCsas: {
        return this.offlineDataService.fetchPackageData(true, false, this.cpId);
      }
      case Request.getMcpCsas: {
        return this.offlineDataService.fetchPackageData(true, true, this.mcpId);
      }
      case Request.getCp: {
        return this.offlineDataService.fetchPackageData(false, false, this.cpId);
      }
      case Request.getMcp: {
        return this.offlineDataService.fetchPackageData(false, true, this.mcpId);
      }
      case Request.getMcpPunches: {
        return this.offlineDataService.fetchMcpPunches();
      }
      case Request.getCpPunches: {
        return this.offlineDataService.fetchCpPunches();
      }
      case Request.getCompanies: {
        return this.offlineDataService.fetchCompanies();
      }
      case Request.getUsers: {
        return this.offlineDataService.fetchUsers();
      }
      case Request.getAttachment: {
        return this.offlineDataService.fetchAttachment(request);
      }
      case Request.postAttachment: {
        return this.offlineDataService.postAttachment(request);
      }
      case Request.deleteAttachment: {
        return this.offlineDataService.deleteAttachment(request);
      }
      case Request.putChecksheetItemStatus: {
        return this.offlineDataService.updateChecksheetItem(request);
      }
      case Request.putSetRecordValue: {
        return this.offlineDataService.setRecordValue(request);
      }
      case Request.putSignOrVerifyChecksheet: {
        return this.offlineDataService.signOrVerifyCheckSheet(request);
      }
      case Request.putAddOrEditComment: {
        return this.offlineDataService.addOrEditComment(request);
      }
      case Request.postAddPunch: {
        return this.offlineDataService.addPunch(request);
      }
      case Request.putUpdatePunch: {
        return this.offlineDataService.editPunch(request);
      }
      case Request.deletePunch: {
        return this.offlineDataService.deletePunch(request);
      }
      case Request.getProjectAccess: {
        return this.offlineDataService.getProjectAccess();
      }
      default:
        return of(new HttpResponse({ status: 404, body: {} }));
    }
  }

  private parseRequestUrl(request: HttpRequest<any>): Request {
    const url = request.url;
    // fetching offline data
    if (/\/api\/projects$/.test(url)) {
      return Request.getProject;
    } else if (/\/api\/projects\/[0-9]+\/project-tree-nodes/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getProjectTreeNodes;
    } else if (/\/api\/projects\/[0-9]+\/cps$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getCps;
    } else if (/\/api\/projects\/[0-9]+\/cps\/[0-9]+$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getCp;
    } else if (/\/api\/projects\/[0-9]+\/cps\/[0-9]+\/checkedTags$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getCpCsas;
    } else if (/\/api\/projects\/[0-9]+\/cps\/[0-9]+\/checkedTags\/[0-9]+$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getCpCsa;
    } else if (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getMcp;
    } else if (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/checkedTags$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getMcpCsas;
    } else if (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/checkedTags\/[0-9]+$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getMcpCsa;
    } else if (/\/api\/projects\/[0-9]+\/tasks$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getTasks;
    } else if (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/punches$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getMcpPunches;
    } else if (/\/api\/projects\/[0-9]+\/cps\/[0-9]+\/punches$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getCpPunches;
    }
    // options
    else if (/\/api\/companies$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getCompanies;
    } else if (/\/api\/projects\/[0-9]+\/users$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getUsers;
    }
    // attachments
    else if (/\/api\/projects\/[0-9]+\/punches\/[0-9]+\/attachments\/[0-9]+$/.test(url) && request.method === HttpRequestMethod.GET) {
      return Request.getAttachment;
    }
    else if (/\/api\/projects\/[0-9]+\/punches\/[0-9]+\/attachments$/.test(url) && request.method === HttpRequestMethod.POST) {
      return Request.postAttachment;
    }
    else if (/\/api\/projects\/[0-9]+\/punches\/[0-9]+\/attachments\/[0-9]+$/.test(url) && request.method === HttpRequestMethod.DELETE) {
      return Request.deleteAttachment;
    }
    // storing change log events
    else if (
      (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/checkedTags\/[0-9]+\/csItems\/[0-9]+.[0-9]+$/.test(url) ||
        /\/api\/projects\/[0-9]+\/cps\/[0-9]+\/checkedTags\/[0-9]+\/csItems\/[0-9]+.[0-9]+$/.test(url)) &&
      request.method === HttpRequestMethod.PUT
    ) {
      return Request.putChecksheetItemStatus;
    } else if (
      (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/checkedTags\/[0-9]+\/csItems\/[0-9]+.[0-9]+\/recordValues\/[0-9]+$/.test(url) ||
        /\/api\/projects\/[0-9]+\/cps\/[0-9]+\/checkedTags\/[0-9]+\/csItems\/[0-9]+.[0-9]+\/recordValues\/[0-9]+$/.test(url)) &&
      request.method === HttpRequestMethod.PUT
    ) {
      return Request.putSetRecordValue;
    } else if (
      (/\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/checkedTags\/[0-9]+$/.test(url) ||
        /\/api\/projects\/[0-9]+\/cps\/[0-9]+\/checkedTags\/[0-9]+$/.test(url)) &&
      request.method === HttpRequestMethod.PUT
    ) {
      if (request.params.has('doSign') || request.params.has('doVerify')) {
        return Request.putSignOrVerifyChecksheet;
      } else {
        return Request.putAddOrEditComment;
      }
    } else if (
      (/\/api\/projects\/[0-9]+\/cps\/[0-9]+\/checkedTags\/[0-9]+\/csItems\/[0-9]+.[0-9]+\/punches+$/.test(url) ||
        /\/api\/projects\/[0-9]+\/mcps\/[0-9]+\/checkedTags\/[0-9]+\/csItems\/[0-9]+.[0-9]+\/punches+$/.test(url)) &&
      request.method === HttpRequestMethod.POST
    ) {
      return Request.postAddPunch;
    } else if ((/\/api\/projects\/[0-9]+\/punches\/[0-9]+$/.test(url)) &&
      request.method === HttpRequestMethod.PUT) {
      return Request.putUpdatePunch;
    } else if ((/\/api\/projects\/[0-9]+\/punches\/[0-9]+$/.test(url)) &&
      request.method === HttpRequestMethod.DELETE) {
      return Request.deletePunch;
    } else if ((/\/api\/projects\/[0-9]+\/project-access/.test(url)  && request.method === HttpRequestMethod.GET)) {
      return Request.getProjectAccess;
    } else {
      return Request.unhandled;
    }
  }
}
