import { createFeatureSelector, createSelector } from "@ngrx/store";

import { DashboardState } from "../reducers/dashboard";

const getDashboardFeatureState = createFeatureSelector<DashboardState>('dashboard');


export const getDashBoardCps = createSelector(
  getDashboardFeatureState,
  (state: DashboardState) => state.cps
);

export const getDashBoardMcps = createSelector(
  getDashboardFeatureState,
  (state: DashboardState) => state.mcps
);

export const getDashboardCheckSheetAssignmentStatus = createSelector(
  getDashboardFeatureState,
  (state: DashboardState) => state.csaStatus
);

export const getUserSignatureAssignments = createSelector(
  getDashboardFeatureState,
  (state: DashboardState) => state.userSignatureAssignments
);
