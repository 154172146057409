import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {Subject} from "rxjs";
import {
  getCheckSheetAssignmentsForReport,
  getCurrentTask,
  getReportPunches,
  getResourceState
} from "@completion/selectors";
import {takeUntil} from "rxjs/operators";
import {CheckSheetAssignment, CommissioningPackage, Confirmation, Punch, Task} from "@completion/models";
import {
  LoadCheckSheetAssignmentForReportByProject,
  LoadCheckSheetAssignmentForReportByTask,
  LoadPunchesForReportByProject,
  LoadPunchesForReportByTask
} from "@completion/actions";
import {ExcelService} from "@completion/services";
import {ProjectConfigs, ResourceStatus, ResourceType} from "@completion/enums";
import {ConfirmationDialogComponent} from "../shared/confirmation-dialog";
import {MatDialog} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {LegendPosition} from "@swimlane/ngx-charts";
import {ProjectConfigService} from "../core/services/project-config.service";
import {punches} from "../shared/testing";
import {weekNumber, weeksPerYear} from 'weeknumber';
import {PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";

export interface WeeklyData {
  firstYear: number;
  firstWeek: number;
  data: Map<number, Map<number, WeekData>>;
}

export interface WeekData {
  year: number;
  week: number;
  entered: number;
  cleared: number;
  accepted: number;
}

export interface YearWeekKey {
  year: number;
  week: number;
}

export interface CpDataItem {
  cpNumber: string;
    total: number;
    totalMcCsas: number;
    rfoc: number;
    rfcc: number;
    mcc: number;
    os: number;
}


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy , AfterViewInit{

  @ViewChild('parentRef') parentRef: ElementRef;
  parentWidth: number;
  parentHeight: number;
  yScaleMin = 0;

  @ViewChild('stackWidthRef') stackWidthRef: ElementRef;
  stackWidth: number;

  weeklyChartData: ({ series: any[]; name: string })[];

  cpChartData: ({ series: any[]; name: string })[];

  @HostListener('window:resize')
  onWindowResize() {
    this.updateParentSize();
  }

  updateParentSize() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.parentWidth = Math.min(this.parentRef.nativeElement.offsetWidth, windowWidth- 150);
    this.parentHeight = Math.min(this.parentRef.nativeElement.offsetHeight, windowHeight - 150);

    this.stackWidth = Math.min(this.stackWidthRef.nativeElement.offsetWidth, windowWidth - 550) ;
    console.log(this.stackWidth);
  }

  colorScheme = {
    domain: ['#003145', '#d72311', '#ff8000', '#0d8762', '#AAAAAA']
  };

  weeklyColorScheme = {
    domain: ['#d72311', '#004d91', '#0d8762', '#AAAAAA']
  };

  punchCakeColorScheme = {
    domain: ['#ff8000' ,'#d72311']
  };
  certificateCakeColorScheme = {
    domain: ['#ff8000', '#d72311',  '#0d8762']
  };

  horizontalStackScheme = {
    domain: ['#AAAAAA', '#ADD8E6', '#007FFF', '#0d8762'] // gray, light blue, azure, green
  };


  isPunchesLoading = false;
  isCsaLoading = false;
  filterForm: FormGroup;
  punchFilterForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentTask: Task = null;
  punchData = [];
  punches: Punch[] = [];
  filteredPunches: Punch[] = [];
  checkSheetAssignments: CheckSheetAssignment[] = [];
  commissioningPackages: Map<string, Array<CheckSheetAssignment>> = new Map<string, Array<CheckSheetAssignment>>();
  cpPreparedData: any[] = [];
  filteredCsas: CheckSheetAssignment[] = [];
  punchChartData: any;
  checkSheetAssignmentChartData: any;
  selectedFilter: string;
  filterString = new FormControl();
  punchFilterString = new FormControl();
  filterStringValue: string;
  punchFilterStringValue: string;
  filterValues: string[] = [];

  weekly

  selectedPunchFilter: string;
  punchFilterValues: string[] = [];

  filters = ['System', 'SubSystem', 'CommPkg', 'McPkg', 'Tag', 'CheckSheet', 'Discipline', 'Area'];
  csaOptionsMap: Map<string, string[]> = new Map<string, string[]>();
  punchOptionsMap: Map<string, string[]> = new Map<string, string[]>();
  punchPaCount = 0;
  punchPbCount = 0;
  punchA: Punch[] = [];
  punchB: Punch[] = [];
  notClearedPunchA: Punch[] = [];
  notClearedPunchB: Punch[] = [];

  punchTypeDataNotAccepted: { name: string, value: number } [];
  punchTypeDataNotCleared: { name: string, value: number } [];
  legendPosition = LegendPosition.Below;

  csaWithUnsignedMcCertificateCount: number = 0;
  csaWithUnsignedCertificateData = [];
  csaWithoutMcCertificateCount: number = 0;
  csaWithoutMcCertificateData = [];
  csaWithSignedCertificateCount: number = 0;
  csaWithSignedCertificateData = [];

  csaMcCertificateData: { name: string, value: number }[];

  requireAccepted = false;

  punchWeeklyBurnDownData: Array<{ name: string, value: number }>;


  punchWeeklyChartData: any;




  firstYear: number;
  firstWeek: number;

  pageSize = 100;
  currentPage = 0;
  paginatedCpPreparedData: CpDataItem[] = [];

  constructor(private store: Store, private readonly excelService: ExcelService, private dialog: MatDialog, projectConfigService: ProjectConfigService, private router: Router) {
    this.filterForm = new FormGroup({
      filterString: this.filterString
    });
    this.punchFilterForm = new FormGroup({
      punchFilterString: this.punchFilterString
    });
    this.filters.forEach(filter => {
      this.csaOptionsMap.set(filter, []);
      this.punchOptionsMap.set(filter, []);
    });

    this.requireAccepted = projectConfigService.getConfig(ProjectConfigs.USE_ACCEPTANCE_ON_CERTIFICATE_MCC)
      || projectConfigService.getConfig(ProjectConfigs.USE_SIGNATURE_MATRIX_ON_MCC)
  }


  ngOnInit(): void {
    this.store.select(getCurrentTask).pipe(takeUntil(this.destroy$)).subscribe(task => {
      if (task) {
        this.currentTask = task;
      }
    });

    this.store.select(getReportPunches).pipe(takeUntil(this.destroy$)).subscribe(punches => {
      setTimeout(() => {
        if (punches && punches.length > 0) {
          this.punches = punches;
          const weeklyCounts = this.calculateWeeklyCounts(punches);
          this.generateWeeklyChartData(weeklyCounts);
          this.generateWeeklyBurndownData(weeklyCounts);
          this.isPunchesLoading = false;
          this.punches.forEach(punch => this.addPunchToOptionsMap(punch));
          this.parsePunchData(punches);
          this.setupChartOptions();
          this.setUpWeeklyChartOptions();
          this.updateParentSize();
        }
      }, 0);
    });

    this.store.select(getCheckSheetAssignmentsForReport).pipe(takeUntil(this.destroy$)).subscribe(csas => {
      if (csas && csas.length > 0) {
        this.checkSheetAssignments = csas;
        if (!this.filterStringValue || this.filterStringValue === '') {
          this.parseCheckSheetAssignmentData(csas);
        } else {
          this.applyFilterAndParse();
        }

      }
    });

    this.store.select(getResourceState, ResourceType.GetProjectPunches).pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (state) {

        if (state.status === ResourceStatus.Success) {
          this.isPunchesLoading = false;
        }
      }
    });

    this.store.select(getResourceState, ResourceType.GetProjectPunches).pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (state) {

        if (state.status === ResourceStatus.Success) {
          this.isCsaLoading = false;
        }
      }
    });

    this.filterString.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.filterStringValue = value;
        this.applyFilterAndParse();
      });

    this.punchFilterString.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.punchFilterStringValue = value;
        this.applyPunchFilterAndParse();
      });

  }
  ngAfterViewInit(): void {
    this.updateParentSize();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  parsePunchData(punches: Punch[]) {
    const dateCounts = {};
    let earliestDate = null;
    let earliestClearedDate = null;
    let runningTotalCleared = 0;
    let runningTotalAccepted = 0;
    const clearedAlreadyCounted = [];
    const acceptedAlreadyCounted = [];
    this.punchPaCount = 0;
    this.punchPbCount = 0;
    this.punchA = [];
    this.punchB = [];
    this.notClearedPunchA = [];
    this.notClearedPunchB = [];

    for (const punch of punches) {
      let createdDate = null;
      let clearedDate = null;
      let acceptedDate = null;

      if (punch.enteredBy && punch.enteredBy.timestamp) {
        createdDate = new Date(punch.enteredBy.timestamp).toISOString().split('T')[0];
        if (!earliestDate || new Date(createdDate) < new Date(earliestDate)) {
          earliestDate = createdDate;
        }
      }

      if (punch.clearedBy && punch.clearedBy.timestamp) {
        clearedDate = new Date(punch.clearedBy.timestamp).toISOString().split('T')[0];
        if (!earliestClearedDate || new Date(earliestClearedDate) < new Date(earliestClearedDate)) {
          earliestClearedDate = clearedDate;
        }
      }

      if (punch.acceptedBy && punch.acceptedBy.timestamp) {
        acceptedDate = new Date(punch.acceptedBy.timestamp).toISOString().split('T')[0];
        if (!earliestClearedDate || new Date(earliestClearedDate) < new Date(earliestClearedDate)) {
          earliestClearedDate = acceptedDate;
        }
      }

      if (!punch.acceptedBy) {
        if (punch.category === 'PA') {
          this.punchPaCount++;
          this.punchA.push(punch);
        } else if (punch.category === 'PB') {
          this.punchPbCount++;
          this.punchB.push(punch);
        }
      }

      if (!punch.clearedBy) {
        if (punch.category === 'PA') {
          this.notClearedPunchA.push(punch);
        } else if (punch.category === 'PB') {
          this.notClearedPunchB.push(punch);
        }
      }

      if (createdDate) {
        if (!dateCounts[createdDate]) {
          dateCounts[createdDate] = {created: 0, cleared: 0, accepted: 0};
        }
        dateCounts[createdDate].created++;
      }

      if (clearedDate) {
        if (!dateCounts[clearedDate]) {
          dateCounts[clearedDate] = {created: 0, cleared: 0, accepted: 0};
        }
        if (!clearedAlreadyCounted.includes(punch.id)) {
          clearedAlreadyCounted.push(punch.id);
          dateCounts[clearedDate].cleared++;
        }
      }

      if (acceptedDate) {
        if (!dateCounts[acceptedDate]) {
          dateCounts[acceptedDate] = {created: 0, cleared: 0, accepted: 0};
        }
        if (!acceptedAlreadyCounted.includes(punch.id)) {
          acceptedAlreadyCounted.push(punch.id);
          dateCounts[acceptedDate].accepted++;
        }
      }

    }
    if (!earliestDate) {
      earliestDate = earliestClearedDate;
    }

    for (const punch of punches) {
      if (!punch.enteredBy || !punch.enteredBy.timestamp) {
        dateCounts[earliestDate].created++;
      }
      if (punch.acceptedBy && !punch.acceptedBy.timestamp) {
        dateCounts[earliestDate].accepted++;
      }

      if (punch.clearedBy && !punch.clearedBy.timestamp) {
        dateCounts[earliestDate].cleared++;
      }
    }

    const sortedDates = Object.keys(dateCounts).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const burndownData = sortedDates.map(date => {
      const deltaCreated = dateCounts[date].created;
      const deltaCleared = dateCounts[date].created - dateCounts[date].cleared;
      const deltaAccepted = dateCounts[date].created - dateCounts[date].accepted;
      runningTotalCleared += deltaCleared;
      runningTotalAccepted += deltaAccepted;
      return {
        date,
        deltaCreated,
        deltaCleared,
        deltaAccepted,
        totalCleared: runningTotalCleared,
        totalAccepted: runningTotalAccepted
      };
    });
    if (this.punchPaCount > 0 || this.punchPbCount > 0) {
      this.punchTypeDataNotAccepted = [{
        name: 'PA: ' + this.punchPaCount,
        value: this.punchPaCount
      }, {name: 'PB: ' + this.punchPbCount, value: this.punchPbCount}];
    } else {
      this.punchTypeDataNotAccepted = null;
    }

    if (this.notClearedPunchA.length > 0 || this.notClearedPunchB.length > 0) {
      this.punchTypeDataNotCleared = [{
        name: 'PA: ' + this.notClearedPunchA.length,
        value: this.notClearedPunchA.length
      },
        {
          name: 'PB: ' + this.notClearedPunchB.length,
          value: this.notClearedPunchB.length
        }];
    } else {
      this.punchTypeDataNotCleared = null;
    }
    this.punchData = burndownData;
  }

  setupChartOptions() {
    this.punchChartData = [
      {
        name: 'Punches not cleared',
        series: this.punchData.map(punch => ({
          name: punch.date,
          value: punch.totalCleared
        }))
      },
      {
        name: 'Punches not accepted',
        series: this.punchData.map(punch => ({
          name: punch.date,
          value: punch.totalAccepted
        }))
      }
    ];
  }

  setUpWeeklyChartOptions() :void {
    this.punchWeeklyChartData = [
      {
        name: 'Open Punches',
        series: this.punchWeeklyBurnDownData
      }
    ];
  }

  calculateWeeklyCounts(punches: Punch[]) {

    const weeklyCountsData = this.initializeWeeklyCounts();
    const weeklyCounts = weeklyCountsData.data;
    const firstYear = weeklyCountsData.firstYear;
    const firstWeek = weeklyCountsData.firstWeek;
    this.firstYear = firstYear;
    this.firstWeek = firstWeek;

    for (const punch of punches) {
      const createdYear = punch.enteredBy ? new Date(punch.enteredBy.timestamp).getFullYear() : null;
      const clearedYear = punch.clearedBy ? new Date(punch.clearedBy.timestamp).getFullYear() : null;
      const acceptedYear = punch.acceptedBy ? new Date(punch.acceptedBy.timestamp).getFullYear() : null

      const createdWeek = punch.enteredBy && punch.enteredBy.timestamp ? weekNumber(new Date(punch.enteredBy.timestamp)) : null;
      const clearedWeek = punch.clearedBy && punch.clearedBy.timestamp ? weekNumber(new Date(punch.clearedBy.timestamp)) : null;
      const acceptedWeek = punch.acceptedBy && punch.acceptedBy.timestamp ? weekNumber(new Date(punch.acceptedBy.timestamp)) : null;

      const checkYear = 2024;
      const checkWeek = 36;

      if (weeklyCounts[createdYear] && weeklyCounts[createdYear][createdWeek]) {
        weeklyCounts[createdYear][createdWeek].entered++;
      } else {
        weeklyCounts[firstYear][firstWeek].entered++;
      }

      if (clearedWeek && weeklyCounts[clearedYear] && weeklyCounts[clearedYear][clearedWeek]) {
        weeklyCounts[clearedYear][clearedWeek].cleared++;
      } else if (punch.clearedBy) {
        weeklyCounts[firstYear][firstWeek].cleared++;
      }

      if (acceptedWeek && weeklyCounts[acceptedYear] && weeklyCounts[acceptedYear][acceptedWeek]) {
        weeklyCounts[acceptedYear][acceptedWeek].accepted++;
      } else if (punch.acceptedBy) {
        weeklyCounts[firstYear][firstWeek].accepted++;
      }
    }
    return weeklyCounts;
  }

  initializeWeeklyCounts(): WeeklyData {
    let firstEnteredWeek = null;
    let firstEnteredYear = null;
    this.punches.forEach(punch => {
      if (punch.enteredBy && punch.enteredBy.timestamp) {
        const week = weekNumber(new Date(punch.enteredBy.timestamp));
        const year = new Date(punch.enteredBy.timestamp).getFullYear();
        if (year <= firstEnteredYear || firstEnteredYear === null) {
          firstEnteredYear = year;
          if (week < firstEnteredWeek || firstEnteredWeek === null) {
            firstEnteredWeek = week;
          }
        }
      }
    });

    const weeklyCounts: Map<number, Map<number, WeekData>> = new Map<number, Map<number, WeekData>>();

    const currentWeek = weekNumber(new Date());
    const currentYear = new Date().getFullYear();
    for (let y = firstEnteredYear; y <= currentYear; y++) {
      weeklyCounts[y] = new Map<number, WeekData>();
      if (y < currentYear) {
        for (let i = 1; i <= weeksPerYear(y); i++) {
          weeklyCounts[y][i] = {year: y, week: i, entered: 0, cleared: 0, accepted: 0};
        }
      }
      if (y === currentYear) {
        for (let i = 1; i <= currentWeek; i++) {
          weeklyCounts[y][i] = {year: y, week: i, entered: 0, cleared: 0, accepted: 0};
        }
      }

    }


    return {firstYear: firstEnteredYear, firstWeek: firstEnteredWeek, data: weeklyCounts};
  }

  getCurrentWeekOfYear() {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const diff = now.getTime() - start.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    return Math.ceil((dayOfYear + 1) / 7);
  }

  getWeekNumberFromDate(date: Date) {
    // Set base date to 01.01.2020
    const baseDate = new Date(2020, 0, 1);

    // Calculate difference in milliseconds
    const diffInMilliseconds = date.getTime() - baseDate.getTime();

    // Convert milliseconds to days (1 day = 24*60*60*1000 milliseconds)
    const diffInDays = Math.floor(diffInMilliseconds / (24 * 60 * 60 * 1000));

    // Convert days to weeks and round down to nearest whole number
    return Math.floor(diffInDays / 7);
  }

  generateWeeklyChartData(weeklyCounts: Map<number, Map<number, WeekData>>) {
    const chartData = [];

    const weeksToProcess = this.createWeeksToProcess(8);
    for (const weekKey of weeksToProcess) {
      const week = weeklyCounts[weekKey.year][weekKey.week];
      const weekData = {
        name: `Week ${week.week}`,
        series: [
          {name: 'Entered', value: week.entered},
          {name: 'Cleared', value: week.cleared},
          {name: 'Accepted', value: week.accepted}
        ]
      };
      chartData.push(weekData);
    }
    this.weeklyChartData = chartData;
  }

  generateWeeklyBurndownData(weeklyCounts: Map<number,Map<number,WeekData>>) {
    const lastEightWeeks = this.createWeeksToProcess(8);
    const chartData = [];
    let totalOpenPunches = 0;
    let startWeek = lastEightWeeks[0].week;
    // Calculate the total number of open punches for each week before the startWeek;

    let weekIndex = this.firstWeek;
    let yearIndex = this.firstYear;
    while(!(weekIndex === startWeek && yearIndex === lastEightWeeks[0].year)) {
      const week = weeklyCounts[yearIndex][weekIndex];
      const openPunches = week.entered - week.accepted;
      totalOpenPunches += openPunches;
      weekIndex++;
      if (weekIndex > weeksPerYear(yearIndex)) {
        weekIndex = 1;
        yearIndex++;
      }
    }

    for (const weekKey of lastEightWeeks) {
      // Calculate the number of open punches for the week

      const week = weeklyCounts[weekKey.year][weekKey.week];
      const openPunches = week.entered - week.accepted;

      totalOpenPunches += openPunches;
      const weekData = {
        name: `Week ${startWeek++}`,
        value: totalOpenPunches
      };

      chartData.push(weekData);
    }

    this.punchWeeklyBurnDownData = chartData;


  }

  createWeeksToProcess(weeks: number) {
    const currentYear = new Date().getFullYear();
    const currentWeek = weekNumber(new Date());

    let weeksToProcess: YearWeekKey[] = [];
    if (currentWeek < weeks) {
      const weekDiff = weeks - currentWeek;
      const firstYear = currentYear - 1;
      const firstWeek = weeksPerYear(firstYear) - weekDiff + 1;
      // get the first weeks of current year and last weeks of previous year

      for (let i = firstWeek; i <= weeksPerYear(firstYear); i++) {
        weeksToProcess.push({year: firstYear, week: i});
      }
      for (let i = 1; i <= currentWeek; i++) {
        weeksToProcess.push({year: currentYear, week: i});
      }
    } else {
      for (let i = currentWeek - (weeks - 1); i <= currentWeek; i++) {
        weeksToProcess.push({year: currentYear, week: i});
      }
    }

    return weeksToProcess;
  }


  normalizeWeekNumber(weekNumber: number) {
    if (weekNumber > 52) {
      return weekNumber - 52;
    } else if (weekNumber < 1) {
      return weekNumber + 52;
    }
    return weekNumber;
  }

  refreshPunchData() {
    this.store.dispatch(new LoadPunchesForReportByTask(this.currentTask.id));
  }

  downloadPunches(): void {
    this.downLoadPunchSelection(this.filteredPunches);
  }

  downLoadPunchSelection(punches: Punch[], title?: string): void {
    let excelData: any[] = punches.map(punch => {
      return {
        PunchNo: punch.punchNumber,
        CP: punch.relatedTag.commissioningPackage.cpNumber,
        MCP: punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.mcpNumber : '',
        Area: punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.area.name : '',
        Discipline: punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.discipline ? punch.relatedTag.mcPackage.discipline.disciplineNumber : '' : '',
        Tag: punch.relatedTag.tagNumber,
        CheckSheet: punch.relatedCheckSheet ? punch.relatedCheckSheet.name : '',
        Item: punch.relatedItem ? punch.relatedItem.name : '',
        Description: punch.description,
        Notes: punch.notes ? punch.notes : '',
        DueDate: punch.due ? punch.due : '',
        Category: punch.category,
        RaisedBy: punch.raisedBy ? punch.raisedBy.name : '',
        AssignedTo: punch.assignedUser ? punch.assignedUser.name : '',
        EnteredBy: punch.enteredBy ? punch.enteredBy.user.name : '',
        EnteredByDate: punch.enteredBy ? punch.enteredBy.timestamp : '',
        ClearedBy: punch.clearedBy ? punch.clearedBy.user.name : '',
        ClearedByDate: punch.clearedBy ? punch.clearedBy.timestamp : '',
        AcceptedBy: punch.acceptedBy ? punch.acceptedBy.user.name : '',
        AcceptedByDate: punch.acceptedBy ? punch.acceptedBy.timestamp : '',
      };
    });
    if (!title) {
      title = 'punches' + Date.now().toString();
    } else {
      title = title + Date.now().toString();
    }

    this.excelService.generateExcel(excelData, title);
  }

  downloadCsas(): void {
    let csas = this.filterString && this.filterStringValue !== '' ? this.filteredCsas : this.checkSheetAssignments;
    let excelData: any[] = csas.map(csa => {
      return {
        System: csa.tag.commissioningPackage ? csa.tag.commissioningPackage.system.number + ' - ' + csa.tag.commissioningPackage.system.name : '',
        SubSystem: csa.tag.commissioningPackage ? csa.tag.commissioningPackage.subSystem.number + ' - ' + csa.tag.commissioningPackage.subSystem.name : '',
        CP: csa.tag.commissioningPackage.cpNumber,
        CpStatus: csa.tag.commissioningPackage.status.completionState.toString(),
        MCP: csa.tag.mcPackage ? csa.tag.mcPackage.mcpNumber : '',
        McpStatus: csa.tag.mcPackage ? csa.tag.mcPackage.status.completionState.toString() : '',
        Tag: csa.tag.tagNumber,
        CheckSheet: csa.checkSheet.name,
        CheckSheetStatus: csa.status.toString(),
        SignedBy: csa.signedBy ? csa.signedBy.user.name : '',
        SignedByDate: csa.signedBy ? csa.signedBy.timestamp : '',
        VerifiedBy: csa.verifiedBy ? csa.verifiedBy.user.name : '',
        VerifiedByDate: csa.verifiedBy ? csa.verifiedBy.timestamp : '',
      };
    });

    this.excelService.generateExcel(excelData, 'Checksheets: ' + Date.now().toString());
  }

  loadCsaData() {
    this.store.dispatch(new LoadCheckSheetAssignmentForReportByProject());
  }

  addCsaToOptionsMap(csa: CheckSheetAssignment) {
    for (const filter of this.filters) {
      switch (filter) {
        case 'System':
          if (csa.tag.commissioningPackage && csa.tag.commissioningPackage.system && !this.csaOptionsMap.get(filter).includes(csa.tag.commissioningPackage.system.number)) {
            this.csaOptionsMap.get(filter).push(csa.tag.commissioningPackage.system.number);
          }
          break;
        case 'SubSystem':
          if (csa.tag.commissioningPackage && csa.tag.commissioningPackage.subSystem && !this.csaOptionsMap.get(filter).includes(csa.tag.commissioningPackage.subSystem.number)) {
            this.csaOptionsMap.get(filter).push(csa.tag.commissioningPackage.subSystem.number);
          }
          break;
        case 'CommPkg':
          if (csa.tag.commissioningPackage && csa.tag.commissioningPackage.cpNumber && !this.csaOptionsMap.get(filter).includes(csa.tag.commissioningPackage.cpNumber)) {
            this.csaOptionsMap.get(filter).push(csa.tag.commissioningPackage.cpNumber);
          }
          break;
        case 'McPkg':
          if (csa.tag.mcPackage && csa.tag.mcPackage.mcpNumber && !this.csaOptionsMap.get(filter).includes(csa.tag.mcPackage.mcpNumber)) {
            this.csaOptionsMap.get(filter).push(csa.tag.mcPackage.mcpNumber);
          }
          break;
        case 'Tag':
          if (csa.tag.tagNumber && !this.csaOptionsMap.get(filter).includes(csa.tag.tagNumber)) {
            this.csaOptionsMap.get(filter).push(csa.tag.tagNumber);
          }
          break;
        case 'CheckSheet':
          if (csa.checkSheet.csNumber && !this.csaOptionsMap.get(filter).includes(csa.checkSheet.csNumber)) {
            this.csaOptionsMap.get(filter).push(csa.checkSheet.csNumber);
          }
          break;
        case 'Discipline':
          if (csa.tag.mcPackage && csa.tag.mcPackage.discipline && csa.tag.mcPackage.discipline.disciplineNumber && !this.csaOptionsMap.get(filter).includes(csa.tag.mcPackage.discipline.disciplineNumber)) {
            this.csaOptionsMap.get(filter).push(csa.tag.mcPackage.discipline.disciplineNumber);
          }
          break;
        case 'Area':
          if (csa.tag.mcPackage && csa.tag.mcPackage.area && csa.tag.mcPackage.area.name && !this.csaOptionsMap.get(filter).includes(csa.tag.mcPackage.area.name)) {
            this.csaOptionsMap.get(filter).push(csa.tag.mcPackage.area.name);
          }
          break;
        default:
          break;
      }
    }
  }

  parseCheckSheetAssignmentData(csas: CheckSheetAssignment[]) {
    const dateCounts = {};
    let earliestDate = null;
    let runningTotal = csas.length;

    for (const csa of csas) {
      let signedDate = null;
      let verifiedDate = null;

      if (csa.mcCertificateIssued && !csa.mcCertificateSigned) {
        this.csaWithUnsignedMcCertificateCount++;
        this.csaWithUnsignedCertificateData.push(csa);
      } else if (!csa.mcCertificateIssued) {
        this.csaWithoutMcCertificateCount++;
        this.csaWithoutMcCertificateData.push(csa);
      } else if (csa.mcCertificateIssued && csa.mcCertificateSigned) {
        this.csaWithSignedCertificateCount++;
        this.csaWithSignedCertificateData.push(csa);
      }

      this.addCsaToOptionsMap(csa);

      if (csa.signedBy && csa.signedBy.timestamp) {
        signedDate = new Date(csa.signedBy.timestamp).toISOString().split('T')[0];
        if (!earliestDate || new Date(signedDate) < new Date(earliestDate)) {
          earliestDate = signedDate;
        }
      }

      if (csa.verifiedBy && csa.verifiedBy.timestamp) {
        verifiedDate = new Date(csa.verifiedBy.timestamp).toISOString().split('T')[0];
      }


      if (verifiedDate) {
        if (!dateCounts[verifiedDate]) {
          dateCounts[verifiedDate] = {created: 0, closed: 0};
        }
        dateCounts[verifiedDate].closed++;
      }
    }


    const sortedDates = Object.keys(dateCounts).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const burndownData = sortedDates.map(date => {
      const delta = 0 - dateCounts[date].closed;
      runningTotal += delta;
      return {
        date,
        delta,
        total: runningTotal
      };
    });

    // Format data for ngx-charts
    const chartData = [
      {
        name: 'CheckSheetAssignments',
        series: burndownData.map(data => ({
          name: data.date,
          value: data.total
        }))
      }
    ];

    this.csaMcCertificateData = [
      {
        name: 'CSA with unsigned MCC',
        value: this.csaWithUnsignedMcCertificateCount
      },
      {
        name: 'CSA without MCC',
        value: this.csaWithoutMcCertificateCount
      },
      {
        name: 'CSA with signed MCC',
        value: this.csaWithSignedCertificateCount
      }
    ];

    this.checkSheetAssignmentChartData = chartData;
  }

  private dialogMessage(): Confirmation {
    return {
      title: `No filter parameter selected!`,
      message: 'You need to select a filter parameter to apply a filter.',
      isCancelDisplay: false,
      confirmationTile: 'Got it!',
    };
  }

  onFilterChange(event: any) {
    this.selectedFilter = event.value;
    this.populateFilterValues();
    this.applyFilterAndParse();
  }

  applyFilterAndParse() {
    if (!this.selectedFilter) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: this.dialogMessage()
      });
      return;
    }
    if(!this.filterStringValue){
      this.filteredCsas = this.checkSheetAssignments;
    }else {
      this.filteredCsas = this.checkSheetAssignments
        .filter(csa => csa.tag.commissioningPackage && csa.tag.mcPackage)
        .filter(csa => {
          // Check if the selected property exists and if it includes the filter string
          switch (this.selectedFilter) {
            case 'System':
              return csa.tag.commissioningPackage.system.number.includes(this.filterStringValue);
            case 'SubSystem':
              return csa.tag.commissioningPackage.subSystem.number.includes(this.filterStringValue);
            case 'CommPkg':
              return csa.tag.commissioningPackage.cpNumber.includes(this.filterStringValue);
            case 'McPkg':
              return csa.tag.mcPackage ? csa.tag.mcPackage.mcpNumber.includes(this.filterStringValue) : false;
            case 'Tag':
              return csa.tag.tagNumber.includes(this.filterStringValue);
            case 'CheckSheet':
              return csa.checkSheet.csNumber.includes(this.filterStringValue);
            case 'Discipline':
              return csa.tag.mcPackage ? csa.tag.mcPackage.discipline.disciplineNumber.includes(this.filterStringValue) : false;
            case 'Area':
              return csa.tag.mcPackage ? csa.tag.mcPackage.area.name.includes(this.filterStringValue) : false;
            default:
              return false;
          }
        });
    }

    this.parseCheckSheetAssignmentData(this.filteredCsas);
  }

  refreshCsaData() {
    if (this.currentTask) {
      this.store.dispatch(new LoadCheckSheetAssignmentForReportByTask());
    }
    this.store.dispatch(new LoadCheckSheetAssignmentForReportByProject());
  }


  private populateFilterValues() {
    this.filterValues = this.csaOptionsMap.get(this.selectedFilter).sort();

    this.filterStringValue = '';
  }

  populatePunchFilterValues() {
    this.punchFilterValues = this.punchOptionsMap.get(this.selectedPunchFilter).sort();
    this.filterStringValue = '';
  }

  applyPunchFilterAndParse() {
    if (!this.selectedPunchFilter) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: this.dialogMessage()
      });
      return;
    }
    const filteredPunches = this.punches.filter(punch => {
      switch (this.selectedPunchFilter) {
        case 'System':
          return punch.relatedTag.commissioningPackage.system.number.includes(this.punchFilterStringValue);
        case 'SubSystem':
          return punch.relatedTag.commissioningPackage.subSystem.number.includes(this.punchFilterStringValue);
        case 'CommPkg':
          return punch.relatedTag.commissioningPackage.cpNumber.includes(this.punchFilterStringValue);
        case 'McPkg':
          return punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.mcpNumber.includes(this.punchFilterStringValue) : false;
        case 'Tag':
          return punch.relatedTag.tagNumber.includes(this.punchFilterStringValue);
        case 'CheckSheet':
          return punch.relatedCheckSheet ? punch.relatedCheckSheet.name.includes(this.punchFilterStringValue) : false;
        case 'Discipline':
          return punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.discipline.disciplineNumber.includes(this.punchFilterStringValue) : false;
        case 'Area':
          return punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.area.name.includes(this.punchFilterStringValue) : false;
        default:
          return false;
      }
    });
    this.filteredPunches = filteredPunches;
    this.parsePunchData(filteredPunches);
    this.setupChartOptions();
  }

  addPunchToOptionsMap(punch: Punch) {
    for (const filter of this.filters) {
      switch (filter) {
        case 'System':
          if (punch.relatedTag.commissioningPackage && punch.relatedTag.commissioningPackage.system && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.commissioningPackage.system.number)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.commissioningPackage.system.number);
          }
          break;
        case 'SubSystem':
          if (punch.relatedTag.commissioningPackage && punch.relatedTag.commissioningPackage.subSystem && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.commissioningPackage.subSystem.number)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.commissioningPackage.subSystem.number);
          }
          break;
        case 'CommPkg':
          if (punch.relatedTag.commissioningPackage && punch.relatedTag.commissioningPackage.cpNumber && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.commissioningPackage.cpNumber)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.commissioningPackage.cpNumber);
          }
          break;
        case 'McPkg':
          if (punch.relatedTag.mcPackage && punch.relatedTag.mcPackage.mcpNumber && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.mcPackage.mcpNumber)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.mcPackage.mcpNumber);
          }
          break;
        case 'Tag':
          if (punch.relatedTag.tagNumber && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.tagNumber)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.tagNumber);
          }
          break;
        case 'CheckSheet':
          if (punch.relatedCheckSheet && punch.relatedCheckSheet.name && !this.punchOptionsMap.get(filter).includes(punch.relatedCheckSheet.name)) {
            this.punchOptionsMap.get(filter).push(punch.relatedCheckSheet.name);
          }
          break;
        case 'Discipline':
          if (punch.relatedTag.mcPackage && punch.relatedTag.mcPackage.discipline && punch.relatedTag.mcPackage.discipline.disciplineNumber && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.mcPackage.discipline.disciplineNumber)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.mcPackage.discipline.disciplineNumber);
          }
          break;
        case 'Area':
          if (punch.relatedTag.mcPackage && punch.relatedTag.mcPackage.area && punch.relatedTag.mcPackage.area.name && !this.punchOptionsMap.get(filter).includes(punch.relatedTag.mcPackage.area.name)) {
            this.punchOptionsMap.get(filter).push(punch.relatedTag.mcPackage.area.name);
          }
          break;
        default:
          break;
      }
    }
  }

  onSelectNotAccepted($event: any) {
    if ($event.name.includes('PA') && this.punchA.length > 0) {
      this.downLoadPunchSelection(this.punchA, 'PA-not-accepted-');
    } else if ($event.name.includes('PB') && this.punchB.length > 0) {
      this.downLoadPunchSelection(this.punchB, 'PB-not-accepted-');
    }
  }

  onSelectNotCleared($event: any) {
    if ($event.name.includes('PA') && this.notClearedPunchA.length > 0) {
      this.downLoadPunchSelection(this.notClearedPunchA, 'PA-not-cleared-');
    } else if ($event.name.includes('PB') && this.notClearedPunchB.length > 0) {
      this.downLoadPunchSelection(this.notClearedPunchB, 'PB-not-cleared-');
    }
  }

  loadPunchData() {
    if (this.currentTask) {
      this.store.dispatch(new LoadPunchesForReportByTask(this.currentTask.id));
    } else {
      this.store.dispatch(new LoadPunchesForReportByProject());
    }
    this.isPunchesLoading = true;
  }

  parseToGetCommPackageData() {
    this.cpPreparedData = [];
    this.checkSheetAssignments.forEach(csa => {
      if (csa.tag.commissioningPackage) {
        if (this.commissioningPackages.has(csa.tag.commissioningPackage.cpNumber)) {
          this.commissioningPackages.get(csa.tag.commissioningPackage.cpNumber).push(csa);
        } else {
          this.commissioningPackages.set(csa.tag.commissioningPackage.cpNumber, [csa]);
        }
      }
    });

    this.commissioningPackages.forEach((csas, cpNumber, id) => {
      const cpData:CpDataItem = {
        cpNumber: cpNumber,

        total: csas.length,
        totalMcCsas: csas.filter(csa => csa.checkSheet.type === 'MC').length,
        rfoc: 0,
        rfcc: 0,
        mcc: 0,
        os: 0,
      };


      csas.forEach(csa => {
        if (csa.rfoCertificateIssued) {
          console.log("Found rfo certificate" + csa.rfoCertificateIssued);
          cpData.rfoc++;
        } else if(csa.rfcCertificateIssued) {
          console.log("Found rfc certificate" + csa.rfcCertificateIssued);
          cpData.rfcc++;
        }
        else if(csa.mcCertificateIssued) {
          console.log("Found mc certificate" + csa.mcCertificateIssued);
          cpData.mcc++;

        }
        else  {
          cpData.os++;

        }

      });
      this.cpPreparedData.push(cpData);
    });
    this.cpPreparedData = this.sortCpDataByRfoc(this.cpPreparedData);
    this.updatePaginatedData();
    this.transformCpDataToChartData();
    this.updateParentSize();
  }

  transformCpDataToChartData(): void {

    this.paginatedCpPreparedData.sort((a, b) => {
      const rfocDiff = (b.rfoc / b.total) - (a.rfoc / a.total);
      if (rfocDiff !== 0) return rfocDiff;

      const rfccDiff = (b.rfcc / b.total) - (a.rfcc / a.totalMcCsas);
      if (rfccDiff !== 0) return rfccDiff;

      return (b.mcc / b.total) - (a.mcc / a.totalMcCsas);
    });
    this.cpChartData = this.paginatedCpPreparedData.map(item => ({
      name: item.cpNumber,
      series: [
        { name: 'os', value: (item.os / item.total) * 100 },
        { name: 'mcc', value: (item.mcc / item.total) * 100 },
        { name: 'rfcc', value: (item.rfcc / item.total) * 100 },
        { name: 'rfoc', value: (item.rfoc / item.total) * 100 }
      ]
    }));
  }

  sortCpDataByRfoc(cpData: CpDataItem[]): CpDataItem[] {
    return cpData.sort((a, b) => {
      const rfocDiff = (b.rfoc / b.total) - (a.rfoc / a.total);
      if (rfocDiff !== 0) return rfocDiff;

      const rfccDiff = (b.rfcc / b.total) - (a.rfcc / a.total);
      if (rfccDiff !== 0) return rfccDiff;

      return (b.mcc / b.total) - (a.mcc / a.total);
    });
  }

  isCheckSheetAssignmentsEmpty(): boolean {
    return !this.checkSheetAssignments || this.checkSheetAssignments.length === 0;
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedData();
    this.transformCpDataToChartData();
  }

  updatePaginatedData() {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedCpPreparedData = this.cpPreparedData.slice(start, end);
  }

  onBarClick(event: any) {
    console.log(event);
    let cpId = this.commissioningPackages.get(event.series)[0].tag.commissioningPackage.id;

    this.router.navigate(['/cp', cpId]);

  }

}
