import { PackageCommonAction, PackageCommonActionTypes } from '@completion/actions';
import { CheckSheetAssignment, DuplicationErrors } from '@completion/models';
import { sortByCheckSheet, sortByTag } from '@completion/utils';

export const initialState: PackageCommonState = {
  mcpScopeOfWork: [],
  cpScopeOfWork: [],
  latestError: null,
  checkSheetAssignments: {
    selectedIds: null,
    responseStatus: null,
    duplicationErrors: null
  }
};

export interface PackageCommonState {
  mcpScopeOfWork: CheckSheetAssignment[];
  cpScopeOfWork: CheckSheetAssignment[];
  latestError: string;
  checkSheetAssignments: {
    selectedIds: number[];
    responseStatus: number;
    duplicationErrors: DuplicationErrors;
  };
}

export function packageCommonReducer(state: PackageCommonState = initialState, action: PackageCommonAction): PackageCommonState {
  switch (action.type) {
    case PackageCommonActionTypes.CpScopeOfWorkSuccess:
      const cpScopeOfWork = [...action.sow].sort((a, b) => sortByTag(a, b) || sortByCheckSheet(a, b));

      return {
        ...state,
        cpScopeOfWork
      };
    case PackageCommonActionTypes.McpScopeOfWorkSuccess:
      const mcpScopeOfWork = [...action.sow].sort((a, b) => sortByTag(a, b) || sortByCheckSheet(a, b));

      return {
        ...state,
        mcpScopeOfWork
      };
    case PackageCommonActionTypes.ClearScopeOfWork:
      return {
        ...state,
        cpScopeOfWork: null,
        mcpScopeOfWork: null
      };
    case PackageCommonActionTypes.DuplicateTagsFailure:
      return {
        ...state,
        checkSheetAssignments: {
          ...state.checkSheetAssignments,
          responseStatus: null,
          duplicationErrors: action.errors
        }
      };
    case PackageCommonActionTypes.DuplicateTagsSuccess:
      return {
        ...state,
        latestError: null,
        checkSheetAssignments: {
          ...state.checkSheetAssignments,
          responseStatus: action.responseStatus,
          duplicationErrors: null
        }
      };
    case PackageCommonActionTypes.DuplicateTagsClearState:
      return {
        ...state,
        latestError: null,
        checkSheetAssignments: {
          selectedIds: null,
          responseStatus: null,
          duplicationErrors: null
        }
      };
    case PackageCommonActionTypes.PCLoadFailure:
      return {
        ...state,
        latestError: action.error
      };
    case PackageCommonActionTypes.SelectCheckSheetAssignmentIds:
      return {
        ...state,
        checkSheetAssignments: {
          ...state.checkSheetAssignments,
          selectedIds: action.selectedIds
        }
      };
    default:
      return state;
  }
}
