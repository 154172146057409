import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SideNavigationModule } from '../side-navigation/side-navigation.module';
import { effects } from './store/effects';
import { reducers } from './store/reducers';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    SideNavigationModule,
    MatSnackBarModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false
      },
    }),
    EffectsModule.forRoot(effects)
  ],
  exports: [SideNavigationModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
