import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectState } from '../reducers/project';

const getProjectFeatureState = createFeatureSelector<ProjectState>('project');

export const getProjects = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state.projects
);

export const getCurrentProject = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state.currentProject
);

export const getCurrentConfig = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state.currentProjectConfigs
);

export const getProjectTreeNodes = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state.treeNodes
);

export const getDefaultSignatureMatrices = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state.currentProject?.signatureMatrixTemplates
);
