import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CertificateType, ResourceType } from '@completion/enums';
import {
  CertifiableMcp,
  Certificates,
  MCCDetails,
  MccIssueOption,
  RFCCDetails,
  RfccIssueOption,
  RFOCDetails,
  RfocIssueOptionsGrouping,
  SignatureMatrix,
  User
} from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CertificateService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.CertificateGetCertificates)
  getCertificates(projectId: number, cpId: number): Observable<Certificates> {
    return this.http.get<Certificates>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates`);
  }

  @Resource(ResourceType.CertificateGetMccIssueOptions)
  getMccIssueOptions(projectId: number, cpId: number): Observable<MccIssueOption[]> {
    return this.http.get<MccIssueOption[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/selections/mcc`);
  }

  @Resource(ResourceType.CertificateGetRfccIssueOptions)
  getRfccIssueOptions(projectId: number, cpId: number): Observable<RfccIssueOption[]> {
    return this.http.get<RfccIssueOption[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/selections/rfcc`);
  }

  @Resource(ResourceType.CertificateGetRfocIssueOptions)
  getRfocIssueOptions(projectId: number, cpId: number): Observable<RfocIssueOptionsGrouping[]> {
    return this.http.get<RfocIssueOptionsGrouping[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/selections/rfoc`);
  }

  @Resource(ResourceType.CertificateIssueMccs)
  issueMccs(projectId: number, cpId: number, data: CertifiableMcp[]): Observable<HttpResponse<void>> {
    return this.http.post<void>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/mcc`, data, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.CertificateIssueRfcc)
  issueRfcc(projectId: number, cpId: number, data: number[]): Observable<HttpResponse<void>> {
    return this.http.post<void>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/rfcc`, data, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.CertificateIssueRfoc)
  issueRfoc(projectId: number, cpId: number, data: number[]): Observable<HttpResponse<void>> {
    return this.http.post<void>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/rfoc`, data, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.CertificateGetCertificateDetails)
  getCertificateDetails(
    projectId: number,
    cpId: number,
    certificateType: CertificateType,
    certificateId: number
  ): Observable<MCCDetails | RFCCDetails | RFOCDetails> {
    return this.http.get<MCCDetails>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/${certificateType.toLowerCase()}/${certificateId}`
    );
  }

  @Resource(ResourceType.CertificateSaveCertificateComment)
  saveCertificateComment(
    projectId: number,
    cpId: number,
    certificateId: number,
    certificateType: CertificateType,
    comment: string
  ): Observable<{ comment: string }> {
    return this.http.put<{ comment: string }>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/${certificateType.toLowerCase()}/${certificateId}`,
      { comment }
    );
  }

  @Resource(ResourceType.CertificateUpdateMccSignature)
  updateMccSignature(
    projectId: number,
    cpId: number,
    certificateId: number,
    signing: boolean
  ): Observable<{
    signedBy: User;
    signedDate: string;
  }> {
    return this.http.put<{
      signedBy: User;
      signedDate: string;
    }>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/mcc/${certificateId}/${signing ? 'sign' : 'unsign'}`, {});
  }

  @Resource(ResourceType.CertificateUpdateMccAccepted)
  updateMccAccepted(
    projectId: number,
    cpId: number,
    certificateId: number,
    accepted: boolean
  ): Observable<{
    acceptedBy: User;
    acceptedDate: string;
  }> {
    return this.http.put<{
      acceptedBy: User;
      acceptedDate: string;
    }>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/mcc/${certificateId}/${accepted ? 'accept' : 'revoke'}`, {});
  }

  @Resource(ResourceType.CertificateUpdateRfccSignature)
  updateRfccSignature(projectId: number, cpId: number, certificateId: number, signing: boolean): Observable<SignatureMatrix> {
    return this.http.put<SignatureMatrix>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/rfcc/${certificateId}/${signing ? 'sign' : 'unsign'}`,
      {}
    );
  }

  @Resource(ResourceType.CertificateUpdateRfocSignature)
  updateRfocSignature(projectId: number, cpId: number, certificateId: number, signing: boolean): Observable<SignatureMatrix> {
    return this.http.put<SignatureMatrix>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/rfoc/${certificateId}/${signing ? 'sign' : 'unsign'}`,
      {}
    );
  }

  @Resource(ResourceType.UploadCertificateAttachment)
  uploadMccAttachment(projectId: number, cpId: number, file: File, certificateId: number): Observable<HttpResponse<MCCDetails>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<MCCDetails>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/mcc/${certificateId}/upload`, formData, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.DeleteMccAttachment)
  deleteMccAttachment(projectId: number, cpId: number, certificateId: number, attachmentId: number): Observable<HttpResponse<MCCDetails>> {
    return this.http.delete<MCCDetails>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/mcc/${certificateId}/attachment/${attachmentId}`, {
      observe: 'response'
    });
  }
}
