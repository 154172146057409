import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteSignatureRole, ProjectsLoad, SignatureRoleLoad } from '@completion/actions';
import { Confirmation, SignatureRole } from '@completion/models';
import { getCurrentProject, getSignatureRoles } from '@completion/selectors';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog';
import { SignatureRoleEditDialogComponent } from '../signature-role-edit-dialog/signature-role-edit-dialog.component';
import { L } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-signature-role',
  templateUrl: './signature-role.component.html',
  styleUrls: ['./signature-role.component.scss']
})
export class SignatureRoleComponent implements OnInit {


  dataSource: SignatureRole[] = [];
  displayedColumns = ['name', 'description', 'groupEmail', 'edit', 'delete'];
  private readonly destroy$ = new Subject<void>();
  constructor(private readonly store: Store, private readonly dialog: MatDialog) {
    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(
      (project) => {
        if (project) {
          this.store.dispatch(new SignatureRoleLoad());
        }
        else {
          this.store.dispatch(new ProjectsLoad());
        }
      }
    );

  }

  ngOnInit(): void {

    this.store.select(getSignatureRoles).pipe(takeUntil(this.destroy$)).subscribe(
      (signatureRoles) => {
        if (signatureRoles) {
          this.dataSource = signatureRoles;
        }
      }
    );

  }

  isLoading() {
    return false;
  }

  addSignatureRole() {
    this.dialog.open(SignatureRoleEditDialogComponent, {
      data: {
        signatureRole: {
          name: '',
          description: ''
        } as SignatureRole
      }
    }).afterClosed().subscribe((result) => {
      if (result) {

      }
    });
  }


  editSignatureRole(signatureRole: SignatureRole) {
    this.dialog.open(SignatureRoleEditDialogComponent, {
      data: {
        signatureRole: signatureRole
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  deleteSignatureRole(signatureRole: SignatureRole) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Signature Role',
        message: `Are you sure you want to delete ${signatureRole.name}?`,
        confirmationTile: 'Delete',
        cancelTitle: 'Cancel',
        isCancelDisplay: true

      } as Confirmation
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new DeleteSignatureRole(signatureRole));
      }
    });
  }

}
