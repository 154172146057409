import { ComponentFactoryResolver, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ResourceCancel } from '@completion/actions';
import { ResourceType } from '@completion/enums';
import { State } from '@completion/reducers';
import { getResourceState } from '@completion/selectors';
import { ResourceWrapperComponent } from './resource-wrapper/resource-wrapper.component';

export interface ResourceStatusConfig {
  hasData?: boolean;
  customNoDataComponent?: TemplateRef<any>;
  resourceType: ResourceType;
}

@Directive({ selector: '[appResourceStatus]' })
export class ResourceStatusDirective implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  currentResource: ResourceType;
  error: string;

  @Input('appResourceStatus')
  public set resourceStatus(config: ResourceStatusConfig) {
    if (!config.resourceType) {
      throw new Error('*appResourceStatus directive: resourceType must be specified');
    }

    // `hasData` needs to be an explicit boolean.
    // If `hasData` references a falsy/truthy object, it will trigger the child component of the directive to rerender if the object changes.
    // There is also a discussion about OnPush change detection strategy for structural directives,
    // but it seems it went nowhere: https://github.com/angular/angular/issues/19098.
    if (config.hasData && typeof config.hasData !== 'boolean') {
      throw new Error('*appResourceStatus directive: hasData must be of type boolean | undefined');
    }

    // if `hasData` is not provided, we assume it to be true
    const appliedConfig: ResourceStatusConfig = { hasData: true, ...config };
    this.currentResource = appliedConfig.resourceType;

    this.store
      .pipe(
        select(getResourceState, config.resourceType),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        this.viewContainer.clear();
        const component = this.componentFactoryResolver.resolveComponentFactory(ResourceWrapperComponent);
        const componentRef = this.viewContainer.createComponent(component);
        componentRef.instance.customNoDataComponent = appliedConfig.customNoDataComponent;
        componentRef.instance.status = state.status;
        componentRef.instance.error = state.lastError;
        componentRef.instance.hasData = appliedConfig.hasData;
        componentRef.instance.templateRef = this.templateRef;
      });
  }

  constructor(
    private readonly store: Store<State>,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.store.dispatch(new ResourceCancel(this.currentResource));
  }
}
