import { ApplicationMode } from '@completion/enums';

import { OfflineAction, OfflineActionTypes } from '../../actions/offline.action';

export const initialState: OfflineState = {
  applicationMode: ApplicationMode.ONLINE
};

export interface OfflineState {
  applicationMode: ApplicationMode;
}

export function offlineReducer(state: OfflineState = initialState, action: OfflineAction): OfflineState {
  let applicationMode: ApplicationMode;
  switch (action.type) {
    case OfflineActionTypes.SetOffline:
      applicationMode = ApplicationMode.OFFLINE;
      return {
        ...state,
        applicationMode
      };
    case OfflineActionTypes.SetOnline: {
      applicationMode = ApplicationMode.ONLINE;
      return {
        ...state,
        applicationMode
      };
    }
    default:
      return state;
  }
}
