import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OfflineDownloadScope } from '@completion/models';

const getOfflineDownloadsState = createFeatureSelector<OfflineDownloadScope>('offlineDownloads');

export const getOfflineDownloads = createSelector(
  getOfflineDownloadsState,
  (state: OfflineDownloadScope)  => state
)
