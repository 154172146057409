<mat-tab-group>
  <mat-tab label="User List">
    <ng-template matTabContent>
      <app-user-list></app-user-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Signature Role Matrix">
    <ng-template matTabContent>
      <app-signature-role-matrix></app-signature-role-matrix>
    </ng-template>
  </mat-tab>
</mat-tab-group>
