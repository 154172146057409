import { CertificateEffect } from './certificate.effect';
import { CpCheckSheetEffect } from './cp-checksheet.effect';
import { CommPkgsEffects } from './cp.effect';
import { DashboardEffect } from "./dashboard.effect";
import { DeliveryEffect } from './delivery.effect';
import { HandoverDocsEffect } from './handover-docs.effect';
import { HandoverDossierEffect } from './handover-dossier.effect';
import { HandoverTemplateEffect } from './handover-template.effect';
import { HandoverEffect } from './handover.effect';
import { McpCheckSheetEffect } from './mcp-checksheet.effect';
import { McpEffect } from './mcp.effect';
import { OfflineDownloadsEffects } from './offline-downloads.effect';
import { OfflineEffects } from './offline.effect';
import { OptionsEffects } from './options.effect';
import { PackageCommonEffects } from './package-common.effect';
import { ProjectAccessEffect } from './project-access.effect';
import { ProjectEffect } from './project.effect';
import { PunchEffect } from './punch.effect';
import { SignatureMatrixEffect } from './signature-matrix.effect';
import { TagEffect } from './tag.effect';
import { TaskEffect } from './task.effect';
import { UserEffects } from './user.effect';
import { ReportEffect } from "./report.effect";

export const effects = [
  CertificateEffect,
  CpCheckSheetEffect,
  CommPkgsEffects,
  DeliveryEffect,
  HandoverDossierEffect,
  HandoverEffect,
  HandoverTemplateEffect,
  HandoverDocsEffect,
  McpEffect,
  McpCheckSheetEffect,
  OptionsEffects,
  OfflineEffects,
  OfflineDownloadsEffects,
  PackageCommonEffects,
  ProjectEffect,
  ProjectAccessEffect,
  PunchEffect,
  SignatureMatrixEffect,
  TagEffect,
  TaskEffect,
  UserEffects,
  DashboardEffect,
  ReportEffect,
];

export * from './certificate.effect';
export * from './cp-checksheet.effect';
export * from './mcp-checksheet.effect';
export * from './cp.effect';
export * from './delivery.effect';
export * from './handover.effect';
export * from './handover-docs.effect';
export * from './handover-dossier.effect';
export * from './handover-template.effect';
export * from './options.effect';
export * from './package-common.effect';
export * from './project.effect';
export * from './punch.effect';
export * from './signature-matrix.effect';
export * from './tag.effect';
export * from './task.effect';
export * from './user.effect';
export * from './offline.effect';
export * from './offline-downloads.effect';
export * from './project-access.effect';
export * from './dashboard.effect';
export * from './report.effect';
