<div class="grey-title">
  <span>&nbsp;</span>
  <h2 *ngIf="signatureRole.id; else addRole" mat-dialog-title>Edit Signature Matrix</h2>
  <ng-template #addRole>
    <h2 mat-dialog-title>Add Signature Role</h2>
  </ng-template>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSave()" id="ngForm" autocomplete="off">
    <div class="table">
      <div class="row">
        <div class="cell">
          <mat-form-field appearance="fill" class="no-validation role">
            <mat-label>Role:</mat-label>
            <input matInput formControlName="name" type="text" name="name" [(ngModel)]="signatureRole.name" required="true" />
            <mat-error *ngIf="form.controls['name'].hasError('required')">Role is required</mat-error>
          </mat-form-field>
        </div>
        <div class="cell">
          <mat-form-field appearance="fill" class="no-validation verified">
            <mat-label>Validation:</mat-label>
            <input matInput formControlName="description" type="text" name="description" [(ngModel)]="signatureRole.description" />
            <mat-error *ngIf="form.controls['description'].hasError('required')">Verified is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="cell">
          <mat-form-field appearance="fill" class="no-validation role">
            <mat-label>Group mail:</mat-label>
            <input matInput formControlName="groupEmail" type="text" name="groupEmail" [(ngModel)]="signatureRole.groupEmail" />
          </mat-form-field>
        </div>
        <div class="cell">
          &nbsp;
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="error-message">
    <app-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-error-message>
  </div>

  <div>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <app-commit-button
      [isRoundButton]="true"
      [buttonStatus]="buttonStatus"
      buttonTestId="userEditDialogSave"
      (commitButtonClicked)="onSave()"
      idleLabel="Save"
    ></app-commit-button>
  </div>
</mat-dialog-actions>
