export enum CompletionStatus {
  OS = 'OS',
  NA = 'NA',
  OK = 'OK',
  PB = 'PB',
  PA = 'PA'
}

export  enum ResetLevel {
  RFC = 'RFC'  , // removes RFO
  MC = 'MC', // removed RFO and RFC
  CP_CSA = 'CP_CSA',  // Removes RFO,   RFC and resets signatures on CP CSAs
  TO_MC = 'TO_MC', // Removes RFO and RFC and MC certificates
  MC_CSA = 'MC_CSA', // removed RFO, RFC, MC and resets signatures on MC CSAs
  ALL = 'ALL', // removes RFO, RFC, MC and resets signatures on all CSAs including items.
}
