import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProjectImageType, ResourceType } from '@completion/enums';
import { Attachment, CheckRecordValue, Delivery, MipsCheckSheetItemValue, Project, ProjectConfig, Task, TreeNode } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.GetProjects)
  public getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.baseUrl}/projects`);
  }

  @Resource(ResourceType.GetTasks)
  public getTasks(projectId: number): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.baseUrl}/projects/${projectId}/tasks`);
  }

  @Resource(ResourceType.GetDeliveries)
  public getDeliveries(projectId: number): Observable<Delivery[]> {
    return this.http.get<Delivery[]>(`${this.baseUrl}/projects/${projectId}/deliveries`);
  }

  @Resource(ResourceType.ProjectGetProjectTree)
  public getProjectTree(projectId: number, siteId: number): Observable<TreeNode[]> {
    let url = `${this.baseUrl}/projects/${projectId}/project-tree-nodes`;
    if (siteId) {
      url = url + `/site/${siteId}`
    }
    return this.http.get<TreeNode[]>(url);
  }

  @Resource(ResourceType.ProjectImageUpload)
  uploadProjectImage(projectId: number, imageType: ProjectImageType, fileToUpload: File): Observable<Attachment> {
    const url = `${this.baseUrl}/projects/${projectId}/${imageType}/attachments`;
    const formData: FormData = new FormData();
    formData.append('attachmentFiles', fileToUpload, fileToUpload.name);
    return this.http.post<Attachment>(url, formData);
  }

  @Resource(ResourceType.ProjectImageDelete)
  deleteProjectImage(projectId: number, imageType: ProjectImageType) {
    return this.http.delete(`${this.baseUrl}/projects/${projectId}/${imageType}/attachments`);
  }

  @Resource(ResourceType.ProjectConfigUpdate)
  updateProjectConfig(projectId: number, config: ProjectConfig) {
    return this.http.put(`${this.baseUrl}/projects/${projectId}/project-config`, { id: config.id, name: config.name, value: config.value });
  }

  @Resource(ResourceType.InjectCSItemValues)
  injectCsItemValues(projectId: number, data: MipsCheckSheetItemValue[]): Observable<CheckRecordValue[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.post<CheckRecordValue[]>(`${this.baseUrl}/projects/${projectId}/insertStatusValues`, data, options);
  }
}
