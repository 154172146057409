import { createFeatureSelector, createSelector } from '@ngrx/store';

import { McpState } from '../reducers/mcp';

const getMcpFeatureState = createFeatureSelector<McpState>('mcp');

export const getCurrentMcpId = createSelector(
  getMcpFeatureState,
  (state: McpState) => state.currentMcpId
);

export const getCurrentMcp = createSelector(
  getMcpFeatureState,
  (state: McpState) => state.currentMcp
);

export const getUpdateError = createSelector(
  getMcpFeatureState,
  (state: McpState) => state.latestError
);

export const getStatus = createSelector(
  getMcpFeatureState,
  (state: McpState) => state.responseStatus
);
