import { Component, Input } from '@angular/core';

import { CompletionStatus } from '@completion/enums';

@Component({
  selector: 'app-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss']
})
export class StatusCircleComponent {
  statusSymbols: Map<CompletionStatus, string> = new Map<CompletionStatus, string>([
    [CompletionStatus.PA, 'A'],
    [CompletionStatus.PB, 'B'],
    [CompletionStatus.NA, 'N'],
    [CompletionStatus.OK, '✔']
  ]);

  @Input() status: CompletionStatus = CompletionStatus.OS;
  @Input() isOffline: boolean;
}
