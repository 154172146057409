/* tslint:disable: no-duplicate-string */

import { Injectable } from '@angular/core';
import Dexie from 'dexie';

import {
  Company,
  ProjectAccess,
  ProjectConfig,
  SignatureEntryMatrix,
  SignatureMatrixTemplates,
  User
} from '@completion/models';

export interface CP {
  cpId: number;
  packageData: any;
}

export interface MCP {
  mcpId: number;
  cpId: number;
  packageData: any;
}

export interface CSA {
  id: number;
  csa: any;
  projectId: number;
  cpId: number;
  mcpId: number;
  type: string; // cp or mcp
}

export interface Punch {
  id?: number;
  punchId?: number;
  csaId: number;
  mcpId?: number;
  cpId: number;
  data: any;
}

export interface ProjectAccessData {
  projectId: number;
  data: ProjectAccess;
}

export interface PunchAttachment {
  id?: number;
  attachmentId?: number;
  attachmentName: string;
  isPushed: boolean; // is either already pushed to server or is in changeLog ready to be pushed.
  punchId?: number;
  csaId: number;
  data: Blob;
}

export interface Proj {
  id: number;
  name: string;
  config: ProjectConfig[];
  signatureMatrixTemplates: SignatureMatrixTemplates;
}

export enum ChangeLogStatus {
  pending,
  synced
}

export interface ChangeLogEvent {
  id?: number;
  csaId: number;
  objectId?: number; // id of the object, should be used alongside the offlineCreatedType
  method: string;
  offlineCreatedType: string; // punch, csa, ...
  url: string;
  payload: any;
  status: ChangeLogStatus;
  content?: any;
  created: number;
  updated: number;
  params?: any;
  attachments?: PunchAttachment[];
}

export interface Companies {
  projectId: number;
  category: string;
  data: Company[];
}

export interface Users {
  projectId: number;
  data: User[];
}

export interface SignatureMatrices {
  projectId: number;
  data: SignatureEntryMatrix;

}

@Injectable({
  providedIn: 'root'
})
export class OfflineDataDb {
  csa: Dexie.Table<CSA>;
  cp: Dexie.Table<CP>;
  mcp: Dexie.Table<MCP>;
  project: Dexie.Table<Proj>;
  punch: Dexie.Table<Punch>;
  punchAttachment: Dexie.Table<PunchAttachment>;
  projectAccess: Dexie.Table<ProjectAccessData>;
  changeLog: Dexie.Table<ChangeLogEvent>;
  companies: Dexie.Table<Companies>;
  users: Dexie.Table<Users>;

  constructor() {
    const db = new Dexie('completion-offline-data');
    db.version(1).stores({
      cp: 'cpId, packageData',
      mcp: 'mcpId, cpId, packageData',
      csa: 'id, csa, projectId, [cpId+type], [mcpId+type], cpId, mcpId, type',
      project: 'id, name, config, signatureMatrixTemplates',
      projectAccess: 'projectId, data',
      punch: 'id++, punchId, csaId, cpId, mcpId, data',
      punchAttachment: 'id++, attachmentId, [punchId+isInSync], attachmentName, punchId, csaId, data',
      changeLog: '++id, csaId, objectId, method, offlineCreatedType, url, payload, status, content, created, updated',
      companies: 'projectId, category, data',
      users: 'projectId, data',
    });

    this.cp = db.table('cp');
    this.mcp = db.table('mcp');
    this.csa = db.table('csa');
    this.project = db.table('project');
    this.projectAccess = db.table('projectAccess');
    this.punch = db.table('punch');
    this.punchAttachment = db.table('punchAttachment');
    this.changeLog = db.table('changeLog');
    this.companies = db.table('companies');
    this.users = db.table('users');
  }
}
