import { HandoverDocsAction, HandoverDocsActionTypes } from '@completion/actions';
import { Mcsi } from '@completion/models';

export const initialState: Mcsi = null;

export function mcsiReducer(state: Mcsi = initialState, action: HandoverDocsAction): Mcsi {
  switch (action.type) {
    case HandoverDocsActionTypes.McsiLoadSuccess: {
      return action.mcsi;
    }

    default:
      return state;
  }
}
