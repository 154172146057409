import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { DashboardCheckSheetAssignmentStatus } from "../../shared/models/dashboard-checksheet-status";
import { DashboardPackage } from "../../shared/models/dashboard-package";
import { BaseService, Resource } from './base.service';
import { Punch, UserSignatureAssignments } from '@completion/models';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.LoadDashboardCps)
  public getDashboardCps(projectId: number): Observable<DashboardPackage[]> {
    return this.http.get<DashboardPackage[]>(`${this.baseUrl}/projects/${projectId}/dashboard/cp`);
  }
  @Resource(ResourceType.LoadDashboardMcps)
  public getDashboardMcps(projectId: number): Observable<DashboardPackage[]> {
    return this.http.get<DashboardPackage[]>(`${this.baseUrl}/projects/${projectId}/dashboard/mcp`);
  }
  @Resource(ResourceType.LoadDashboardCheckSheetAssignmentStatus)
  public getDashboardCheckSheetAssignmentStatus(projectId: number): Observable<DashboardCheckSheetAssignmentStatus> {
    return this.http.get<DashboardCheckSheetAssignmentStatus>(`${this.baseUrl}/projects/${projectId}/dashboard/checksheetsummary`);
  }

  @Resource(ResourceType.LoadUserSignatureAssignments)
  public getUserSignatureAssignments(projectId: number): Observable<UserSignatureAssignments> {
    return this.http.get<UserSignatureAssignments>(`${this.baseUrl}/projects/${projectId}/dashboard/userSignatureAssignments`);
  }

  @Resource(ResourceType.LoadUserPunches)
  public getUserPunches(projectId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.baseUrl}/projects/${projectId}/dashboard/punches`);
  }

}
