import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'reflect-metadata';

import { ResourceType } from '@completion/enums';
import { baseUrl, baseUrlIntegration } from '../../shared/constants';

export const RESOURCE_TYPE_KEY = Symbol('Resource type');
export const RESOURCE_IDENTIFIERS_KEY = Symbol('Resource identifiers');

/**
 * Decorator for Services that provide asynchronous resources. If there can be more than one
 * instance of the same resource in progress, then the `identifiers` argument should be provided
 * to uniquely identify each request.
 *
 * @param resourceType Type of resource
 * @param identifiers A list of properties in the original triggering action that uniquely identify this resource
 */
export function Resource(resourceType: ResourceType, identifiers: string[] = []) {
  return (target: any, resourcePropertyKey: string | symbol) => {
    const resourceTypeMetadata = Reflect.getMetadata(RESOURCE_TYPE_KEY, target) || {};
    resourceTypeMetadata[resourcePropertyKey] = resourceTypeMetadata[resourcePropertyKey] || {};
    resourceTypeMetadata[resourcePropertyKey] = resourceType;
    Reflect.defineMetadata(RESOURCE_TYPE_KEY, resourceTypeMetadata, target);

    const resourceIdentifiersMetadata = Reflect.getMetadata(RESOURCE_IDENTIFIERS_KEY, target) || {};
    resourceIdentifiersMetadata[resourcePropertyKey] = resourceIdentifiersMetadata[resourcePropertyKey] || {};
    resourceIdentifiersMetadata[resourcePropertyKey] = identifiers;
    Reflect.defineMetadata(RESOURCE_IDENTIFIERS_KEY, resourceIdentifiersMetadata, target);
  };
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  constructor(readonly http: HttpClient) { }

  readonly baseUrl = baseUrl;
  readonly baseUrlIntegration = baseUrlIntegration;
}
