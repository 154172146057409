<header>
  <h1>Downloads</h1>
</header>
<div>
  <mat-tab-group [selectedIndex]="activeTabIndex" (selectedIndexChange)="setUrlFragment($event)">
    <mat-tab label="Commissioning Packages">
      <ng-template matTabContent>
        <app-offline-downloads></app-offline-downloads>
      </ng-template>
    </mat-tab>
    <mat-tab label="Mechanical Completion Packages">
      <ng-template matTabContent>
        <app-offline-downloads [mcPackage]="true"></app-offline-downloads>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
