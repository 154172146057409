import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import {
  ChangeCurrentCpId,
  ClearCurrentCp,
  ClearCurrentMcp,
  CpActionTypes,
  CpResetData,
  CpsLoad,
  CpsLoadSuccess,
  CpUpdate,
  CpUpdateSuccess,
  CreateMcps,
  CreateMcpsSuccess,
  DeleteCurrentCp,
  LoadCurrentCp,
  LoadCurrentCpSuccess,
  LoadMcpsForCp,
  LoadMcpsForCpSuccess,
  ProjectTreeLoad, ResetCpItems
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentProject } from '@completion/selectors';
import { CpService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import { E } from '@angular/cdk/keycodes';

@Injectable()
export class CommPkgsEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly cpService: CpService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, cpService, store, toastr);
  }

  @Effect()
  changeCurrentCpId$: Observable<Action> = this.actions$.pipe(
    ofType<ChangeCurrentCpId>(CpActionTypes.ChangeCurrentCpId),
    map(() => new ClearCurrentMcp())
  );

  @Effect()
  clearCurrentCp$: Observable<Action> = this.actions$.pipe(
    ofType<ClearCurrentCp>(CpActionTypes.ClearCurrentCp),
    map(() => new ClearCurrentMcp())
  );

  @Effect()
  loadCPs$: Observable<Action> = this.actions$.pipe(
    ofType<CpsLoad>(CpActionTypes.CpsLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getCommPkgs', CpsLoadSuccess, ([action, currentProject]) => [currentProject.id, action.taskId])
  );

  @Effect()
  loadCP$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCurrentCp>(CpActionTypes.LoadCurrentCp),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getCp', LoadCurrentCpSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  updateCp$: Observable<Action> = this.actions$.pipe(
    ofType<CpUpdate>(CpActionTypes.CpUpdate),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateCp', CpUpdateSuccess, ([action, currentProject]) => [currentProject.id, action.cp])
  );

  @Effect()
  loadMcpsForCp$: Observable<Action> = this.actions$.pipe(
    ofType<LoadMcpsForCp>(CpActionTypes.LoadMcpsForCp),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getAllMcps', LoadMcpsForCpSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  createMcps$: Observable<Action> = this.actions$.pipe(
    ofType<CreateMcps>(CpActionTypes.CreateMcps),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('createMcps', CreateMcpsSuccess, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.mcps])
  );

  @Effect()
  deleteCP$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteCurrentCp>(CpActionTypes.DeleteCurrentCp),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('deleteCp', ProjectTreeLoad, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  refetchCpData$: Observable<Action> = this.actions$.pipe(
    ofType<CpResetData>(CpActionTypes.CpResetData),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('refetchCpData', null, ([_, currentProject, currentCpId]) => [currentProject.id, currentCpId])
  );

  @Effect()
  resetCpItems$: Observable<Action> = this.actions$.pipe(
    ofType<ResetCpItems>(CpActionTypes.ResetCpItems),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('resetCpItems', null, ([action, currentProject, currentCpId]) => [currentProject.id, currentCpId, action.level])
  );

}
