import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  ClearCurrentCp,
  ClearCurrentMcp,
  ClearScopeOfWork,
  CurrentTask,
  TaskActionTypes,
  TasksLoad,
  TasksLoadSuccess
} from '@completion/actions';
import { State } from '@completion/reducers';
import { ProjectService } from '../../services';
import { getCurrentProject } from '../selectors';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class TaskEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly service: ProjectService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, service, store, toastr);
  }

  @Effect()
  loadTasks$: Observable<Action> = this.actions$.pipe(
    ofType<TasksLoad>(TaskActionTypes.TasksLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getTasks', TasksLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  changeTask: Observable<Action> = this.actions$.pipe(
    ofType<CurrentTask>(TaskActionTypes.CurrentTask),
    mergeMap(() => [new ClearCurrentCp(), new ClearScopeOfWork(), new ClearCurrentMcp()])
  );
}
