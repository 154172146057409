import { Action } from '@ngrx/store';

import { DossierStatus, DossierUrl } from '@completion/models';

export enum HandoverDossierActionTypes {
  CompileDossierStart = 'Compile Dossier Start',
  DossierDelete = 'Dossier Delete',
  LoadDossierFile = 'Load Dossier File',
  LoadDossierFileSuccess = 'Load Dossier File Success',
  ClearDossierFile = 'Clear Dossier File',

  GetDossierStatus = 'Request Dossier Job Status',
  RequestJobStatus = 'Request Job Status',

  PreviewDossierStart = 'Preview Dossier Start',

  LoadPreviewDossierFile = 'Load Preview Dossier File',
  LoadPreviewDossierFileSuccess = 'Load Preview Dossier File Success',

  RequestPreviewJobStatus = 'Request Preview Job Status',
  GetPreviewJobStatus = 'Get Preview Job Status'
}

export class CompileDossierStart implements Action {
  readonly type = HandoverDossierActionTypes.CompileDossierStart;
}

export class PreviewDossierStart implements Action {
  readonly type = HandoverDossierActionTypes.PreviewDossierStart;
}

export class DossierDelete implements Action {
  readonly type = HandoverDossierActionTypes.DossierDelete;
}

export class RequestJobStatus implements Action {
  readonly type = HandoverDossierActionTypes.RequestJobStatus;
}

export class GetDossierStatus implements Action {
  readonly type = HandoverDossierActionTypes.GetDossierStatus;
  constructor(public dossierStatus: DossierStatus) { }
}

export class RequestPreviewJobStatus implements Action {
  readonly type = HandoverDossierActionTypes.RequestPreviewJobStatus;
}

export class GetPreviewDossierStatus implements Action {
  readonly type = HandoverDossierActionTypes.GetPreviewJobStatus;
  constructor(public dossierStatus: DossierStatus) { }
}

// ---------------

export class LoadDossierFile implements Action {
  readonly type = HandoverDossierActionTypes.LoadDossierFile;
}

export class LoadDossierFileSuccess implements Action {
  readonly type = HandoverDossierActionTypes.LoadDossierFileSuccess;

  constructor(public url: DossierUrl) { }
}

export class LoadPreviewDossierFile implements Action {
  readonly type = HandoverDossierActionTypes.LoadPreviewDossierFile;
}

export class LoadPreviewDossierFileSuccess implements Action {
  readonly type = HandoverDossierActionTypes.LoadPreviewDossierFileSuccess;

  constructor(public url: DossierUrl) { }
}

export class ClearDossierFile implements Action {
  readonly type = HandoverDossierActionTypes.ClearDossierFile;
}

export type HandoverDossierAction =
  | CompileDossierStart
  | GetDossierStatus
  | RequestJobStatus
  | DossierDelete
  | LoadDossierFile
  | LoadDossierFileSuccess
  | ClearDossierFile
  | PreviewDossierStart
  | GetPreviewDossierStatus
  | LoadPreviewDossierFile
  | LoadPreviewDossierFileSuccess;
