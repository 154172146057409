import { CompletionStatus } from '@completion/enums';
import { Company } from './company';
import { Phase } from './phase';
import { Site } from './site';

export interface MccIssueOption {
  mcpId: number;
  mcpNumber: string;
  tagsTotal: number;
  tagsCompleted: number;
  completionState: CompletionStatus;
  tags: MccIssueTagOption[];
  mcpLocked: boolean;
}

export interface MccIssueTagOption {
  id: number;
  number: string;
  completed: boolean;
  assignments: MccIssueCheckSheetAssignmentOption[];
}

export interface MccIssueCheckSheetAssignmentOption {
  checkSheetAssignmentId: number;
  checkSheetNumber: string;
  company: Company;
  completed: boolean;
  phase: Phase;
  signed: boolean;
  site: Site;
  status: CompletionStatus;
  verified: boolean;
}

export interface CertifiableMcp {
  mcpId: number;
  assignments: CertifiableMcpCheckSheetAssignment[];
}

export interface CertifiableMcpCheckSheetAssignment {
  id: number;
}

export interface RfccIssueOption {
  mcpId: number;
  mcpNumber: string;
  tagsTotal: number;
  tagsIssued: number;
  mccId?: number;
  documentNumber?: string;
  signed: boolean;
  partHandover: boolean;
}

export interface RfccCpIssueOption {
  tagsTotal: number;
  tagsIssued: number;
  mccId?: number;
  documentNumber?: string;
  signed: boolean;
  partHandover: boolean;
  tags: RfocIssueOptionsGrouping[];
}

export interface RfocIssueOptions {
  availableRfccs: string[];
  existSignedRfccs: boolean;
  partHandover: boolean;
  tags: RfocIssueOptionsGrouping[];
}

export interface RfccCpIssueOptionsGrouping {
  tagNumber: string;
  mcpNumber: string;
  completed: boolean;
  assignments: RfocCheckSheetAssignmentOption[];
}

export interface RfocIssueOptionsGrouping {
  tagNumber: string;
  mcpNumber: string;
  completed: boolean;
  assignments: RfocCheckSheetAssignmentOption[];
}

export interface RfocCheckSheetAssignmentOption {
  id: number;
  status: CompletionStatus;
  checkSheetNumber: string;
  siteNumber: string;
  companyNumber: string;
  phaseNumber: string;
  completed: boolean;
}
