import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { CommitButtonComponent } from './commit-button.component';

@NgModule({
  declarations: [CommitButtonComponent],
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
  exports: [CommitButtonComponent]
})
export class CommitButtonModule {}
