import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  CpDuplicateTags,
  DuplicateTagsFailure,
  DuplicateTagsSuccess,
  McpDuplicateTags,
  PackageCommonActionTypes,
  PCLoadFailure
} from '@completion/actions';
import { HttpStatus } from '@completion/enums';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentMcpId, getCurrentProject } from '@completion/selectors';
import { CpService, McpService } from '@completion/services';

@Injectable()
export class PackageCommonEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly cpService: CpService,
    private readonly mcpService: McpService,
    private readonly store: Store<State>
  ) {}

  @Effect()
  cpDuplicateTags$: Observable<Action> = this.actions$.pipe(
    ofType<CpDuplicateTags>(PackageCommonActionTypes.CpDuplicateTags),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    mergeMap(([action, currentProject, cpId]) =>
      this.cpService.duplicateTags(currentProject.id, cpId, action.checkSheetAssignment).pipe(
        map(response => new DuplicateTagsSuccess(response.status)),
        catchError((response: HttpErrorResponse) => {
          switch (response.status) {
            case HttpStatus.BAD_REQUEST:
              return of(new DuplicateTagsFailure(response.error));
            default:
              return of(new PCLoadFailure(response.message));
          }
        })
      )
    )
  );

  @Effect()
  mcpDuplicateTags$: Observable<Action> = this.actions$.pipe(
    ofType<McpDuplicateTags>(PackageCommonActionTypes.McpDuplicateTags),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, currentProject, mcpId]) => !!(currentProject && mcpId)),
    mergeMap(([action, currentProject, mcpId]) =>
      this.mcpService.duplicateTags(currentProject.id, mcpId, action.checkSheetAssignment).pipe(
        map(response => new DuplicateTagsSuccess(response.status)),
        catchError((response: HttpErrorResponse) => {
          switch (response.status) {
            case HttpStatus.BAD_REQUEST:
              return of(new DuplicateTagsFailure(response.error));
            default:
              return of(new PCLoadFailure(response.message));
          }
        })
      )
    )
  );
}
