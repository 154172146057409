import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SignatureType } from '@completion/enums';
import { SignatureMatrixTemplate, SignatureMatrixTemplates } from '@completion/models';
import { getCurrentProject, getDefaultSignatureMatrices } from '@completion/selectors';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-signature-matrix-template',
  templateUrl: './signature-matrix-template.component.html',
  styleUrls: ['./signature-matrix-template.component.scss']
})
export class SignatureMatrixTemplateComponent {

  getSignatureTypes() {
    return Object.keys(SignatureType);
  }

}
