import { SignatureMatrixAction, SignatureMatrixActionTypes, SiteAction, SiteActionTypes } from '@completion/actions';
import { SignatureType } from '@completion/enums';

export interface SignatureMatrixState {
  currentType: SignatureType;
}

export const initialState: SignatureMatrixState = {
  currentType: null,
};

export function signatureMatrixReducer(state: SignatureMatrixState = initialState, action: SignatureMatrixAction): SignatureMatrixState {
  switch (action.type) {
    case SignatureMatrixActionTypes.SetMatrixType:
      return {
        ...state,
        currentType: action.matrixType
      };
    case SignatureMatrixActionTypes.ClearCurrentType:
      return {
        ...state,
        currentType: null
      };
    default:
      return state;
  }
}
