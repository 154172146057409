import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from "@angular/material/select";

import { ProjectSelectorComponent } from './project-selector.component';

@NgModule({
  imports: [CommonModule, MatSelectModule],
  declarations: [ProjectSelectorComponent],
  exports: [ProjectSelectorComponent]
})
export class ProjectSelectorModule {}
