import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  AdminRevokeAccepted, AdminRevokeCleared,
  CreateCPPunch,
  CreateMCPPunch,
  CreatePunchSuccess,
  DeleteAttachment,
  DeleteAttachmentSuccess,
  DeletePunch,
  DeletePunchSuccess,
  LoadCPPunches,
  LoadMCPPunches,
  LoadPunchesSuccess,
  LoadSelectedAttachment,
  LoadSelectedAttachmentSuccess,
  LoadUnacceptedPunches,
  LoadUnacceptedPunchesSuccess, PublishAllCpPunchesToMips, PublishPunchToMips,
  PunchActionTypes,
  UpdatePunch,
  UploadAttachments,
  UploadAttachmentsSuccess
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentCsaId, getCurrentMcpId, getCurrentProject } from '@completion/selectors';
import { PunchService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class PunchEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly punchService: PunchService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, punchService, store, toastr);
  }

  @Effect()
  loadMcpPunches$: Observable<Action> = this.actions$.pipe(
    ofType<LoadMCPPunches>(PunchActionTypes.LoadMCPPunches),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, currentProject, mcpId]) => !!(currentProject && mcpId)),
    this.fetchResource('getMcpPunches', LoadPunchesSuccess, ([_, currentProject, mcpId]) => [currentProject.id, mcpId])
  );

  @Effect()
  loadCpPunches$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCPPunches>(PunchActionTypes.LoadCPPunches),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getCpPunches', LoadPunchesSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  createCpPunch$: Observable<Action> = this.actions$.pipe(
    ofType<CreateCPPunch>(PunchActionTypes.CreateCPPunch),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('createCpPunch', CreatePunchSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      action.csiNum,
      action.punch
    ])
  );

  @Effect()
  createMcpPunch$: Observable<Action> = this.actions$.pipe(
    ofType<CreateMCPPunch>(PunchActionTypes.CreateMCPPunch),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('createMcpPunch', CreatePunchSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      action.csiNum,
      action.punch
    ])
  );

  @Effect()
  updatePunch$: Observable<Action> = this.actions$.pipe(
    ofType<UpdatePunch>(PunchActionTypes.UpdatePunch),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('updatePunch', CreatePunchSuccess, ([action, currentProject]) => [currentProject.id, action.punch])
  );

  @Effect()
  deletePunch$: Observable<Action> = this.actions$.pipe(
    ofType<DeletePunch>(PunchActionTypes.DeletePunch),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource(
      'deletePunch',
      DeletePunchSuccess,
      ([action, currentProject]) => [currentProject.id, action.punchId],
      response => [response.status]
    )
  );

  @Effect()
  uploadAttachments$: Observable<Action> = this.actions$.pipe(
    ofType<UploadAttachments>(PunchActionTypes.UploadAttachments),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('uploadAttachments', UploadAttachmentsSuccess, ([action, currentProject]) => [
      currentProject.id,
      action.punchId,
      action.attachments
    ])
  );

  @Effect()
  loadSelectedAttachment$: Observable<Action> = this.actions$.pipe(
    ofType<LoadSelectedAttachment>(PunchActionTypes.LoadSelectedAttachment),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('getAttachment', LoadSelectedAttachmentSuccess, ([action, currentProject]) => [
      currentProject.id,
      action.punchId,
      action.attachmentId
    ])
  );

  @Effect()
  deleteAttachment$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteAttachment>(PunchActionTypes.DeleteAttachment),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('deleteAttachment', null, ([action, currentProject]) => [currentProject.id, action.punchId, action.attachmentId])
  );

  @Effect()
  loadUnacceptedPunches$: Observable<Action> = this.actions$
    .pipe(
      ofType<LoadUnacceptedPunches>(PunchActionTypes.LoadUnacceptedPunches),
      withLatestFrom(this.store.select(getCurrentProject)),
      filter(([_, currentProject]) => !!currentProject),
      this.fetchResource('getUnacceptedPunches', LoadUnacceptedPunchesSuccess, ([_, currentProject]) => {
        return [currentProject.id]
      })
    );

  @Effect()
  adminRevokeAccepted$: Observable<Action> = this.actions$.pipe(
    ofType<AdminRevokeAccepted>(PunchActionTypes.AdminRevokeAccepted),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([action, currentProject]) => !!currentProject),
    this.fetchResource('adminRevokeAccepted', null, ([action, currentProject]) => [currentProject.id, action.punchId, action.isMcp])
  );

  @Effect()
  adminRevokeCleades$: Observable<Action> = this.actions$.pipe(
    ofType<AdminRevokeCleared>(PunchActionTypes.AdminRevokeCleared),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([action, currentProject]) => !!currentProject),
    this.fetchResource('adminRevokeCleared', null, ([action, currentProject]) => [currentProject.id, action.punchId, action.isMcp])
  );

  @Effect()
  publishPunchesToMIPS$: Observable<Action> = this.actions$.pipe(
    ofType<PublishAllCpPunchesToMips>(PunchActionTypes.PublishAllCpPunchesToMips),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('publishPunchesToMips', null, ([_, currentProject, cpId]) => [currentProject.id, cpId])

  );

  @Effect()
  publishPunchToMIPS$: Observable<Action> = this.actions$.pipe(
    ofType<PublishPunchToMips>(PunchActionTypes.PublishPunchToMips),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('publishPunchToMips', null, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.punch.id])
  );
}
