import { HandoverDossierAction, HandoverDossierActionTypes } from '@completion/actions';
import { DossierStatus } from '@completion/models';

export const initialState: DossierState = {
  dossierPreviewStatus: null,
  dossierStatus: null,
};

export interface DossierState {
  dossierPreviewStatus: DossierStatus;
  dossierStatus: DossierStatus;
  previewUrl?: string;
  dossierUrl?: string;
}

export function dossierStatusReducer(state: DossierState = initialState, action: HandoverDossierAction): DossierState {
  switch (action.type) {
    case HandoverDossierActionTypes.GetDossierStatus:
      return {
        ...state,
        dossierStatus: action.dossierStatus
      };
    case HandoverDossierActionTypes.GetPreviewJobStatus:
      return {
        ...state,
        dossierPreviewStatus: action.dossierStatus
      };
    case HandoverDossierActionTypes.LoadDossierFileSuccess:
      return {
        ...state,
        dossierUrl: action.url.url
      };
    case HandoverDossierActionTypes.LoadPreviewDossierFileSuccess:
      return {
        ...state,
        previewUrl: action.url.url,
      };
    case HandoverDossierActionTypes.ClearDossierFile:
      return {
        ...state,
        dossierUrl: null,
      };

    default:
      return state;
  }
}
