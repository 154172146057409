import { Action } from '@ngrx/store';

import { Attachment, Punch } from '@completion/models';

export enum PunchActionTypes {
  LoadMCPPunches = 'Load MCP Punches',
  LoadPunchesSuccess = 'Load Punches Success',
  LoadCPPunches = 'Load CP Punches',
  CreateCPPunch = 'Create CP Punch',
  CreateMCPPunch = 'Create MCP Punch',
  CreatePunchSuccess = 'Create Punch Success',
  ClearPunch = 'Clear Punch',
  UpdatePunch = 'Update Punch',
  DeletePunch = 'Delete Punch',
  DeletePunchSuccess = 'Delete Punch Success',
  UploadAttachments = 'Upload Attachments',
  UploadAttachmentsSuccess = 'Upload Attachments Success',
  LoadSelectedAttachment = 'Load Selected Attachment',
  LoadSelectedAttachmentSuccess = 'Load Selected Attachment Success',
  ClearSelectedAttachment = 'Clear Selected Attachment',
  DeleteAttachment = 'Delete Attachment',
  DeleteAttachmentSuccess = 'Delete Attachment Success',
  LoadUserPunches = "LoadUserPunches",
  LoadUserPunchesSuccess = "LoadUserPunchesSuccess",
  LoadUnacceptedPunches = "Load Unaccepted Punches",
  LoadUnacceptedPunchesSuccess = "LoadUnacceptedPunchesSuccess",
  AdminRevokeAccepted = "AdminRevokeAccepted",
  PublishAllCpPunchesToMips = "PublishAllCpPunchesToMips",
  AdminRevokeCleared = "AdminRevokeCleared",

}

export class LoadMCPPunches implements Action {
  readonly type = PunchActionTypes.LoadMCPPunches;
}

export class LoadCPPunches implements Action {
  readonly type = PunchActionTypes.LoadCPPunches;
}

export class ClearPunch implements Action {
  readonly type = PunchActionTypes.ClearPunch;
}

export class CreateCPPunch implements Action {
  readonly type = PunchActionTypes.CreateCPPunch;

  constructor(public csiNum: number, public punch: Punch) { }
}

export class CreateMCPPunch implements Action {
  readonly type = PunchActionTypes.CreateMCPPunch;

  constructor(public csiNum: number, public punch: Punch) { }
}

export class LoadPunchesSuccess implements Action {
  readonly type = PunchActionTypes.LoadPunchesSuccess;

  constructor(public punches: Punch[]) { }
}

export class CreatePunchSuccess implements Action {
  readonly type = PunchActionTypes.CreatePunchSuccess;

  constructor(public punch: Punch) { }
}

export class UpdatePunch implements Action {
  readonly type = PunchActionTypes.UpdatePunch;

  constructor(public punch: Punch) { }
}

export class DeletePunch implements Action {
  readonly type = PunchActionTypes.DeletePunch;

  constructor(public punchId: number) { }
}

export class DeletePunchSuccess implements Action {
  readonly type = PunchActionTypes.DeletePunchSuccess;

  constructor(public deleteStatus: number) { }
}

export class UploadAttachments implements Action {
  readonly type = PunchActionTypes.UploadAttachments;

  constructor(public punchId: number, public attachments: File[]) { }
}

export class UploadAttachmentsSuccess implements Action {
  readonly type = PunchActionTypes.UploadAttachmentsSuccess;

  constructor(public attachments: Attachment[]) { }
}

export class LoadSelectedAttachment implements Action {
  readonly type = PunchActionTypes.LoadSelectedAttachment;

  constructor(public punchId: number, public attachmentId: number) { }
}

export class LoadSelectedAttachmentSuccess implements Action {
  readonly type = PunchActionTypes.LoadSelectedAttachmentSuccess;

  constructor(public attachment: Blob) { }
}

export class ClearSelectedAttachment implements Action {
  readonly type = PunchActionTypes.ClearSelectedAttachment;
}

export class DeleteAttachment implements Action {
  readonly type = PunchActionTypes.DeleteAttachment;

  constructor(public punchId: number, public attachmentId: number) { }
}

export class DeleteAttachmentSuccess implements Action {
  readonly type = PunchActionTypes.DeleteAttachmentSuccess;

  constructor(public punchId: number, public attachmentId: number) { }
}

export class LoadUserPunches implements Action {
  readonly type = PunchActionTypes.LoadUserPunches;
}

export class LoadUserPunchesSuccess implements Action {
  readonly type = PunchActionTypes.LoadUserPunchesSuccess;
  constructor(public punches: Punch[]) { }
}

export class LoadUnacceptedPunches implements Action {
  readonly type = PunchActionTypes.LoadUnacceptedPunches;
}

export class LoadUnacceptedPunchesSuccess implements Action {
  readonly type = PunchActionTypes.LoadUnacceptedPunchesSuccess;
  constructor(public punches: Punch[]) { }
}

export class AdminRevokeAccepted implements Action {
  readonly type = PunchActionTypes.AdminRevokeAccepted;
  constructor(public punchId: number, isMcp: boolean) {
  }
}

export class AdminRevokeCleared implements Action {
  readonly type = PunchActionTypes.AdminRevokeCleared;
  constructor(public punchId: number, isMcp: boolean) {
  }

}

export class PublishAllCpPunchesToMips implements Action {
  readonly type = PunchActionTypes.PublishAllCpPunchesToMips;
}

export type PunchAction =
  | LoadMCPPunches
  | LoadPunchesSuccess
  | LoadCPPunches
  | CreateCPPunch
  | CreateMCPPunch
  | CreatePunchSuccess
  | ClearPunch
  | UpdatePunch
  | DeletePunch
  | DeletePunchSuccess
  | UploadAttachments
  | UploadAttachmentsSuccess
  | LoadSelectedAttachment
  | LoadSelectedAttachmentSuccess
  | ClearSelectedAttachment
  | DeleteAttachment
  | DeleteAttachmentSuccess
  | LoadUserPunches
  | LoadUserPunchesSuccess
  | LoadUnacceptedPunches
  | LoadUnacceptedPunchesSuccess
  | AdminRevokeAccepted
  | AdminRevokeCleared
  | PublishAllCpPunchesToMips;
