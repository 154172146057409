import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ResourceType } from '@completion/enums';
import {
  HandoverFile,
  HandoverFileUploadRequest,
  HandoverPart,
  HandoverRow,
  HandoverRowUpdate,
  HandoverSectionTemplate,
  SignatureMatrix
} from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class HandoverService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.HandoverGetHandover)
  getHandover(projectId: number, cpId: number): Observable<HandoverPart> {
    return this.http.get<HandoverPart>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover`);
  }

  @Resource(ResourceType.HandoverGetHandoverSectionTemplates)
  getSectionTemplates(projectId: number, cpId: number) {
    return this.http.get<HandoverSectionTemplate[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/sections`);
  }

  @Resource(ResourceType.HandoverUpdateSection, ['sectionId'])
  updateSection(projectId: number, cpId: number, section: HandoverSectionTemplate): Observable<HandoverSectionTemplate[]> {
    return this.http.put<HandoverSectionTemplate[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/sections`, [section]);
  }

  @Resource(ResourceType.HandoverUpdateSections)
  updateSections(projectId: number, cpId: number, sections: HandoverSectionTemplate[]): Observable<HandoverSectionTemplate[]> {
    return this.http.put<HandoverSectionTemplate[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/sections`, sections);
  }

  @Resource(ResourceType.HandoverUpdateFrontPage)
  updateFrontPage(projectId: number, cpId: number, handoverId: number, fontPageValue: { refNumber: string }) {
    return this.http.patch(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}`, fontPageValue);
  }

  @Resource(ResourceType.HandoverUpdateSections)
  updateSectionOrdinals(projectId: number, cpId: number, sections: HandoverSectionTemplate[]): Observable<HandoverSectionTemplate[]> {
    return this.http.patch<HandoverSectionTemplate[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/sections/sort`, sections);
  }

  @Resource(ResourceType.HandoverCreateSection)
  createSection(projectId: number, cpId: number, section: HandoverSectionTemplate): Observable<HandoverSectionTemplate[]> {
    return this.http.post<HandoverSectionTemplate[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/sections`, [section]);
  }

  @Resource(ResourceType.HandoverDeleteSection)
  deleteSection(projectId: number, cpId: number, sectionId: number): Observable<HandoverSectionTemplate> {
    return this.http.delete<HandoverSectionTemplate>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/sections/${sectionId}`);
  }

  @Resource(ResourceType.HandoverDeleteSection)
  softDeleteHandover(projectId: number, cpId: number, handoverId: number): Observable<HandoverSectionTemplate> {
    return this.http.delete<HandoverSectionTemplate>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}`);
  }

  @Resource(ResourceType.HandoverUpdateSignature)
  updateHandoverSignature(projectId: number, cpId: number, handoverId: number, signing: boolean): Observable<SignatureMatrix> {
    return this.http.put<SignatureMatrix>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/${signing ? 'sign' : 'unsign'}`,
      {}
    );
  }

  @Resource(ResourceType.HandoverFileUpload)
  fileUpload(
    projectId: number,
    cpId: number,
    handoverId: number,
    rowId: number,
    fileToUpload: HandoverFileUploadRequest
  ): Observable<HandoverRowUpdate> {
    const formData = new FormData();
    Object.keys(fileToUpload).forEach(key => formData.append(key, fileToUpload[key]));

    return this.http
      .post<HandoverRow>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/rows/${rowId}/attachments`, formData)
      .pipe(mergeMap(row => of({ row, handoverId, sectionId: row.sectionId, rowId })));
  }

  @Resource(ResourceType.HandoverFileDelete)
  fileDelete(
    projectId: number,
    cpId: number,
    handoverId: number,
    rowId: number,
    fileToDelete: HandoverFile
  ): Observable<HandoverRowUpdate> {
    return this.http
      .delete<HandoverRow>(
        `${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/rows/${rowId}/attachments/${fileToDelete.id}`
      )
      .pipe(mergeMap(row => of({ row, handoverId, sectionId: row.sectionId, rowId })));
  }

  @Resource(ResourceType.HandoverUpdateRow, ['sectionId', 'rowId'])
  updateRow(
    projectId: number,
    cpId: number,
    handoverId: number,
    sectionId: number,
    rowId: number,
    rowChanges: Partial<HandoverRow>
  ): Observable<HandoverRowUpdate> {
    return this.http
      .patch<HandoverRow>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/rows/${rowId}`, rowChanges)
      .pipe(mergeMap(row => of({ row, handoverId, sectionId, rowId })));
  }
}
