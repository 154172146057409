import { CertificateType, CompletionStatus } from '@completion/enums';
import { SignatureMatrix } from './signature-matrix';
import { User } from './user';
import {Tag} from "./tag";
import {Attachment} from "./attachment";

interface BaseCertificateDetails {
  id: number;
  type: CertificateType;
  documentNumber: string;
  issuedDate: string;
  issuedBy: User;
  comment: string;
  headerCategory: string;
  locked: boolean;
}

export interface MCCDetails extends BaseCertificateDetails {
  mcpId: number;
  mccId: number;
  tagsTotal: number;
  tagsIssued: number;
  signedBy: User;
  signedDate: string;
  acceptedBy: User;
  acceptedDate: string;
  status: CompletionStatus;
  useLegacySignature: boolean;
  signatureMatrix?: SignatureMatrix;
  tags?: Tag[];
  attachments: Attachment[];
}

export interface RFOCDetails extends BaseCertificateDetails {
  partHandover?: boolean;
  mcps: McpIssuedInRfcc[];
  signatureMatrix?: SignatureMatrix;
  useLegacySignature: boolean;
  rfocId?: number;
}

export interface RFCCDetails extends BaseCertificateDetails {
  partHandover?: boolean;
  mcps: McpIssuedInRfcc[];
  signatureMatrix?: SignatureMatrix;
  useLegacySignature: boolean;
  rfccId?: number;
}

export interface McpIssuedInRfcc {
  mcpId: number;
  mcpNumber: string;
  description: string;
  partNumber?: string;
  completionState: CompletionStatus;
}

export type CertificateDetails = MCCDetails | RFCCDetails | RFOCDetails;
