import { CertificateAction, CertificateActionTypes } from '@completion/actions';
import { HttpStatus } from '@completion/enums';
import { CertificateDetails, Certificates, MccIssueOption, RfccIssueOption } from '@completion/models';
import { RfocIssueOptions } from '@completion/models';

export const initialState: CertificateState = {
  certificates: {
    mcc: [],
    rfcc: [],
    rfoc: []
  },
  mccIssueOptions: [],
  rfccIssueOptions: [],
  rfocIssueOptions: null,
  latestError: null,
  certificateDetails: null
};

export interface CertificateState {
  certificates: Certificates;
  mccIssueOptions: MccIssueOption[];
  rfccIssueOptions: RfccIssueOption[];
  rfocIssueOptions: RfocIssueOptions;
  latestError: string | HttpStatus;
  certificateDetails: Partial<CertificateDetails>;
}

export function certificateReducer(state: CertificateState = initialState, action: CertificateAction): CertificateState {
  switch (action.type) {
    case CertificateActionTypes.CertificatesLoadSuccess:
      return {
        ...state,
        certificates: action.certificates
      };

    case CertificateActionTypes.CertificateDetailsLoadSuccess:
      return {
        ...state,
        certificateDetails: action.certificateDetails
      };

    case CertificateActionTypes.CertificateDetailsPatch:
      return {
        ...state,
        certificateDetails: {
          ...state.certificateDetails,
          ...action.data
        }
      };

    case CertificateActionTypes.CertificateMccIssueOptionsLoadSuccess:
      return {
        ...state,
        mccIssueOptions: action.data
      };

    case CertificateActionTypes.CertificateRfccIssueOptionsLoadSuccess:
      return {
        ...state,
        rfccIssueOptions: action.data
      };

    case CertificateActionTypes.CertificateRfocIssueOptionsLoadSuccess:
      return {
        ...state,
        rfocIssueOptions: action.data
      };

    default:
      return state;
  }
}
