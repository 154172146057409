import { DeliveryAction, DeliveryActionTypes } from '@completion/actions';
import { byDeliveryNumber, Delivery } from '@completion/models';

export const initialState: DeliveryState = {
  deliveries: [],
};

export interface DeliveryState {
  deliveries: Delivery[];
}

export function deliveryReducer(state: DeliveryState = initialState, action: DeliveryAction): DeliveryState {
  switch (action.type) {
    case DeliveryActionTypes.DeliveriesLoadSuccess:
      return {
        ...state,
        deliveries: [...action.deliveries].sort(byDeliveryNumber)
      };
    default:
      return state;
  }
}
