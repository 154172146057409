import { NamedEntity } from './named-entity';
import {UserPreference} from "../enums/user-preference";

export interface User extends NamedEntity {
  id: number;
  userName: string;
  permissions?: Permission[];
  preferences?: UserPreference[];
}

export interface Permission {
  readonly name: string;
  readonly description: string;
}

// TODO(MC-898): Need to match real permission when implementing access control
export const Permissions: Record<string, Permission> = Object.freeze({
  SIGN_FOR_OTHERS: {
    name: 'Sign as another user',
    description: 'Allow signing certificates as another user'
  }
});
