import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, first, takeUntil } from 'rxjs';

import { AddTestPermissions, SearchTags, SearchTagsSuccess } from '@completion/actions';
import { Permissions } from '@completion/models';
import { State } from '@completion/reducers';
import { FeatureFlagService } from '../core/services/feature-flag.service';
import { ContextTool, ContextToolbarService } from './context-toolbar.service';
import { S } from '@angular/cdk/keycodes';
import { on } from 'events';
import { getSearchTags } from '../core/store/selectors/tag.selector';
import { MatDialog } from '@angular/material/dialog';
import { SearchResultDialogComponent } from './search-result-dialog/search-result-dialog.component';
import { TagSearchDialog } from '../shared/models/tag-search-dialog';

@Component({
  selector: 'app-context-toolbar',
  templateUrl: './context-toolbar.component.html',
  styleUrls: ['./context-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextToolbarComponent implements OnInit, OnDestroy {
  tools$: Observable<ContextTool[]>;
  showTestingUtilities: boolean;
  areTestingToolsActive: boolean;
  testingToolStatusDictionary: Record<string, boolean> = {
    adminPermissions: false
  };
  @Input() isOnline: boolean;
  @Input() isAdmin: boolean;

  value: string;

  iconClass$: BehaviorSubject<string> = new BehaviorSubject('pending');


  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly contextToolbarService: ContextToolbarService,
    private readonly dialog: MatDialog,
    private readonly store: Store<State>,
    private readonly featureFlag: FeatureFlagService,
    private readonly router: Router
  ) {
    this.tools$ = contextToolbarService.currentTools;

    if (this.featureFlag.isEnabled('testingUtils')) {
      this.showTestingUtilities = true;
    }
  }
  ngOnInit(): void {
    this.store.select(getSearchTags).pipe(takeUntil(this.destroy$)).subscribe(tags => {

      if (tags.length > 0) {
        const dialog = this.dialog.open(SearchResultDialogComponent, {
          width: '1100px',
          data: {
            title: 'Search Result',
            confirmationMessage: "Test",
            tags,
            isCancelDisplay: false,
            confirmationTile: 'Close'
          } as TagSearchDialog
        });

        dialog
          .afterClosed()
          .pipe(first())
          .subscribe(() => {
            this.clearSearch();
          });
      }
    });
  }

  goToProjectSetup() {
    this.router.navigate(['project-setup']);
  }

  addAdminPermissions() {
    this.store.dispatch(new AddTestPermissions([Permissions.SIGN_FOR_OTHERS]));
    this.testingToolStatusDictionary.adminPermissions = true;
    this.areTestingToolsActive = true;
  }

  search() {
    this.store.dispatch(new SearchTags(this.value));
  }
  clearSearch() {
    this.value = '';
    this.store.dispatch(new SearchTagsSuccess([]));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
