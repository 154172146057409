<div class="dashboard-header">
  <p>Dashboard</p>
</div>
<hr />

<div *ngIf="!isOffline; else offline">
  <div class="dashboard-row">
    <div class="dashboard-item chart">
      <div class="pie">
        <p class="info-text">Check Sheet Statuses</p>
        <canvas id="csaStatus" width="300" height="300"></canvas>
      </div>
    </div>
    <div class="dashboard-item user-punch">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">assignment_ind</mat-icon>
            My Punches
          </ng-template>
          <app-user-punch></app-user-punch>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">assignment_turned_in</mat-icon>
            <span [matBadge]="assignmentCount" matBadgeColor="warn" [matBadgeHidden]="badgeHidden" matBadgeOverlap="false"
              >My signature assignments</span
            >
          </ng-template>
          <app-signature-assignments (assignmentCount)="addCount($event)"></app-signature-assignments>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <hr />
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">warning</mat-icon>
        Open Punches in project
      </ng-template>
      <app-user-punch listType="unaccepted"></app-user-punch>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">build_circle</mat-icon>
        My responsible CP's
      </ng-template>
      <table mat-table [dataSource]="cpDashboardPackages" (matSortChange)="sortDataCp($event)" matSort class="mat-elevation-z2">
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description CP</th>
          <td mat-cell *matCellDef="let package">{{ package.cpNo }} - {{ package.description }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
          <td mat-cell *matCellDef="let package"><app-status-circle [status]="package.status.completionState"></app-status-circle></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="package-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="goToPackage(row)"></tr>
      </table>

      <div class="empty-list">
        <p *ngIf="filterOnCp && cpDashboardPackages?.length === 0">The list is empty</p>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">construction</mat-icon>
        My responsible MCP's
      </ng-template>
      <table mat-table [dataSource]="mcpDashboardPackages" (matSortChange)="sortDataMcp($event)" matSort class="mat-elevation-z2">
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description MCP</th>
          <td mat-cell *matCellDef="let package">{{ package.cpNo }} - {{ package.mcpNo }} - {{ package.description }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
          <td mat-cell *matCellDef="let package"><app-status-circle [status]="package.status.completionState"></app-status-circle></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="package-row" mat-row (click)="goToPackage(row)" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div class="empty-list">
        <p *ngIf="!filterOnCp && mcpDashboardPackages?.length === 0">The list is empty</p>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<ng-template #offline>
  <div>
    <h1>Dashboard is not available in offline mode</h1>
  </div>
</ng-template>
