import { HeaderComponent } from './header.component';
import { UserComponent } from './user/user.component';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';


import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserPreferenceDialogComponent } from './user-preference-dialog/user-preference-dialog.component';


const loaderProviders = [
  LoaderService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  }
];

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    A11yModule,
    OverlayModule
  ],
  declarations: [HeaderComponent, UserComponent, UserPreferenceDialogComponent],
  providers: loaderProviders,
  exports: [HeaderComponent]
})
export class HeaderModule { }
