import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';


import { Company, ProjectAccess } from '@completion/models';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import { UserListService } from './user-list.service';
import { compare } from '@completion/utils';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  dataSource: Array<ProjectAccess> = [];
  selectedUser: ProjectAccess = null;
  filter = "";
  private readonly destroy$: Subject<void> = new Subject();

  displayedColumns = ['username', 'name', 'company', 'site', 'role', 'edit'];

  constructor(private readonly userListService: UserListService, public dialog: MatDialog,) {
  }

  ngOnInit() {
    this.userListService.projectAccessList$.subscribe(ul => {
      this.dataSource = ul;
    });
    this.filter = this.userListService.filter;
  }

  isLoading(): boolean {
    return this.userListService.loading
  }

  applyFilter() {
    this.userListService.setFilter(this.filter);
  }

  clearFilter() {
    this.filter = "";
    this.applyFilter();
  }

  editProjectAccess(item: ProjectAccess): void {
    this.dialog.open(UserEditDialogComponent, { data: { projectAccess: item } });
  }

  displayCompanies(comnpanies: Company[]): string {
    if (comnpanies.length > 0) {
      return comnpanies.map(company => company.compNumber).toString();
    }
    return 'N/A';
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      switch (sort.active) {
        case 'role':
          return compare(a.role, b.role, sort.direction === 'asc');
        case 'name':
          return compare(a.user.name, b.user.name, sort.direction === 'asc');
        case 'username':
          return compare(a.user.userName, b.user.userName, sort.direction === 'asc');
        default:
          return 0;
      }
    });
  }
}
