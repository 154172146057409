import { Action } from '@ngrx/store';

import { CertificateType } from '@completion/enums';
import {
  CertifiableMcp,
  CertificateDetails,
  Certificates,
  MCCDetails,
  MccIssueOption,
  RFCCDetails,
  RfccIssueOption,
  RFOCDetails,
  RfocIssueOptions, SignatureState
} from '@completion/models';

export enum CertificateActionTypes {
  CertificatesLoad = 'Certificates Load',
  CertificatesLoadSuccess = 'Certificates Load Success',

  CertificateIssueMccs = 'Certificates Issue Mccs',
  CertificateIssueRfcc = 'Certificates Issue Rfcc',
  CertificateIssueRfoc = 'Certificate Issue Rfoc',

  CertificateMccIssueOptionsLoad = 'Certificates Mcc Issue Options Load',
  CertificateMccIssueOptionsLoadSuccess = 'Certificates Mcc Issue Options Load Success',
  CertificateRfccIssueOptionsLoad = 'Certificates Rfcc Issue Options Load',
  CertificateRfccIssueOptionsLoadSuccess = 'Certificates Rfcc Issue Options Load Success',
  CertificateRfocIssueOptionsLoad = 'Certificates Rfoc Issue Options Load',
  CertificateRfocIssueOptionsLoadSuccess = 'Certificates Rfoc Issue Options Load Success',

  CertificateDetailsLoad = 'Certificate Details Load',
  CertificateDetailsLoadSuccess = 'Certificate Details Load Success',
  CertificateDetailsPatch = 'Certificate Details Patch',

  CertificateUpdateMccSignature = 'Certificate Update Mcc Signature',
  CertificateUpdateMccAcceptance = 'Certificate Update Mcc Acceptance',
  CertificateSaveComment = 'Certificate Save Comment',

  CertificateUpdateRfccSignature = 'Certificate Update Rfcc Signature',
  CertificateUpdateRfocSignature = 'Certificate Update Rfoc Signature',
  InsertAndSignSignatureMatrix = 'Sign Signature Matrix',
  SignSignatureMatrix = 'Sign Signature Matrix',
  UploadMccAttachment = 'Upload mcc File',
  DeleteMccFile = 'Delete mcc File',
  ResetTags = 'Reset Tags',
}

export class CertificatesLoad implements Action {
  readonly type = CertificateActionTypes.CertificatesLoad;
}

export class CertificatesLoadSuccess implements Action {
  readonly type = CertificateActionTypes.CertificatesLoadSuccess;

  constructor(public certificates: Certificates) { }
}

export class CertificateIssueMccs implements Action {
  readonly type = CertificateActionTypes.CertificateIssueMccs;

  constructor(public data: CertifiableMcp[]) { }
}

export class CertificateIssueRfcc implements Action {
  readonly type = CertificateActionTypes.CertificateIssueRfcc;

  constructor(public data: number[]) { }
}

export class CertificateIssueRfoc implements Action {
  readonly type = CertificateActionTypes.CertificateIssueRfoc;

  constructor(public data: number[]) { }
}

export class CertificateDetailsLoad implements Action {
  readonly type = CertificateActionTypes.CertificateDetailsLoad;

  constructor(public certificateId: number, public certificateType: CertificateType) { }
}

export class CertificateDetailsLoadSuccess implements Action {
  readonly type = CertificateActionTypes.CertificateDetailsLoadSuccess;

  constructor(public certificateDetails: MCCDetails | RFCCDetails | RFOCDetails) { }
}

export class CertificateUpdateMccSignature implements Action {
  readonly type = CertificateActionTypes.CertificateUpdateMccSignature;

  constructor(public data: { certificateId: number; signing: boolean }) { }
}

export class CertificateUpdateMccAccept implements Action {
  readonly type = CertificateActionTypes.CertificateUpdateMccAcceptance;

  constructor(public data: { certificateId: number; accepted: boolean }) { }
}

export class CertificateUpdateRfccSignature implements Action {
  readonly type = CertificateActionTypes.CertificateUpdateRfccSignature;

  constructor(public data: { certificateId: number; signing: boolean }) { }
}

export class CertificateUpdateRfocSignature implements Action {
  readonly type = CertificateActionTypes.CertificateUpdateRfocSignature;

  constructor(public data: { certificateId: number; signing: boolean }) { }
}

export class CertificateSaveComment implements Action {
  readonly type = CertificateActionTypes.CertificateSaveComment;

  constructor(public data: { certificateId: number; certificateType: CertificateType; comment: string }) { }
}

export class CertificateDetailsPatch implements Action {
  readonly type = CertificateActionTypes.CertificateDetailsPatch;

  constructor(public data: Partial<CertificateDetails>) { }
}

export class CertificateMccIssueOptionsLoad implements Action {
  readonly type = CertificateActionTypes.CertificateMccIssueOptionsLoad;
}

export class CertificateMccIssueOptionsLoadSuccess implements Action {
  readonly type = CertificateActionTypes.CertificateMccIssueOptionsLoadSuccess;

  constructor(public data: MccIssueOption[]) { }
}

export class CertificateRfccIssueOptionsLoad implements Action {
  readonly type = CertificateActionTypes.CertificateRfccIssueOptionsLoad;
}

export class CertificateRfccIssueOptionsLoadSuccess implements Action {
  readonly type = CertificateActionTypes.CertificateRfccIssueOptionsLoadSuccess;

  constructor(public data: RfccIssueOption[]) { }
}

export class CertificateRfocIssueOptionsLoad implements Action {
  readonly type = CertificateActionTypes.CertificateRfocIssueOptionsLoad;
}
export class CertificateRfocIssueOptionsLoadSuccess implements Action {
  readonly type = CertificateActionTypes.CertificateRfocIssueOptionsLoadSuccess;

  constructor(public data: RfocIssueOptions) { }
}

export class InsertAndSignSignatureMatrix implements Action {
  readonly type = CertificateActionTypes.InsertAndSignSignatureMatrix;
  constructor(public entry: SignatureState, public parentId: number) { }
}
export class SignSignatureMatrix implements Action {
  readonly type = CertificateActionTypes.SignSignatureMatrix;
  constructor(public entry: SignatureState) { }
}

export class UploadMccAttachment implements Action {
  readonly type = CertificateActionTypes.UploadMccAttachment;
  constructor(public file: File, public certificateId:number) { }
}

export class DeleteMccFile implements Action {
  readonly type = CertificateActionTypes.DeleteMccFile;
  constructor(public attachmentId: number, public mccId:number) { }
}

export class ResetTags implements Action {
  constructor(public certificateId: number) {

  }
  readonly type = CertificateActionTypes.ResetTags;
}


export type CertificateAction =
  | CertificatesLoad
  | CertificatesLoadSuccess
  | CertificateIssueMccs
  | CertificateIssueRfcc
  | CertificateIssueRfoc
  | CertificateMccIssueOptionsLoad
  | CertificateMccIssueOptionsLoadSuccess
  | CertificateRfccIssueOptionsLoad
  | CertificateRfccIssueOptionsLoadSuccess
  | CertificateRfocIssueOptionsLoad
  | CertificateRfocIssueOptionsLoadSuccess
  | CertificateDetailsLoad
  | CertificateDetailsLoadSuccess
  | CertificateDetailsPatch
  | CertificateUpdateMccSignature
  | CertificateSaveComment
  | CertificateUpdateRfccSignature
  | InsertAndSignSignatureMatrix
  | SignSignatureMatrix
  | UploadMccAttachment
  | DeleteMccFile
  | ResetTags;
