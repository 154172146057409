<h1>Setup Project "{{ projectName }}"</h1>
<div>
  <mat-tab-group [selectedIndex]="activeTabIndex" (selectedIndexChange)="setUrlFragment($event)">
    <mat-tab label="Project dossier files">
      <ng-template matTabContent>
        <div class="container">
          <h3>Project Handover Images</h3>
          <div class="project-images">
            <div class="upload-container">
              <h3>Customer Logo:</h3>
              <app-file-uploader
                [height]="'180px'"
                [processingImage]="inProgressCustomerLogo"
                [image]="customerLogo"
                (fileUpload)="customerLogoUpload($event)"
                (fileDelete)="customerLogoDelete()"
              ></app-file-uploader>
            </div>

            <div class="upload-container handover-image-container">
              <h3>Handover Image:</h3>
              <app-file-uploader
                [height]="'480px'"
                [processingImage]="inProgressHandoverImage"
                [image]="handoverImage"
                (fileUpload)="handoverImageUpload($event)"
                (fileDelete)="handoverImageDelete()"
              ></app-file-uploader>
            </div>
          </div>
          <div>
            <button mat-button (click)="openDataDialog()">Upload Check Status Item Values</button>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Project display configuration">
      <ng-template matTabContent>
        <div class="container">
          <h3>Project Display settings</h3>
          <div>
            <app-project-config [config]="config"></app-project-config>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Dossier template setup">
      <ng-template matTabContent>
        <div class="container">
          <h3>Project dossier template</h3>
          <div *ngIf="handoverTemplate">
            <app-project-template [handoverTemplate]="handoverTemplate"></app-project-template>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Signature setup">
      <ng-template matTabContent>
        <div class="container" style="background-color: #f2f2f2;">
          <h3>Signature Matrix Template</h3>
          <div>
            <app-signature-matrix-template></app-signature-matrix-template>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="User admin">
      <ng-template matTabContent>
        <div class="container">
          <h3>User admin</h3>
          <div *ngIf="handoverTemplate">
            <app-user-admin></app-user-admin>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Metadata editor">
      <ng-template matTabContent>
        <div class="container">
          <h3>Check sheet admin</h3>
           <app-metadata-editor></app-metadata-editor>
        </div>
      </ng-template>

    </mat-tab>
  </mat-tab-group>
</div>
