import { Component, Input, TemplateRef } from '@angular/core';

import { ResourceStatus } from '@completion/enums';

@Component({
  templateUrl: './resource-wrapper.component.html',
  styleUrls: ['./resource-wrapper.component.scss']
})
export class ResourceWrapperComponent {
  @Input()
  customNoDataComponent: TemplateRef<any>;
  @Input()
  hasData: boolean;
  @Input()
  status: ResourceStatus;
  @Input()
  error: string;
  @Input()
  templateRef: TemplateRef<any>;

  ResourceStatus = ResourceStatus;
}
