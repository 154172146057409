import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeliveryState } from '../reducers/delivery';

const getDeliveryFeatureState = createFeatureSelector<DeliveryState>('delivery');

export const getDeliveries = createSelector(
  getDeliveryFeatureState,
  (state: DeliveryState) => state.deliveries
);
