import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PunchState } from '../reducers/punch';

const getPunchFeatureState = createFeatureSelector<PunchState>('punch');

export const getPunches = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.punches
);

export const getLatestPunches = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.punch
);

export const getDeleteStatus = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.deleteStatus
);

export const getLatestAttachments = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.attachments
);

export const getSelectedAttachment = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.selectedAttachment
);

export const getUserPunches = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.userPunches
);

export const getUnacceptedPunches = createSelector(
  getPunchFeatureState,
  (state: PunchState) => state.unacceptedPunches
);
