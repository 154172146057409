import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { withLatestFrom } from "rxjs/operators";

import { State } from "@completion/reducers";
import { getCurrentProject } from "@completion/selectors";
import { DashboardService } from "@completion/services";
import {
  CpsDashBoardPackageLoadSuccess,
  DashBoardActionTypes, DashboardCheckSheetAssignmentStatusLoadSuccess,

  LoadDashboardCps, LoadDashboardMcps,
  LoadUserPunches,
  LoadUserPunchesSuccess,
  LoadUserSignatureAssignments,
  McpsDashBoardPackageLoadSuccess,
  PunchActionTypes,
  UserSignatureAssignmentsLoadSuccess
} from "@completion/actions";
import { ResourceEffect } from "./resource.effect";
import { E } from "@angular/cdk/keycodes";
import { Punch } from "@completion/models";

@Injectable()
export class DashboardEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly dashboardService: DashboardService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, dashboardService, store, toastr);
  }

  @Effect()
  loadDashboardCps$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDashboardCps>(DashBoardActionTypes.LoadDashboardCps),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getDashboardCps', CpsDashBoardPackageLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadDashboardMcps$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDashboardMcps>(DashBoardActionTypes.LoadDashboardMcps),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getDashboardMcps', McpsDashBoardPackageLoadSuccess, ([_, currentProject]) => [currentProject.id,])
  );

  @Effect()
  loadUserPunches$: Observable<Action> = this.actions$.pipe(
    ofType<LoadUserPunches>(PunchActionTypes.LoadUserPunches),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getUserPunches', LoadUserPunchesSuccess, ([_, currentProject]) => [currentProject.id,])
  );

  @Effect()
  loadUserSignatureAssignments$: Observable<Action> = this.actions$.pipe(
    ofType<LoadUserSignatureAssignments>(DashBoardActionTypes.LoadUserSignatureAssignments),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getUserSignatureAssignments', UserSignatureAssignmentsLoadSuccess, ([_, currentProject]) => [currentProject.id,])
  );

  @Effect()
  loadDashboardCheckSheetAssignmentStatus$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDashboardMcps>(DashBoardActionTypes.LoadDashboardCheckSheetAssignmentStatus),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getDashboardCheckSheetAssignmentStatus', DashboardCheckSheetAssignmentStatusLoadSuccess, ([_, currentProject]) => [currentProject.id,])
  );

}
