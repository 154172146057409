import { Action } from '@ngrx/store';

import {
  HandoverFileGeneric,
  HandoverFileUploadRequest,
  HandoverPart,
  HandoverRow,
  HandoverRowUpdate,
  HandoverSectionTemplate
} from '@completion/models';

export enum HandoverActionTypes {
  HandoverLoad = 'Request Handover Load',
  HandoverLoadSuccess = 'Handover Load Success',
  HandoverUnload = 'Handover Unload',
  HandoverSectionTemplatesLoad = 'Request Handover Section Templates Load',
  HandoverSectionTemplatesLoadSuccess = 'Handover Section Templates Load Success',
  HandoverFileUpload = 'Request Handover File Upload',
  HandoverFileDelete = 'Request Handover File Deletion',
  HandoverCreateSection = 'Handover Create Section',
  HandoverCreateSectionSuccess = 'Handover Create Section Success',
  HandoverSaveSection = 'HandoverSaveSection',
  HandoverSaveSections = 'HandoverSaveSections',
  HandoverChangeSectionOrdinals = 'Handover Change Section Ordinals',
  HandoverUpdateSectionsSuccess = 'Handover Update Sections Success',
  HandoverUpdateRow = 'Handover Update Row',
  HandoverUpdateRowSuccess = 'Handover Update Row Success',
  HandoverChangeSelectedPart = 'Handover Change Selected Part',
  HandoverDeleteSection = 'Handover Delete Section',
  HandoverUpdateSignature = 'Handover Update Signature',
  HandoverPatch = 'Handover Patch',
  HandoverUpdateFrontPage = 'Handover Update FrontPage',
  HandoverSoftDelete = 'Handover soft delete'
}

export class HandoverLoad implements Action {
  readonly type = HandoverActionTypes.HandoverLoad;
}

export class HandoverLoadSuccess implements Action {
  readonly type = HandoverActionTypes.HandoverLoadSuccess;

  constructor(public handoverParts: HandoverPart[]) { }
}

export class HandoverUnload implements Action {
  readonly type = HandoverActionTypes.HandoverUnload;
}

export class HandoverUpdateFrontPage implements Action {
  readonly type = HandoverActionTypes.HandoverUpdateFrontPage;

  constructor(public data: { refNumber: string }) { }
}

export class HandoverPatch implements Action {
  readonly type = HandoverActionTypes.HandoverPatch;

  constructor(public data: Partial<HandoverPart>) { }
}

export class HandoverSectionTemplatesLoad implements Action {
  readonly type = HandoverActionTypes.HandoverSectionTemplatesLoad;
}

export class HandoverSectionTemplatesLoadSuccess implements Action {
  readonly type = HandoverActionTypes.HandoverSectionTemplatesLoadSuccess;

  constructor(public sections: HandoverSectionTemplate[]) { }
}

export class HandoverFileUpload implements Action {
  readonly type = HandoverActionTypes.HandoverFileUpload;

  constructor(public file: HandoverFileUploadRequest, public sectionId: number, public rowId: number) { }
}

export class HandoverFileDelete implements Action {
  readonly type = HandoverActionTypes.HandoverFileDelete;

  constructor(public file: HandoverFileGeneric, public sectionId: number, public rowId: number) { }
}

export class HandoverCreateSection implements Action {
  readonly type = HandoverActionTypes.HandoverCreateSection;
}

export class HandoverCreateSectionSuccess implements Action {
  readonly type = HandoverActionTypes.HandoverCreateSectionSuccess;

  constructor(public sections: HandoverSectionTemplate[]) { }
}

export class HandoverSaveSection implements Action {
  readonly type = HandoverActionTypes.HandoverSaveSection;

  constructor(public section: HandoverSectionTemplate, public sectionId: number) { }
}

export class HandoverSaveSections implements Action {
  readonly type = HandoverActionTypes.HandoverSaveSections;

  constructor(public sections: HandoverSectionTemplate[]) { }
}

export class HandoverUpdateRow implements Action {
  readonly type = HandoverActionTypes.HandoverUpdateRow;

  constructor(public rowChanges: Partial<HandoverRow>, public sectionId: number, public rowId: number) { }
}

export class HandoverUpdateRowSuccess implements Action {
  readonly type = HandoverActionTypes.HandoverUpdateRowSuccess;

  constructor(public update: HandoverRowUpdate) { }
}

export class HandoverChangeSectionOrdinals implements Action {
  readonly type = HandoverActionTypes.HandoverChangeSectionOrdinals;

  constructor(public sections: HandoverSectionTemplate[], public startIndex: number) { }
}

export class HandoverUpdateSectionsSuccess implements Action {
  readonly type = HandoverActionTypes.HandoverUpdateSectionsSuccess;

  constructor(public sections: HandoverSectionTemplate[]) { }
}

export class HandoverChangeSelectedPart implements Action {
  readonly type = HandoverActionTypes.HandoverChangeSelectedPart;

  constructor(public handoverPart: number) { }
}

export class HandoverDeleteSection implements Action {
  readonly type = HandoverActionTypes.HandoverDeleteSection;

  constructor(public sectionId: number) { }
}

export class HandoverUpdateSignature implements Action {
  readonly type = HandoverActionTypes.HandoverUpdateSignature;

  constructor(public data: { handoverId: number; signing: boolean }) { }
}

export class HandoverSoftDelete implements Action {
  readonly type = HandoverActionTypes.HandoverSoftDelete;

  constructor(public handoverId: number) { }
}

export type HandoverAction =
  | HandoverLoad
  | HandoverLoadSuccess
  | HandoverUnload
  | HandoverSectionTemplatesLoad
  | HandoverSectionTemplatesLoadSuccess
  | HandoverFileUpload
  | HandoverFileDelete
  | HandoverCreateSection
  | HandoverCreateSectionSuccess
  | HandoverSaveSection
  | HandoverSaveSections
  | HandoverChangeSectionOrdinals
  | HandoverUpdateSectionsSuccess
  | HandoverUpdateRow
  | HandoverUpdateRowSuccess
  | HandoverChangeSelectedPart
  | HandoverDeleteSection
  | HandoverUpdateSignature
  | HandoverPatch
  | HandoverUpdateFrontPage
  | HandoverSoftDelete;
