import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CertificateType, ResourceType } from '@completion/enums';
import { CertificateDetails, SignatureEntryMatrix, SignatureMatrix, SignatureMatrixTemplates } from '@completion/models';
import { BaseService, Resource } from './base.service';
import { Store } from '@ngrx/store';
import { ProjectState } from '../store/reducers/project';

@Injectable({
  providedIn: 'root'
})
export class SignatureMatrixService extends BaseService {
  constructor(readonly http: HttpClient, private readonly store: Store<ProjectState>) {
    super(http);
  }

  @Resource(ResourceType.SaveSignatureMatrix)
  certificateSaveSignatureMatrix(
    projectId: number,
    cpId: number,
    certType: CertificateType,
    certId: number,
    entries: SignatureEntryMatrix
  ): Observable<CertificateDetails> {
    // The BE expects a `signeeId` (number) and no `signee` User object, so we strip that.
    // It also expects an `ordinal` property

    const signee = undefined;
    const patchedEntries = entries.map((signatureEntry, idx) => ({
      ...signatureEntry,
      signee,
      signeeId: signatureEntry.signee.id,
      ordinal: idx + 1
    }));

    return this.http.put<CertificateDetails>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/certificates/${certType.toLowerCase()}/${certId}/signaturematrix`,
      patchedEntries
    );
  }

  @Resource(ResourceType.SaveSignatureMatrix)
  handoverSaveSignatureMatrix(
    projectId: number,
    cpId: number,
    handoverId: number,
    entries: SignatureMatrix
  ): Observable<SignatureMatrix> {
    // The BE expects a `signeeId` (number) and no `signee` User object, so we strip that.
    // It also expects an `ordinal` property

    const signee = undefined;
    const patchedEntries = entries.map((signatureEntry, idx) => ({
      ...signatureEntry,
      signee,
      signeeId: signatureEntry.signee ? signatureEntry.signee.id : null,
      ordinal: signatureEntry.ordinal
    }));

    return this.http.put<SignatureMatrix>(
      `${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/signaturematrix`,
      patchedEntries
    );
  }

  @Resource(ResourceType.FetchDefaultSignatureMatrixces)
  fetchDefaultSignatureMatrices(projectId: number): Observable<SignatureMatrix[]> {
    return this.http.get<SignatureMatrix[]>(`${this.baseUrl}/projects/${projectId}/defaultsignaturematrix`);
  }

  @Resource(ResourceType.DeleteSignatureMatrixTemplate)
  deleteSignatureMatrixTemplate(projectId: number, matrixId: number): Observable<SignatureMatrixTemplates> {
    return this.http.delete<SignatureMatrixTemplates>(`${this.baseUrl}/projects/${projectId}/signaturematrixTemplate/${matrixId}`);
  }

  @Resource(ResourceType.UpdateSignatureMatrixTemplate)
  updateSignatureMatrixTemplate(projectId: number, matrix: SignatureMatrixTemplates): Observable<SignatureMatrixTemplates> {
    return this.http.put<SignatureMatrixTemplates>(`${this.baseUrl}/projects/${projectId}/signaturematrixTemplate`, matrix);
  }

  @Resource(ResourceType.AddSignatureMatrixTemplate)
  addSignatureMatrixTemplate(projectId: number, matrix: SignatureMatrixTemplates): Observable<SignatureMatrixTemplates> {
    return this.http.post<SignatureMatrixTemplates>(`${this.baseUrl}/projects/${projectId}/signaturematrixTemplate`, matrix);
  }

  @Resource(ResourceType.AddUnsignedSignatureMatrices)
  addUnsignedSignatureMatrices(projectId: number, matrix: SignatureMatrix[]): Observable<SignatureMatrix[]> {
    return this.http.post<SignatureMatrix[]>(`${this.baseUrl}/projects/${projectId}/signatureMatrix/addUnsigned`, matrix);
  }

  @Resource(ResourceType.SaveSignatureMatrix)
  saveSignatureMatrix(projectId: number, matrix: SignatureEntryMatrix[]): Observable<SignatureEntryMatrix[]> {
    return this.http.put<SignatureEntryMatrix[]>(`${this.baseUrl}/projects/${projectId}/signatureMatrix/save`, matrix);
  }

  @Resource(ResourceType.DeleteSignatureMatrix)
  deleteSignatureMatrix(projectId: number, matrixId: number): Observable<SignatureMatrix[]> {
    return this.http.delete<SignatureMatrix[]>(`${this.baseUrl}/projects/${projectId}/signatureMatrix/${matrixId}`);
  }

  @Resource(ResourceType.InsertAndSignSignatureMatrix)
  insertAndSignSignatureMatrix(projectId: number, parentId:number,  entry: SignatureEntryMatrix): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/projects/${projectId}/signatureMatrix/parent/${parentId}/insertAndSign`, entry);
  }

  @Resource(ResourceType.ToggleSignSignatureMatrix)
  toggleSignSignatureMatrix(projectId: number, matrixId: number): Observable<SignatureMatrix> {
    return this.http.put<SignatureMatrix>(`${this.baseUrl}/projects/${projectId}/signatureMatrix/${matrixId}/toggleSign`, {});
  }
}
