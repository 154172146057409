<ng-container *ngIf="router.url === '/health'">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="router.url !== '/health'">
  <app-header [apdTitle]="title" titleClickUrl="/dashboard"></app-header>>
  <div class="page-container">
    <app-side-navigation [isOnline]="!offlineMode"></app-side-navigation>
    <div class="main-container">
      <app-context-toolbar [isOnline]="!offlineMode" [isAdmin]="isAdmin"></app-context-toolbar>
      <main class="page-content">
        <router-outlet></router-outlet>
      </main>
      <footer *ngIf="offlineMode">
        <app-offline-toolbar></app-offline-toolbar>
      </footer>
    </div>
  </div>
</ng-container>
