import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from "@angular/material/tabs";

import { OfflineDownloadsModule } from '../offline-downloads/offline-downloads.module';
import { OfflineDownloadsOverviewComponent } from './offline-downloads-overview.component';

@NgModule({
  declarations: [OfflineDownloadsOverviewComponent],
  imports: [CommonModule, MatTabsModule, OfflineDownloadsModule],
  exports: [OfflineDownloadsOverviewComponent]
})
export class OfflineDownloadsOverview {}
