import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ChangeCurrentCpId, ChangeCurrentCsaId, ChangeCurrentMcpId, CurrentUserLoad, ProjectsLoad } from "@completion/actions";
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';

import { CpState } from '../store/reducers/cp';
import { getCurrentUser } from '../store/selectors/user.selector';
import { User } from '@completion/models';
import { getCurrentProject } from '../store/selectors/project.selector';

@Injectable({
  providedIn: 'root'
})
export class RouteParamsResolverService implements Resolve<any> {
  constructor(private readonly store: Store<CpState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    const cpId = +route.paramMap.get('id');
    if (cpId) {
      this.store.dispatch(new ChangeCurrentCpId(cpId));
    }
    const mcpId = +route.paramMap.get('mcpId');
    if (mcpId) {
      this.store.dispatch(new ChangeCurrentMcpId(mcpId));
    }

    const csId = +route.paramMap.get('csId');
    if (csId) {
      this.store.dispatch(new ChangeCurrentCsaId(csId));
    }
    return null;
  }
}
