import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HandoverTemplateState } from '../reducers/handover-template/index';

const getHandoverTemplateFeatureState = createFeatureSelector<HandoverTemplateState>('handoverTemplate');

export const getCurrentHandoverTemplateId = createSelector(
  getHandoverTemplateFeatureState,
  (state: HandoverTemplateState) => {
    const handoverTemplate = state.handoverTemplate;
    return handoverTemplate ? handoverTemplate.id : null;
  }
);

export const getHandoverTemplate = createSelector(
  getHandoverTemplateFeatureState,
  (state: HandoverTemplateState) => state.handoverTemplate
);
