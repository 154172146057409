import { HandoverDocsAction, HandoverDocsActionTypes } from '@completion/actions';
import { Cpsi } from '@completion/models';

export const initialState: Cpsi = null;

export function cpsiReducer(state: Cpsi = initialState, action: HandoverDocsAction): Cpsi {
  switch (action.type) {
    case HandoverDocsActionTypes.CpsiLoadSuccess: {
      return action.cpsi;
    }

    default:
      return state;
  }
}
