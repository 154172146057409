import { CpAction, CpActionTypes } from '@completion/actions';
import { CommissioningPackage, cpSort, McPackage } from '@completion/models';

export const initialState: CpState = {
  cps: [],
  currentCpId: null,
  currentCp: null,
  mcps: [],
  latestError: null,
  responseStatus: null
};

export interface CpState {
  cps: CommissioningPackage[];
  currentCpId: number;
  currentCp: CommissioningPackage;
  mcps: McPackage[];
  latestError: string;
  responseStatus: number;
}

export function commPkgsReducer(state: CpState = initialState, action: CpAction): CpState {
  switch (action.type) {
    case CpActionTypes.ChangeCurrentCpId:
      const resetCp = action.cpId !== state.currentCpId;
      return {
        ...state,
        currentCpId: action.cpId,
        currentCp: resetCp ? null : state.currentCp
      };
    case CpActionTypes.LoadCurrentCpSuccess:
      return {
        ...state,
        currentCp: action.cp
      };
    case CpActionTypes.CpsLoadSuccess:
      return {
        ...state,
        cps: [...action.commissioningPackages].sort(cpSort)
      };
    case CpActionTypes.CpUpdateSuccess:
      const newCps = [...state.cps];
      const cpIndex = newCps.findIndex(cp => cp.id === action.cp.id);
      newCps.splice(cpIndex, 1, action.cp);
      return {
        ...state,
        currentCp: action.cp,
        cps: newCps,
        latestError: null
      };
    case CpActionTypes.CpUpdateFailure:
      return {
        ...state,
        latestError: action.errorMessage
      };
    case CpActionTypes.ClearCurrentCp:
      return {
        ...state,
        currentCpId: null,
        currentCp: null,
        mcps: []
      };
    case CpActionTypes.LoadMcpsForCpSuccess:
      return {
        ...state,
        mcps: action.mcps
      };
    case CpActionTypes.CreateMcpsSuccess:
      const newCpsList = [...state.cps];
      const index = newCpsList.findIndex(cp => cp.id === action.cpId);
      newCpsList.splice(index, 1, { ...state.cps[index], mcPackages: action.mcps });
      return {
        ...state,
        mcps: action.mcps,
        cps: newCpsList
      };
    case CpActionTypes.ClearLatestError:
      return {
        ...state,
        latestError: null,
        responseStatus: null
      };
    default:
      return state;
  }
}
