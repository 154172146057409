import { Action } from '@ngrx/store';

import { CheckSheetAssignment, CommissioningPackage, Tag } from '@completion/models';




export enum TagActionTypes {
  LoadAvailableMcpTags = 'Load Available Tags',
  LoadAvailableTagsSuccess = 'Load Available Tags Success',
  LoadAvailableCpTags = 'Load Available Cp Tags',
  SetSerialNumberMcp = 'Set Serial Number Mcp',
  SetSerialNumberCp = 'Set Serial Number Cp',
  SearchTags = "SearchTags",
  LoadSearchTagsSuccess = "LoadSearchTagsSuccess"
}

export class LoadAvailableMcpTags implements Action {
  readonly type = TagActionTypes.LoadAvailableMcpTags;
}

export class LoadAvailableTagsSuccess implements Action {
  readonly type = TagActionTypes.LoadAvailableTagsSuccess;

  constructor(public tags: Tag[]) { }
}

export class LoadAvailableCpTags implements Action {
  readonly type = TagActionTypes.LoadAvailableCpTags;
}

export class SetSerialNumberCp implements Action {
  readonly type = TagActionTypes.SetSerialNumberCp;
  constructor(public tag: Tag) { }
}

export class SetSerialNumberMcp implements Action {
  readonly type = TagActionTypes.SetSerialNumberMcp;
  constructor(public tag: Tag) { }
}

export class SearchTags implements Action {
  readonly type = TagActionTypes.SearchTags;
  constructor(public search: string) { }
}

export class SearchTagsSuccess implements Action {
  readonly type = TagActionTypes.LoadSearchTagsSuccess;
  constructor(public csas: CheckSheetAssignment[]) { }
}

export type TagAction = LoadAvailableMcpTags | LoadAvailableTagsSuccess | LoadAvailableCpTags | SetSerialNumberCp | SetSerialNumberMcp | SearchTags | SearchTagsSuccess;
