import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function triggerValidationAllFields(formGroup: FormGroup | FormArray) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);

    if (control instanceof FormControl) {
      if (control.enabled) {
        control.markAsTouched({ onlySelf: true });
      } else {
        control.markAsUntouched({ onlySelf: true });
      }
    } else if (control instanceof FormGroup) {
      triggerValidationAllFields(control);
    } else if (control instanceof FormArray) {
      Object.keys(control.controls).forEach(index => {
        triggerValidationAllFields(control.get(index) as FormGroup);
      });
    }
  });
}

export function validateUser(formControl: FormControl) {
  if (!formControl.value) {
    return null;
  }
  if (formControl.value instanceof Object && formControl.value.id && formControl.value.userName) {
    return null;
  }

  return {
    user: {
      valid: false
    }
  };
}
