import { CheckSheetAssignment } from '../models/check-sheet-assignment';

export function getSignVerifyValue(doSignOrVerify: string, assignment: CheckSheetAssignment): number {
  switch (doSignOrVerify) {
    case 'doSign': {
      return assignment.signedBy ? 0 : 1;
    }
    case 'doVerify': {
      return assignment.verifiedBy ? 0 : 1;
    }
  }
}
