import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectAccessState } from '../reducers/project-access';

const getProjectAccessFeatureState = createFeatureSelector<ProjectAccessState>('projectAccess');

export const getProjectAccess = createSelector(
  getProjectAccessFeatureState,
  (state: ProjectAccessState) => state.projectAccess
);

export const getProjectAccessList = createSelector(
  getProjectAccessFeatureState,
  (state: ProjectAccessState) => state.projectAccessList
);

export const getRole = createSelector(
  getProjectAccessFeatureState,
  (state: ProjectAccessState) => state.role
);
