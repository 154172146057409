<div class="grey-title">
  <span>&nbsp;</span>
  <h2 mat-dialog-title *ngIf="editMode; else createPunch">Update punch #{{ punch.punchNumber }}</h2>
  <ng-template #createPunch>
    <h2 mat-dialog-title>Create punch</h2>
  </ng-template>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="dialog-header" *ngIf="!cameraMode">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <app-breadcrumb [breadcrumbs]="breadcrumbs$" [isParentIncluded]="breadcrumbsParentInclude"></app-breadcrumb>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent *ngIf="punch">
        <p>
          {{ punch.relatedItem.name }}
        </p>

        <div class="key-values">
          <div class="key-value"><span>Area:</span> {{ (package$ | async)?.area?.name }}</div>
          <div class="key-value"><span>Delivery ID:</span> {{ punch.relatedTag.delivery?.deliveryNumber }}</div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </div>
  <app-punch-action *ngIf="displayPunchActions()" [punch]="punch" [class.hidden]="cameraMode"></app-punch-action>
  <form [formGroup]="form" [class.hidden]="cameraMode" (ngSubmit)="onSave(); disabled = form.valid" id="ngForm" autocomplete="off">
    <div>
      <app-status-circle [status]="punch.category"></app-status-circle>

      <mat-radio-group
        *ngIf="!punch.acceptedBy"
        aria-label="punch category"
        formControlName="category"
        labelPosition="before"
        [(value)]="punch.category"
        (change)="changeCategory($event)"
        name="category"
      >
        <mat-radio-button value="PB">Punch B</mat-radio-button>
        <mat-radio-button value="PA">Punch A</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-field">
      <!--Description form field-->
      <mat-form-field>
        <input
          matInput
          required
          #description
          name="description"
          type="text"
          placeholder="Description"
          aria-label="description"
          maxlength="254"
          formControlName="description"
        />
        <mat-hint>Max 254 characters</mat-hint>
        <mat-hint align="end">{{ description.value?.length || 0 }}/254</mat-hint>
        <mat-error *ngIf="form.controls['description'].hasError('required')"> Description is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>
    <div class="inline-forms">
      <!--Raised by Company form field-->
      <mat-form-field>
        <mat-select required formControlName="raisedBy" name="raisedBy" placeholder="Raised by Company" [compareWith]="hasSameIds">
          <mat-option *ngFor="let company of companies$ | async" [value]="company">{{ company.compNumber }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['raisedBy'].hasError('required')"> Raised By is <strong>required</strong> </mat-error>
      </mat-form-field>

      <!--Due date form field-->
      <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Due date" formControlName="due" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="inline-forms">
      <!--Assigned to Company form field-->
      <mat-form-field>
        <mat-select
          required
          formControlName="assignedCompany"
          placeholder="Assigned to Company"
          [compareWith]="hasSameIds"
          name="assignedCompany"
          (selectionChange)="assignedCompanyChange($event)"
        >
          <mat-option *ngFor="let company of companies$ | async" [value]="company">{{ company.compNumber }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['assignedCompany'].hasError('required')"> Assigned company is <strong>required</strong> </mat-error>
      </mat-form-field>

      <!--Assigned to Person form field-->
      <app-user-with-date
        label="Assigned to Person"
        name="assignedUsers"
        [assignmentCandidates]="assignedUsers"
        [control]="form.controls['assignedUser']"
      ></app-user-with-date>
    </div>

    <div class="form-field" *ngIf="editMode">
      <!--Notes form field-->
      <mat-form-field>
        <textarea
          matInput
          cdkTextareaAutosize
          #notes
          name="notes"
          type="text"
          placeholder="Notes"
          aria-label="notes"
          maxlength="2500"
          formControlName="notes"
        ></textarea>
        <mat-hint>Max 2500 characters</mat-hint>
        <mat-hint align="end">{{ notes.value?.length || 0 }}/2500</mat-hint>
      </mat-form-field>
    </div>
  </form>
  <app-file-upload (toggleCamera)="cameraMode = $event" (files)="onChangeUploadedFiles($event)"></app-file-upload>
  <ng-container *ngIf="!cameraMode">
    <div *ngFor="let attachment of punch.attachments; let i = index" class="attachment-buttons">
      <button mat-button name="attachment" class="attachment" (click)="openFile(attachment)">{{ attachment.name }}</button>
      <button mat-icon-button class="remove-attachments" (click)="removeAttachment(i, attachment)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!cameraMode">
  <div class="error-message">
    <app-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-error-message>
  </div>

  <div>
    <button mat-button [disabled]="punch.clearedBy && !isAdmin()" class="delete" (click)="onDelete()" name="delete" *ngIf="editMode">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
    <app-commit-button
      [isRoundButton]="true"
      [disabled]="disabled"
      [buttonStatus]="buttonStatus"
      buttonTestId="punchDialogSave"
      (commitButtonClicked)="onSave()"
      idleLabel="Save"
    ></app-commit-button>
  </div>
</mat-dialog-actions>
