<div class="breadcrumbs">
  <ng-container *ngFor="let crumb of breadCrumbList; let last = last; let first = first">
    <span *ngIf="!first" class="separator">
      <i class="material-icons">keyboard_arrow_right</i>
    </span>
    <a *ngIf="crumb.url && !last" [routerLink]="[crumb.url]" [matTooltip]="crumb.name">
      {{ crumb.number }}
    </a>
    <span [matTooltip]="crumb.name" *ngIf="!crumb.url && !last" [class.not-last]="!last">{{ crumb.number }}</span>
    <span *ngIf="last">{{ crumb.number }}</span>
  </ng-container>
</div>
