import {CheckSheetAssignment, Punch} from "@completion/models";
import {ReportAction, ReportsActionTypes} from "@completion/actions";


export const initialState: ReportState = {
  punches: [],
  checkSheetAssignments: []
};

export interface ReportState {
  punches: Punch[];
  checkSheetAssignments: CheckSheetAssignment[];
}

export function reportsReducer(state: ReportState = initialState, action: ReportAction): ReportState {
  switch (action.type) {
    case ReportsActionTypes.LoadPunchesForBurnDownSuccess:
      return {
        ...state,
        punches: action.data
      };
    case ReportsActionTypes.LoadCheckSheetAssignmentForReportSuccess:
      return {
        ...state,
        checkSheetAssignments: action.data
      };
    default:
      return state;
  }
}
