<div class="grey-title">
  <span>&nbsp;</span>
  <h2 mat-dialog-title>Inject Check Sheet Item Values</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="dataForm" (ngSubmit)="onSave()" id="dataForm" autocomplete="off">
    <div class="cell site-name">
      <mat-form-field class="example-full-width">
        <mat-label>Data</mat-label>
        <textarea
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="5"
          formControlName="jsonData"
          matInput
          placeholder="Check status item values"
          class="data-textarea"
        ></textarea>
      </mat-form-field>
    </div>

    <mat-dialog-actions>
      <div class="error-message">
        <app-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-error-message>
      </div>

      <div>
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <app-commit-button
          [isRoundButton]="true"
          [buttonStatus]="buttonStatus"
          buttonTestId="userEditDialogSave"
          (commitButtonClicked)="onSave()"
          idleLabel="Save"
        ></app-commit-button>
      </div>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
