import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {ToastrService} from "ngx-toastr";
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import {
  HandoverTemplateActionTypes,
  HandoverTemplateAdd,
  HandoverTemplateChangeOrdinals,
  HandoverTemplateDelete,
  HandoverTemplateLoad,
  HandoverTemplateLoadSuccess,
  HandoverTemplateUpdate,
  HandoverTemplateUpdateSuccess
} from '@completion/actions';
import { HandoverTemplate } from '@completion/models';
import { State } from '@completion/reducers';
import { getCurrentProject } from '@completion/selectors';
import { HandoverTemplateService } from '../../services/handover-template.service';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class HandoverTemplateEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly handoverTemplateService: HandoverTemplateService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, handoverTemplateService, store, toastr);
  }

  @Effect({ dispatch: true })
  loadHandoverTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverTemplateLoad>(HandoverTemplateActionTypes.HandoverTemplateLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getHandoverTemplate', HandoverTemplateLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  changeTemplateOrdinals$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverTemplateChangeOrdinals>(HandoverTemplateActionTypes.HandoverTemplateChangeOrdinals),
    withLatestFrom(...this.multiSelect(getCurrentProject)),
    map(([action, currentProject]) => {
      // Emitting fields not relevant for the ordinal change
      // @ts-ignore
      const sections: HandoverTemplate = action.sections.map((section, index) => ({
        id: section.id,
        ordinal: index + action.startIndex + 1,
        projectId: section.projectId
      }));

      return [currentProject, sections];
    }),
    this.fetchResource('updateHandoverTemplateOrdinals', HandoverTemplateUpdateSuccess, ([currentProject, sections]) => [
      currentProject.id,
      sections
    ])
  );

  @Effect()
  deleteHandoverTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverTemplateDelete>(HandoverTemplateActionTypes.HandoverTemplateDelete),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('deleteHandoverTemplate', HandoverTemplateUpdateSuccess, ([action, currentProject]) => [
      currentProject.id,
      action.handoverTemplate.id
    ])
  );

  @Effect()
  addHandoverTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverTemplateAdd>(HandoverTemplateActionTypes.HandoverTemplateAdd),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('addHandoverTemplate', HandoverTemplateUpdateSuccess, ([action, currentProject]) => [
      currentProject.id,
      action.handoverTemplate
    ])
  );

  @Effect()
  updateHandoverTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverTemplateUpdate>(HandoverTemplateActionTypes.HandoverTemplateUpdate),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateHandoverTemplate', HandoverTemplateUpdateSuccess, ([action, currentProject]) => [
      currentProject.id,
      action.handoverTemplates
    ])
  );
}
