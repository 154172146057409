import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HandoverState } from '../reducers/handover/index';

const getHandoverFeatureState = createFeatureSelector<HandoverState>('handover');

export const getCurrentHandoverId = createSelector(
  getHandoverFeatureState,
  (state: HandoverState) => {
    const handover = state.parts.find(part => part.partNumber === state.selectedHandoverPart);
    return handover ? handover.id : null;
  }
);

export const getHandover = createSelector(
  getHandoverFeatureState,
  (state: HandoverState) => state.parts
);

export const getHandoverSectionTemplate = createSelector(
  getHandoverFeatureState,
  (state: HandoverState, sectionId: number) => state.sectionTemplates.find(section => section.id === sectionId)
);

export const getHandoverSectionTemplates = createSelector(
  getHandoverFeatureState,
  (state: HandoverState) => state.sectionTemplates
);

export const getSelectedHandoverPart = createSelector(
  getHandoverFeatureState,
  (state: HandoverState) => state.parts.find(part => part.partNumber === state.selectedHandoverPart)
);

export const getHandoverPartNumbers = createSelector(
  getHandoverFeatureState,
  (state: HandoverState) => state.parts.map(part => part.partNumber)
);
