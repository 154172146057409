import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {ToastrService} from "ngx-toastr";
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import {
  CompanyUsers,
  CompanyUsersSuccess,
  CurrentUserLoadSuccess,
  ProjectUsers,
  ProjectUsersSuccess,
  UserActionTypes
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentProject } from '@completion/selectors';
import { UserService } from '../../services';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class UserEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly service: UserService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, service, store, toastr);
  }

  @Effect()
  loadCurrentUser$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.CurrentUserLoad),
    this.fetchResource('getCurrentUser', CurrentUserLoadSuccess)
  );

  @Effect()
  loadProjectUsers$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectUsers>(UserActionTypes.ProjectUsers),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getUsers', ProjectUsersSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadCompanyUsers$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyUsers>(UserActionTypes.CompanyUsers),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getUsers', CompanyUsersSuccess, ([action, currentProject]) => [currentProject.id, action.companyId])
  );
}
