import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  CpScopeOfWork,
  LoadAvailableCpTags,
  LoadAvailableMcpTags,
  LoadAvailableTagsSuccess, McpScopeOfWork, SearchTags, SearchTagsSuccess, SetSerialNumberCp, SetSerialNumberMcp,
  TagActionTypes
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentMcpId, getCurrentProject } from '@completion/selectors';
import { TagService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import { ToastrService } from "ngx-toastr";

const mapTagsToProject = ([_, project, cpId]) => [project.id, cpId];

@Injectable()
export class TagEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly service: TagService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, service, store, toastr);
  }

  @Effect()
  loadAvailableMcpTags$: Observable<Action> = this.actions$.pipe(
    ofType<LoadAvailableMcpTags>(TagActionTypes.LoadAvailableMcpTags),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, project, mcpId]) => project != null && mcpId != null),
    this.fetchResource('getMcpAvailableTags', LoadAvailableTagsSuccess, mapTagsToProject)
  );

  @Effect()
  loadAvailableCpTags$: Observable<Action> = this.actions$.pipe(
    ofType<LoadAvailableCpTags>(TagActionTypes.LoadAvailableCpTags),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, project, cpId]) => project != null && cpId != null),
    this.fetchResource('getCpAvailableTags', LoadAvailableTagsSuccess, mapTagsToProject)
  );

  @Effect()
  setSerialNumberTagCp$: Observable<Action> = this.actions$.pipe(
    ofType<SetSerialNumberCp>(TagActionTypes.SetSerialNumberCp),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('setSerialNumberTag', CpScopeOfWork, ([action, currentProject]) => [currentProject.id, action.tag])
  );

  @Effect()
  setSerialNumberTagMcp$: Observable<Action> = this.actions$.pipe(
    ofType<SetSerialNumberMcp>(TagActionTypes.SetSerialNumberMcp),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('setSerialNumberTag', McpScopeOfWork, ([action, currentProject]) => [currentProject.id, action.tag])
  );

  @Effect()
  searchTags$: Observable<Action> = this.actions$.pipe(
    ofType<SearchTags>(TagActionTypes.SearchTags),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('searchTags', SearchTagsSuccess, ([action, currentProject]) => [currentProject.id, action.search])
  );
}
