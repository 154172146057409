import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NumberValueAccessor } from '@angular/forms';
import { ProjectConfigUpdate } from '@completion/actions';
import { ProjectConfigs } from '@completion/enums';
import { ProjectConfig } from '@completion/models';
import { getCurrentConfig } from '@completion/selectors';
import { Store } from '@ngrx/store';
import { Subject, take, takeUntil } from 'rxjs';
import { ProjectState } from 'src/app/core/store/reducers/project';

@Component({
  selector: 'app-project-config',
  templateUrl: './project-config.component.html',
  styleUrls: ['./project-config.component.scss']
})
export class ProjectConfigComponent implements OnInit, OnDestroy {

  config: ProjectConfig[];
  @Input() projectId: number;
  private readonly TRUE = 'true';
  private readonly FALSE = 'false';
  selectedOptions: string[] = [];
  mergedConfig: ProjectConfig[] = [];

  destroy$ = new Subject<void>();

  constructor(private readonly store: Store<ProjectState>,) { }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.store.select(getCurrentConfig).pipe(takeUntil(this.destroy$)).subscribe(cfg => {
      this.config = cfg;
      this.mergedConfig = [];
      for (let config in ProjectConfigs) {
        let value = 'false';
        const existing = this.config.find(pc => pc.name === config);
        if (existing) {
          value = existing.value;
          if (value === 'true') {
            this.selectedOptions.push(config)
          }
        }
        this.mergedConfig.push({ name: config, projectId: this.projectId, value, description: ProjectConfigs[config] } as ProjectConfig);
      }
    })
  }

  onChange(event: Event) {
    // detect if a config has been changed to true
    this.mergedConfig.forEach(mc => {
      if (this.selectedOptions.find(so => so === mc.name) && mc.value === this.FALSE) {
        mc.value = this.TRUE;
        this.store.dispatch(new ProjectConfigUpdate(mc));
      }
      if (this.selectedOptions.find(so => so === mc.name) === undefined && mc.value === this.TRUE) {
        mc.value = this.FALSE;
        this.store.dispatch(new ProjectConfigUpdate(mc));
      }
    });
  }

}
