<header>
  <div class="main-header apd-dark-theme">
    <div class="logo-container">
      <a [href]="logoClickUrl" class="logo" cdkMonitorElementFocus>
        <div alt="Aize logo">
          <img height="25" width="25" src="assets/img/logo-white.svg" />
        </div>
      </a>
      <h2 class="app-title">
        <a [href]="titleClickUrl" *ngIf="!!titleClickUrl; else titleText">{{ apdTitle }}</a>
        <ng-template #titleText>{{ apdTitle }}</ng-template>
      </h2>
    </div>
    <ng-content select="[apdTool]"></ng-content>
    <div class="toolbar-button-group">
      <button class="support-button" mat-button [matMenuTriggerFor]="menu" mat-icon-button>
        <svg
          width="22px"
          height="22px"
          viewBox="0 0 22 22"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Support</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-4" transform="translate(-1.000000, -1.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g id="Group-3" transform="translate(9.000000, 1.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <path
                  d="M3,0 C4.65685425,0 6,1.34314575 6,3 C6,4.65685425 4.65685425,6 3,6 C1.34314575,6 0,4.65685425 0,3 C0,1.34314575 1.34314575,0 3,0 Z M3,1.5 C2.17157288,1.5 1.5,2.17157288 1.5,3 C1.5,3.82842712 2.17157288,4.5 3,4.5 C3.82842712,4.5 4.5,3.82842712 4.5,3 C4.5,2.17157288 3.82842712,1.5 3,1.5 Z"
                  id="Oval"
                ></path>
              </g>
              <rect id="Rectangle" fill="#FFFFFF" x="6" y="17" width="12" height="1"></rect>
              <rect id="Rectangle" fill="#FFFFFF" x="1" y="13" width="22" height="2"></rect>
              <path
                d="M12,8 C14.6125579,8 16.8351204,9.66976896 17.6587023,12.0003218 L16.0321347,11.9997878 C15.2957429,10.5181843 13.7667763,9.5 12,9.5 C10.2332237,9.5 8.70425709,10.5181843 7.96786527,11.9997878 L6.34129768,12.0003218 C7.16487963,9.66976896 9.38744209,8 12,8 Z"
                id="Combined-Shape"
                fill="#FFFFFF"
                fill-rule="nonzero"
              ></path>
              <path
                d="M21,13 L21,23 L3,23 L3,13 L21,13 Z M20,14 L4,14 L4,22 L20,22 L20,14 Z"
                id="Rectangle"
                fill="#FFFFFF"
                fill-rule="nonzero"
              ></path>
            </g>
          </g>
        </svg>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>bug_report</mat-icon>
          <a
            class="support-link"
            href="https://servicenow.akersolutions.com/esc?id=sc_cat_item&sys_id=98a848aa87e76110664862040cbb35a5"
            target="_blank"
            ><span>Report incident</span></a
          >
        </button>
        <button mat-menu-item>
          <mat-icon>library_add</mat-icon>
          <a
            class="support-link"
            href="https://servicenow.akersolutions.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=2a2998c797d76910c511f0b0f053af8a"
            target="_blank"
            ><span>Add change request</span></a
          >
        </button>
      </mat-menu>
      <app-user class="apd-user" *ngIf="!hideUserLogin"><ng-content select="[HeaderMenuItems]"></ng-content></app-user>
    </div>
  </div>
  <mat-progress-bar
    *ngIf="isLoading | async"
    [@fadeInAnimation]="{ value: ':enter', params: { delay: animationTime } }"
    mode="indeterminate"
  >
  </mat-progress-bar>
</header>
