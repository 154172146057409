import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HandoverDocsObjectTypeNames, ResourceType } from '@completion/enums';
import { Cppl, Cpsi, Mcpl, Mcsi } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class HandoverDocsService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.HandoverDocsGetMcsi)
  getMcsi(projectId: number, cpId: number, handoverId: number, rfccId: number): Observable<Mcsi> {
    return this.http
      .get<Mcsi>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/mcsi/${rfccId}`, {
        headers: { accept: 'application/json' }
      })
      .pipe(map(translateObjectTypeNames));
  }

  @Resource(ResourceType.HandoverDocsGetCpsi)
  getCpsi(projectId: number, cpId: number, handoverId: number): Observable<Cpsi> {
    return this.http.get<Cpsi>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/cpsi`);
  }

  @Resource(ResourceType.HandoverDocsGetCppl)
  getCppl(projectId: number, cpId: number, handoverId: number): Observable<Cppl> {
    return this.http.get<Cppl>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/cppl`, {
      headers: { accept: 'application/json' }
    });
  }

  @Resource(ResourceType.HandoverDocsGetCppl)
  getMcpl(projectId: number, cpId: number, handoverId: number, rfccId: number): Observable<Cppl> {
    return this.http.get<Mcpl>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/mcpl/${rfccId}`, {
      headers: { accept: 'application/json' }
    });
  }
}

function translateObjectTypeNames(mcsi: Mcsi) {
  mcsi.mcPackages.forEach(mcPackage =>
    mcPackage.assignments.forEach(csa => (csa.tagObjectType = HandoverDocsObjectTypeNames[csa.tagObjectType] || csa.tagObjectType))
  );
  return mcsi;
}
