import { Action } from '@ngrx/store';

import { HandoverTemplate } from '@completion/models';

export enum HandoverTemplateActionTypes {
  HandoverTemplateLoad = 'Request Handover template Load',
  HandoverTemplateLoadSuccess = 'Handover template Load Success',
  HandoverTemplateUnload = 'Unload handover template',
  HandoverTemplateChangeOrdinals = 'Alter handover template ordinals',
  HandoverTemplateUpdateSuccess = 'Handover template update success',
  HandoverTemplateDelete = 'Delete handover template',
  HandoverTemplateAdd = 'Add handover template',
  HandoverTemplateUpdate = 'Update handover template'
}

export class HandoverTemplateLoad implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateLoad;
  constructor(public projectId: number) {}
}

export class HandoverTemplateLoadSuccess implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateLoadSuccess;

  constructor(public handoverTemplate: HandoverTemplate) {}
}

export class HandoverTemplateUnload implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateUnload;
}

export class HandoverTemplateChangeOrdinals implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateChangeOrdinals;

  constructor(public sections: HandoverTemplate[], public startIndex: number) {}
}

export class HandoverTemplateDelete implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateDelete;

  constructor(public handoverTemplate: HandoverTemplate) {}
}

export class HandoverTemplateAdd implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateAdd;
  constructor(public handoverTemplate: HandoverTemplate) {}
}

export class HandoverTemplateUpdate implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateUpdate;
  constructor(public handoverTemplates: HandoverTemplate[]) {}
}

export class HandoverTemplateUpdateSuccess implements Action {
  readonly type = HandoverTemplateActionTypes.HandoverTemplateUpdateSuccess;
  constructor(public handoverTemplate: HandoverTemplate[]) {}
}

export type HandoverTemplateAction =
  | HandoverTemplateLoad
  | HandoverTemplateLoadSuccess
  | HandoverTemplateUnload
  | HandoverTemplateChangeOrdinals
  | HandoverTemplateUpdateSuccess
  | HandoverTemplateDelete
  | HandoverTemplateAdd
  | HandoverTemplateUpdate;
