import { TestingUtilityActions, TestingUtilityActionTypes, UserActions, UserActionTypes } from '@completion/actions';
import { byName, User } from '@completion/models';
import {UserPreference} from "../../../../shared/enums/user-preference";

export const initialState: UserState = {
  currentUser: {
    id: 0,
    name: '',
    userName: ''
  },
  currentUserPreferences: [],
  projectUsers: [],
  companyUsers: []
};

export interface UserState {
  currentUser: User;
  currentUserPreferences?: UserPreference[];
  projectUsers: User[];
  companyUsers: User[];
}

export function userReducer(state: UserState = initialState, action: UserActions | TestingUtilityActions): UserState {
  switch (action.type) {
    case UserActionTypes.CurrentUserLoadSuccess:
      return {
        ...state,
        currentUser: action.currentUser,
        currentUserPreferences: action.currentUser.preferences
      };
    case UserActionTypes.ProjectUsersSuccess:
      return {
        ...state,
        projectUsers: [...action.users].sort(byName)
      };
    case UserActionTypes.ClearProjectUsers:
      return {
        ...state,
        projectUsers: [],
        companyUsers: []
      };
    case UserActionTypes.CompanyUsersSuccess:
      return {
        ...state,
        companyUsers: [...action.users].sort(byName)
      };
    case TestingUtilityActionTypes.AddTestPermissions:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          permissions: action.permissions
        }
      };
    default:
      return state;
  }
}
