import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  CertificateActionTypes,
  CertificateDetailsLoad,
  CertificateDetailsLoadSuccess,
  CertificateDetailsPatch,
  CertificateIssueMccs,
  CertificateIssueRfcc,
  CertificateIssueRfoc,
  CertificateMccIssueOptionsLoad,
  CertificateMccIssueOptionsLoadSuccess,
  CertificateRfccIssueOptionsLoad,
  CertificateRfccIssueOptionsLoadSuccess,
  CertificateRfocIssueOptionsLoad,
  CertificateRfocIssueOptionsLoadSuccess,
  CertificateSaveComment,
  CertificatesLoad,
  CertificatesLoadSuccess,
  CertificateUpdateMccAccept,
  CertificateUpdateMccSignature,
  CertificateUpdateRfccSignature,
  CertificateUpdateRfocSignature, DeleteMccFile, UploadMccAttachment
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentProject } from '@completion/selectors';
import { CertificateService } from '@completion/services';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class CertificateEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly certificateService: CertificateService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, certificateService, store, toastr);
  }

  @Effect()
  loadCertificates$: Observable<Action> = this.actions$.pipe(
    ofType<CertificatesLoad>(CertificateActionTypes.CertificatesLoad),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getCertificates', CertificatesLoadSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  issueMccs$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateIssueMccs>(CertificateActionTypes.CertificateIssueMccs),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('issueMccs', null, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data])
  );

  @Effect()
  issueRfcc$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateIssueRfcc>(CertificateActionTypes.CertificateIssueRfcc),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('issueRfcc', null, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data])
  );

  @Effect()
  issueRfoc$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateIssueRfoc>(CertificateActionTypes.CertificateIssueRfoc),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('issueRfoc', null, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data])
  );

  @Effect()
  loadMccIssueOptions$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateMccIssueOptionsLoad>(CertificateActionTypes.CertificateMccIssueOptionsLoad),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getMccIssueOptions', CertificateMccIssueOptionsLoadSuccess, ([_, currentProject, cpId]) => [
      currentProject.id,
      cpId
    ])
  );

  @Effect()
  loadRfccIssueOptions$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateRfccIssueOptionsLoad>(CertificateActionTypes.CertificateRfccIssueOptionsLoad),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getRfccIssueOptions', CertificateRfccIssueOptionsLoadSuccess, ([_, currentProject, cpId]) => [
      currentProject.id,
      cpId
    ])
  );

  @Effect()
  loadRfocIssueOptions$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateRfocIssueOptionsLoad>(CertificateActionTypes.CertificateRfocIssueOptionsLoad),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getRfocIssueOptions', CertificateRfocIssueOptionsLoadSuccess, ([_, currentProject, cpId]) => [
      currentProject.id,
      cpId
    ])
  );

  @Effect()
  loadCertificateDetails$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateDetailsLoad>(CertificateActionTypes.CertificateDetailsLoad),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('getCertificateDetails', CertificateDetailsLoadSuccess, ([action, currentProject, currentCpId]) => [
      currentProject.id,
      currentCpId,
      action.certificateType,
      action.certificateId
    ])
  );

  @Effect()
  updateMccSignature$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateUpdateMccSignature>(CertificateActionTypes.CertificateUpdateMccSignature),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'updateMccSignature',
      CertificateDetailsPatch,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data.certificateId, action.data.signing],
      response => [
        {
          signedBy: response.signedBy,
          signedDate: response.signedDate
        }
      ]
    )
  );

  @Effect()
  updateMccAccepted$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateUpdateMccAccept>(CertificateActionTypes.CertificateUpdateMccAcceptance),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'updateMccAccepted',
      CertificateDetailsPatch,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data.certificateId, action.data.accepted],
      response => [
        {
          acceptedBy: response.acceptedBy,
          acceptedDate: response.acceptedDate
        }
      ]
    )
  );

  @Effect()
  updateRfccSignature$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateUpdateRfccSignature>(CertificateActionTypes.CertificateUpdateRfccSignature),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'updateRfccSignature',
      CertificateDetailsPatch,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data.certificateId, action.data.signing],
      response => [
        {
          signatureMatrix: response
        }
      ]
    )
  );

  @Effect()
  updateRfocSignature$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateUpdateRfocSignature>(CertificateActionTypes.CertificateUpdateRfocSignature),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'updateRfocSignature',
      CertificateDetailsPatch,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data.certificateId, action.data.signing],
      response => [
        {
          signatureMatrix: response
        }
      ]
    )
  );

  @Effect()
  saveCertificateComment$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateSaveComment>(CertificateActionTypes.CertificateSaveComment),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'saveCertificateComment',
      CertificateDetailsPatch,
      ([action, currentProject, cpId]) => [
        currentProject.id,
        cpId,
        action.data.certificateId,
        action.data.certificateType,
        action.data.comment
      ],
      response => [{ comment: response.comment }]
    )
  );


  @Effect()
  uploadAttachment$: Observable<any> = this.actions$.pipe(
    ofType<UploadMccAttachment>(CertificateActionTypes.UploadMccAttachment),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'uploadMccAttachment',
      null,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.file, action.certificateId]
    )
  );

  @Effect()
  deleteMccAttachment$: Observable<any> = this.actions$.pipe(
    ofType<DeleteMccFile>(CertificateActionTypes.DeleteMccFile),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'deleteMccAttachment',
      null,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.mccId, action.attachmentId]
    )
  );

  @Effect()
  resetTags$: Observable<Action> = this.actions$.pipe(
    ofType(CertificateActionTypes.ResetTags),
    this.fetchResource('resetTags', null, ([action]) => [action.certificateId])
  );
}
