import { DashBoardAction, DashBoardActionTypes } from "@completion/actions";
import { DashboardPackage, UserSignatureAssignments } from "@completion/models";
import { DashboardCheckSheetAssignmentStatus } from "../../../../shared/models/dashboard-checksheet-status";


export const initialState: DashboardState = {
  cps: [],
  mcps: [],
  csaStatus: null,
  userSignatureAssignments: null

};

export interface DashboardState {
  cps: DashboardPackage[];
  mcps: DashboardPackage[];
  csaStatus: DashboardCheckSheetAssignmentStatus;
  userSignatureAssignments: UserSignatureAssignments;
}

export function dashboardReducer(state: DashboardState = initialState, action: DashBoardAction): DashboardState {
  switch (action.type) {
    case DashBoardActionTypes.CpsDashBoardPackageLoadSuccess:
      return {
        ...state,
        cps: [...action.cps],
      };
    case DashBoardActionTypes.McpsDashBoardPackageLoadSuccess:
      return {
        ...state,
        mcps: [...action.mcps],
      };
    case DashBoardActionTypes.DashboardCheckSheetAssignmentStatusLoadSuccess:
      return {
        ...state,
        csaStatus: action.csaStatus
      };
    case DashBoardActionTypes.LoadUserSignatureAssignmentsSuccess:
      return {
        ...state,
        userSignatureAssignments: action.userSignatureAssignments
      };
    default:
      return state;
  }
}
