import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { SiteState } from '../reducers/site';

const getSiteFeatureState = createFeatureSelector<SiteState>('site');


export const getCurrentSite = createSelector(
  getSiteFeatureState,
  state => state.currentSite
);
