import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";

import { CommitButtonModule } from '../../shared/commit-button/commit-button.module';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { CameraModule } from '../camera/camera.module';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { StatusCircleModule } from '../status-circle/status-circle.module';
import { UserAssignmentModule } from '../user-assignment/user-assignment.module';
import { UserWithDateModule } from '../user-with-date/user-with-date.module';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PunchActionComponent } from './punch-action/punch-action.component';
import { PunchDialogComponent } from './punch-dialog.component';

@NgModule({
    declarations: [PunchDialogComponent, PunchActionComponent, FileUploadComponent],
    imports: [
        BreadcrumbModule,
        CameraModule,
        CommitButtonModule,
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        StatusCircleModule,
        UserAssignmentModule,
        UserWithDateModule,
        ErrorMessageModule,
        ConfirmationDialogModule
    ],
    exports: [PunchDialogComponent]
})
export class PunchDialogModule {}
