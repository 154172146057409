import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CheckSheet } from '@completion/models';
import { CheckSheetLoad, UpdateCheckSheet } from '@completion/actions';
import { getCheckSheets } from '@completion/selectors';
import { EditSignatureMatrixDialogComponent } from './edit-signature-matrix-dialog/edit-signature-matrix-dialog.component';
import { CheckSheetType } from '@completion/enums';

@Component({
  selector: 'app-check-sheet-editor',
  templateUrl: './check-sheet-editor.component.html',
  styleUrls: ['./check-sheet-editor.component.scss']
})
export class CheckSheetEditorComponent implements OnInit {
  displayedColumns: string[] = ['name', 'csNumber', 'description', 'signatureMatrixType', 'edit'];
  dataSource: MatTableDataSource<CheckSheet>;
  filterString: string = '';
  isMcCheckSheets: boolean = true;
  signatureMatrixTypes: string[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private store: Store, public dialog: MatDialog) {
    this.store.select(getCheckSheets).subscribe(checkSheets => {
      this.dataSource = new MatTableDataSource(checkSheets);
      this.dataSource.paginator = this.paginator;
      this.signatureMatrixTypes = this.getUniqueSignatureMatrixTypes(checkSheets);
      this.applyFilter();
    });
  }

  ngOnInit(): void {
    this.loadCheckSheets();
  }

  loadCheckSheets(): void {
    const checkSheetType = this.isMcCheckSheets ? CheckSheetType.MC : CheckSheetType.CP;
    this.store.dispatch(new CheckSheetLoad(checkSheetType));
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterString.trim().toLowerCase();
  }

  toggleCheckSheetType(isMcCheckSheets: boolean): void {
    this.isMcCheckSheets = isMcCheckSheets;
    this.loadCheckSheets();
  }

openEditDialog(checkSheet: CheckSheet): void {
  const checkSheetCopy = { ...checkSheet };
  const dialogRef = this.dialog.open(EditSignatureMatrixDialogComponent, {
    width: '250px',
    data: { checkSheet: checkSheetCopy, signatureMatrixTypes: this.signatureMatrixTypes }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      console.log('Updating check sheet:', result);
      this.store.dispatch(new UpdateCheckSheet(result));
    }
  });
}

  private getUniqueSignatureMatrixTypes(checkSheets: CheckSheet[]): string[] {
    const types = checkSheets.map(cs => cs.signatureMatrixType);
    return Array.from(new Set(types));
  }
}
