import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {MatProgressBarModule} from "@angular/material/progress-bar";

import { ErrorMessageModule } from '../error-message/error-message.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ResourceStatusDirective } from './resource-status.directive';
import { ResourceWrapperComponent } from './resource-wrapper/resource-wrapper.component';

@NgModule({
    declarations: [ResourceStatusDirective, ResourceWrapperComponent],
    imports: [CommonModule, MatProgressBarModule, ErrorMessageModule, SpinnerModule],
    exports: [ResourceStatusDirective]
})
export class ResourceStatusModule {}
