interface AuthConfig {
  apiScope: string;
  clientId: string;
  protectedResource: string;
}

interface ApiConfig {
  baseUrl: string;
}

interface FeatureFlags {
  offline: boolean,
  rfoc: boolean,
  handover: boolean,
  testingUtils: boolean,
  moveTags: boolean
}

interface Env {
  name: string;
  production: boolean;
  serviceWorker: boolean;
  api: ApiConfig
  auth: AuthConfig;
  featureFlags: FeatureFlags;
}

const envData = {
  default: {
    name: "Local",
    production: false,
    serviceWorker: false,
    auth: {
      clientId: "a2c5ad98-d4f8-4ef5-9855-6a9f3841bdc3",
      authority: "https://login.microsoftonline.com/26b749f6-8c72-44e3-bbde-ae3de07b4206",
      apiScope: "api://cf40efd0-d8b7-4751-91cb-6e97414841b7/api.access",
      protectedResource: 'http://localhost:8080/api/*'
    },
    featureFlags: {
      offline: false,
      rfoc: true,
      handover: true,
      testingUtils: true,
      moveTags: true
    },
  },
  envSpecificData: {
    local: {
      api: {
        baseUrl: "http://localhost:8080/api",
      },
    },
    dev: {
      name: "Dev",
      serviceWorker: true,
      api: {
        baseUrl: "https://dev.completion.akersolutions.com/api",
      },
      auth: {
        clientId: "a2c5ad98-d4f8-4ef5-9855-6a9f3841bdc3",
        authority: "https://login.microsoftonline.com/26b749f6-8c72-44e3-bbde-ae3de07b4206",
        apiScope: "api://cf40efd0-d8b7-4751-91cb-6e97414841b7/api.access",
        protectedResource: 'https://dev.completion.akersolutions.com/api/*'
      },
    },
    staging: {
      name: "Staging",
      serviceWorker: true,
      api: {
        baseUrl: "https://staging.completion.akersolutions.com/api",
      },
      auth: {
        clientId: "a2c5ad98-d4f8-4ef5-9855-6a9f3841bdc3",
        authority: "https://login.microsoftonline.com/26b749f6-8c72-44e3-bbde-ae3de07b4206",
        apiScope: "api://cf40efd0-d8b7-4751-91cb-6e97414841b7/api.access",
        protectedResource: 'https://staging.completion.akersolutions.com/api/*'
      }
    },
    prod: {
      name: "Prod",
      production: true,
      serviceWorker: true,
      api: {
        baseUrl: "https://completion.akersolutions.com/api",
      },
      auth: {
        clientId: "b8d2398c-362a-4785-8393-e7071e5e7ec1",
        authority: "https://login.microsoftonline.com/26b749f6-8c72-44e3-bbde-ae3de07b4206",
        apiScope: "api://957f8cc6-467e-4c4d-8bc7-c4a17923dc79/api.access",
        protectedResource: 'https://completion.akersolutions.com/api/*'
      }
    },
  },
  getWithSpecificData(envName: string): Env {
    return {
      ...this.default,
      ...this.envSpecificData[envName],
    };
  },
  get(): Env {
    const hostname = window.location.hostname;

    if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
      return this.getWithSpecificData("local");
    }

    if (hostname.includes("dev")) {
      return this.getWithSpecificData("dev");
    }

    if (hostname.includes("staging")) {
      return this.getWithSpecificData("staging");
    }

    return this.getWithSpecificData("prod");
  },
};

export const env = (() => {
  const instance = envData.get();
  return {
    get: () => instance,
  };
})();
