import { CheckSheetAction, CheckSheetActionTypes } from '@completion/actions';
import { CheckListItem, CheckSheetAssignment } from '@completion/models';

export const initialState: CheckSheetState = {
  currentCsaId: null,
  currentCsa: null,
  currentCsItems: null,
  matrixTypes: []
};

export interface CheckSheetState {
  currentCsaId: number;
  currentCsa: CheckSheetAssignment;
  currentCsItems: CheckListItem[];
  matrixTypes: string[];
}

export function checkSheetReducer(state: CheckSheetState = initialState, action: CheckSheetAction): CheckSheetState {
  switch (action.type) {
    case CheckSheetActionTypes.ChangeCurrentCsaId:
      return {
        ...state,
        currentCsaId: action.csaId
      };
    case CheckSheetActionTypes.LoadCurrentCsaSuccess:
      return {
        ...state,
        currentCsa: action.csa,
        currentCsItems: action.csa.csItems
      };
    case CheckSheetActionTypes.ClearCurrentCsa:
      return {
        ...state,
        currentCsa: null,
        currentCsItems: null
      };
    case CheckSheetActionTypes.UpdateCsItemStatusSuccess:
      return {
        ...state,
        currentCsItems: action.csItems
      };
    case CheckSheetActionTypes.UpdateSignaturesSuccess:
      return {
        ...state,
        currentCsa: {
          ...state.currentCsa,
          signedBy: action.csa.signedBy,
          verifiedBy: action.csa.verifiedBy
        }
      };
      case CheckSheetActionTypes.GetCheckSheetMatrixTypesSuccess:
      return {
        ...state,
        matrixTypes: action.matrixTypes
      };

    default:
      return state;
  }
}
