import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {ResourceEffect} from "./resource.effect";
import {Action, Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {CheckSheetService} from "../../services/check-sheet.service";
import {State} from "@completion/reducers";

import {Observable} from "rxjs";
import {
  CheckSheetActionTypes,
  CheckSheetLoadSuccess, GetCheckSheetMatrixTypes, GetCheckSheetMatrixTypesSuccess,
  UpdateCheckSheet,
} from "@completion/actions";
import {withLatestFrom} from "rxjs/operators";
import {getCurrentProject} from "@completion/selectors";



@Injectable()
export class CheckSheetEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly checkSheetService: CheckSheetService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, checkSheetService, store, toastr);
  }

  @Effect()
  updateCheckSheet$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateCheckSheet>(CheckSheetActionTypes.UpdateCheckSheet),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateCheckSheet', CheckSheetLoadSuccess, ([action, currentProject]) => [currentProject.id, action.checkSheet])
  );

  @Effect()
  getCheckSheetMatrixTypes$: Observable<Action> = this.actions$.pipe(
    ofType<GetCheckSheetMatrixTypes>(CheckSheetActionTypes.GetCheckSheetMatrixTypes),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getCheckSheetMatrixTypes', GetCheckSheetMatrixTypesSuccess, ([action, currentProject]) => [currentProject.id])
  );
}
