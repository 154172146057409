import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { getHandoverTemplate } from 'src/app/core/store/selectors/handover-template.selector';

import { ProjectAccessListLoad, ProjectAccessLoad, ProjectDeleteImage, ProjectImageUpload, ProjectUsers, ResourceReset, UserActionTypes } from '@completion/actions';
import { ProjectConfigs, ProjectImageType, ResourceStatus, ResourceType } from '@completion/enums';
import { HandoverTemplate, ProjectConfig } from '@completion/models';
import { getCurrentProject, getResourceState, projectUsers } from '@completion/selectors';
import * as handoverTemplateAction from '../../core/store/actions/handover-template.action';
import { ProjectState } from '../../core/store/reducers/project';
import { baseUrl } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { DataDialogComponent } from './data-dialog/data-dialog.component';

@Component({
  selector: 'app-setup-page',
  templateUrl: './setup-page.component.html',
  styleUrls: ['./setup-page.component.scss']
})
export class SetupPageComponent implements OnDestroy {
  projectName: string;
  inProgressCustomerLogo = false;
  inProgressHandoverImage = false;
  selectedType: ProjectImageType;
  private readonly destroy$: Subject<void> = new Subject();

  uploadingFile = false;
  handoverImage: string;
  customerLogo: string;
  handoverTemplate: HandoverTemplate;
  activeTabIndex = 0;
  tabs = ['dim', 'pcfg', 'pht', 'psmt', 'pua','mde'];

  constructor(
    private readonly store: Store<ProjectState>,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog
  ) {
    this.handleTabRouting();

    this.store
      .select(getCurrentProject)
      .pipe(
        filter(currentProject => !!currentProject),
        takeUntil(this.destroy$)
      )
      .subscribe(project => {
        this.store.dispatch(new handoverTemplateAction.HandoverTemplateLoad(project.id));
        this.store.dispatch(new ProjectAccessListLoad(project));
        this.projectName = project.name;

        this.handoverImage = project.secureHandoverImageUrl;
        this.customerLogo = project.secureCustomerLogoUrl;

        this.inProgressCustomerLogo = true;
        this.inProgressHandoverImage = true;
      });

    this.store
      .select(getHandoverTemplate)
      .pipe(takeUntil(this.destroy$))
      .subscribe(handoverTemplate => {
        this.handoverTemplate = handoverTemplate;
      });

    this.uploadingImageResource();

    this.deletingImageResource();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.store.dispatch(new ResourceReset(ResourceType.ProjectImageUpload));
    this.store.dispatch(new ResourceReset(ResourceType.ProjectImageDelete));
  }

  uploadingImageResource() {
    this.store
      .pipe(
        select(getResourceState, ResourceType.ProjectImageUpload),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        this.selectedType === ProjectImageType.Handover
          ? (this.inProgressHandoverImage = state.status === ResourceStatus.InProgress)
          : (this.inProgressCustomerLogo = state.status === ResourceStatus.InProgress);

        if (state.lastError) {
          this.snackBar.open(state.lastError, 'OK', null);
        }
      });
  }

  deletingImageResource() {
    this.store
      .pipe(
        select(getResourceState, ResourceType.ProjectImageDelete),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        this.selectedType === ProjectImageType.Handover
          ? (this.inProgressHandoverImage = state.status === ResourceStatus.InProgress)
          : (this.inProgressCustomerLogo = state.status === ResourceStatus.InProgress);

        if (state.lastError) {
          this.snackBar.open(state.lastError, 'OK', null);
        }
      });
  }

  customerLogoUpload(file: File) {
    this.selectedType = ProjectImageType.CustomerLogo;
    this.store.dispatch(new ProjectImageUpload(ProjectImageType.CustomerLogo, file));
  }

  handoverImageUpload(file: File) {
    this.selectedType = ProjectImageType.Handover;
    this.store.dispatch(new ProjectImageUpload(ProjectImageType.Handover, file));
  }

  customerLogoDelete() {
    this.selectedType = ProjectImageType.CustomerLogo;
    this.store.dispatch(new ProjectDeleteImage(ProjectImageType.CustomerLogo));
  }

  handoverImageDelete() {
    this.selectedType = ProjectImageType.Handover;
    this.store.dispatch(new ProjectDeleteImage(ProjectImageType.Handover));
  }

  private handleTabRouting(): void {
    this.route.fragment
      .pipe(
        map(fragment => (this.tabs.includes(fragment) ? this.tabs.indexOf(fragment) : 0)),
        takeUntil(this.destroy$)
      )
      .subscribe(tabIndex => {
        this.activeTabIndex = tabIndex;
      });
  }

  setUrlFragment(tabIndex: number): void {
    if (tabIndex !== this.activeTabIndex) {
      const fragment = this.tabs[tabIndex];

      this.router.navigate([], { fragment });
    }
  }

  openDataDialog() {
    const dialog = this.dialog.open(DataDialogComponent, {
      width: '1100px',
      height: '600px',
      data: { jsonData: '' }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}
