import { Action } from '@ngrx/store';

export enum ErrorActionTypes {
  LoadFailure = 'Load Failure',
  ClearError = 'Clear Error'
}

export class LoadFailure implements Action {
  readonly type = ErrorActionTypes.LoadFailure;
  constructor(public error: string) {}
}

export class ClearError implements Action {
  readonly type = ErrorActionTypes.ClearError;
}

export type ErrorActions = LoadFailure | ClearError;
