import { Action } from '@ngrx/store';

import { OfflineDownloadScope } from '@completion/models';

export enum OfflineDownloadsActionTypes {
  OfflineScopesLoad = 'Request Offline Scopes (CSAs)',
  OfflineScopesLoadSuccess = 'Offline Scopes Load Success (CSAs)',
  OfflineScopesRelease = 'Release Offline Scopes (CSAs)'
}

export class OfflineScopesLoad implements Action {
  readonly type = OfflineDownloadsActionTypes.OfflineScopesLoad;
}

export class OfflineScopesLoadSuccess implements Action {
  readonly type = OfflineDownloadsActionTypes.OfflineScopesLoadSuccess;
  constructor(public offlineDownloads: OfflineDownloadScope) {}
}

export class OfflineScopesRelease implements Action {
  readonly type = OfflineDownloadsActionTypes.OfflineScopesRelease;
  constructor(public pkgId: number, public pkgType: string, public csaIds: number[]) {}
}

export type OfflineDownloadsAction = OfflineScopesLoad | OfflineScopesLoadSuccess | OfflineScopesRelease;
