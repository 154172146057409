<div [ngClass]="getClassByType()">
  <div class="title-container">
    <ng-content></ng-content>
    <h3 [ngClass]="getTitleClassByType()">
      <span class="section-prefix" *ngIf="handoverTemplate.type && handoverTemplate.type === 'SECTION'">
        {{ handoverTemplate.type }} {{ index + 1 }}
      </span>
      {{ handoverTemplate.templateName }}
    </h3>
    <span class="section-actions" *ngIf="handoverTemplate.type === 'SECTION'">
      <button class="expand-button" mat-icon-button type="button" (click)="toggle.emit(index)" [class.open]="isOpen">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </span>
  </div>

  <!-- Sections-->
  <div *ngIf="!handoverTemplate.type" cdkDropList cdkDropListLockAxis="y" (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let template of children; index as idx; trackBy: trackSections" class="section">
      <app-project-template [handoverTemplate]="template" [index]="idx" [isOpen]="openSections[idx]" (toggle)="handleToggle($event)">
        <!--
            Need to content project the drag handle as Angular can't find it when it inside the child component 🤦
            https://github.com/angular/components/issues/13784
          -->
        <div class="dragHandle" cdkDragHandle>
          <mat-icon>drag_indicator</mat-icon>
        </div>
      </app-project-template>
      <div class="section-delete">
        <button mat-icon-button type="button" (click)="delete(idx, children)" class="delete-section" [disabled]="isDisabled">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!handoverTemplate.type" class="add-section">
    <button mat-button (click)="addSection(handoverTemplate, children)" [disabled]="lockedUI">
      <mat-icon>add_circle</mat-icon>
      Add Section
    </button>
  </div>

  <!-- Rows-->
  <div
    [@expandContent]="isOpen ? 'expanded' : 'collapsed'"
    #row
    class="children-container content"
    *ngIf="handoverTemplate.type === 'SECTION'"
  >
    <div class="row">
      <div class="row-content-wrapper">
        <div class="row-ordinal">Nr.</div>
        <div class="row-name">Name</div>
        <div class="row-type">Row type</div>
        <div class="row-type">Content type</div>
        <span class="proces-code">Process code</span>
      </div>
      <span class="delete-row">&nbsp;</span>
    </div>
    <div class="row" *ngFor="let template of children; index as idx">
      <div class="row-content-wrapper" *ngIf="!rowEdit(idx)">
        <div class="row-ordinal">
          <span>{{ template.ordinal }}</span>
        </div>
        <div class="row-name">
          <span>{{ template.templateName }}</span>
        </div>
        <div class="row-type">
          <span>{{ template.type }}</span>
        </div>
        <div class="row-type">
          <span>{{ template.contentType }}</span>
        </div>
        <div class="process-code">
          <span> {{ getProcessCodeName(template.processCode) }} </span>
        </div>
      </div>
      <!-- Row form -->
      <form class="row-content-wrapper" [formGroup]="rowForm" *ngIf="rowEdit(idx)">
        <div class="row-ordinal">
          <mat-form-field class="inline-validation">
            <input matInput required maxlength="2" formControlName="templateOrdinal" name="templateOrdinal" />
          </mat-form-field>
        </div>
        <div class="row-name">
          <mat-form-field class="inline-validation">
            <input matInput required maxlength="50" formControlName="templateName" name="templateName" />
          </mat-form-field>
        </div>
        <div class="row-type">
          <mat-select formControlName="rowType">
            <mat-option *ngFor="let type of rowTypes" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </div>
        <div class="row-type">
          <mat-select formControlName="contentType">
            <mat-option *ngFor="let contentType of getContentTypesByRowType()" [value]="contentType">
              {{ contentType }}
            </mat-option>
          </mat-select>
        </div>
        <div class="process-code">
          <mat-select formControlName="processCode">
            <mat-option *ngFor="let processCode of ProcessCodeDictionary | keyvalue" [value]="processCode.key">
              {{ processCode.key + ' - ' + processCode.value }}
            </mat-option>
          </mat-select>
        </div>
      </form>
      <!-- end row form-->
      <button mat-icon-button type="button" (click)="edit(idx, children)" class="row-action" *ngIf="!rowEdit(idx)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="save(idx, children)" class="row-action" *ngIf="rowEdit(idx)">
        <mat-icon>save</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="cancel(idxn)" class="row-action" *ngIf="rowEdit(idx)">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="delete(idx, children)" class="row-action" *ngIf="!rowEdit(idx)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="row">
      <button mat-icon-button type="button" (click)="addRow(handoverTemplate, children)">
        <mat-icon>{{ 'add_circle' }}</mat-icon>
      </button>
    </div>
  </div>
</div>
