import { I } from '@angular/cdk/keycodes';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteSignatureMatrixTemplate, UpdateSignatureMatrixTemplate } from '@completion/actions';
import { SignatureType } from '@completion/enums';
import { Confirmation, SignatureMatrixTemplate } from '@completion/models';
import { getCurrentProject } from '@completion/selectors';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog';
import { MatrixEditDialogComponent } from '../matrix-template-edit-dialog/matrix-template-edit-dialog.component';

@Component({
  selector: 'app-signature-matrix-template-list',
  templateUrl: './signature-matrix-template-list.component.html',
  styleUrls: ['./signature-matrix-template-list.component.scss']
})
export class SignatureMatrixTemplateListComponent implements OnInit {

  dataSource: Array<SignatureMatrixTemplate> = []
  type: SignatureType;

  @Input()
  set signatureType(type: string) {
    this.type = SignatureType[type];
  }

  displayedColumns = ['ordinal',  'verified','role', 'signee', 'signatureRole', 'edit', 'delete'];

  private readonly destroy$ = new Subject<void>();
  constructor(private readonly store: Store, private readonly dialog: MatDialog) { }

  ngOnInit(): void {


    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(
      (project) => {
        if (project) {
          let matrices = project.signatureMatrixTemplates;

          if (matrices !== null && matrices[this.type]) {
            this.dataSource = matrices[this.type];
          }
          else {
            this.dataSource = [];
          }
        }

      }
    );
  }

  isLoading() {
    return false;
  }

  editSignatureMatrix(signatureMatrixTemplate: SignatureMatrixTemplate) {
    const di = this.dialog.open(MatrixEditDialogComponent, {
      data: {
        signatureMatrixTemplate: signatureMatrixTemplate,

      },
      width: '800px'

    });

    di.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new UpdateSignatureMatrixTemplate(signatureMatrixTemplate));
      }
    });
  }

  openAddDialog() {
  }

  deleteSignatureMatrix(signatureMatrixTemplate: SignatureMatrixTemplate) {
    const di = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Signature Matrix Template',
        message: 'Are you sure you want to delete this signature matrix template?',
        isCancelDisplay: true,
        confirmationTile: 'Confirm'
      } as Confirmation

    });

    di.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DeleteSignatureMatrixTemplate(signatureMatrixTemplate));
      }
    });
  }

  addSignatureMatrix() {
    const di = this.dialog.open(MatrixEditDialogComponent, {
      data: {
        signatureMatrixTemplate: {

          ordinal: this.dataSource.length + 1,
          role: null,
          verified: null,
          signee: null,
          signatureRole: null,
          signatureType: this.type
        } as SignatureMatrixTemplate
      },
      width: '800px'

    });

    di.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  convertSignatureTypeToName(type: SignatureType) {
    switch (type) {
      case SignatureType.CSA_CP:
        return 'CP Checksheet';
      case SignatureType.CSA_MCP:
        return 'MCP Checksheet';
      case SignatureType.CSA_VENDOR:
        return 'Vendor Checksheet';
      case SignatureType.MCC:
        return 'Mc Certificate';
      case SignatureType.RFCC:
        return 'Rfc Certificate';
      case SignatureType.RFOC:
        return 'Rfo Certificate';
      case SignatureType.HANDOVER:
        return 'Handover';
    }
  }

}
