<div class="user" *ngIf="user">
  <button
    class="sign-in-button"
    title="User menu"
    aria-label="Open user menu"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"
    (click)="isOpen = !isOpen"
    cdkMonitorElementFocus
  >
    <span class="user-button-content" *ngIf="usersFirstLetter; else userIcon">
      {{ usersFirstLetter.toUpperCase() }}
    </span>
    <ng-template #userIcon>
      <mat-icon class="user-button-content">person</mat-icon>
    </ng-template>
    <mat-icon class="user-button-content-mobile">{{ isOpen ? 'clear' : 'more_vert' }}</mat-icon>
  </button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayOpen]="isOpen"
    cdkConnectedOverlayHasBackdrop
    cdkConnectedOverlayBackdropClass="apd-user-menu-backdrop"
    (backdropClick)="isOpen = false"
    (detach)="isOpen = false"
  >
    <div>
      <div class="user-menu-panel" cdkTrapFocus cdkTrapFocusAutoCapture>
        <div class="profile-menu" (click)="clickOnUserInfo($event)">
          <div class="user-overlay" *ngIf="user">
            <span class="user-button-content" *ngIf="usersFirstLetter; else userIcon">
              {{ usersFirstLetter.toUpperCase() }}
            </span>
            <ng-template #userIcon>
              <mat-icon class="user-button-content">person</mat-icon>
            </ng-template>
          </div>
          <p *ngIf="user.name" class="name">{{ user.name }}</p>
          <p *ngIf="user.email" class="email">{{ user.email }}</p>
        </div>
        <div class="scroll-container">
          <div class="header-menu-items">
            <ng-content></ng-content>
          </div>
          <a
            href="https://ix3services.atlassian.net/servicedesk/customer/portal/21"
            class="support-link"
            target="_blank"
            cdkMonitorElementFocus
          >
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path d="M-1-1h24v24H-1z" />
                <path
                  d="M11 0a3 3 0 110 6 3 3 0 010-6zm0 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                  fill="#FFF"
                  fill-rule="nonzero"
                />
                <path fill="#FFF" d="M5 16h12v1H5zM0 12h22v2H0z" />
                <path
                  d="M11 7a6.002 6.002 0 015.659 4h-1.627a4.5 4.5 0 00-8.064 0H5.34C6.165 8.67 8.387 7 11 7zM20 12v10H2V12h18zm-1 1H3v8h16v-8z"
                  fill="#FFF"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
            <span>Service desk</span>
            <mat-icon>open_in_new</mat-icon>
          </a>
        </div>
        <button aria-label="Sign out" class="sign-out-button" (click)="openUserPreference()" cdkMonitorElementFocus>
          <mat-icon>settings</mat-icon>
          <span>Preference</span>
        </button>
        <button aria-label="Sign out" class="sign-out-button" (click)="signOut()" cdkMonitorElementFocus>
          <mat-icon>exit_to_app</mat-icon>
          <span>Log out</span>
        </button>
      </div>
    </div>
  </ng-template>
</div>
