export enum SignatureType {
    CSA_CP = 'CSA_CP',
    CSA_MCP = 'CSA_MCP',
    MCC = 'MCC',
    RFCC = 'RFCC',
    RFOC = 'RFOC',
    HANDOVER = 'HANDOVER',
    // these 2 have to be the last 2 in the enum
    CHECK_SHEET = 'CHECK_SHEET',
    COMPANY = 'COMPANY'
}
