import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from '../reducers/user';

const getUserFeatureState = createFeatureSelector<UserState>('user');

export const getCurrentUser = createSelector(
  getUserFeatureState,
  state => state.currentUser
);

export const projectUsers = createSelector(
  getUserFeatureState,
  state => state.projectUsers
);

export const getCompanyUser = createSelector(
  getUserFeatureState,
  state => state.companyUsers
);
