import { McpAction, McpActionTypes } from '@completion/actions';
import { McPackage } from '@completion/models';

export const initialState: McpState = {
  currentMcp: null,
  currentMcpId: null,
  latestError: null,
  responseStatus: null
};

export interface McpState {
  currentMcp: McPackage;
  currentMcpId: number;
  latestError: string;
  responseStatus: number;
}

export function mcPkgsReducer(state: McpState = initialState, action: McpAction): McpState {
  switch (action.type) {
    case McpActionTypes.ChangeCurrentMcpId:
      const resetMcp = action.mcpId !== state.currentMcpId;
      return {
        ...state,
        currentMcpId: action.mcpId,
        currentMcp: resetMcp ? null : state.currentMcp
      };
    case McpActionTypes.LoadCurrentMcpSuccess:
      return {
        ...state,
        currentMcp: action.mcp,
        latestError: null
      };
    case McpActionTypes.ClearCurrentMcp:
      return {
        ...state,
        currentMcpId: null,
        currentMcp: null
      };
    case McpActionTypes.McpUpdateFailure:
      return {
        ...state,
        latestError: action.errorMessage,
        responseStatus: null
      };
    case McpActionTypes.ClearMCPError:
      return {
        ...state,
        latestError: null,
        responseStatus: null
      };
    default:
      return state;
  }
}
