import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {ToastrService} from "ngx-toastr";
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { CpScopeOfWork, DownloadCpSOW, DownloadMcpSOW, DownloadSOWSuccess, McpScopeOfWork, OfflineActionTypes } from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentMcpId, getCurrentProject } from '@completion/selectors';
import { OfflineService } from '../../services/offline.service';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class OfflineEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly offlineService: OfflineService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, offlineService, store, toastr);
  }

  @Effect({ dispatch: true })
  downloadMcpSOW$: Observable<Action> = this.actions$.pipe(
    ofType<DownloadMcpSOW>(OfflineActionTypes.DownloadMcpSOW),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentMcpId)),
    map(([action, currentProject, mcpId]) => {
      const csaIds = action.sowToDownload.map(sowToDownload => sowToDownload.id);
      return [currentProject.id, mcpId, csaIds];
    }),
    this.fetchResource('downloadMcpSOW', McpScopeOfWork, ([projectId, mcpId, csaIds]) => [projectId, mcpId, csaIds])
  );

  @Effect({ dispatch: true })
  downloadCpSOW$: Observable<Action> = this.actions$.pipe(
    ofType<DownloadCpSOW>(OfflineActionTypes.DownloadCpSOW),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    map(([action, currentProject, cpId]) => {
      const csaIds = action.sowToDownload.map(sowToDownload => sowToDownload.id);
      return [currentProject.id, cpId, csaIds];
    }),
    this.fetchResource('downloadCpSOW', CpScopeOfWork, ([projectId, cpId, csaIds]) => [projectId, cpId, csaIds])
  );
}
