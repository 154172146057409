import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ReportState } from "../reducers/reports";

const getReportFeatureState = createFeatureSelector<ReportState>('report');


export const getReportPunches = createSelector(
  getReportFeatureState,
  state => state.punches
);

export const getCheckSheetAssignmentsForReport = createSelector(
  getReportFeatureState,
  state => state.checkSheetAssignments
);

export const getCertificateOverviewReport = createSelector(
  getReportFeatureState,
  state => state.certificates
);
