import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEqual, isObject } from 'lodash';
import {ToastrService} from "ngx-toastr";
import 'reflect-metadata';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ResourceActionTypes, ResourceCancel, ResourceFailed, ResourceStart, ResourceSuccess } from '@completion/actions';
import { ResourceDescriptor } from '@completion/models';
import { State } from '@completion/reducers';
import { BaseService, RESOURCE_IDENTIFIERS_KEY, RESOURCE_TYPE_KEY } from '@completion/services';

type Constructable<T> = new (...args: any[]) => T;

const passThrough = (...args) => args;

function getResourceDescriptor(action: Action, service: BaseService, resourcePropertyKey: string | symbol): ResourceDescriptor {
  const resourceTypeMetadata = Reflect.getMetadata(RESOURCE_TYPE_KEY, service) || {};
  const resourceIdentifiersMetadata = Reflect.getMetadata(RESOURCE_IDENTIFIERS_KEY, service) || [];

  // We need this test for cases where the service didn't extend BaseService (e.g. mock services in tests)
  if (!resourceIdentifiersMetadata[resourcePropertyKey]) {
    return resourceTypeMetadata[resourcePropertyKey];
  }

  const identifiers = {};
  resourceIdentifiersMetadata[resourcePropertyKey].forEach(identifierName => {
    identifiers[identifierName] = action[identifierName];
  });

  return {
    type: resourceTypeMetadata[resourcePropertyKey],
    identifiers
  };
}

/**
 * Base class for effects that fetch async resources.
 * This keeps track of the state of the resource (idle, in progress, succeeded, failed)
 *
 * ## Example
 *
 * ```ts
 * const mapThings = ([_, something]) => [something.id, something.status];
 *
 * class SomeEffect extends ResourceEffect {
 *
 * @Effect()
 * loadSomething$: Observable<Action> = this.actions$.pipe(
 *   ofType(SomeType),
 *   withLatestFrom(this.store.select(someSelector)),
 *   filter(([_, someThing]) => thing != null),
 *   this.fetchResource('getThings', ThingsSuccess, mapThings)
 * );
 *
 * ```
 * The `loadSomething` effect will call `this.service.getThings(something.id, something.status)`
 */
@Injectable()
export abstract class ResourceEffect {
  constructor(readonly actions$: Actions, readonly service: BaseService, readonly store: Store<State>, readonly toastr: ToastrService) {}

  /**
   * Utility to avoid long list of verbose selectors in `withLatestFrom`. E.g. turn this:
   *
   *    this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentHandoverId)
   *
   * into this:
   *
   *    ...multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)
   */
  readonly multiSelect = (...selectors) => selectors.map(selector => this.store.select(selector));

  /**
   * Returns an error handler to parse errors generated by services
   */
  makeErrorHandler = resourceDescriptor => err => {
    let message;

    if (isObject(err.error) && err.error.error) {
      message = `${err.error.error}: ${err.error.message}`;
    } else if (err.error) {
      message = `${err.error}: ${err.message || 'No error details provided'}`;
    } else if (err.message) {
      message = err.message;
    } else {
      message = err;
    }
    this.toastr.error(message);

    this.store.dispatch(new ResourceFailed(resourceDescriptor, message));
    return of();
  };

  /**
   * Operator for executing a resource request while keeping track of progress in the Redux store
   * @param serviceMethodName The name of the method to invoke in this.service to fetch the resource
   * @param successAction Action to dispatch with the successfully-fetched resource
   * @param serviceArgsMapper Function that maps arguments (returned by operators prior to calling `fetchResource`) for `serviceMethodName`
   * @param actionArgsMapper Function that maps the successfully-fetched resource into `successAction`
   */
  fetchResource = (
    serviceMethodName: string,
    successAction?: Constructable<Action>,
    serviceArgsMapper = passThrough,
    actionArgsMapper = passThrough
  ) => {
    return input$ =>
      input$.pipe(
        mergeMap((maybeAnAction, ...args) => {
          // 1) It would be nice to not have to do this, but then the binding would have to be done be the
          //    invoker of `fetchResource`, which would make the ergonomics arguably worse 🤷‍♂️
          // 2) If `fetchResource` is preceded by `withLatestFrom` then all the arguments are placed into a
          //    single array, with the first element as the action. Otherwise the action itself is the first
          //    argument 🤷‍♂️
          const serviceFn = this.service[serviceMethodName].bind(this.service); // 1
          const action = Array.isArray(maybeAnAction) ? maybeAnAction[0] : maybeAnAction; // 2
          const resourceDescriptor = getResourceDescriptor(action, this.service, serviceMethodName);

          // Mark the request as in progress
          this.store.dispatch(new ResourceStart(resourceDescriptor));

          // Call the service
          return serviceFn(...serviceArgsMapper(maybeAnAction, ...args)).pipe(
            // Later… mark the request as successful and…
            tap(() => this.store.dispatch(new ResourceSuccess(resourceDescriptor))),
            // … dispatch the "on success" action with the data provided by the service
            switchMap(resource => (successAction ? of(new successAction(...actionArgsMapper(resource))) : EMPTY)),
            // If something went wrong, handle the error (this marks the request as failed)
            catchError(this.makeErrorHandler(resourceDescriptor)),
            // Stop caring if the request is cancelled
            takeUntil(
              this.actions$.pipe(
                ofType<ResourceCancel>(ResourceActionTypes.Cancel),
                filter(cancelAction => isEqual(cancelAction.resourceDescriptor, resourceDescriptor))
              )
            )
          );
        })
      );
  };
}
