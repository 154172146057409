import { Action } from '@ngrx/store';

import {CheckListItem, CheckRecordValue, CheckSheet, CheckSheetAssignment, SignatureState} from '@completion/models';
import {CertificateActionTypes} from "./certificate.action";

export enum CheckSheetActionTypes {
  ChangeCurrentCsaId = 'Change Current Check Sheet Assignment Id',
  LoadCurrentCpCsa = 'Load Current CP Check Sheet Assignment',
  LoadCurrentMcpCsa = 'Load Current MCP Check Sheet Assignment',
  LoadCurrentCsaSuccess = 'Load Current Check Sheet Assignment Success',
  ClearCurrentCsa = 'Clear Current Check Sheet',
  UpdateCpCsItemStatus = 'Update CP CS Item Status',
  UpdateMcpCsItemStatus = 'Update MCP CS Item Status',
  UpdateCsItemStatusSuccess = 'Update CS Item Status Success',
  UpdateCpCsa = 'Update CP CSA',
  UpdateMcpCsa = 'Update MCP CSA',
  AddCpCheckRecord = 'Add  CP check record',
  AddMcpCheckRecord = 'Add MCP check record',
  DeleteCpCheckRecord = 'Delete  CP check record',
  DeleteMcpCheckRecord = 'Delete MCP check record',
  UpdateCpRecordValue = 'Update CP Record Value',
  UpdateMcpRecordValue = 'Update MCP Record Value',
  RecalculateCsaStatuses = "Update CSA statuses",
  FetchCpSignaturesFromHistory = "FetchCpSignaturesFromHistory",
  FetchMcpSignaturesFromHistory = "FetchMcSignaturesFromHistory",
  UpdateSignaturesSuccess = "UpdateSignaturesSuccess",
  SignCpCsaSigntureEntry = "SignCpCsaSigntureEntry",
  UploadMcpCsaFile = "UploadMcpCsaFile",
  UploadCpCsaFile = "UploadCpCsaFile",
  DeleteCpCsaAttachment = "DeleteCpCsaAttachment",
  DeleteMcpCsaAttachment = "DeleteMcpCsaAttachment",
  FetchMcItemstatusesFromHistory = "FetchMcItemstatusesFromHistory",
  FetchCpItemstatusesFromHistory = "FetchCpItemstatusesFromHistory",
  UpdateCheckSheet = "UpdateCheckSheet",
  GetCheckSheetMatrixTypes = "GetCheckSheetMatrixTypes",
  GetCheckSheetMatrixTypesSuccess = "GetCheckSheetMatrixTypesSuccess",

}

export class ChangeCurrentCsaId implements Action {
  readonly type = CheckSheetActionTypes.ChangeCurrentCsaId;

  constructor(public csaId: number) { }
}

export class LoadCurrentCpCsa implements Action {
  readonly type = CheckSheetActionTypes.LoadCurrentCpCsa;
}

export class LoadCurrentMcpCsa implements Action {
  readonly type = CheckSheetActionTypes.LoadCurrentMcpCsa;
}

export class LoadCurrentCsaSuccess implements Action {
  readonly type = CheckSheetActionTypes.LoadCurrentCsaSuccess;

  constructor(public csa: CheckSheetAssignment) { }
}

export class ClearCurrentCsa implements Action {
  readonly type = CheckSheetActionTypes.ClearCurrentCsa;
}

export class UpdateCpCsItemStatus implements Action {
  readonly type = CheckSheetActionTypes.UpdateCpCsItemStatus;

  constructor(public item: CheckListItem) { }
}

export class UpdateMcpCsItemStatus implements Action {
  readonly type = CheckSheetActionTypes.UpdateMcpCsItemStatus;

  constructor(public item: CheckListItem) { }
}

export class UpdateCsItemStatusSuccess implements Action {
  readonly type = CheckSheetActionTypes.UpdateCsItemStatusSuccess;

  constructor(public csItems: CheckListItem[]) { }
}

export class UpdateAllCsItemStatus implements Action {
  readonly type = CheckSheetActionTypes.UpdateCsItemStatusSuccess;

  constructor(public csItems: CheckListItem[]) { }
}

export class UpdateCpCsa implements Action {
  readonly type = CheckSheetActionTypes.UpdateCpCsa;

  constructor(public csa: CheckSheetAssignment, public doSignOrVerify?: string) {
  }
}

export class UpdateMcpCsa implements Action {
  readonly type = CheckSheetActionTypes.UpdateMcpCsa;

  constructor(public csa: CheckSheetAssignment, public doSignOrVerify?: string) {
  }
}

export class UpdateCpRecordValue implements Action {
  readonly type = CheckSheetActionTypes.UpdateCpRecordValue;

  constructor(public csItemNumber: number, public recordValue: CheckRecordValue) { }
}

export class AddCpCheckRecord implements Action {
  readonly type = CheckSheetActionTypes.AddCpCheckRecord;

  constructor(public csItem: CheckListItem, public recordValue: CheckRecordValue) { }
}

export class AddMcpCheckRecord implements Action {
  readonly type = CheckSheetActionTypes.AddMcpCheckRecord;

  constructor(public csItem: CheckListItem, public recordValue: CheckRecordValue) { }
}

export class DeleteCpCheckRecord implements Action {
  readonly type = CheckSheetActionTypes.DeleteCpCheckRecord;

  constructor(public csItemNumber: number, public recordValue: CheckRecordValue) { }
}

export class DeleteMcpCheckRecord implements Action {
  readonly type = CheckSheetActionTypes.DeleteMcpCheckRecord;

  constructor(public csItemNumber: number, public recordValue: CheckRecordValue) { }
}

export class UpdateMcpRecordValue implements Action {
  readonly type = CheckSheetActionTypes.UpdateMcpRecordValue;

  constructor(public csItemNumber: number, public recordValue: CheckRecordValue) { }
}

export class RecalculateCsaStatuses implements Action {
  readonly type = CheckSheetActionTypes.RecalculateCsaStatuses;
}

export class FetchCpSignaturesFromHistory implements Action {
  readonly type = CheckSheetActionTypes.FetchCpSignaturesFromHistory;
  constructor(public csaId: number) { }
}

export class FetchMcpSignaturesFromHistory implements Action {
  readonly type = CheckSheetActionTypes.FetchMcpSignaturesFromHistory;
  constructor(public csaId: number) { }
}

export class FetchCpItemStatusesFromHistory implements Action {
  readonly type = CheckSheetActionTypes.FetchCpItemstatusesFromHistory;
  constructor(public csaId: number) { }
}

export class FetchMcItemStatusesFromHistory implements Action {
  readonly type = CheckSheetActionTypes.FetchMcItemstatusesFromHistory;
  constructor(public csaId: number) { }
}

export class UpdateSignaturesSuccess implements Action {
  readonly type = CheckSheetActionTypes.UpdateSignaturesSuccess;
  constructor(public csa: CheckSheetAssignment) { }
}

export class UploadMcpCsaFile implements Action {
  readonly type = CheckSheetActionTypes.UploadMcpCsaFile;
  constructor( public file: File) { }
}
export class UploadCpCsaFile implements Action {
  readonly type = CheckSheetActionTypes.UploadCpCsaFile;
  constructor( public file: File) { }
}

export class DeleteCpCsaAttachment implements Action {
  readonly type = CheckSheetActionTypes.DeleteCpCsaAttachment;
  constructor( public id: number) { }
}

export class DeleteMcpCsaAttachment implements Action {
  readonly type = CheckSheetActionTypes.DeleteMcpCsaAttachment;
  constructor( public id: number) { }
}

export class UpdateCheckSheet implements Action {
  readonly type = CheckSheetActionTypes.UpdateCheckSheet;
  constructor(public checkSheet: CheckSheet) { }
}

export class GetCheckSheetMatrixTypes implements Action {
  readonly type = CheckSheetActionTypes.GetCheckSheetMatrixTypes;
  constructor(public projectId: number) { }
}

export class GetCheckSheetMatrixTypesSuccess implements Action {
  readonly type = CheckSheetActionTypes.GetCheckSheetMatrixTypesSuccess;
  constructor(public matrixTypes: string[]) { }
}


export type CheckSheetAction =
  | ChangeCurrentCsaId
  | LoadCurrentCpCsa
  | LoadCurrentMcpCsa
  | LoadCurrentCsaSuccess
  | ClearCurrentCsa
  | UpdateCpCsItemStatus
  | UpdateMcpCsItemStatus
  | UpdateCsItemStatusSuccess
  | UpdateCpCsa
  | UpdateMcpCsa
  | AddCpCheckRecord
  | AddMcpCheckRecord
  | DeleteCpCheckRecord
  | DeleteMcpCheckRecord
  | UpdateMcpRecordValue
  | UpdateCpRecordValue
  | RecalculateCsaStatuses
  | FetchCpSignaturesFromHistory
  | FetchMcpSignaturesFromHistory
  | FetchCpItemStatusesFromHistory
  | FetchMcItemStatusesFromHistory
  | UpdateSignaturesSuccess
  | UploadMcpCsaFile
  | UploadCpCsaFile
  | DeleteCpCsaAttachment
  | DeleteMcpCsaAttachment
  | UpdateCheckSheet
  | GetCheckSheetMatrixTypes
  | GetCheckSheetMatrixTypesSuccess;
