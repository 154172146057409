<div class="user-list-container">
  <div class="user-filter">
    <mat-form-field class="filter-form-field" appearance="fill">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="filter" (keyup)="applyFilter()" type="text" name="filter" />
      <button *ngIf="filter.length > 0" matSuffix mat-icon-button aria-label="Clear" color="primary" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="spinner-container" *ngIf="isLoading(); else loaded">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>
  <ng-template #loaded>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
      <ng-container matColumnDef="username">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>USERNAME</th>
        <td mat-cell class="username" *matCellDef="let element">{{ element.user.userName }}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>NAME</th>
        <td mat-cell class="name" *matCellDef="let element">{{ element.user.name }}</td>
      </ng-container>
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef>Site</th>
        <td mat-cell class="site" *matCellDef="let element">{{ element.site?.siteNumber || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>Companies</th>
        <td mat-cell class="company" *matCellDef="let element">{{ displayCompanies(element.companies) }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>ROLE</th>
        <td mat-cell class="role" *matCellDef="let element">{{ element.role }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell class="edit" *matCellDef="let element">
          <button class="edit-button" (click)="editProjectAccess(element)" mat-flat-button><mat-icon>edit</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-template>
</div>
