import { ErrorActions, ErrorActionTypes } from '@completion/actions';

export const initialState: ErrorState = {
  error: ''
};

export interface ErrorState {
  error: string;
}

export function errorReducer(state: ErrorState = initialState, action: ErrorActions): ErrorState {
  switch (action.type) {
    case ErrorActionTypes.LoadFailure:
      return {
        ...state,
        error: action.error
      };
    case ErrorActionTypes.ClearError:
      return {
        ...state,
        error: ''
      };
    default:
      return state;
  }
}
