import { Project } from './project';
import { User } from './user';
import { SignatureType } from '@completion/enums';

export const MANDATORY_SIGNATURE_COUNT = 4;
export const ADDITIONAL_SIGNATURE_COUNT = 2;

export interface SignatureEntry {
  id?: number;
  parentId?: number;
  ordinal?: number;
  role?: string;
  signee?: User;
  verified?: string;
  signatureType?: SignatureType;
  signatureRole?: SignatureRole;
}

export type SignatureEntryMatrix = SignatureEntry[];

export interface SignatureState extends SignatureEntry {
  signed: boolean;
  signedDate?: Date;
}

export type SignatureMatrix = SignatureState[];

export function getDefaultSignatureMatrix(projectId: number) {
  switch (projectId) {
    case 462:
      return signatureMatrix462;
    default:
      return defaultSignatureMatrix;
  }
}

export class SignatureRole {
  id: number;
  name: string;
  description: string;
  groupEmail?: string;
}

export class SignatureMatrixTemplate {
  id: number;
  signee?: User;
  signatureType: SignatureType;
  ordinal: number;
  role?: string;
  verified?: string;
  signatureRole?: SignatureRole;
  project: Project;
  projectDefinedType?: string;
}

export class SignatureMatrixTemplates {
  CSA_CP: SignatureMatrixTemplate[];
  CSA_MCP: SignatureMatrixTemplate[];
  COMPANY: SignatureMatrixTemplate[];
  CHECK_SHEET: SignatureMatrixTemplate[];
  RFCC: SignatureMatrixTemplate[];
  RFOC: SignatureMatrixTemplate[];
  MCC: SignatureMatrixTemplate[];
  HANDOVER: SignatureMatrixTemplate[];
}



const defaultSignatureMatrix: SignatureMatrix = [
  {
    ordinal: 1,
    verified: 'Prepared by Contractor',
    role: 'MC System Responsible',
    signed: false
  },
  {
    ordinal: 2,
    verified: 'Verified by Contractor',
    role: 'CO System Responsible',
    signed: false
  },
  {
    ordinal: 3,
    verified: 'Verified by Company',
    role: 'Technician',
    signed: false
  },
  {
    ordinal: 4,
    verified: 'Accepted by Company',
    role: 'Offshore Product Superintendant (OPS)',
    signed: false
  }
];

const signatureMatrix462: SignatureMatrix = [
  {
    ordinal: 1,
    verified: 'Prepared by Contractor',
    role: 'MC Responsible',
    signed: false
  },
  {
    ordinal: 2,
    verified: 'Verified by Contractor',
    role: 'System Responsible',
    signed: false
  },
  {
    ordinal: 3,
    verified: 'Verified by Company',
    role: 'System Responsible',
    signed: false
  },
  {
    ordinal: 4,
    verified: 'Accepted by Company',
    role: 'Site Represent',
    signed: false
  }
];

const emptySignatureState = {
  verified: null,
  role: null,
  signed: false
};

for (let i = 0; i < ADDITIONAL_SIGNATURE_COUNT; ++i) {
  defaultSignatureMatrix.push({ ...emptySignatureState });
}

for (let i = 0; i < ADDITIONAL_SIGNATURE_COUNT; ++i) {
  signatureMatrix462.push({ ...emptySignatureState });
}

export interface CustomizedOptions {
  theme?: string;
  preventDuplicateSignees?: boolean;
  documentBeingSigned?: string;
}
