import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ErrorState } from '../reducers/error';

const getErrorFeatureState = createFeatureSelector<ErrorState>('error');

export const getError = createSelector(
  getErrorFeatureState,
  state => state.error
);
