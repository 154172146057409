import { InjectionToken } from '@angular/core';

export interface LoaderConfig {
  ignorePatterns: Array<string | RegExp>;
  delayLoadbar?: number;
}

export const defaultLoaderConfig: LoaderConfig = {
  ignorePatterns: []
};



//export const LOADER_CONFIG = new InjectionToken<LoaderConfig>('loader.config');
