import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from '@angular/platform-browser';

import { MatSortModule } from "@angular/material/sort";
import { AppRoutingModule } from '../app-routing.module';
import { StatusCircleModule } from "../shared/status-circle/status-circle.module";
import { DashboardComponent } from './dashboard.component';
import { UserPunchModule } from '../shared/user-punch/user-punch.module';
import { MatTabsModule } from '@angular/material/tabs';
import { SignatureAssignmentsModule } from '../shared/signature-assignments/signature-assignments.module';
import { MatBadgeModule } from '@angular/material/badge';
import { ReportsComponent } from '../reports/reports.component';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    LayoutModule,
    StatusCircleModule,
    MatListModule,
    MatTabsModule,
    MatSortModule,
    UserPunchModule,
    SignatureAssignmentsModule,
    MatBadgeModule,
    NgxChartsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
  ]
})
export class DashboardModule { }
