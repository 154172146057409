import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  ChangeProject,
  ClearCurrentCp,
  ClearCurrentTask,
  ClearProjectUsers,
  ClearScopeOfWork,
  CpsLoad,
  LoadFailure,
  ProjectActionTypes,
  ProjectConfigLoadSuccess,
  ProjectConfigUpdate,
  ProjectDeleteImage,
  ProjectImageUpload,
  ProjectInjectsCheckSheetItemValues,
  ProjectsLoadSuccess,
  ProjectTreeLoad,
  ProjectTreeLoadSuccess,
  SignatureRoleLoad,
  SignatureRoleLoadSuccess,
  TasksLoad
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentProject, getCurrentSite } from '@completion/selectors';
import { ProjectService } from '../../services';
import { ResourceEffect } from './resource.effect';
import { E } from '@angular/cdk/keycodes';

@Injectable()
export class ProjectEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly projectService: ProjectService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, projectService, store, toastr);
  }

  @Effect()
  loadProjects$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectTreeLoad>(ProjectActionTypes.ProjectsLoad),
    this.fetchResource('getProjects', ProjectsLoadSuccess)
  );

  @Effect()
  changeProject$: Observable<Action> = this.actions$.pipe(
    ofType<ChangeProject>(ProjectActionTypes.ChangeProject),
    map(action => action),
    switchMap(action => [
      new TasksLoad(action.project.id),
      new CpsLoad(),
      new ClearCurrentTask(),
      new ClearCurrentCp(),
      new ClearProjectUsers(),
      new ClearScopeOfWork(),
      new ProjectTreeLoad()
    ]),
    catchError(err => of(new LoadFailure(err)))
  );

  @Effect()
  loadProjectTree$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectTreeLoad>(ProjectActionTypes.ProjectTreeLoad),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentSite)),
    this.fetchResource('getProjectTree', ProjectTreeLoadSuccess, ([_, currentProject, currentSite]) => [currentProject.id, currentSite?.id], treeNodes => [treeNodes])
  );

  @Effect()
  uploadProjectImage$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectImageUpload>(ProjectActionTypes.ProjectImageUpload),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('uploadProjectImage', null, ([action, currentProject]) => [currentProject.id, action.imageType, action.attachment])
  );

  @Effect()
  deleteProjectImage$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectDeleteImage>(ProjectActionTypes.ProjectDeleteImage),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('deleteProjectImage', null, ([action, currentProject]) => [currentProject.id, action.imageType])
  );

  @Effect()
  updateProjectConfig$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectConfigUpdate>(ProjectActionTypes.ProjectConfigUpdate),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateProjectConfig', ProjectConfigLoadSuccess, ([action, currentProject]) => [currentProject.id, action.projectConfig])
  );

  @Effect()
  injectCsItemValues$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectInjectsCheckSheetItemValues>(ProjectActionTypes.ProjectInjectsCheckSheetItemValues),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('injectCsItemValues', null, ([action, currentProject]) => [currentProject.id, action.data])
  );
}
