import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import {
  HandoverActionTypes,
  HandoverChangeSectionOrdinals,
  HandoverCreateSection,
  HandoverCreateSectionSuccess,
  HandoverDeleteSection,
  HandoverFileDelete,
  HandoverFileUpload,
  HandoverLoad,
  HandoverLoadSuccess,
  HandoverPatch,
  HandoverSaveSection,
  HandoverSaveSections,
  HandoverSectionTemplatesLoadSuccess,
  HandoverSoftDelete,
  HandoverUpdateFrontPage,
  HandoverUpdateRow,
  HandoverUpdateRowSuccess,
  HandoverUpdateSectionsSuccess,
  HandoverUpdateSignature
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentHandoverId, getCurrentProject, getHandoverSectionTemplates } from '@completion/selectors';
import { HandoverService } from '@completion/services';
import { ResourceEffect } from './resource.effect';

const fileServiceArgumentMapper = ([action, currentProject, cpId, handoverId]) => [
  currentProject.id,
  cpId,
  handoverId,
  action.rowId,
  action.file
];

@Injectable()
export class HandoverEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly handoverService: HandoverService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, handoverService, store, toastr);
  }

  @Effect()
  loadHandover$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverLoad>(HandoverActionTypes.HandoverLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('getHandover', HandoverLoadSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  loadSectionTemplates$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverLoad>(HandoverActionTypes.HandoverSectionTemplatesLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('getSectionTemplates', HandoverSectionTemplatesLoadSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  changeSectionOrdinals: Observable<Action> = this.actions$.pipe(
    ofType<HandoverChangeSectionOrdinals>(HandoverActionTypes.HandoverChangeSectionOrdinals),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    map(([action, currentProject, cpId]) => {
      // Emitting fields not relevant for the ordinal change
      const sections = action.sections.map((section, index) => ({
        id: section.id,
        ordinal: index + action.startIndex + 1
      }));

      return [currentProject.id, cpId, sections];
    }),
    this.fetchResource('updateSectionOrdinals', HandoverUpdateSectionsSuccess, ([projectId, cpId, sections]) => [projectId, cpId, sections])
  );

  @Effect()
  uploadFile$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverFileUpload>(HandoverActionTypes.HandoverFileUpload),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('fileUpload', HandoverUpdateRowSuccess, fileServiceArgumentMapper)
  );

  @Effect()
  deleteFile$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverFileDelete>(HandoverActionTypes.HandoverFileDelete),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('fileDelete', HandoverUpdateRowSuccess, fileServiceArgumentMapper)
  );

  @Effect()
  createSection$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverCreateSection>(HandoverActionTypes.HandoverCreateSection),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getHandoverSectionTemplates)),
    map(([_, currentProject, cpId, sectionTemplates]) => {
      // Would be nice if ordinal was just calculated on the backend
      const newSection = { title: 'Other', ordinal: sectionTemplates.length + 1 };

      return [currentProject.id, cpId, newSection];
    }),
    this.fetchResource('createSection', HandoverCreateSectionSuccess, ([projectId, cpId, section]) => [projectId, cpId, section])
  );

  @Effect()
  saveSection$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverSaveSection>(HandoverActionTypes.HandoverSaveSection),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('updateSection', HandoverUpdateSectionsSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.section
    ])
  );

  @Effect()
  saveSections$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverSaveSections>(HandoverActionTypes.HandoverSaveSections),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId)),
    this.fetchResource('updateSections', HandoverUpdateSectionsSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.sections
    ])
  );

  @Effect()
  updateRow$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverUpdateRow>(HandoverActionTypes.HandoverUpdateRow),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('updateRow', HandoverUpdateRowSuccess, ([action, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId,
      action.sectionId,
      action.rowId,
      action.rowChanges
    ])
  );

  @Effect()
  deleteSection$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverDeleteSection>(HandoverActionTypes.HandoverDeleteSection),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    // HandoverSectionTemplatesLoadSuccess is used here to pushing lastest data to store.
    this.fetchResource('deleteSection', HandoverSectionTemplatesLoadSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.sectionId
    ])
  );

  @Effect()
  softDeleteSection$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverSoftDelete>(HandoverActionTypes.HandoverSoftDelete),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    // HandoverSectionTemplatesLoadSuccess is used here to pushing lastest data to store.
    this.fetchResource('softDeleteHandover', HandoverLoadSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.handoverId
    ])
  );

  @Effect()
  updateHandoverSignature$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverUpdateSignature>(HandoverActionTypes.HandoverUpdateSignature),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource(
      'updateHandoverSignature',
      HandoverPatch,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.data.handoverId, action.data.signing],
      response => [
        {
          signatureMatrix: response
        }
      ]
    )
  );

  @Effect()
  updateFrontPage$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverUpdateFrontPage>(HandoverActionTypes.HandoverUpdateFrontPage),
    withLatestFrom(...this.multiSelect(getCurrentProject, getCurrentCpId, getCurrentHandoverId)),
    this.fetchResource('updateFrontPage', null, ([action, currentProject, cpId, handoverId]) => [
      currentProject.id,
      cpId,
      handoverId,
      action.data
    ])
  );
}
