import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPunchComponent } from './user-punch.component';
import { MatTableModule } from '@angular/material/table';
import { StatusCircleModule } from '../status-circle/status-circle.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { PunchDialogModule } from '../punch-dialog/punch-dialog.module';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '../spinner/spinner.module';
import {MatButtonModule} from "@angular/material/button";




@NgModule({
  declarations: [UserPunchComponent],
    imports: [
        CommonModule,
        MatTableModule,
        StatusCircleModule,
        MatDialogModule,
        MatIconModule,
        PunchDialogModule,
        MatInputModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule,
        MatButtonModule,
    ],
  exports: [UserPunchComponent]
})
export class UserPunchModule { }
