import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { ClearCurrentCsa, ClearCurrentMcp, LoadCurrentMcp, LoadCurrentMcpSuccess, McpActionTypes, McpUpdate } from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentMcpId, getCurrentProject } from '@completion/selectors';
import { McpService } from '@completion/services';
import { ResourceEffect } from './resource.effect';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class McpEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly mcpService: McpService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, mcpService, store, toastr);
  }

  @Effect()
  clearCurrentMcp$: Observable<Action> = this.actions$.pipe(
    ofType<ClearCurrentMcp>(McpActionTypes.ClearCurrentMcp),
    map(() => new ClearCurrentCsa())
  );

  @Effect()
  loadMcp$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCurrentMcp>(McpActionTypes.LoadCurrentMcp),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, currentProject, mcpId]) => !!(currentProject && mcpId)),
    this.fetchResource('getMcp', LoadCurrentMcpSuccess, ([_, currentProject, mcpId]) => [currentProject.id, mcpId])
  );

  @Effect()
  updateMcp$: Observable<Action> = this.actions$.pipe(
    ofType<McpUpdate>(McpActionTypes.McpUpdate),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateMcp', LoadCurrentMcpSuccess, ([action, currentProject]) => [currentProject.id, action.mcp])
  );
}
