import { HandoverDocumentPunch } from './handover-document';

export interface Mcpl {
  cpDescription: string;
  cpNumber: string;
  documentNumber: string;
  id: number;
  mcPackages: McplMcPackage[];
  partNumber: number;
  punches: HandoverDocumentPunch[];
  title: string;
}

export interface McplMcPackage {
  mcpNumber: string;
  punches: HandoverDocumentPunch[];
}
