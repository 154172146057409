<div
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  class="initials-button"
  [class.empty]="!caption"
  (mouseenter)="open = true"
  (mouseleave)="open = false"
  [ngClass]="{ disabled: isOffline }"
>
  {{ caption }}
</div>

<ng-template *ngIf="actualName" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="open">
  <div class="assignment-overlay" [ngClass]="{ disabled: isOffline }">
    <span class="timestamp" *ngIf="dateString">{{ dateString }}</span>
    <span class="full-name">{{ actualName }}</span>
  </div>
</ng-template>
