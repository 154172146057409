import { User } from './user';

export class UserAssignment {
  user: User;
  timestamp: Date;

  constructor(user: User, timestamp: Date = new Date(Date.now())) {
    this.user = user;
    this.timestamp = timestamp;
  }
}
