<div class="certificate-wrapper mat-elevation-z3">
  <div>
    <h3>Filter</h3>
    <div class="filter-container">

      <mat-form-field>
        <input matInput placeholder="Task Number" [(ngModel)]="filterValues.taskNumber" (input)="onFilterChange()">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="System No" [(ngModel)]="filterValues.systemNo" (input)="onFilterChange()">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Subsystem No" [(ngModel)]="filterValues.subsystemNo" (input)="onFilterChange()">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Commissioning Package No" [(ngModel)]="filterValues.commissioningPackageNo"
               (input)="onFilterChange()">
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!loading && reportData.length === 0">
    <button mat-icon-button (click)="loadCertificateReportData()"> Load data<mat-icon>refresh</mat-icon></button>
  </div>
<div *ngIf="!loading && reportData.length > 0 ">
  <table mat-table [dataSource]="filteredData" >
    <ng-container matColumnDef="taskNumber">
      <th mat-header-cell *matHeaderCellDef> Task Number</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.taskNumber}} </td>
    </ng-container>


    <ng-container matColumnDef="systemNo">
      <th mat-header-cell *matHeaderCellDef> System No</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.systemNo}} </td>
    </ng-container>



    <ng-container matColumnDef="subsystemNo">
      <th mat-header-cell *matHeaderCellDef> Subsystem No</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.subsystemNo}} </td>
    </ng-container>



    <ng-container matColumnDef="commissioningPackageNo">
      <th mat-header-cell *matHeaderCellDef> Commissioning Package No</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.commissioningPackageNo}} </td>
    </ng-container>



    <ng-container matColumnDef="signedRfcc">
      <th mat-header-cell *matHeaderCellDef> Signed RFCC</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.signedRfcc}} / {{ element.totalRfcc }}
        <div [ngClass]="readinessClass(element.signedRfcc, element.totalRfcc)"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="signedRfoc">
      <th mat-header-cell *matHeaderCellDef> Signed RFOC</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.signedRfoc}}  / {{ element.totalRfoc }}
        <div [ngClass]="readinessClass(element.signedRfoc, element.totalRfoc)"></div>
      </td>
    </ng-container>


    <ng-container matColumnDef="signedMcc">
      <th mat-header-cell *matHeaderCellDef> Signed MCC</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': rowColor(element)}" > {{element.signedMcc}} / {{element.totalMcc}}
        <div [ngClass]="readinessClass(element.signedMcc, element.totalMcc)"></div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

</div>
