import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ProjectAccessState } from 'src/app/core/store/reducers/project-access';

import { AddSignatureMatrixTemplate, ClearError, CompanyLoad, CompanyUsers, ProjectUsers, SignatureRoleLoad, SiteLoad, UpdateSignatureMatrixTemplate } from '@completion/actions';
import { ButtonStatus, CompanyCategory, ResourceStatus, ResourceType } from '@completion/enums';
import { SignatureMatrixTemplate, SignatureRole, Site, User } from '@completion/models';
import { getCompanyUser, getResourceState, getSignatureRoles, getSites, projectUsers } from '@completion/selectors';
import { hasSameId } from '@completion/utils';
import { A } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-matrix-template-edit-dialog',
  templateUrl: './matrix-template-edit-dialog.component.html',
  styleUrls: ['./matrix-template-edit-dialog.component.scss']
})
export class MatrixEditDialogComponent implements OnInit {
  hasSameIds = hasSameId;

  buttonStatus = ButtonStatus.IDLE;
  errorMsg: string | null;
  form: FormGroup;

  signatureMatrixTemplate: SignatureMatrixTemplate | undefined;
  private readonly destroy$: Subject<void> = new Subject();

  signatureRoles$: Observable<SignatureRole[]> = this.store.pipe(
    select(getSignatureRoles),
    takeUntil(this.destroy$)
  );
  signees$: Observable<User[]> = this.store.pipe(
    select(projectUsers),
    takeUntil(this.destroy$)
  );

  constructor(private readonly store: Store<ProjectAccessState>, private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MatrixEditDialogComponent>, @Inject(MAT_DIALOG_DATA) readonly data = null) {
    this.store.dispatch(new SignatureRoleLoad());
    this.store.dispatch(new ProjectUsers());
    this.signatureMatrixTemplate = { ...this.data.signatureMatrixTemplate };

    this.store
      .pipe(
        select(getResourceState, ResourceType.UpdateSignatureMatrixTemplate),
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        if (state.status === ResourceStatus.InProgress) {
          this.buttonStatus = ButtonStatus.IN_PROGRESS;
        }

        if (state.status === ResourceStatus.Failure) {
          this.buttonStatus = ButtonStatus.IDLE;
        }

        this.errorMsg = state.lastError;

        if (state.status === ResourceStatus.Success) {
          this.buttonStatus = ButtonStatus.COMPLETED;
          setTimeout(() => {
            this.buttonStatus = ButtonStatus.IDLE;
            this.dialogRef.close();
          }, 1000);

        }
      });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      ordinal: [this.signatureMatrixTemplate.ordinal],
      role: [this.signatureMatrixTemplate.role],
      verified: [this.signatureMatrixTemplate.verified],
      signee: [this.signatureMatrixTemplate.signee],
      signatureRole: [this.signatureMatrixTemplate.signatureRole]
    });
  }

  onSave(): void {
    this.form.markAllAsTouched();
    this.errorMsg = null;
    this.store.dispatch(new ClearError());

    if (this.form.valid) {
      this.buttonStatus = ButtonStatus.IN_PROGRESS;
      if (this.signatureMatrixTemplate.id) {
        this.store.dispatch(new UpdateSignatureMatrixTemplate(this.signatureMatrixTemplate));
      }
      else {
        this.store.dispatch(new AddSignatureMatrixTemplate(this.signatureMatrixTemplate));
      }

      if (!this.errorMsg || this.errorMsg.length === 0) {
        this.buttonStatus = ButtonStatus.COMPLETED;
        setTimeout(() => this.buttonStatus = ButtonStatus.IDLE, 2000);
        this.dialogRef.close();
      }
    }
  }

}
