import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ResourceStatus, ResourceType } from '@completion/enums';
import { TreeNode, TreeNodeType } from '@completion/models';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentMcpId, getCurrentProject, getCurrentTask, getProjectTreeNodes, getResourceState } from '@completion/selectors';
import { groupBy } from '@completion/utils';
import { TreeStatusService } from '@completion/services';
import { ProjectTreeUpdateNode } from '@completion/actions';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, OnDestroy {
  resourceType = ResourceType.ProjectGetProjectTree;
  nodeType = TreeNodeType;
  treeControl: FlatTreeControl<FakeFlatNode> = new FlatTreeControl<FakeFlatNode>(getNodeLevel, getIsNodeExpandable);
  nodes: MatTreeFlatDataSource<TreeNode, FakeFlatNode>;
  loading: boolean;
  loadingError: string;
  isOpen: boolean;
  displayStatusIcons: boolean;

  toggle(node: FakeFlatNode): void {
    this.treeControl.toggle(node);
  }

  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly store: Store<State>, private readonly treeStatusService: TreeStatusService) {
    const treeFlattener = new MatTreeFlattener<TreeNode, FakeFlatNode>(nodeTransformer, getNodeLevel, getIsNodeExpandable, getNodeChildren);

    combineLatest([this.store.select(getProjectTreeNodes), this.store.select(getCurrentTask)])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(([taskNodes, task]) => {
          if (task && task.id) {
            return of(this.toSystemNodes(taskNodes.filter(node => node.id === task.id)));
          }

          const systemNodes = this.toSystemNodes(taskNodes);
          return of(this.groupNodes(systemNodes));
        })
      )
      .subscribe(treeNodes => {
        this.nodes = new MatTreeFlatDataSource(this.treeControl, treeFlattener);
        this.nodes.data = treeNodes;
      });

    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(p => {
      if (p) {
        const projectConfig = p.config.find(c => c.name === 'DISPLAY_STATUS_ICONS');
        this.displayStatusIcons = projectConfig?.value === 'true';
      }
    });
    this.store
      .pipe(
        select(getResourceState, this.resourceType),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        this.loading = state.status === ResourceStatus.InProgress;
        this.loadingError = state.lastError;
      });

    this.store
      .pipe(
        select(getCurrentCpId),
        takeUntil(this.destroy$)
      )
      .subscribe(id => {
        this.expandNode(id, TreeNodeType.CP);
      });

    this.store
      .pipe(
        select(getCurrentMcpId),
        takeUntil(this.destroy$)
      )
      .subscribe(id => this.expandNode(id, TreeNodeType.MCP));
  }

  ngOnInit(): void {
    // Dispatching loading for data is done in project.effect.ts:changeProject$
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  private groupNodes(ungroupedSystemNodes: TreeNode[]): TreeNode[] {
    const systemNumberToSystemsMap = groupBy(ungroupedSystemNodes, 'number');
    const groupedSystems = Object.values(systemNumberToSystemsMap).map((systems: TreeNode[]) => {
      const [firstSystem] = systems;

      return {
        ...firstSystem,
        children: systems.reduce((array, system) => [...array, ...system.children], [])
      };
    });

    groupedSystems.sort(this.sortTreenode);

    return groupedSystems.map(system => {
      const groupedSubsystem = groupBy(system.children, 'number');
      const children = Object.values(groupedSubsystem).map((subsystems: TreeNode[]) => {
        const [firstSubSystem] = subsystems;

        const cps: TreeNode[] = subsystems.reduce((array, subsystem) => [...array, ...subsystem.children], []);
        cps.sort(this.sortTreenode);

        return {
          ...firstSubSystem,
          children: cps,
        };
      });

      children.sort(this.sortTreenode);



      return {
        ...system,
        children
      };
    });
  }

  private readonly sortTreenode = (a, b) => {
    if (a.number > b.number) {
      return 1;
    } else {
      return -1
    }
  }

  // Top level is tasks, but the tree only shows from system level
  private toSystemNodes(treeNodes: TreeNode[]): TreeNode[] {
    return treeNodes.reduce((array: TreeNode[], node: TreeNode) => {
      return [...array, ...node.children];
    }, []);
  }

  private expandNode(nodeId: number, nodeType: TreeNodeType) {
    const foundNodes: FakeFlatNode[] = new Array();
    for (const itNode of this.nodes.data) {
      let node: FakeFlatNode = this.searchTree(itNode, nodeId, nodeType);

      while (node) {
        foundNodes.push(node);

        node = this.searchTree(itNode, node.parentId, this.getParentNodeType(node.nodeType));
      }
    }
    for (let i = foundNodes.length - 1; i >= 0; i--) {
      this.treeControl.expand(this.treeControl.dataNodes.filter(e => e.id === foundNodes[i].id)[0]);
    }
  }

  private searchTree(node, compare: number, nodeType: TreeNodeType): any {
    if (node.id === compare && node.nodeType === nodeType) {
      return node;
    } else if (node.children != null) {
      let result = null;
      for (let i = 0; result == null && i < node.children.length; i++) {
        result = this.searchTree(node.children[i], compare, nodeType);
      }
      return result;
    }
    return null;
  }

  private getParentNodeType(currentNodeType: TreeNodeType) {
    switch (currentNodeType) {
      case TreeNodeType.MCP:
        return TreeNodeType.CP;
      case TreeNodeType.CP:
        return TreeNodeType.SUBSYSTEM;
      case TreeNodeType.SUBSYSTEM:
        return TreeNodeType.SYSTEM;
      default:
        return null;
    }
  }

  displayStatusIcon(): boolean {
    return this.displayStatusIcons;
  }

}


const nodeTransformer = (node: TreeNode, level: number) => {
  return {
    ...node,
    level,
    hasChildren: node.children && node.children.length > 0
  };
};

const getNodeChildren = ({ children }: TreeNode) => {
  return children;
};

const getNodeLevel = ({ level }: FakeFlatNode) => {
  return level;
};

const getIsNodeExpandable = ({ hasChildren }: FakeFlatNode) => {
  return hasChildren;
};

interface FakeFlatNode extends TreeNode {
  level: number;
  hasChildren: boolean;
}
