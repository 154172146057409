import { Injectable } from '@angular/core';
import {BaseService, Resource} from './base.service';
import {HttpClient} from "@angular/common/http";
import {ResourceType} from "@completion/enums";
import {Observable} from "rxjs";
import {CheckSheetAssignment, Punch} from "@completion/models";

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {

  private readonly projectUrl = '/api/projects';

  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.GetProjectPunches)
  getProjectPunches(projectId: number, taskId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.projectUrl}/${projectId}/reports/punches`);
  }

  @Resource(ResourceType.GetProjectPunchesByTask)
  getProjectPunchesByTask(projectId: number, taskId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.projectUrl}/${projectId}/reports/punches/task/${taskId}`);
  }

  @Resource(ResourceType.GetCheckSheetAssignmentByProject)
  getCheckSheetAssignmentByProject(projectId: number): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.projectUrl}/${projectId}/reports/csas`);
  }

  @Resource(ResourceType.GetCheckSheetAssignmentByTask)
  getCheckSheetAssignmentByTask(projectId: number, taskId: number): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.projectUrl}/${projectId}/reports/csas/task/${taskId}`);
  }
}
