import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  AddMcpCheckRecord,
  CheckSheetActionTypes,
  DeleteMcpCheckRecord,
  LoadCurrentCsaSuccess,
  LoadCurrentMcpCsa,
  McpActionTypes,
  McpAddCheckedTag,
  McpDeleteCSA,
  McpMoveCSA,
  McpScopeOfWork,
  PackageCommonActionTypes,
  McpScopeOfWorkSuccess,
  UpdateCsItemStatusSuccess,
  UpdateMcpCsa,
  UpdateMcpCsItemStatus,
  UpdateMcpRecordValue,
  FetchMcpSignaturesFromHistory,
  UpdateSignaturesSuccess,
  SignMcpCsaSigntureEntry,
  InsertAndSignMcpCsaSigntureEntry,
  UploadCpCsaFile,
  CertificateDetailsPatch,
  UploadMcpCsaFile,
  DeleteMcpCsaAttachment,
  FetchMcItemStatusesFromHistory
} from '@completion/actions';
import { State } from '@completion/reducers';
import {getCurrentCpId, getCurrentCsaId, getCurrentMcpId, getCurrentProject} from '@completion/selectors';
import { McpService } from '@completion/services';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class McpCheckSheetEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly mcpService: McpService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, mcpService, store, toastr);
  }

  @Effect()
  loadMcpScopeOfWork$: Observable<Action> = this.actions$.pipe(
    ofType<McpScopeOfWork>(PackageCommonActionTypes.McpScopeOfWork),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, currentProject, mcpId]) => !!(currentProject && mcpId)),
    this.fetchResource('getCheckedTags', McpScopeOfWorkSuccess, ([_, currentProject, mcpId]) => [currentProject.id, mcpId])
  );

  @Effect()
  addCheckedTags$: Observable<Action> = this.actions$.pipe(
    ofType<McpAddCheckedTag>(McpActionTypes.McpAddCheckedTag),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, currentProject, mcpId]) => currentProject != null && mcpId != null),
    this.fetchResource(
      'addCheckedTags',
      null,
      ([action, currentProject, mcpId]) => [currentProject.id, mcpId, action.checkSheetAssignment],
      response => [response.status]
    )
  );

  @Effect()
  deleteCheckSheetAssignment$: Observable<Action> = this.actions$.pipe(
    ofType<McpDeleteCSA>(McpActionTypes.McpDeleteCSA),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    this.fetchResource(
      'deleteMcpCheckSheetAssignment',
      null,
      ([action, currentProject, mcpId]) => [currentProject.id, mcpId, action.ids],
      response => [response.status]
    )
  );

  @Effect()
  moveTags$: Observable<Action> = this.actions$.pipe(
    ofType<McpMoveCSA>(McpActionTypes.McpMoveCSA),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    this.fetchResource(
      'moveTags',
      null,
      ([action, currentProject, mcpId]) => [currentProject.id, mcpId, action.csas],
      response => [response.status]
    )
  );

  @Effect()
  loadMcpCsa$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCurrentMcpCsa>(CheckSheetActionTypes.LoadCurrentMcpCsa),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('getCheckSheetAssignment', LoadCurrentCsaSuccess, ([_, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId
    ])
  );

  @Effect()
  updateMcpCsItemStatus$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateMcpCsItemStatus>(CheckSheetActionTypes.UpdateMcpCsItemStatus),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, project, mcpId, csaId]) => !!(project && mcpId && csaId)),
    this.fetchResource('updateCheckListItem', LoadCurrentCsaSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      action.item
    ])
  );

  @Effect()
  updateMcpCsa$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateMcpCsa>(CheckSheetActionTypes.UpdateMcpCsa),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId)),
    filter(([_, currentProject, mcpId]) => !!(currentProject && mcpId)),
    this.fetchResource('updateCheckSheetAssignment', LoadCurrentCsaSuccess, ([action, currentProject, mcpId]) => [
      currentProject.id,
      mcpId,
      action.csa,
      action.doSignOrVerify
    ])
  );

  @Effect()
  updateMcpRecordValue$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateMcpRecordValue>(CheckSheetActionTypes.UpdateMcpRecordValue),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('updateMcpRecordValue', LoadCurrentCsaSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      action.csItemNumber,
      action.recordValue
    ])
  );

  @Effect()
  addMcpRecordValue$: Observable<Action> = this.actions$.pipe(
    ofType<AddMcpCheckRecord>(CheckSheetActionTypes.AddMcpCheckRecord),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('addMcpRecordValue', LoadCurrentCsaSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      action.csItem.number,
      action.recordValue
    ])
  );
  @Effect()
  deleteMcpRecordValue$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteMcpCheckRecord>(CheckSheetActionTypes.DeleteMcpCheckRecord),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('deleteMcpRecordValue', LoadCurrentCsaSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      action.csItemNumber,
      action.recordValue
    ])
  );

  @Effect()
  FetchMcpSignaturesFromHistory$: Observable<Action> = this.actions$.pipe(
    ofType<FetchMcpSignaturesFromHistory>(CheckSheetActionTypes.FetchMcpSignaturesFromHistory),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('fetchMcpSignaturesFromHistory', UpdateSignaturesSuccess, ([_, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId
    ])
  );

  @Effect()
  FetchMcItemStatusesFromHistory$: Observable<Action> = this.actions$.pipe(
    ofType<FetchMcItemStatusesFromHistory>(CheckSheetActionTypes.FetchMcItemstatusesFromHistory),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('fetchMcItemStatusesFromHistory', UpdateSignaturesSuccess, ([_, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId
    ])
  );

  @Effect()
  SignMcpCsaSigntureEntry$: Observable<Action> = this.actions$.pipe(
    ofType<SignMcpCsaSigntureEntry>(McpActionTypes.SignMcpCsaSigntureEntry),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('signMcpCsaSigntureEntry', LoadCurrentCsaSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      action.entry.id
    ])
  );

  @Effect()
  InsertAndSignMcpCsaSigntureEntry$: Observable<Action> = this.actions$.pipe(
    ofType<InsertAndSignMcpCsaSigntureEntry>(McpActionTypes.InsertAndSignMcpCsaSigntureEntry),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource('insertAndSignMcpCsaSigntureEntry', LoadCurrentCsaSuccess, ([action, currentProject, mcpId, csaId]) => [
      currentProject.id,
      mcpId,
      csaId,
      true,
      [action.entry]
    ])
  );

  @Effect()
  uploadMcpCsaFile$: Observable<any> = this.actions$.pipe(
    ofType<UploadMcpCsaFile>(CheckSheetActionTypes.UploadMcpCsaFile),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource(
      'uploadMcpCsaFile',
      LoadCurrentCsaSuccess,
      ([action, currentProject, mcpId, csaId]) => [currentProject.id, mcpId, csaId,  action.file]
    )
  );

  @Effect()
  deleteMcpCsaAttachment$: Observable<any> = this.actions$.pipe(
    ofType<DeleteMcpCsaAttachment>(CheckSheetActionTypes.DeleteMcpCsaAttachment),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentMcpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, mcpId, csaId]) => !!(currentProject && mcpId && csaId)),
    this.fetchResource(
      'deleteMcpCsaAttachment',
      LoadCurrentCsaSuccess,
      ([action, currentProject, mcpId, csaId]) => [currentProject.id, mcpId, csaId, action.id]
    )
  );
}
