import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from '@completion/models';

@Component({
  selector: 'app-company-editor-dialog',
  templateUrl: './company-editor-dialog.component.html',
  styleUrls: ['./company-editor-dialog.component.scss']
})
export class CompanyEditorDialogComponent implements OnInit {
  companyForm: FormGroup;
  signatureMatrixTypes: string[];

  constructor(
    public dialogRef: MatDialogRef<CompanyEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company, signatureMatrixTypes: string[] },
    private fb: FormBuilder
  ) {
    this.signatureMatrixTypes = data.signatureMatrixTypes;
    this.companyForm = this.fb.group({
      signatureMatrixType: [data.company.signatureMatrixType, Validators.required]
    });
  }

  ngOnInit(): void {}

  onSave(): void {
    if (this.companyForm.valid) {
      this.dialogRef.close(this.companyForm.value);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
