import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationMode } from '@completion/enums';

import { ResourceType } from '@completion/enums';
import { CheckSheetAssignment } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineService extends BaseService {
  applicationMode = ApplicationMode.ONLINE;
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.OfflineDownloadSOW)
  downloadMcpSOW(projectId: number, mcpId: number, csaIds: number[]): Observable<CheckSheetAssignment[]> {
    return this.http.put<CheckSheetAssignment[]>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/offline/enable`, csaIds);
  }
  @Resource(ResourceType.OfflineDownloadSOW)
  downloadCpSOW(projectId: number, cpId: number, csaIds: number[]): Observable<CheckSheetAssignment[]> {
    return this.http.put<CheckSheetAssignment[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/offline/enable`, csaIds);
  }
}
