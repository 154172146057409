import { Action } from '@ngrx/store';

import { ProjectImageType } from '@completion/enums';
import { MipsCheckSheetItemValue, Project, ProjectConfig, SignatureMatrixTemplates, TreeNode } from '@completion/models';
import { PackageStatusUpdate } from '../../services/tree-status.service';
















export enum ProjectActionTypes {
  ProjectsLoad = 'Projects Load',
  ProjectsLoadSuccess = 'Projects Load Success',
  ChangeProject = 'Change Project',
  ProjectTreeLoad = 'Project Tree Load',
  ProjectTreeLoadSuccess = 'Project Tree Load Success',
  ProjectImageUpload = 'Project Image Upload',
  ProjectDeleteImage = 'Project Delete Image',
  ProjectConfigUpdate = 'Project config Update',
  ProjectConfigLoadSuccess = 'Project Config Load Success',
  ProjectTreeUpdateNode = 'Project Tree Update Node',
  ProjectInjectsCheckSheetItemValues = "InjectCSItemValues",
  SignatureMatrixTemplateLoadSuccess = "SignatureMatrixTemplateLoadSuccess",
  SignatureRoleLoad = "SignatureRoleLoad",
  SignatureRoleLoadSuccess = "SignatureRoleLoadSuccess"
}

export class ProjectsLoad implements Action {
  readonly type = ProjectActionTypes.ProjectsLoad;
}

export class ProjectsLoadSuccess implements Action {
  readonly type = ProjectActionTypes.ProjectsLoadSuccess;

  constructor(public projects: Project[]) { }
}

export class ChangeProject implements Action {
  readonly type = ProjectActionTypes.ChangeProject;

  constructor(public project: Project) { }
}

export class ProjectTreeLoad implements Action {
  readonly type = ProjectActionTypes.ProjectTreeLoad;
}

export class ProjectTreeLoadSuccess implements Action {
  readonly type = ProjectActionTypes.ProjectTreeLoadSuccess;

  constructor(public treeNodes: TreeNode[]) { }
}

export class ProjectImageUpload implements Action {
  readonly type = ProjectActionTypes.ProjectImageUpload;

  constructor(public imageType: ProjectImageType, public attachment: File) { }
}

export class ProjectDeleteImage implements Action {
  readonly type = ProjectActionTypes.ProjectDeleteImage;

  constructor(public imageType: ProjectImageType) { }
}

export class ProjectConfigUpdate implements Action {
  readonly type = ProjectActionTypes.ProjectConfigUpdate;
  constructor(public projectConfig: ProjectConfig) { }
}
export class ProjectConfigLoadSuccess implements Action {
  readonly type = ProjectActionTypes.ProjectConfigLoadSuccess;
  constructor(public config: ProjectConfig[]) { };
}

export class ProjectTreeUpdateNode implements Action {
  readonly type = ProjectActionTypes.ProjectTreeUpdateNode;
  constructor(public update: PackageStatusUpdate) { }
}

export class ProjectInjectsCheckSheetItemValues implements Action {
  readonly type = ProjectActionTypes.ProjectInjectsCheckSheetItemValues;
  constructor(public data: MipsCheckSheetItemValue[]) {
  }
}

export class SigntureMatrixTemplateLoadSuccess implements Action {
  readonly type = ProjectActionTypes.SignatureMatrixTemplateLoadSuccess;

  constructor(public signatureMatrices: SignatureMatrixTemplates) { }
}

export type ProjectAction =
  | ProjectsLoad
  | ProjectsLoadSuccess
  | ChangeProject
  | ProjectTreeLoad
  | ProjectTreeLoadSuccess
  | ProjectImageUpload
  | ProjectDeleteImage
  | ProjectConfigUpdate
  | ProjectConfigLoadSuccess
  | ProjectTreeUpdateNode
  | ProjectInjectsCheckSheetItemValues
  | SigntureMatrixTemplateLoadSuccess;
