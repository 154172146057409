import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { DossierStatus, DossierUrl } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class HandoverDossierService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.StartCompileDossier)
  startCompileDossier(projectId: number, cpId: number, handoverId: number): Observable<DossierStatus> {
    return this.http.post<DossierStatus>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/dossier`, null);
  }

  @Resource(ResourceType.StartPreviewDossier)
  startPreviewDossier(projectId: number, cpId: number, handoverId: number): Observable<DossierStatus> {
    return this.http.post<DossierStatus>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/preview`, null);
  }

  @Resource(ResourceType.GetDossier)
  getDossier(projectId: number, cpId: number, handoverId: number): Observable<DossierUrl> {
    return this.http.get<DossierUrl>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/dossier`);
  }

  @Resource(ResourceType.PreviewDossier)
  getPreviewDossier(projectId: number, cpId: number, handoverId: number): Observable<DossierUrl> {
    return this.http.get<DossierUrl>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/preview`);
  }

  @Resource(ResourceType.DeleteDossier)
  deleteDossier(projectId: number, cpId: number, handoverId: number) {
    return this.http.delete(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/dossier`);
  }

  @Resource(ResourceType.GetJobStatus)
  getJobStatus(projectId: number, cpId: number, handoverId: number): Observable<DossierStatus> {
    return this.http.get<DossierStatus>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/job-status`);
  }

  @Resource(ResourceType.PreviewJobStatus)
  getPreviewJobStatus(projectId: number, cpId: number, handoverId: number): Observable<DossierStatus> {
    return this.http.get<DossierStatus>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/handover/${handoverId}/preview/job-status`);
  }
}
