import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';

import {AdminRevokeAccepted, AdminRevokeCleared, ProjectUsers} from '@completion/actions';
import { Confirmation, Punch, User, UserAssignment } from '@completion/models';
import { State } from '@completion/reducers';
import { getCurrentMcp, getCurrentUser, getResourceState, projectUsers } from '@completion/selectors';
import { ProjectAccessService, Resource } from '@completion/services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog';
import { ResourceType } from '../../enums/resource-type';
import { ResourceStatus } from '../../enums/resource-status';
import { Router } from '@angular/router';

@Component({
  selector: 'app-punch-action',
  templateUrl: './punch-action.component.html',
  styleUrls: ['./punch-action.component.scss']
})
export class PunchActionComponent implements OnDestroy {
  @Input()
  set punch(punch: Punch) {
    this.punchInput = punch;
    if (punch.clearedBy) {
      this.clearedByForm.setValue(punch.clearedBy);
      if (this.currentUser && (punch.clearedByExecutor || punch.clearedBy)) {
        const userId = punch.clearedByExecutor ? punch.clearedByExecutor.user.id : punch.clearedBy.user.id;
        this.showUnClear = userId === this.currentUser.id || this.access.isAdmin();
      }
    } else {
      this.clearedByForm.setValue(new UserAssignment(this.currentUser));
      this.showUnClear = true;
    }

    if (punch.acceptedBy) {
      this.acceptedByForm.setValue(punch.acceptedBy);
    } else {
      this.acceptedByForm.setValue(new UserAssignment(this.currentUser));
    }
  }

  get punch(): Punch {
    return this.punchInput;
  }

  users$: Observable<UserAssignment[]>;
  acceptedByForm = new FormControl();
  clearedByForm = new FormControl();
  showUnClear = false;
  private punchInput: Punch;
  private currentUser: User;
  private readonly subscription = new Subscription();

  hasSameUserIds: ((o1: UserAssignment, o2: UserAssignment) => boolean) | null = (o1, o2) => o1 && o2 && o1.user.id === o2.user.id;

  constructor(private readonly access: ProjectAccessService, private readonly store: Store<State>, private readonly dialog: MatDialog, private readonly router: Router) {
    this.store.dispatch(new ProjectUsers());
    this.subscription.add(
      this.store.select(projectUsers).subscribe(users => {
        this.users$ = of(users.map(user => new UserAssignment(user)));
      })
    );
    this.subscription.add(
      this.store.select(getCurrentUser).subscribe(user => {
        if (user) {
          this.currentUser = user;
        }
      })
    );
  }

  showClearAccepted() {
    return this.access.canClearPunches() && this.access.isAdmin();
  }

  clearAccepted() {
    let isMcp = false;
    this.store.select(getCurrentMcp).subscribe(mcp => {
      if (mcp) {
        isMcp = true;
      }
    });
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Revoke Acceptance on Punch',
        message: 'Are you sure you want to revoke the acceptance on this punch?',
        isCancelDisplay: true,
        confirmationTile: 'Revoke Acceptance',
        icon: 'warning',
        confirmationMessage: 'This action will require the packagestatus to be recalculated.'
      } as Confirmation
    }).afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new AdminRevokeAccepted(this.punch.id, isMcp));
      }
    });
  }


  clearDisabled() {
    return !this.access.canClearPunches();
  }

  acceptDisabled() {
    return !this.access.canAcceptPunches();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onUndoClear(): void {
    if( this.currentUser !== this.punch.clearedBy.user && this.access.isAdmin()) {
      let isMcp = false;
      this.store.select(getCurrentMcp).subscribe(mcp => {
        if (mcp) {
          isMcp = true;
        }
      });
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Revoke Clearance on Punch',
          message: 'Are you sure you want to revoke the clearance on this punch?',
          isCancelDisplay: true,
          confirmationTile: 'Revoke Clearance',
          icon: 'warning',
          confirmationMessage: 'This action will require the packagestatus to be recalculated.'
        } as Confirmation
      }).afterClosed().subscribe(result => {
        if (result) {
          this.store.dispatch(new AdminRevokeCleared(this.punch.id, isMcp));
        }
      });
    }
    this.punch.clearedBy = new UserAssignment(null);
  }
}
