<div class="mat-elevation-z2">
  <h2>Signature roles setup</h2>
  <div class="spinner-container" *ngIf="isLoading(); else loaded">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>
  <ng-template #loaded>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Role Name</th>
        <td mat-cell class="name" *matCellDef="let element">{{ element.name || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Verified</th>
        <td mat-cell class="role" *matCellDef="let element">{{ element.description || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="groupEmail">
        <th mat-header-cell *matHeaderCellDef>Group Email</th>
        <td mat-cell class="role" *matCellDef="let element">{{ element.groupEmail || 'N/A' }}</td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell class="edit" *matCellDef="let element">
          <button class="edit-button" (click)="editSignatureRole(element)" mat-flat-button><mat-icon>edit</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell class="delete" *matCellDef="let element">
          <button class="edit-button" (click)="deleteSignatureRole(element)" mat-flat-button><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-template>
  <div>
    <button mat-button (click)="addSignatureRole()">Add Signature Role</button>
  </div>
</div>
