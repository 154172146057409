import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { CheckSheetAssignment, Tag } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TagService extends BaseService {
  // Why does this have to be here? No one knows. But the @Injectable() behaviour breaks if it's removed.
  // FUN FACT: keeping this code here, **but commented**, also still works(!), but removing it altogether will cause tears.
  constructor(http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.TagsGetMcpAvailableTags)
  public getMcpAvailableTags(projectId: number, mcpId: number): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/availableTags`);
  }

  @Resource(ResourceType.TagsGetCpAvailableTags)
  public getCpAvailableTags(projectId: number, cpId: number): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}/projects/${projectId}/cps/${cpId}/availableTags`);
  }

  @Resource(ResourceType.TagSetSerialNumberTags)
  public setSerialNumberTag(projectId: number, tag: Tag): Observable<any> {
    return this.http.put(`${this.baseUrl}/projects/${projectId}/tag/serialnumber`, tag)
  }

  @Resource(ResourceType.TagSearchTag)
  public searchTags(projectId: number, search: string): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.baseUrl}/projects/${projectId}/tag/search?search=${search}`);
  }

}
