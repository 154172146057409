import {Component, OnDestroy, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {SignatureRole, ProjectAccess, ProjectAccessRoles} from '@completion/models';
import {getCurrentProject, getProjectAccessList, getSignatureRoles} from '@completion/selectors';
import {ProjectAccessListLoad, SignatureRoleLoad, UpdateProjectAccess} from '@completion/actions';

import {map, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-signature-role-matrix',
  templateUrl: './signature-role-matrix.component.html',
  styleUrls: ['./signature-role-matrix.component.scss']
})
export class SignatureRoleMatrixComponent implements OnInit, OnDestroy {
  projectAccessList$: Observable<ProjectAccess[]>;
  filteredProjectAccessList$: Observable<ProjectAccess[]>;
  signatureRoles$: Observable<SignatureRole[]>;
  displayedColumns$: Observable<string[]>;
  destroy$: Subject<void> = new Subject();
  roleFilter: string = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(project => {
      if (project) {
        this.store.dispatch(new ProjectAccessListLoad(project));
        this.store.dispatch(new SignatureRoleLoad());
      }
    });

    this.projectAccessList$ = this.store.select(getProjectAccessList);
    this.signatureRoles$ = this.store.select(getSignatureRoles);
    this.displayedColumns$ = this.signatureRoles$.pipe(
      map(roles => ['username', ...roles.map(role => this.roleToColumn(role))])
    );

    this.filteredProjectAccessList$ = this.projectAccessList$.pipe(
      map(accessList => this.filterProjectAccessList(accessList))
    );
  }

  filterProjectAccessList(accessList: ProjectAccess[]): ProjectAccess[] {
    let filteredList = accessList.filter(access => access.role !== ProjectAccessRoles.READ_ONLY);
    if(!this.roleFilter) {
      return filteredList;
    }
    return filteredList.filter(access => access.role === this.roleFilter );
  }

  onRoleFilterChange(role: string): void {
    this.roleFilter = role;
    this.filteredProjectAccessList$ = this.projectAccessList$.pipe(
      map(accessList => this.filterProjectAccessList(accessList))
    );
  }

hasSignatureRole(projectAccess: ProjectAccess, role: SignatureRole): boolean {
  return projectAccess.signatureRoles.some(r => r.id === role.id);
}
  toggleSignatureRole(projectAccess: ProjectAccess, role: SignatureRole): void {
    const updatedProjectAccess = {
      ...projectAccess,
      signatureRoles: this.hasSignatureRole(projectAccess, role)
        ? projectAccess.signatureRoles.filter(r => r !== role)
        : [...projectAccess.signatureRoles, role]
    };
    this.store.dispatch(new UpdateProjectAccess(updatedProjectAccess));
  }

  addSignatureRole(projectAccess: ProjectAccess, role: SignatureRole): void {
    const updatedProjectAccess = {
      ...projectAccess,
      signatureRoles: [...projectAccess.signatureRoles, role]
    };
    this.store.dispatch(new UpdateProjectAccess(updatedProjectAccess));
  }

  removeSignatureRole(projectAccess: ProjectAccess, role: SignatureRole): void {
    console.log('removeSignatureRole');
    const updatedProjectAccess = {
      ...projectAccess,
      signatureRoles: projectAccess.signatureRoles.filter(r => r.id !== role.id)
    };
    this.store.dispatch(new UpdateProjectAccess(updatedProjectAccess));
  }

  getRoleIcon(user: ProjectAccess, role: SignatureRole): string {
    return this.hasSignatureRole(user, role) ? 'check_circle' : 'radio_button_unchecked';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  roleToColumn(role: SignatureRole): string {
    return role.name + "-" + role.id;
  }
}
