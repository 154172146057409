import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ErrorMessageComponent } from './error-message.component';

@NgModule({
  declarations: [ErrorMessageComponent],
  imports: [CommonModule, MatIconModule],
  exports: [ErrorMessageComponent]
})

export class ErrorMessageModule {}
