import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { animationExpandContent } from 'src/app/shared/animations';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ResourceType } from 'src/app/shared/enums/resource-type';

import { HandoverTemplateAdd, HandoverTemplateChangeOrdinals, HandoverTemplateDelete, HandoverTemplateUpdate, SetOnline } from '@completion/actions';
import { Confirmation, HandoverTemplate, ProcessCode, ProcessCodeDictionary } from '@completion/models';
import { State } from '@completion/reducers';

export enum HandoverTemplateContentTypes {
  Drawing = 'DRAWING',
  RFCC = 'RFCC',
  RFOC = 'RFOC',
  CPSI = 'CPSI',
  CPPL = 'CPPL',
  MCSI = 'MCSI',
  MCPL = 'MCPL',
  MCC = 'MCC',
  Other = 'OTHER',
  Procedure = 'PROCEDURE'
}
export enum HandoverTemplateTypes {
  Section = 'SECTION',
  SystemDocument = 'SYS_DOCUMENT',
  FileUpload = 'FILE_UPLOAD'
}

@Component({
  selector: 'app-project-template',
  templateUrl: './project-template.component.html',
  styleUrls: ['./project-template.component.scss'],
  animations: [animationExpandContent]
})
export class ProjectTemplateComponent implements OnInit, OnChanges {
  ProcessCodeDictionary = ProcessCodeDictionary;
  ResourceType = ResourceType;
  @Input() parent: HandoverTemplate;
  @Input() handoverTemplate: HandoverTemplate;
  @Input() index: number;
  @Input() isOpen = false;

  @Input() templateName: string;
  @Input() processCode: string;

  @Output() toggle = new EventEmitter<number>();

  rowForm: FormGroup;
  sysDocs: HandoverTemplateContentTypes[] = [
    HandoverTemplateContentTypes.RFCC,
    HandoverTemplateContentTypes.RFOC,
    HandoverTemplateContentTypes.CPSI,
    HandoverTemplateContentTypes.CPPL,
    HandoverTemplateContentTypes.MCSI,
    HandoverTemplateContentTypes.MCPL,
    HandoverTemplateContentTypes.MCC
  ];

  fileUploads: HandoverTemplateContentTypes[] = [
    HandoverTemplateContentTypes.Drawing,
    HandoverTemplateContentTypes.Procedure,
    HandoverTemplateContentTypes.Other
  ];

  rowTypes: HandoverTemplateTypes[] = [HandoverTemplateTypes.FileUpload, HandoverTemplateTypes.SystemDocument];

  children: HandoverTemplate[] = [];
  openSections: boolean[] = [];

  savingOrdinals = false;
  addingRow = false;
  editRowIndex: Set<number> = new Set();
  previousRowOrdinal: number = null;

  isEditingName: boolean = false;
  editedName: string = '';

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly store: Store<State>, private readonly formBuilder: FormBuilder, private readonly dialog: MatDialog) { }

  ngOnInit() {
    this.populateArrays();
  }

  ngOnChanges() {
    this.populateArrays();
  }

  private populateArrays(): void {
    if (this.handoverTemplate.children) {
      this.children = [...this.handoverTemplate.children];
      this.openSections = this.children.map((_, idx) => this.openSections[idx] || false);
    }
  }


  toggleEditName() {
    this.isEditingName = !this.isEditingName;
    if (this.isEditingName) {
      this.editedName = this.handoverTemplate.templateName;
    }
  }



  saveName() {
    if (this.editedName.trim()) {
      // Create a new object to avoid modifying the read-only property
      this.handoverTemplate = {
        ...this.handoverTemplate,
        templateName: this.editedName
      };
      this.isEditingName = false;
    }
    this.store.dispatch(new HandoverTemplateUpdate([this.handoverTemplate]));
  }

  public getClassByType(): string {
    if (this.handoverTemplate && this.handoverTemplate.type) {
      switch (this.handoverTemplate.type) {
        case HandoverTemplateTypes.Section:
          return 'section-container';
        default:
          return 'row-container';
      }
    } else {
      return 'main-template-container';
    }
  }

  public getTitleClassByType(): string {
    if (this.handoverTemplate && this.handoverTemplate.type) {
      switch (this.handoverTemplate.type) {
        case HandoverTemplateTypes.Section:
          return 'section-title';
        default:
          return 'row-title';
      }
    } else {
      return 'main-title';
    }
  }

  getProcessCodeName(processCode: string) {
    switch (processCode) {
      case ProcessCode.A:
        return ProcessCodeDictionary.A;
      case ProcessCode.B:
        return ProcessCodeDictionary.B;
      case ProcessCode.C:
        return ProcessCodeDictionary.C;
      case ProcessCode.D:
        return ProcessCodeDictionary.D;
    }
  }

  drop(event: CdkDragDrop<HandoverTemplate[]>) {
    const { previousIndex, currentIndex } = event;
    const indexChanged = previousIndex !== currentIndex;
    if (indexChanged) {
      this.savingOrdinals = true;
      moveItemInArray(this.children, previousIndex, currentIndex);
      moveItemInArray(this.openSections, previousIndex, currentIndex);

      const updateFromIndex = Math.min(previousIndex, currentIndex);
      const sectionsToUpdate = this.children.slice(updateFromIndex);
      this.store.dispatch(new HandoverTemplateChangeOrdinals(sectionsToUpdate, updateFromIndex));
    }
  }

  handleToggle(index: number): void {
    this.openSections[index] = !this.openSections[index];
  }

  trackSections(_: number, section: HandoverTemplate): number {
    return section.id;
  }

  addRow(parentTemplate: HandoverTemplate, children: HandoverTemplate[]) {
    this.addingRow = true;
    const ordinal: number = children.length > 0 ? children[children.length - 1].ordinal + 1 : 1;
    const toAdd = {
      parentId: parentTemplate.id,
      ordinal,
      projectId: parentTemplate.projectId,
      templateName: 'Added row',
      contentType: HandoverTemplateContentTypes.Other,
      type: HandoverTemplateTypes.FileUpload,
      processCode: ProcessCode.A
    } as HandoverTemplate;

    this.store.dispatch(new HandoverTemplateAdd(toAdd));
    children.push(toAdd);
  }

  addSection(parentTemplate: HandoverTemplate, children: HandoverTemplate[]) {
    const ordinal: number = children.length > 0 ? children[children.length - 1].ordinal + 1 : 1;
    const toAdd = {
      parentId: parentTemplate.id,
      ordinal,
      projectId: parentTemplate.projectId,
      templateName: 'Other',
      type: HandoverTemplateTypes.Section
    } as HandoverTemplate;

    this.store.dispatch(new HandoverTemplateAdd(toAdd));
    children.push(toAdd);
  }

  delete(index: number, children: HandoverTemplate[]) {
    let count = 0;
    if (children[index].children) {
      count = children[index].children.length;
    }
    const title = 'Confirm deletion';
    let confirmationMessage = 'Confirm deletion of ' + children[index].templateName;
    if (count > 0) {
      confirmationMessage += '<br> item has ' + count + ' child rows';
    }

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        confirmationMessage,
        isCancelDisplay: true,
        confirmationTile: 'Delete'
      } as Confirmation
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          const deleted = children.splice(index, 1)[0];
          this.store.dispatch(new HandoverTemplateDelete(deleted));
        }
      });
  }

  edit(index: number, children: HandoverTemplate[]) {
    if (this.editRowIndex.size > 0) {
      return;
    }
    this.previousRowOrdinal = children[index].ordinal;
    this.rowForm = this.formBuilder.group({
      templateOrdinal: [children[index].ordinal, [Validators.required, Validators.maxLength(2)]],
      templateName: [children[index].templateName, [Validators.required, Validators.maxLength(50)]],
      rowType: [children[index].type],
      contentType: [children[index].contentType],
      processCode: [children[index].processCode]
    });

    this.editRowIndex.add(index);
  }

  save(index: number, children: HandoverTemplate[]) {
    let ordinalChanged = false;
    const formOrdinal = parseInt(this.rowForm.value.templateOrdinal, 10);
    if (this.previousRowOrdinal !== formOrdinal) {
      ordinalChanged = true;
    }
    const template = {
      id: children[index].id,
      parentId: children[index].parentId,
      projectId: children[index].projectId,
      templateName: this.rowForm.value.templateName,
      contentType: this.rowForm.value.contentType,
      type: this.rowForm.value.rowType,
      processCode: this.rowForm.value.processCode ? this.rowForm.value.processCode : ProcessCode.A,
      ordinal: formOrdinal
    } as HandoverTemplate;
    this.store.dispatch(new HandoverTemplateUpdate([template]));
    this.editRowIndex.clear();

    if (ordinalChanged) {
      let newIndex: number = formOrdinal - 1;
      if (newIndex < 0) {
        newIndex = 0;
      }
      const arr: HandoverTemplate[] = [];
      children.forEach(item => arr.push({ ...item }));
      const elementToMove = arr.splice(index, 1);
      arr.splice(newIndex, 0, elementToMove[0]);
      arr.forEach((item, idx) => item.ordinal = idx + 1);
      this.store.dispatch(new HandoverTemplateUpdate(arr));
    }
  }

  getContentTypesByRowType(): HandoverTemplateContentTypes[] {
    switch (this.rowForm.get('rowType').value) {
      case HandoverTemplateTypes.SystemDocument:
        return this.sysDocs;
      case HandoverTemplateTypes.FileUpload:
        return this.fileUploads;
    }
    return [];
  }

  cancel(index: number) {
    this.editRowIndex.clear();
  }

  rowEdit(index: number): boolean {
    return this.editRowIndex.has(index);
  }
}
