<div *ngIf="scopes.length === 0">No offline downloads found.</div>
<div *ngIf="scopes.length > 0" class="expand-all">
  <button mat-button (click)="handleToggleAll()">
    {{ openPackages.some(isOpen) ? 'Expand all' : 'Collapse all' }}
    <mat-icon>{{ openPackages.some(isOpen) ? 'unfold_more' : 'unfold_less' }}</mat-icon>
  </button>
</div>

<div class="wrapper" *ngFor="let scope of scopes; index as idx">
  <section>
    <header>
      <h3>
        <span class="number" *ngIf="activeTabIndex === 0">{{ scope.cpNumber }}</span>
        <span class="number" *ngIf="activeTabIndex === 1">{{ scope.mcpNumber }}</span>
        <span class="scopes">Scopes: {{scope.assignments.length}}</span>
      </h3>
      <button class="release-scopes" mat-icon-button (click)="releaseAllScopes(activeTabIndex === 0 ? scope.cpId : scope.mcpId)">
        <img class="release-scope-icon" draggable="false" src="../../../assets/icon/Release.svg" alt="Release" />Release scopes
      </button>
      <button class="expand-button" mat-icon-button (click)="handleToggle(idx)" [class.open]="openPackages[idx]">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </header>

    <div class="content" [@expandContent]="openPackages[idx] ? 'expanded' : 'collapsed'">
      <table mat-table [dataSource]="scope.assignments">
        <ng-container matColumnDef="tagNo">
          <th mat-header-cell *matHeaderCellDef>
            <button [class.in-active]="toggleTagName" mat-button (click)="toggleTag(false)">TAG</button>
            /
            <button [class.in-active]="!toggleTagName" mat-button (click)="toggleTag(true)">NAME</button>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="tag-value" *ngIf="!toggleTagName"> {{ element.tagNumber }} </span>
            <span class="tag-value" *ngIf="toggleTagName"> {{ element.tagName }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="checkSheetNo">
          <th mat-header-cell *matHeaderCellDef>Check Sheet</th>
          <td mat-cell *matCellDef="let element">
            <div class="cell-items-center">
              <a [routerLink]="loadCheckSheetAssignment(element.cpId, element.mcpId, element.id)">
                {{ element.checkSheetNumber }}
              </a>
              <app-status-circle [status]="element.status"></app-status-circle>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="mcPkg" *ngIf="activeTabIndex === 0">
          <th mat-header-cell *matHeaderCellDef>MC PKG</th>
          <td mat-cell *matCellDef="let element">{{ element.mcpNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="phase">
          <th mat-header-cell *matHeaderCellDef>PHASE</th>
          <td mat-cell *matCellDef="let element">{{ element.phaseNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>COM</th>
          <td mat-cell *matCellDef="let element">{{ element.companyNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="signed">
          <th mat-header-cell *matHeaderCellDef>SIGNED</th>
          <td mat-cell *matCellDef="let element">
            <app-user-assignment *ngIf="element.verifiedBy" [userAssignment]="element.verifiedBy" [useDate]="true"></app-user-assignment>
          </td>
        </ng-container>

        <ng-container matColumnDef="verified">
          <th mat-header-cell *matHeaderCellDef>VERIFIED</th>
          <td mat-cell *matCellDef="let element">
            <app-user-assignment *ngIf="element.verifiedBy" [userAssignment]="element.verifiedBy" [useDate]="true"></app-user-assignment>
          </td>
        </ng-container>

        <ng-container matColumnDef="releaseScope">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="releaseScope(element.cpId, element.mcpId, element.id)" >
              <img class="release-scope-icon" draggable="false" src="../../../assets/icon/Release.svg" alt="Release" />
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </section>
</div>
