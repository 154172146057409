<app-spinner *ngIf="loading"></app-spinner>
<app-error-message *ngIf="loadingError" [errorMessage]="loadingError"></app-error-message>
<ng-container *ngIf="!loading && !loadingError">
  <div class="no-results" *ngIf="!nodes.data.length">
    There are no tasks for this project
  </div>

  <cdk-virtual-scroll-viewport *ngIf="nodes.data.length" class="virtual-scroll-container apd-dark-theme" itemSize="30">
    <ng-container *cdkVirtualFor="let node of nodes">
      <div class="node">
        <ng-container [ngSwitch]="node.nodeType">
          <a
            *ngSwitchCase="nodeType.CP"
            [routerLink]="['cp', node.id]"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="active-node"
            class="cp-expand-node"
          >
            <span>
              <span [matTooltip]="node.name" [matTooltipPosition]="'right'" [matTooltipShowDelay]="2000">
                <app-status-icon
                  *ngIf="displayStatusIcon()"
                  [status]="node.status"
                  [packageType]="node.nodeType"
                  [packageNumber]="node.number"
                ></app-status-icon>
                {{ node.number }}</span
              >
            </span>

            <a
              [attr.data-testid]="node.nodeType + '-' + node.number"
              *ngIf="node.nodeType == nodeType.CP && node.hasChildren"
              (click)="toggle(node)"
              [name]="node.number"
            >
              <mat-icon>
                {{ treeControl.isExpanded(node) ? 'arrow_drop_up' : 'arrow_drop_down' }}
              </mat-icon>
            </a>
          </a>
          <a
            *ngSwitchCase="nodeType.MCP"
            [routerLink]="['cp', node.parentId, 'mcp', node.id]"
            routerLinkActive="active-node"
            class="node-link"
          >
            <div>
              <span
                style="padding-left: 40px; width: 100%"
                [attr.data-testid]="node.nodeType + '-' + node.number"
                [matTooltip]="node.name"
                [matTooltipPosition]="'right'"
                [matTooltipShowDelay]="2000"
              >
                <app-status-icon
                  *ngIf="displayStatusIcon()"
                  [status]="node.status"
                  [packageType]="node.nodeType"
                  [packageNumber]="node.number"
                ></app-status-icon>
                {{ node.number }}
              </span>
            </div>
          </a>
          <button
            [attr.data-testid]="node.nodeType + '-' + node.number"
            class="system-node"
            *ngIf="node.nodeType == nodeType.SYSTEM"
            (click)="toggle(node)"
            [name]="node.number"
          >
            <span *ngSwitchCase="nodeType.SYSTEM">
              <span [matTooltip]="node.name" [matTooltipPosition]="'right'" [matTooltipShowDelay]="2000"> {{ node.number }}</span>
            </span>
            <mat-icon>
              {{ treeControl.isExpanded(node) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </button>
          <div *ngSwitchCase="nodeType.SUBSYSTEM" class="subsystem-node">
            <span [matTooltip]="node.name" [matTooltipPosition]="'right'" [matTooltipShowDelay]="2000"> {{ node.number }}</span>
          </div>
        </ng-container>
        <span class="tree-expand-button" *ngIf="node.nodeType == nodeType.SUBSYSTEM">
          {{ treeControl.expand(node) }}
        </span>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-container>
