<div class="grey-title">
  <span>&nbsp;</span>
  <h2 mat-dialog-title>Edit user</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSave()" id="ngForm" autocomplete="off">
    <div class="table">
      <div class="row">
        <div class="cell user-name">Username: {{ projectAccess.user.userName }}</div>
        <div class="cell name">Name: {{ projectAccess.user.name }}</div>
      </div>
      <div class="row">
        <div class="cell site-name">
          <mat-form-field appearance="fill" class="no-validation">
            <mat-label>Site:</mat-label>
            <mat-select formControlName="site" name="site" [(value)]="projectAccess.site" [compareWith]="hasSameIds">
              <mat-option *ngFor="let site of sites$ | async" [value]="site">{{ site.siteNumber }} {{ site.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="cell company-name">
          <mat-form-field appearance="fill" class="no-validation">
            <mat-label>Company:</mat-label>
            <mat-select formControlName="companies" name="company" [(value)]="projectAccess.companies" [compareWith]="hasSameIds" multiple>
              <mat-option *ngFor="let company of companies$ | async" [value]="company">{{ company.compNumber }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="cell role">
          <mat-form-field appearance="fill" class="no-validation">
            <mat-select required formControlName="role" name="role" [(value)]="projectAccess.role">
              <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="cell signature-role">
          <mat-form-field appearance="fill" class="no-validation">
            <mat-label>Signature Roles:</mat-label>
            <mat-select
              formControlName="signatureRoles"
              name="signature-role"
              [(value)]="projectAccess.signatureRoles"
              [compareWith]="hasSameIds"
              multiple
            >
              <mat-option *ngFor="let signatureRole of signatureRoles$ | async" [value]="signatureRole">{{
                signatureRole.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="error-message">
    <app-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-error-message>
  </div>

  <div>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <app-commit-button
      [isRoundButton]="true"
      [buttonStatus]="buttonStatus"
      buttonTestId="userEditDialogSave"
      (commitButtonClicked)="onSave()"
      idleLabel="Save"
    ></app-commit-button>
  </div>
</mat-dialog-actions>
