import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import { State } from '@completion/reducers';
import { CpService } from '../../services/cp.service';
import {
  AddCpCheckRecord,
  CheckSheetActionTypes,
  DeleteCpCheckRecord,
  LoadCurrentCpCsa,
  LoadCurrentCsaSuccess,
  UpdateCpCsa,
  UpdateCpCsItemStatus,
  UpdateCpRecordValue,
  RecalculateCsaStatuses,
  FetchCpSignaturesFromHistory, UploadCpCsaFile, DeleteCpCsaAttachment, FetchCpItemStatusesFromHistory
} from '../actions/check-sheet.action';
import { CpActionTypes, CpAddCheckedTags, CpDeleteCSA, InsertAndSignCpCsaSigntureEntry, LoadCurrentCpSuccess, SignCpCsaSigntureEntry } from '../actions/cp.action';
import { CpScopeOfWork, PackageCommonActionTypes, CpScopeOfWorkSuccess } from '../actions/package-common.action';
import { getCurrentCsaId } from '../selectors/check-sheet.selector';
import { getCurrentCpId } from '../selectors/cp.selector';
import { getCurrentProject } from '../selectors/project.selector';
import { ResourceEffect } from './resource.effect';


@Injectable()
export class CpCheckSheetEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly cpService: CpService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, cpService, store, toastr);
  }

  @Effect()
  loadCpScopeOfWork$: Observable<Action> = this.actions$.pipe(
    ofType<CpScopeOfWork>(PackageCommonActionTypes.CpScopeOfWork),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('getCheckedTags', CpScopeOfWorkSuccess, ([_, currentProject, cpId]) => [currentProject.id, cpId])
  );

  @Effect()
  addCheckedTags$: Observable<Action> = this.actions$.pipe(
    ofType<CpAddCheckedTags>(CpActionTypes.CpAddCheckedTags),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('addCheckedTags', null, ([action, currentProject, cpId]) => [currentProject.id, cpId, action.checkSheetAssignment])
  );

  @Effect()
  deleteCheckSheetAssignment$: Observable<Action> = this.actions$.pipe(
    ofType<CpDeleteCSA>(CpActionTypes.CpDeleteCSA),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource(
      'deleteCpCheckSheetAssignment',
      null,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.ids],
      response => [response.status]
    )
  );

  @Effect()
  moveTags$: Observable<Action> = this.actions$.pipe(
    ofType<CpDeleteCSA>(CpActionTypes.CpMoveCSA),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource(
      'moveTags',
      null,
      ([action, currentProject, cpId]) => [currentProject.id, cpId, action.csas],
      response => [response.status]
    )
  );

  @Effect()
  loadCpCsa$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCurrentCpCsa>(CheckSheetActionTypes.LoadCurrentCpCsa),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('getCheckSheetAssignment', LoadCurrentCsaSuccess, ([_, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId
    ])
  );

  @Effect()
  updateCpCsItemStatus$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateCpCsItemStatus>(CheckSheetActionTypes.UpdateCpCsItemStatus),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, project, cpId, csaId]) => !!(project && cpId && csaId)),
    this.fetchResource('updateCheckListItem', LoadCurrentCsaSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      action.item
    ])
  );

  @Effect()
  updateCpCsa$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateCpCsa>(CheckSheetActionTypes.UpdateCpCsa),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('updateCheckSheetAssignment', LoadCurrentCsaSuccess, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.csa,
      action.doSignOrVerify
    ])
  );

  @Effect()
  updateCpRecordValue$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateCpRecordValue>(CheckSheetActionTypes.UpdateCpRecordValue),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('updateCpRecordValue', LoadCurrentCsaSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      action.csItemNumber,
      action.recordValue
    ])
  );

  @Effect()
  addCpRecordValue$: Observable<Action> = this.actions$.pipe(
    ofType<AddCpCheckRecord>(CheckSheetActionTypes.AddCpCheckRecord),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('addCpRecordValue', LoadCurrentCsaSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      action.csItem.number,
      action.recordValue
    ])
  );

  @Effect()
  deleteCpRecordValue$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteCpCheckRecord>(CheckSheetActionTypes.DeleteCpCheckRecord),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('deleteCpRecordValue', LoadCurrentCsaSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      action.csItemNumber,
      action.recordValue
    ])
  );

  @Effect()
  updateCpCsaStatus$: Observable<Action> = this.actions$.pipe(
    ofType<RecalculateCsaStatuses>(CheckSheetActionTypes.RecalculateCsaStatuses),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('updateCpCsaStatuses', LoadCurrentCpSuccess, ([_, currentProject, cpId]) => [
      currentProject.id,
      cpId
    ])
  );

  @Effect()
  FetchCpSignaturesFromHistory$: Observable<Action> = this.actions$.pipe(
    ofType<FetchCpSignaturesFromHistory>(CheckSheetActionTypes.FetchCpSignaturesFromHistory),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('fetchCpSignaturesFromHistory', LoadCurrentCsaSuccess, ([_, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId
    ])
  );

  @Effect()
  FetchCpItemStatusesFromHistory: Observable<Action> = this.actions$.pipe(
    ofType<FetchCpItemStatusesFromHistory>(CheckSheetActionTypes.FetchCpItemstatusesFromHistory),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId)),
    filter(([_, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource('fetchCpItemStatusesFromHistory', LoadCurrentCsaSuccess, ([_, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId
    ])
  );



  @Effect()
  SignCpCsaSigntureEntry$: Observable<Action> = this.actions$.pipe(
    ofType<SignCpCsaSigntureEntry>(CpActionTypes.SignCpCsaSigntureEntry),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId),),
    filter(([action, currentProject, cpId, csaId]) => !!(currentProject && cpId)),
    this.fetchResource('signCpCsaSigntureEntry', LoadCurrentCsaSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      action.entry.id
    ])
  );

  @Effect()
  InsertAndSignCpCsaSigntureEntry$: Observable<Action> = this.actions$.pipe(
    ofType<InsertAndSignCpCsaSigntureEntry>(CpActionTypes.InsertAndSignCpCsaSigntureEntry),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId), this.store.select(getCurrentCsaId),),
    filter(([action, currentProject, cpId, csaId]) => !!(currentProject && cpId)),
    this.fetchResource('insertAndSignCpCsaSigntureEntry', LoadCurrentCsaSuccess, ([action, currentProject, cpId, csaId]) => [
      currentProject.id,
      cpId,
      csaId,
      true,
      [action.entry]
    ])
  );

  @Effect()
  uploadCpCsaFile$: Observable<any> = this.actions$.pipe(
    ofType<UploadCpCsaFile>(CheckSheetActionTypes.UploadCpCsaFile),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId),this.store.select(getCurrentCsaId),),
    filter(([action, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource(
      'uploadCpCsaFile',
      LoadCurrentCsaSuccess,
      ([action, currentProject, cpId, csaId]) => [currentProject.id, cpId,csaId, action.file ]
    )
  );

  @Effect()
  deleteCpCsaAttachment$: Observable<any> = this.actions$.pipe(
    ofType<DeleteCpCsaAttachment>(CheckSheetActionTypes.DeleteCpCsaAttachment),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId),this.store.select(getCurrentCsaId)),
    filter(([action, currentProject, cpId, csaId]) => !!(currentProject && cpId && csaId)),
    this.fetchResource(
      'deleteCpCsaAttachment',
      LoadCurrentCsaSuccess,
      ([action, currentProject, cpId, csaId]) => [currentProject.id, cpId, csaId, action.id]
    )
  );

}
