import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UserWithDateComponent } from './user-with-date.component';

@NgModule({
  declarations: [UserWithDateComponent],
  imports: [CommonModule, ReactiveFormsModule, MatAutocompleteModule, MatButtonModule, MatIconModule, MatInputModule, MatTooltipModule],
  exports: [UserWithDateComponent]
})
export class UserWithDateModule {}
