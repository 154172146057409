<mat-form-field class="user-with-date-container">
  <mat-label>{{label}}</mat-label>
  <input matInput
         type="text"
         [placeholder]="label"
         [matTooltip]="selectedAssignmentDate | date"
         [required]="required"
         [formControl]="control"
         [matAutocomplete]="userWithDateAutocomplete">
  <button mat-icon-button matSuffix type="button">
    <mat-icon>person_outline</mat-icon>
  </button>
  <mat-autocomplete #userWithDateAutocomplete="matAutocomplete" [displayWith]="displayAssignment">
    <mat-option *ngFor="let assignment of filteredOptions | async" [value]="assignment">{{assignment.user.userName}}</mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.hasError('required')">
    {{label}} is <strong>required</strong>
  </mat-error>
</mat-form-field>
