import { ActionReducerMap } from '@ngrx/store';

import { Cppl, Cpsi, Mcpl, Mcsi, OfflineDownloadScope } from '@completion/models';
import * as fromCertificate from './certificate';
import { CertificateState } from './certificate';
import * as fromCheckSheet from './check-sheet';
import { CheckSheetState } from './check-sheet';
import * as fromCp from './cp';
import { CpState } from './cp';
import * as fromCppl from './cppl';
import * as fromCpsi from './cpsi';
import * as fromDashboard from "./dashboard";
import { DashboardState } from "./dashboard";
import * as fromDelivery from './delivery';
import { DeliveryState } from './delivery';
import * as fromDossierStatus from './dossier-status';
import { DossierState } from './dossier-status';
import * as fromError from './error';
import { ErrorState } from './error';
import * as fromHandoverTemplate from './handover-template/index';
import { HandoverTemplateState } from './handover-template/index';
import * as fromHandover from './handover/index';
import { HandoverState } from './handover/index';
import * as fromMcPkgs from './mcp';
import { McpState } from './mcp';
import * as fromMcpl from './mcpl';
import * as fromMcsi from './mcsi';
import * as fromOffline from './offline';
import * as fromOfflineDownloads from './offline-downloads';
import * as fromOptions from './options';
import { OptionsState } from './options';
import * as fromPackageCommon from './package-common';
import { PackageCommonState } from './package-common';
import * as fromProject from './project';
import { ProjectState } from './project';
import * as fromProjectAccess from './project-access'
import { ProjectAccessState } from './project-access';
import * as fromPunch from './punch';
import { PunchState } from './punch';
import * as fromResource from './resource';
import { ResourceMapState } from './resource';
import * as fromTag from './tag';
import { TagState } from './tag';
import * as fromTask from './task';
import { TaskState } from './task';
import * as fromUser from './user';
import { UserState } from './user';
import { SiteState } from './site';
import * as fromSite from './site';
import { SignatureMatrixState } from './signature-matrix';
import * as fromSignatureMatrix from './signature-matrix';
import {ReportState} from "./reports";
import * as fromReports from "./reports";
import { ResetState}   from "./reset";
import * as fromReset from "./reset";

export interface State {
  certificate: CertificateState;
  checkSheet: CheckSheetState;
  cp: CpState;
  cpsi: Cpsi;
  cppl: Cppl;
  dashboard: DashboardState;
  delivery: DeliveryState;
  dossier: DossierState;
  error: ErrorState;
  handover: HandoverState;
  handoverTemplate: HandoverTemplateState;
  mcp: McpState;
  mcpl: Mcpl;
  mcsi: Mcsi;
  options: OptionsState;
  offline: fromOffline.OfflineState;
  packageCommon: PackageCommonState;
  project: ProjectState;
  projectAccess: ProjectAccessState;
  punch: PunchState;
  resource: ResourceMapState;
  tag: TagState;
  task: TaskState;
  site: SiteState;
  user: UserState;
  offlineDownloads: OfflineDownloadScope;
  signatureMatrix: SignatureMatrixState;
  report: ReportState;
  reset: ResetState;
}

export const initialState: State = {
  certificate: fromCertificate.initialState,
  checkSheet: fromCheckSheet.initialState,
  cp: fromCp.initialState,
  cpsi: fromCpsi.initialState,
  cppl: fromCppl.initialState,
  dashboard: fromDashboard.initialState,
  dossier: fromDossierStatus.initialState,
  delivery: fromDelivery.initialState,
  error: fromError.initialState,
  handover: fromHandover.initialState,
  handoverTemplate: fromHandoverTemplate.initialState,
  mcp: fromMcPkgs.initialState,
  mcpl: fromMcpl.initialState,
  mcsi: fromMcsi.initialState,
  options: fromOptions.initialState,
  offline: fromOffline.initialState,
  packageCommon: fromPackageCommon.initialState,
  project: fromProject.initialState,
  projectAccess: fromProjectAccess.initialState,
  punch: fromPunch.initialState,
  resource: fromResource.initialState,
  tag: fromTag.initialState,
  task: fromTask.initialState,
  site: fromSite.initialState,
  user: fromUser.initialState,
  offlineDownloads: fromOfflineDownloads.initialState,
  signatureMatrix: fromSignatureMatrix.initialState,
  report:fromReports.initialState,
  reset: fromReset.initialState
};

export const reducers: ActionReducerMap<State> = {
  certificate: fromCertificate.certificateReducer,
  checkSheet: fromCheckSheet.checkSheetReducer,
  cp: fromCp.commPkgsReducer,
  cpsi: fromCpsi.cpsiReducer,
  cppl: fromCppl.cpplReducer,
  dashboard: fromDashboard.dashboardReducer,
  delivery: fromDelivery.deliveryReducer,
  dossier: fromDossierStatus.dossierStatusReducer,
  error: fromError.errorReducer,
  handover: fromHandover.handoverReducer,
  handoverTemplate: fromHandoverTemplate.handoverTemplateReducer,
  mcp: fromMcPkgs.mcPkgsReducer,
  mcpl: fromMcpl.mcplReducer,
  mcsi: fromMcsi.mcsiReducer,
  offline: fromOffline.offlineReducer,
  options: fromOptions.optionsReducer,
  packageCommon: fromPackageCommon.packageCommonReducer,
  project: fromProject.projectReducer,
  projectAccess: fromProjectAccess.projectAccessReducer,
  punch: fromPunch.punchReducer,
  resource: fromResource.resourceReducer,
  tag: fromTag.tagReducer,
  task: fromTask.taskReducer,
  site: fromSite.siteReducer,
  user: fromUser.userReducer,
  offlineDownloads: fromOfflineDownloads.offlineDownloadsReducer,
  signatureMatrix: fromSignatureMatrix.signatureMatrixReducer,
  report:fromReports.reportsReducer,
  reset: fromReset.resetReducer
};

export { projectAccessReducer, ProjectAccessState } from './project-access';
export { commPkgsReducer } from './cp';
export { OfflineState, offlineReducer } from './offline';
export { OptionsState } from './options';

