import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService, RequestIdent } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count = 0;

  constructor(private loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.loaderService.ignoreResource(req.url)) {
      return next.handle(req);
    }
    const request: RequestIdent = {
      id: this.count,
      time: Date.now(),
      url: req.url
    };
    this.loaderService.addRequest(request);
    this.count++;
    return new Observable(observer => {
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.loaderService.removeRequest(request);
          }
          observer.next(event);
        },
        (err: HttpErrorResponse) => {
          this.loaderService.removeRequest(request);
          observer.error(err);
        },
        () => {
          this.loaderService.removeRequest(request);
          observer.complete();
        }
      );
      return () => {
        subscription.unsubscribe();
        this.loaderService.removeRequest(request);
      };
    });
  }
}
