import { NamedEntity } from './named-entity';

export class CheckSheet implements NamedEntity {
  id: number;
  csNumber: string;
  name: string;
  type: string;
}

export function byCsNumber(a: CheckSheet, b: CheckSheet) {
  return a.csNumber.localeCompare(b.csNumber);
}
