import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { CameraComponent } from './camera.component';

@NgModule({
  declarations: [CameraComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [CameraComponent]
})
export class CameraModule {}
