import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { Task } from '@completion/models';
import { TaskState } from '../reducers/task';

const getTaskFeatureState = createFeatureSelector<TaskState>('task');

export const getTasks: MemoizedSelector<TaskState, Task[]> = createSelector(
  getTaskFeatureState,
  (state: TaskState) => state.tasks
);

export const getCurrentTask = createSelector(
  getTaskFeatureState,
  state => state.currentTask
);
