import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OptionsState } from '../reducers/options';

const getOptionsFeatureState = createFeatureSelector<OptionsState>('options');

export const getSites = createSelector(
  getOptionsFeatureState,
  (state: OptionsState) => state.sites
);

export const getCompanies = createSelector(
  getOptionsFeatureState,
  (state: OptionsState) => state.companies
);

export const getPhases = createSelector(
  getOptionsFeatureState,
  (state: OptionsState) => state.phases
);

export const getCheckSheets = createSelector(
  getOptionsFeatureState,
  (state: OptionsState) => state.checkSheets
);

export const getSignatureRoles = createSelector(
  getOptionsFeatureState,
  (state: OptionsState) => state.signatureRoles
);
