import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import { UserAssignment } from '@completion/models';

@Component({
  selector: 'app-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.scss']
})
export class UserAssignmentComponent {
  @Input()
  set userAssignment(userAssignment) {
    this.userAssign = userAssignment;
    this.updateNameAndDate();
  }

  @Input()
  set fullName(name) {
    this.name = name;
    this.updateNameAndDate();
  }

  @Input()
  set timestamp(timestamp: Date | string) {
    this.date = timestamp;
    this.updateNameAndDate();
  }

  @Input()
  set useDate(useDate) {
    this.shouldUseDate = useDate;
    this.updateNameAndDate();
  }

  @Input()
  isOffline: boolean;

  caption: string;
  dateString: string;
  actualName: string;
  open = false;

  private userAssign: UserAssignment;
  private name: string;
  private date: Date | string;
  private shouldUseDate: boolean;

  private static getFormattedDate(date: Date | string): string {
    return new DatePipe('en-US').transform(date, 'yyyy-MM-dd');
  }

  private static extractInitials(fullName: string): string {
    let initials = '';

    if (fullName) {
      initials += fullName[0].toLocaleUpperCase();
      initials += fullName[fullName.lastIndexOf(' ') + 1].toLocaleUpperCase();
    }

    return initials;
  }

  private updateNameAndDate(): void {
    if (this.userAssign) {
      this.dateString = UserAssignmentComponent.getFormattedDate(this.userAssign.timestamp);
      this.actualName = this.userAssign.user.name;
    } else {
      this.dateString = UserAssignmentComponent.getFormattedDate(this.date);
      this.actualName = this.name;
    }

    this.caption = this.shouldUseDate
      ? this.dateString
      : UserAssignmentComponent.extractInitials(this.actualName ? this.actualName.trim() : null);
  }
}
