import { Action } from '@ngrx/store';

import { Site } from '@completion/models';

export enum SiteActionTypes {
  SetCurrentSite = 'Set current Task',
  ClearCurrentSite = 'Clear Current Task'
}

export class SetCurrentSite implements Action {
  readonly type = SiteActionTypes.SetCurrentSite;

  constructor(public site: Site) { }
}

export class ClearCurrentSite implements Action {
  readonly type = SiteActionTypes.ClearCurrentSite;
}

export type SiteAction = SetCurrentSite | ClearCurrentSite;
