import { Action } from '@ngrx/store';

import {
  CheckSheetAssignment, MCCDetails,
  MCCertificate,
  RFCCDetails,
  RFCCertificate, RFOCDetails,
  RFOCertificate,
  Site
} from '@completion/models';

export enum ResetActionTypes {
  LoadRfocs = 'Load Rfocs',
  LoadRfocSuccess = 'Load Rfoc Success',
  LoadRfccSuccess = 'Load Rfcc Success',
  LoadRfccs = 'Load Rfccs',
  LoadMccs = 'Load Mccs',
  LoadMccSuccess = 'Load Mcc Success',
  LoadCpCsas = 'Load Cp Csas',
  LoadCpCsasSuccess = 'Load Cp Csas Success',
  LoadMcCsas = 'Load Mc Csas',
  LoadMcCsasSuccess = 'Load Mc Csas Success',
}

export class LoadRfocs implements Action {
  readonly type = ResetActionTypes.LoadRfocs;
}

export class LoadRfocSuccess implements Action {
  readonly type = ResetActionTypes.LoadRfocSuccess;
  constructor(public rfoc:RFOCDetails[]) {
  }
}

export class  LoadRfccs implements Action {
  readonly type = ResetActionTypes.LoadRfccs;
}

export class LoadRfccsSuccess implements Action {
  readonly type = ResetActionTypes.LoadRfccSuccess;
  constructor(public rfcc:RFCCDetails[]) {
  }
}

export class LoadMccs implements Action {
  readonly type = ResetActionTypes.LoadMccs;
}

export class LoadMccsSuccess implements Action {
  readonly type = ResetActionTypes.LoadMccSuccess;
  constructor(public mcc:MCCDetails[]) {
  }
}

export class LoadCpCsas implements Action {
  readonly type = ResetActionTypes.LoadCpCsas;
}

export class LoadCpCsasSuccess implements Action {
  readonly type = ResetActionTypes.LoadCpCsasSuccess;
  constructor(public cpCsa:CheckSheetAssignment[]) {
  }
}

export class LoadMcCsas implements Action {
  readonly type = ResetActionTypes.LoadMcCsas;
}

export class LoadMcCsasSuccess implements Action {
  readonly type = ResetActionTypes.LoadMcCsasSuccess;
  constructor(public mcCsa:CheckSheetAssignment[]) {
  }
}


export type ResetAction = LoadRfocs
  | LoadRfocSuccess
  | LoadRfccs
  | LoadRfccsSuccess
  | LoadMccs
  | LoadMccsSuccess
  | LoadCpCsas
  | LoadCpCsasSuccess
  | LoadMcCsas
  | LoadMcCsasSuccess;
