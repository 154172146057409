import {CheckSheetAssignment, Punch} from "@completion/models";
import {ReportAction, ReportsActionTypes} from "@completion/actions";
import {CertificateOverviewReport} from "../../../../shared/models/report-data";


export const initialState: ReportState = {
  punches: [],
  checkSheetAssignments: [],
  certificates: []
};

export interface ReportState {
  punches: Punch[];
  checkSheetAssignments: CheckSheetAssignment[];
  certificates?: CertificateOverviewReport[];
}

export function reportsReducer(state: ReportState = initialState, action: ReportAction): ReportState {
  switch (action.type) {
    case ReportsActionTypes.LoadPunchesForBurnDownSuccess:
      return {
        ...state,
        punches: action.data
      };
    case ReportsActionTypes.LoadCheckSheetAssignmentForReportSuccess:
      return {
        ...state,
        checkSheetAssignments: action.data
      };
case ReportsActionTypes.LoadCertificateReportSuccess:
      return {
        ...state,
        certificates: action.data
      };
    default:
      return state;
  }
}
