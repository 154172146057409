import { OfflineDownloadScope } from '@completion/models';
import { OfflineDownloadsAction, OfflineDownloadsActionTypes } from '../../actions/offline-downloads.action';

export const initialState: OfflineDownloadScope = {
  cps: [],
  mcps: []
};

export function offlineDownloadsReducer(state: OfflineDownloadScope = initialState, action: OfflineDownloadsAction): OfflineDownloadScope {
  if (action.type === OfflineDownloadsActionTypes.OfflineScopesLoadSuccess) {
    return action.offlineDownloads;
  } else {
    return state;
  }
}
