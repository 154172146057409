import {Injectable} from "@angular/core";
import {BaseService, Resource} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {ResourceType} from "@completion/enums";
import {CheckSheet, CommissioningPackage} from "@completion/models";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CheckSheetService extends BaseService {
    constructor(readonly http: HttpClient) {
        super(http);
    }

  @Resource(ResourceType.UpdateCheckSheet)
  updateCheckSheet(projectId: number, updatedCheckSheet: CheckSheet): Observable<CheckSheet[]> {
    return this.http.put<CheckSheet[]>(`${this.baseUrl}/projects/${projectId}/checkSheets/${updatedCheckSheet.id}`, updatedCheckSheet);
  }

  @Resource(ResourceType.GetCheckSheetMatrixTypes)
  getCheckSheetMatrixTypes(projectId: number): Observable<String[]> {
      return this.http.get<String[]>(`${this.baseUrl}/projects/${projectId}/checkSheets/matrixTypes`);
  }

}
