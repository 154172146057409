import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

import { ProjectSelectorModule } from './project-selector/project-selector.module';
import { SideNavigationComponent } from './side-navigation.component';
import { SiteSelectorModule } from './site-selector/site-selector.module';
import { TaskSelectorModule } from './task-selector/task-selector.module';
import { TreeModule } from './tree/tree.module';

@NgModule({
  declarations: [SideNavigationComponent],
  imports: [
    A11yModule,
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    ProjectSelectorModule,
    SiteSelectorModule,
    TaskSelectorModule,
    TreeModule
  ],
  exports: [SideNavigationComponent]
})
export class SideNavigationModule { }
