import { Action } from '@ngrx/store';
import {CheckSheetAssignment, Punch} from "@completion/models";
import {CertificateOverviewReport} from "../../../shared/models/report-data";

export enum ReportsActionTypes {
  LoadReportData = 'Load Report Data',
  LoadReportDataSuccess = 'Load Report Data Success',
  LoadPunchesForReportByProject = 'Load Punches For Burn Down',
  LoadPunchesForReportByTask = 'Load Punches For Burn Down By Task',
  LoadPunchesForBurnDownSuccess = 'Load Punches For Burn Down Success',
  LoadCheckSheetAssignmentForReportByProject = 'Load Check Sheet Assignment For Report',
  LoadCheckSheetAssignmentForReportByTask = 'Load Check Sheet Assignment For Report By Task',
  LoadCheckSheetAssignmentForReportSuccess = 'Load Check Sheet Assignment For Report Success',
  LoadCertificateReport = 'Get Certificate Report',
  LoadCertificateReportSuccess = 'Get Certificate Report Success',
}

export class LoadReportData implements Action {
  readonly type = ReportsActionTypes.LoadReportData;

  constructor(public taskId: number) { }
}

export class LoadReportDataSuccess implements Action {
  readonly type = ReportsActionTypes.LoadReportDataSuccess;

  constructor(public data: any) { }
}

export class LoadPunchesForReportByTask implements Action {
  readonly type = ReportsActionTypes.LoadPunchesForReportByTask;
  constructor(public taskId: number) {}
}

export class LoadPunchesForReportByProject implements Action {
  readonly type = ReportsActionTypes.LoadPunchesForReportByProject;

}

export class LoadPunchesForBurnDownSuccess implements Action {
  readonly type = ReportsActionTypes.LoadPunchesForBurnDownSuccess;
  constructor(public data: Punch[]) { }
}

export class LoadCheckSheetAssignmentForReportByProject implements Action {
  readonly type = ReportsActionTypes.LoadCheckSheetAssignmentForReportByProject;
}

export class LoadCheckSheetAssignmentForReportByTask implements Action {
  readonly type = ReportsActionTypes.LoadCheckSheetAssignmentForReportByTask;

}

export class LoadCheckSheetAssignmentForReportSuccess implements Action {
  readonly type = ReportsActionTypes.LoadCheckSheetAssignmentForReportSuccess;
  constructor(public data: CheckSheetAssignment[]) {
  }
}

export class LoadCertificateReport implements Action {
  readonly type = ReportsActionTypes.LoadCertificateReport;
}

export class LoadCertificateReportSuccess implements Action {
  readonly type = ReportsActionTypes.LoadCertificateReportSuccess;
  constructor(public data: CertificateOverviewReport[]) {
  }
}

export type ReportAction = LoadReportData
  | LoadReportDataSuccess
  | LoadPunchesForReportByProject
  | LoadPunchesForReportByTask
  | LoadPunchesForBurnDownSuccess
  | LoadCheckSheetAssignmentForReportByProject
  | LoadCheckSheetAssignmentForReportByTask
  | LoadCheckSheetAssignmentForReportSuccess
  | LoadCertificateReport
  | LoadCertificateReportSuccess;

