import { TagAction, TagActionTypes } from '@completion/actions';
import { byTagNumber, CheckSheetAssignment, Tag } from '@completion/models';
import { sortByCheckSheet, sortByTag } from '@completion/utils';

export const initialState: TagState = {
  availableTags: [],
  searchTags: []
};

export interface TagState {
  availableTags: Tag[];
  searchTags?: CheckSheetAssignment[];
}

export function tagReducer(state: TagState = initialState, action: TagAction): TagState {
  switch (action.type) {
    case TagActionTypes.LoadAvailableTagsSuccess:
      return {
        ...state,
        availableTags: [...action.tags].sort(byTagNumber)
      };
    case TagActionTypes.LoadSearchTagsSuccess:
      const searchTags = [...action.csas].sort((a, b) => sortByTag(a, b) || sortByCheckSheet(a, b));
      return {
        ...state,
        searchTags,
      };
    default:
      return state;
  }
}
