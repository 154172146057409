import { Action } from '@ngrx/store';

import { CheckSheetType } from '@completion/enums';
import { CheckSheet, Company, Phase, SignatureRole, Site } from '@completion/models';

export enum OptionsActionTypes {
  SiteLoad = 'Site Load',
  McSiteLoad = 'Mc Site Load',
  SiteLoadSuccess = 'Site Load Success',
  CompanyLoad = 'Company Load',
  CompanyLoadSuccess = 'Company Load Success',
  PhaseLoad = 'Phase Load',
  PhaseLoadSuccess = 'Phase Load Success',
  CheckSheetLoad = 'Check Sheet Load',
  CheckSheetLoadSuccess = 'Check Sheet Load Success',
  SignatureRoleLoad = "SignatureRoleLoad",
  SignatureRoleLoadSuccess = "SignatureRoleLoadSuccess",
  UpdateSignatureRole = "UpdateSignatureRole",
  AddSignatureRole = "AddSignatureRole",
  DeleteSignatureRole = "DeleteSignatureRole",
  CheckSheetSignatureTypeLoad = "CheckSheetSignatureTypeLoad",
  CheckSheetSignatureTypeLoadSuccess = "CheckSheetSignatureTypeLoadSuccess",
  CompanySignatureTypeLoad = "CompanySignatureTypeLoad",
  CompanySignatureTypeLoadSuccess = "CompanySignatureTypeLoadSuccess",
  UpdateCompany = "UpdateCompany",
}

export class SiteLoad implements Action {
  readonly type = OptionsActionTypes.SiteLoad;
}

export class McSiteLoad implements Action {
  readonly type = OptionsActionTypes.McSiteLoad;
}

export class SiteLoadSuccess implements Action {
  readonly type = OptionsActionTypes.SiteLoadSuccess;

  constructor(public sites: Site[]) { }
}

export class CompanyLoad implements Action {
  readonly type = OptionsActionTypes.CompanyLoad;

  constructor(public category: string) { }
}

export class CompanyLoadSuccess implements Action {
  readonly type = OptionsActionTypes.CompanyLoadSuccess;

  constructor(public companies: Company[]) { }
}

export class PhaseLoad implements Action {
  readonly type = OptionsActionTypes.PhaseLoad;
}

export class PhaseLoadSuccess implements Action {
  readonly type = OptionsActionTypes.PhaseLoadSuccess;

  constructor(public phases: Phase[]) { }
}

export class CheckSheetLoad implements Action {
  readonly type = OptionsActionTypes.CheckSheetLoad;
  constructor(public csType: CheckSheetType) { }
}

export class CheckSheetSignatureTypeLoad implements Action {
  readonly type = OptionsActionTypes.CheckSheetSignatureTypeLoad;
  constructor() { }

}

export class CheckSheetLoadSuccess implements Action {
  readonly type = OptionsActionTypes.CheckSheetLoadSuccess;

  constructor(public checkSheets: CheckSheet[]) { }
}

export class CheckSheetSignatureTypeLoadSuccess implements Action {
  readonly type = OptionsActionTypes.CheckSheetSignatureTypeLoadSuccess;

  constructor(public signatureTypes: string[]) { }

}

export class SignatureRoleLoad implements Action {
  readonly type = OptionsActionTypes.SignatureRoleLoad;
}

export class SignatureRoleLoadSuccess implements Action {
  readonly type = OptionsActionTypes.SignatureRoleLoadSuccess;

  constructor(public signatureRoles: SignatureRole[]) { }
}

export class UpdateSignatureRole implements Action {
  readonly type = OptionsActionTypes.UpdateSignatureRole;

  constructor(public signatureRole: SignatureRole) { }
}

export class AddSignatureRole implements Action {
  readonly type = OptionsActionTypes.AddSignatureRole;

  constructor(public signatureRole: SignatureRole) {
  }
}

export class DeleteSignatureRole implements Action {
  readonly type = OptionsActionTypes.DeleteSignatureRole;

  constructor(public signatureRole: SignatureRole) { }
}

export class CompanySignatureTypeLoad implements Action {
  readonly type = OptionsActionTypes.CompanySignatureTypeLoad;
}

export class CompanySignatureTypeLoadSuccess implements Action {
  readonly type = OptionsActionTypes.CompanySignatureTypeLoadSuccess;

  constructor(public signatureTypes: string[]) {
  }
}

export class UpdateCompany implements Action {
  readonly type = OptionsActionTypes.UpdateCompany;

  constructor(public company: Company) { }
}


export type OptionsActions =
  | SiteLoad
  | McSiteLoad
  | SiteLoadSuccess
  | CompanyLoad
  | CompanyLoadSuccess
  | PhaseLoad
  | PhaseLoadSuccess
  | CheckSheetLoadSuccess
  | CheckSheetLoad
  | SignatureRoleLoad
  | SignatureRoleLoadSuccess
  | UpdateSignatureRole
  | AddSignatureRole
  | DeleteSignatureRole
  | CheckSheetSignatureTypeLoad
  | CheckSheetSignatureTypeLoadSuccess
  | CompanySignatureTypeLoad
  | CompanySignatureTypeLoadSuccess;
