export enum CompletionStatus {
  OS = 'OS',
  NA = 'NA',
  OK = 'OK',
  PB = 'PB',
  PA = 'PA'
}

export  enum ResetLevel {
  RFOC = 'RFOC', // removes selected RFO
  RFCC = 'RFCC', // removes selected RFC
  MCC = 'MCC', // removes selected MC
  CP_CHECKSHEET = 'CP_CHECKSHEET', // removes selected CP checksheet
  MC_CHECKSHEET = 'MC_CHECKSHEET', // removes selected MC checksheet
  ALL = 'ALL', // removes RFO, RFC, MC and resets signatures on all CSAs including items.
}

export enum ItemAction {
  REMOVE_SIGNATURES = 'REMOVE_SIGNATURES',
  DELETE_ITEMS = 'DELETE_ITEMS',
  RESET_ITEMS = 'RESET_ITEMS',
}
