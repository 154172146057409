import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { PromiseExtended } from 'dexie';
import { combineLatest, from, Observable, Subject, zip } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HttpRequestMethod } from '@completion/enums';
import { getSignVerifyValue } from '@completion/utils';

import { LoadCPPunches, LoadMCPPunches, ProjectTreeLoadSuccess, TasksLoadSuccess } from '@completion/actions';
import { CompanyCategory, CompletionStatus } from '@completion/enums';
import {
  Attachment,
  CheckListItem,
  CheckRecordValue,
  CheckSheetAssignment,
  CommissioningPackage,
  Company,
  isMcPackage,
  McPackage,
  Project, ProjectAccess,
  ProjectConfig,
  Punch,
  Task,
  TreeNode,
  TreeNodeType,
  User,
  UserAssignment
} from '@completion/models';
import {
  getCurrentCp,
  getCurrentCsaId,
  getCurrentMcp,
  getCurrentProject,
  getCurrentUser,
  getProjectAccess
} from '@completion/selectors';
import {
  ChangeLogEvent,
  ChangeLogStatus,
  CP,
  CSA,
  OfflineDataDb,
  ProjectAccessData,
  Punch as DbPunch,
  PunchAttachment
} from '../db/offline-data';
import { UserState } from '../store/reducers/user';
import { CpService } from './cp.service';
import { McpService } from './mcp.service';
import { OptionsService } from './options.service';
import { PunchService } from './punch.service';
import { UserService } from './user.service';
import { P } from '@angular/cdk/keycodes';

export class CheckSheetChange extends CheckListItem {
  offlineBy: UserAssignment;
}

export class CheckRecordChange extends CheckRecordValue {
  offlineBy: UserAssignment;
}
export class OfflineFormData extends FormData {
  offlineBy: UserAssignment;
}

enum ChangeEventType {
  punch = 'punch',
  punchAttachment = 'punchAttachment'
}

@Injectable({ providedIn: 'root' })
export class OfflineDataService {
  @Output() changeEventsChanged: EventEmitter<any> = new EventEmitter();
  @Output() changeLogError: EventEmitter<HttpErrorResponse> = new EventEmitter();
  private readonly http = new HttpClient(this.httpHandler);
  private currentCsaId;
  private currentProjectId: number;
  private currentMcpId: number;
  private currentCpId: number;
  private readonly checkSheetItemNumberIndex = 9;
  currentUser: User;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly PackageType = { cp: 'cp', mcp: 'mcp' };
  private currentProject: Project;
  private currentCp: CommissioningPackage;
  private currentMcp: McPackage;
  private projectAccess: ProjectAccess;

  constructor(
    private readonly offlineData: OfflineDataDb,
    private readonly store: Store<UserState>,
    private readonly optionService: OptionsService,
    private readonly userService: UserService,
    private readonly httpHandler: HttpHandler,
    private readonly mcpService: McpService,
    private readonly cpService: CpService,
    private readonly punchService: PunchService
  ) {
    combineLatest([
      this.store.select(getCurrentProject),
      this.store.select(getCurrentCp),
      this.store.select(getCurrentMcp),
      this.store.select(getCurrentUser),
      this.store.select(getCurrentCsaId),
      this.store.select(getProjectAccess),

    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([proj, cpPkg, mcPkg, user, csaId, projectAccess ]) => {
        if (proj) {
          this.currentProject = proj;
          this.currentProjectId = proj.id;
        }
        if (cpPkg) {
          this.currentCp = cpPkg;
          this.currentCpId = cpPkg.id;
        }
        if (mcPkg) {
          this.currentMcp = mcPkg;
          this.currentMcpId = mcPkg.id;
        } else {
          this.currentMcp = null;
          this.currentMcpId = null;
        }
        this.projectAccess = projectAccess;

        this.currentUser = user;
        this.currentCsaId = csaId;
      });
  }

  private saveUsers(users: User[]) {
    this.offlineData.users.put({ projectId: this.currentProjectId, data: users });
  }

  private saveCompanies(companies: Company[], category: string) {
    this.offlineData.companies.put({ projectId: this.currentProjectId, data: companies, category });
  }

  private async saveProjectAccess(projectAccess: ProjectAccessData) {
    this.offlineData.projectAccess.put(projectAccess);
  }

  private async saveProject(project: Project): Promise<any> {
    return this.offlineData.project.put({ id: project.id, name: project.name, config: project.config, signatureMatrixTemplates: project.signatureMatrixTemplates});
  }

  private async savePackages(cp: CommissioningPackage, mcp: McPackage): Promise<any> {
    const cpId = cp.id;
    // Save CP
    this.offlineData.cp.put({ cpId, packageData: cp }).then(() => {
      if (mcp && isMcPackage(mcp)) {
        // Save MCP
        const mcpId = mcp.id;
        return this.offlineData.mcp.put({ mcpId, cpId, packageData: mcp });
      }
      return new Promise(resolve => resolve(this));
    });
  }

  private showAlreadyExistingMsg(duplicates: Array<CheckSheetAssignment>) {
    let msg = '';
    duplicates.forEach(csa => {
      msg += `Checksheet: ${csa.checkSheet.csNumber} / ${csa.tag.tagNumber} / ${csa.site.siteNumber} / ${csa.company.compNumber} \n is already existing in local storage!\n`;
    });
    msg += '\nPlease release scope(s) or clear local browser cache if yow wish to work offline with these scope(s) again';
    alert(msg);
  }

  private async getCSA(projectId: number, csaId: number, pkgType: string, pkgId: number): Promise<CheckSheetAssignment> {
    return new Promise<CheckSheetAssignment>(resolve => {
      this.PackageType.mcp === pkgType
        ? this.mcpService.getCheckSheetAssignment(projectId, pkgId, csaId).subscribe(csa => resolve(csa))
        : this.cpService.getCheckSheetAssignment(projectId, pkgId, csaId).subscribe(csa => resolve(csa));
    });
  }

  private async getPunchesByCsa(projectId: number, csaId: number): Promise<Array<Punch>> {
    return new Promise<Array<Punch>>(resolve => {
      this.punchService.getCsaPunches(projectId, csaId).subscribe(punches => resolve(punches));
    });
  }

  private async getAttachmentBlob(projectId: number, punchId: number, attachmentId: number): Promise<Blob> {
    return new Promise<Blob>(resolve => {
      this.punchService.getAttachment(projectId, punchId, attachmentId).subscribe(blob => resolve(blob));
    });
  }

  private async saveCheckSheetAssignmentsWithPunches(
    projectId: number,
    pkgType: string,
    cpId: number,
    mcpId: number,
    csasToAdd: Array<CheckSheetAssignment>,
    currentCsas: Array<CheckSheetAssignment>
  ): Promise<any> {
    const duplicates = csasToAdd.filter(o1 => currentCsas.some(o2 => o2.id === o1.id));
    if (duplicates.length > 0) {
      this.showAlreadyExistingMsg(duplicates);
    }

    const nonDuplicates = csasToAdd.filter(o1 => currentCsas.every(o2 => o2.id !== o1.id));
    const csaIds = nonDuplicates.map(csa => csa.id);
    for (const csaId of csaIds) {
      // Save CSA
      const csa = await this.getCSA(projectId, csaId, pkgType, this.PackageType.mcp === pkgType ? mcpId : cpId);
      await this.offlineData.csa.put({
        id: csaId,
        csa,
        projectId,
        cpId,
        mcpId,
        type: pkgType
      });

      const punches = await this.getPunchesByCsa(projectId, csaId);
      for (const punch of punches) {
        // Save Punch
        const punchId = punch.id;
        const key = await this.offlineData.punch.put({
          punchId: punch.id,
          csaId: punch.relatedCheckSheetAssignmentId,
          mcpId,
          cpId,
          data: punch
        });

        for (const attachment of punch.attachments) {
          // Save Punch Attachment
          const attachmentId = attachment.id;
          const data = await this.getAttachmentBlob(projectId, punchId, attachmentId);
          await this.offlineData.punchAttachment.put({
            attachmentId,
            isPushed: true,
            attachmentName: attachment.name,
            punchId,
            csaId,
            data
          });
        }
      }
    }
    return new Promise(resolve => resolve(this));
  }

  public downloadAndSaveScopes(csas: Array<CheckSheetAssignment>) {
    return new Promise(async (resolve, reject) => {
      if (!csas) {
        reject('No point saving data in local storage if no scope(s) provided!!!');
      }

      // Save Project
      await this.saveProject(this.currentProject);
      // Save Packages
      await this.savePackages(this.currentCp, this.currentMcp);

      await this.saveProjectAccess({ projectId: this.currentProjectId, data: this.projectAccess });

      const pkgType = this.currentMcp && isMcPackage(this.currentMcp) ? this.PackageType.mcp : this.PackageType.cp;
      const queryObj = pkgType === this.PackageType.cp ? { cpId: this.currentCp.id } : { mcpId: this.currentMcp.id };

      this.offlineData.csa
        .where(queryObj)
        .toArray()
        .then(CSAS => {
          const currentCsas = CSAS.map(csa => csa.csa);
          // Save CSA with their punches
          this.saveCheckSheetAssignmentsWithPunches(
            this.currentProject.id,
            pkgType,
            this.currentCp.id,
            this.currentMcp ? this.currentMcp.id : null,
            csas,
            currentCsas
          )
            .then(() => {
              // Save Companies
              this.optionService
                .getCompanies(this.currentProjectId, CompanyCategory.M)
                .pipe(takeUntil(this.destroy$))
                .subscribe(companies => this.saveCompanies(companies, CompanyCategory.M));

              // Save Users
              this.userService
                .getUsers(this.currentProjectId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(users => this.saveUsers(users));
            })
            .finally(() => resolve('Save Scopes Completed.'));
        });
    });
  }

  private getCurrentOfflineChanges(): PromiseExtended {
    return this.offlineData.changeLog.where({ status: ChangeLogStatus.pending }).toArray();
  }

  public isOfflineChangesPending(): PromiseExtended<boolean> {
    return this.getCurrentOfflineChanges().then(value => value.length > 0);
  }

  public async syncPendingChanges(): Promise<void> {
    const changes = await this.getCurrentOfflineChanges();
    const csaChangeMap: Map<number, ChangeLogEvent[]> = new Map();
    let isInsertObject = false;

    if (changes.length > 0) {
      for (const change of changes) {
        if (csaChangeMap.has(change.csaId)) {
          csaChangeMap.get(change.csaId).push(change);
        } else {
          csaChangeMap.set(change.csaId, [change]);
        }
      }
      mapLoop: for (const changeArray of csaChangeMap.values()) {
        changeLoop: for (const change of changeArray) {
          try {
            let res: any;
            if (change.method === HttpRequestMethod.PUT) {
              res = await this.sendPutRequest(change);
            } else if (change.method === HttpRequestMethod.POST) {
              res = await this.sendPostRequest(change);
            } else if (change.method === HttpRequestMethod.DELETE) {
              res = await this.sendDeleteRequest(change);
              if (res === null) {
                res = true; // delete requests might return a null result. null = ok, undefined og exception is not.
              }
            }
            if (res) {
              if (change.offlineCreatedType && change.method === HttpRequestMethod.POST) {
                await this.handleSyncResponse(res, change);
                isInsertObject = true;

                break mapLoop;
              } else {
                change.status = ChangeLogStatus.synced;
                await this.offlineData.changeLog.update(change.id, change);
              }
              this.changeEventsChanged.emit();
            }
          } catch (e) {
            console.error(e.status);
            this.changeLogError.emit(e as HttpErrorResponse);
            // we break and stop syncing changes related to the current csa, and continue with next csa if possible.
            break changeLoop;
          }
        }
      }
      if (isInsertObject) {
        // an insert is detected, so we restart the process.
        this.syncPendingChanges();
      }
    }
  }

  private async sendPostRequest(change: ChangeLogEvent): Promise<any> {
    let result: any;
    if (change.offlineCreatedType === ChangeEventType.punchAttachment) {
      const formData: FormData = new FormData();
      const offlineBy = { offlineBy: { user: this.currentUser, timestamp: new Date(change.created) } as UserAssignment };
      change.attachments.forEach(file => {
        formData.append('attachmentFiles', file.data, file.attachmentName);
      });
      formData.append('offlineBy', JSON.stringify(offlineBy));
      result = await this.http.post<Attachment[]>(change.url, formData).toPromise();
    }
    else {
      result = await this.http.post<Punch>(change.url, change.payload).toPromise();
    }
    return result;
  }

  private async sendPutRequest(change: ChangeLogEvent): Promise<any> {
    let doSignOrVerify: string;
    let value: number;
    if (change.params.map && change.params.map.has('doSign')) {
      doSignOrVerify = 'doSign';
    } else if (change.params.map && change.params.map.has('doVerify')) {
      doSignOrVerify = 'doVerify';
    }
    if (!!doSignOrVerify) {
      value = getSignVerifyValue(doSignOrVerify, change.payload);
    }
    const params = doSignOrVerify ? { params: new HttpParams().set(doSignOrVerify, value.toString()) } : {};
    return await this.http.put(change.url, change.payload, params).toPromise();
  }

  private async sendDeleteRequest(change: ChangeLogEvent): Promise<any> {
    const deleteParams = { params: new HttpParams().set('offlineById', this.currentUser.id.toString()).set('offlineTimestamp', change.created.toString()) };
    return await this.http.delete(change.url, deleteParams).toPromise();
  }

  private async handleSyncResponse(res: any, change: ChangeLogEvent,): Promise<void> {
    await this.updateRelatedChanges(res, change);

    if (change.offlineCreatedType === ChangeEventType.punch) {
      await this.offlineData.punch.get({ punchId: change.objectId }).then(punch => {
        punch.punchId = res.id;
        delete res.offlineBy;

        punch.data = res;
        this.offlineData.punch.update(punch.id, punch);
      });
      const attachments = await this.offlineData.punchAttachment.where({ punchId: change.objectId }).toArray();
      for (const att of attachments) {
        await this.offlineData.punchAttachment.update(att.id, { punchId: res.id });
      }
    } else if (change.offlineCreatedType === ChangeEventType.punchAttachment) {
      let resAttachments = res as Attachment[];
      resAttachments = resAttachments.reverse();
      const changeAttachments = change.attachments.reverse();

      for (const [i, att] of changeAttachments.entries()) {
        await this.offlineData.punchAttachment.get({ attachmentId: att.attachmentId }).then(dbAtt => {
          this.offlineData.punchAttachment.update(dbAtt.id, { attachmentId: resAttachments[i].id })
        });
        await this.offlineData.punch.get({ punchId: change.objectId }).then(punch => {
          for (const pAtt of punch.data.attachments) {
            if (pAtt.id === att.attachmentId) {
              pAtt.id = resAttachments[i].id;
            }
          }
          this.offlineData.punch.update(punch.id, punch);
        });
      }
      if (!!this.currentMcp) {
        this.store.dispatch(new LoadMCPPunches());
      }
      else {
        this.store.dispatch(new LoadCPPunches());
      }
    }
  }

  public async updateRelatedChanges(res: any, change: ChangeLogEvent): Promise<void> {

    if (change.offlineCreatedType === ChangeEventType.punch) {
      const relatedChanges = await this.offlineData.changeLog.where({ objectId: change.objectId }).toArray(); // fetch both punch and attachments..
      for (const item of relatedChanges) {
        item.payload = res;
        if (item.url.match(change.objectId.toString())) {
          item.url = item.url.replace(change.objectId.toString(), res.id);
        }
        if (item.id === change.id && item.method === HttpRequestMethod.POST) {
          item.status = ChangeLogStatus.synced;
        }
        item.objectId = res.id;
        await this.offlineData.changeLog.update(item.id, item);
      }
    }
    else if (change.offlineCreatedType === ChangeEventType.punchAttachment) {
      // we really don't need to do anything except set the change to synced,
      // since we can only have a create and delete, and if we have a delete before it is synced, then we delete the create sync as well.
      await this.offlineData.changeLog.update(change.id, { status: ChangeLogStatus.synced });
    }
  }

  public async fetchPendingChanges(): Promise<number> {
    return await this.offlineData.changeLog.where({ status: ChangeLogStatus.pending }).count();
  }

  public addPunch(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    let payload: Punch;
    payload = { ...request.body };
    payload.offlineBy = null;
    const punchId = new Date().getTime();
    payload.id = punchId;
    const saved: Promise<any> = this.offlineData.punch
      .put({ punchId, csaId: this.currentCsaId, cpId: this.currentCpId, mcpId: this.currentMcpId, data: payload })
      .then(() => {
        return this.saveChangeEvent<Punch>(request, HttpRequestMethod.POST, punchId, ChangeEventType.punch);
      });

    this.updateCsa(payload, 1);

    return from(saved).pipe(map(() => new HttpResponse({ status: 200, body: payload })));
  }

  private updateCsa(payload: Punch, value: number) {
    // update the csa
    this.offlineData.csa.get(this.currentCsaId).then(csa => {
      if (!!csa.csa.csItems) {
        csa.csa.csItems.forEach(element => {
          if (element.number === payload.relatedItem.number) {
            if (element.punches) {
              if (payload.category === CompletionStatus.PA) {
                element.punches.countA += value;
              }
              if (payload.category === CompletionStatus.PB) {
                element.punches.countB += value;
              }
            } else {
              element.punches = { countA: 0, countB: 0 };
              if (payload.category === CompletionStatus.PA) {
                element.punches.countA = 1;
              }
              if (payload.category === CompletionStatus.PB) {
                element.punches.countB = 1;
              }
            }

            if (element.punches.countB > 0) {
              element.status = CompletionStatus.PB;
            }
            if (element.punches.countA > 0) {
              element.status = CompletionStatus.PA;
            }
          }
        });

        if (csa.csa.status !== CompletionStatus.PA) {
          // if current status is OS, OK or PB it is safe to set new status (PB or PA).
          // If already at PA, there is no need to update the status
          csa.csa.status = payload.category;
        }

        this.offlineData.cp.get(csa.cpId).then(cp => {
          if (cp.packageData.status.completionState !== CompletionStatus.PA) {
            cp.packageData.status.completionState = payload.category;
            this.offlineData.cp.update(cp.cpId, cp);
          }
        });

        if (csa.type === this.PackageType.mcp) {
          this.offlineData.mcp.get(csa.mcpId).then(mcp => {
            if (mcp.packageData.status.completionState !== CompletionStatus.PA) {
              mcp.packageData.status.completionState = payload.category;
              this.offlineData.mcp.update(mcp.mcpId, mcp);
            }
          });
        }
      }

      this.offlineData.csa.update(this.currentCsaId, csa);
    });
  }

  public editPunch(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    let payload: Punch;
    payload = { ...request.body };
    payload.offlineBy = null;
    let saved: PromiseExtended<any>;
    saved = this.offlineData.punch.get({ punchId: payload.id }).then(async punch => {
      punch.data = payload;
      await this.updateCsaStatus(punch.csaId, payload);
      await this.updatePackageStatus(punch.cpId, this.PackageType.cp, payload);
      if (punch.mcpId) {
        await this.updatePackageStatus(punch.mcpId, this.PackageType.mcp, payload);
      }
      this.saveChangeEvent<Punch>(request, HttpRequestMethod.PUT, payload.id);
      return this.offlineData.punch.update(punch.id, punch);
    });
    return from(saved).pipe(map(() => new HttpResponse({ status: 200, body: payload })));
  }

  public deletePunch(request: HttpRequest<any>): Observable<HttpEvent<any>> {

    const reqUrlArray = request.url.split('/');
    const id = parseInt(reqUrlArray[reqUrlArray.length - 1], 10);

    let deleted: PromiseExtended<any>;
    deleted = this.offlineData.changeLog.where({ objectId: id }).toArray().then(logs => {
      const offlineCreated =
        logs.some(log => log.method === HttpRequestMethod.POST && log.offlineCreatedType === ChangeEventType.punch);
      this.offlineData.changeLog.where({ objectId: id }).delete();
      if (!offlineCreated) {
        this.saveChangeEvent<Punch>(request, HttpRequestMethod.DELETE, id);
      }
      this.offlineData.punchAttachment.where({ punchId: id }).delete();
      return this.offlineData.punch.get({ punchId: id }).then(punch => {
        this.updateCsa(punch.data, -1);
        return this.offlineData.punch.where({ punchId: id }).delete();
      })
    });
    this.changeEventsChanged.emit();
    return from(deleted).pipe(map(() => new HttpResponse({ status: 204 })));
  }

  public addOrEditComment(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    this.saveChangeEvent<CheckSheetAssignment>(request, HttpRequestMethod.PUT);
    let payload: CheckSheetAssignment;
    payload = { ...request.body };
    payload.offlineBy = null;
    const saved: PromiseExtended<any> = this.setComment(payload.comments);
    return from(saved).pipe(map(() => new HttpResponse({ status: 200, body: payload })));
  }

  public signOrVerifyCheckSheet(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    this.saveChangeEvent<CheckSheetAssignment>(request, HttpRequestMethod.PUT);
    let payload: CheckSheetAssignment;
    payload = { ...request.body };

    let saved: PromiseExtended<any>;
    if (request.params.has('doSign')) {
      const value = request.params.get('doSign');
      const signedBy: UserAssignment = value === '1' ? { user: this.currentUser, timestamp: new Date() } : null;
      payload.signedBy = signedBy;
      saved = this.setSigned(signedBy);
    } else {
      const value = request.params.get('doVerify');
      const verifiedBy: UserAssignment = value === '1' ? { user: this.currentUser, timestamp: new Date() } : null;
      payload.verifiedBy = verifiedBy;
      saved = this.setVerified(verifiedBy);
    }
    payload.offlineBy = null;
    return from(saved).pipe(map(() => new HttpResponse({ status: 200, body: payload })));
  }

  private setSigned(signedBy): PromiseExtended<any> {
    return this.offlineData.csa.get(this.currentCsaId).then(csa => {
      csa.csa.signedBy = signedBy;
      this.offlineData.csa.update(this.currentCsaId, csa);
    });
  }

  private setVerified(verifiedBy): PromiseExtended<any> {
    return this.offlineData.csa.get(this.currentCsaId).then(csa => {
      csa.csa.verifiedBy = verifiedBy;
      this.offlineData.csa.update(this.currentCsaId, csa);
    });
  }

  private setComment(comment: string) {
    return this.offlineData.csa.get(this.currentCsaId).then(csa => {
      csa.csa.comments = comment;
      this.offlineData.csa.update(this.currentCsaId, csa);
    });
  }

  private async updatePackageStatus(pkgId: number, type: string, punch: Punch) {
    // Note: We can't downgrade state based on offline scope.
    if (type === this.PackageType.cp) {
      this.offlineData.cp.get(pkgId).then(cp => {
        cp.packageData.status.completionState = this.evaluateStatus(punch.category, cp.packageData.status.completionState);
        this.offlineData.cp.update(pkgId, cp);
      });
    } else {
      this.offlineData.mcp.get(pkgId).then(mcp => {
        mcp.packageData.status.completionState = this.evaluateStatus(punch.category, mcp.packageData.status.completionState);
        this.offlineData.mcp.update(pkgId, mcp);
      });
    }
  }

  private async updateCsaStatus(csaId: number, punch: Punch) {
    await this.offlineData.punch
      .where({ csaId })
      .toArray()
      .then(punches => {
        this.offlineData.csa.get(csaId).then(csa => {
          csa.csa.status = this.findHighestStatus(punch, punches);
          this.offlineData.csa.update(csaId, csa);
        });
      });
  }

  private findHighestStatus(punch: Punch, punches: DbPunch[]) {
    const highestPunchStatus = punches.some(
      p1 => p1.data.category === CompletionStatus.PA && !p1.data.acceptedBy && p1.data.id !== punch.id
    )
      ? CompletionStatus.PA
      : punches.some(p1 => p1.data.category === CompletionStatus.PB && !p1.data.acceptedBy && p1.data.id !== punch.id)
        ? CompletionStatus.PB
        : CompletionStatus.OK;

    return this.evaluateStatus(highestPunchStatus, punch.category);
  }

  private evaluateStatus(a: CompletionStatus, b: CompletionStatus): CompletionStatus {
    if (a.charAt(1) < b.charAt(1)) {
      return a;
    } else {
      return b;
    }
  }

  public updateChecksheetItem(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    this.saveChangeEvent<CheckSheetChange>(request, HttpRequestMethod.PUT);
    let payload: CheckSheetChange;
    payload = { ...request.body };
    const updatedCsa = this.updateCSItems(this.currentCsaId, payload as CheckListItem);
    return from(updatedCsa).pipe(map(csa => new HttpResponse({ status: 200, body: csa })));
  }

  private updateCSItems(id, csItem: CheckListItem): PromiseExtended<CheckSheetAssignment> {
    return this.offlineData.csa.get(id).then(csa => {
      csa.csa.signedBy = null;
      csa.csa.verifiedBy = null;
      for (const item of csa.csa.csItems) {
        if (item.number === csItem.number) {
          item.status = csItem.status;
        }
      }
      this.offlineData.csa.update(id, csa);
      return csa.csa;
    });
  }

  public setRecordValue(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    this.saveChangeEvent<CheckRecordChange>(request, HttpRequestMethod.PUT);
    let payload: CheckRecordChange;
    payload = { ...request.body };

    const num = request.url.split('/')[this.checkSheetItemNumberIndex];
    const recordSaved = this.updateCSIRecord(this.currentCsaId, num, payload);

    return from(recordSaved).pipe(map(csa => new HttpResponse({ status: 200, body: csa })));
  }

  private updateCSIRecord(csId: number, csItemNumber: string, checkRecord: CheckRecordValue): PromiseExtended<CheckSheetAssignment> {
    return this.offlineData.csa.get(csId).then(csa => {
      for (const item of csa.csa.csItems) {
        if (item.number === csItemNumber) {
          for (const recVals of item.recordValues) {
            if (recVals.sequenceNumber === checkRecord.sequenceNumber) {
              recVals.value = checkRecord.value;
            }
          }
        }
      }
      this.offlineData.csa.update(csId, csa);
      return csa.csa;
    });
  }

  public async storeOfflineFiles(punchId: number, uploadedFiles: File[]): Promise<void> {
    const punch = await this.offlineData.punch.get({ punchId });

    for (const file of uploadedFiles) {
      const attachmentId = new Date().getTime();
      await this.offlineData.punchAttachment.put({ csaId: punch.csaId, punchId, isPushed: false, attachmentName: file.name, attachmentId, data: file })
        .catch(e => console.log(e));
      punch.data.attachments.push({ id: attachmentId, name: file.name, type: file.type });
    }
    await this.offlineData.punch.update(punch.id, punch);
  }

  private saveChangeEvent<T extends { offlineBy?: UserAssignment }>(
    request: HttpRequest<any>,
    method: string,
    objectId?: number,
    offlineCreatedType?: string
  ): PromiseExtended<any> {
    let payload: T;
    payload = { ...request.body };
    payload.offlineBy = {
      user: this.currentUser,
      timestamp: new Date()
    } as UserAssignment;


    const promise: PromiseExtended<any> = this.offlineData.changeLog.put({
      updated: new Date().getTime(),
      created: new Date().getTime(),
      csaId: this.currentCsaId,
      objectId,
      offlineCreatedType,
      method,
      url: request.url,
      payload,
      params: request.params,
      content: null,
      status: ChangeLogStatus.pending
    } as ChangeLogEvent);

    this.changeEventsChanged.emit();
    return promise;
  }

  private deleteMcpIfCsasNotPresent(mcpId: number): Promise<string> {
    return new Promise<string>(resolve => {
      this.offlineData.csa
        .where({ mcpId })
        .count()
        .then(count => {
          if (count === 0) {
            // Delete MCP
            this.offlineData.mcp.delete(mcpId);
          }
          resolve("Done executing 'deleteMcpIfCsasNotPresent'");
        })
        .catch(reason => new Error(reason));
    });
  }

  private async deleteCpIfCsasNotPresent(cpId: number): Promise<string> {
    return new Promise<string>(resolve => {
      this.offlineData.csa
        .where({ cpId })
        .count()
        .then(count => {
          if (count === 0) {
            // Delete CP
            this.offlineData.cp.delete(cpId);
          }
          resolve("Done executing 'deleteCpIfCsasNotPresent'");
        })
        .catch(reason => new Error(reason));
    });
  }

  private async deleteProjectIfCsaNotPresent(projectId: number): Promise<string> {
    return new Promise<string>(resolve => {
      this.offlineData.csa
        .where({ projectId })
        .count()
        .then(count => {
          if (count === 0) {
            // Delete Project, Companies, Users, Sites & Phases
            this.offlineData.project
              .delete(projectId)
              .then(() => this.offlineData.users.delete(projectId))
              .then(() => this.offlineData.companies.delete(projectId))
          }
          resolve("Done executing 'deleteProjectIfCsaNotPresent'");
        })
        .catch(reason => new Error(reason));
    });
  }

  deleteScope(csaId: number) {
    return new Promise((resolve, reject) => {
      let projectId;
      let cpId;
      let mcpId;
      let pkgType;

      this.offlineData.csa
        .get(csaId)
        .then(csa => {
          if (!csa) {
            throw new Error('No CSA found in local storage!!!');
          }

          projectId = csa.projectId;
          cpId = csa.cpId;
          mcpId = csa.mcpId;
          pkgType = csa.type;
          // Delete CSA and any Changes in the ChangeLog
          this.offlineData.csa.delete(csaId).then(() => {
            this.offlineData.changeLog.where({ csaId }).delete();
          });
        })
        .then(() => {
          // Delete Punches and Attachments
          this.offlineData.punch.filter(punch => punch.csaId === csaId).delete();
          this.offlineData.punchAttachment.filter(att => att.csaId === csaId).delete();
        })
        .then(() => {
          // Check & delete packages and project
          if (this.PackageType.mcp === pkgType) {
            this.deleteMcpIfCsasNotPresent(mcpId).then(() => {
              this.deleteCpIfCsasNotPresent(cpId)
                .then(() => this.deleteProjectIfCsaNotPresent(projectId))
                .finally(() => resolve('Delete Scope on MCP Completed.'));
            });
          } else {
            this.deleteCpIfCsasNotPresent(cpId)
              .then(() => this.deleteProjectIfCsaNotPresent(projectId))
              .finally(() => resolve('Delete Scope on CP Completed.'));
          }
        })
        .catch(reason => {
          console.warn(reason);
          reject(`No CSA found in local storage!!!\n${reason}`);
        });
    });
  }

  public fetchMcpPunches() {
    const punches = this.offlineData.punch.where({ mcpId: this.currentMcpId }).toArray();

    return from(punches).pipe(
      map(item => {
        const p = item.map(punch => {
          return punch.data;
        });

        return new HttpResponse({ status: 200, body: p });
      })
    );
  }

  public fetchCpPunches() {
    const punches = this.offlineData.punch.where({ cpId: this.currentCpId }).toArray();

    return from(punches).pipe(
      map(item => {
        const p = item.map(punch => {
          return punch.data;
        });
        return new HttpResponse({ status: 200, body: p });
      })
    );
  }

  public fetchCompanies(): Observable<HttpEvent<any>> {
    const data = this.offlineData.companies.get(this.currentProjectId);
    return from(data).pipe(
      map(item => {
        return new HttpResponse({ status: 200, body: item.data });
      })
    );
  }

  public fetchUsers(): Observable<HttpEvent<any>> {
    const data = this.offlineData.users.get(this.currentProjectId);
    return from(data).pipe(
      map(item => {
        return new HttpResponse({ status: 200, body: item.data });
      })
    );
  }

  public fetchAttachment(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const reqArray = request.url.split("/");
    const id = parseInt(reqArray[reqArray.length - 1], 10);
    const data = this.offlineData.punchAttachment.get({ attachmentId: id });
    return from(data).pipe(
      map(item => {
        return new HttpResponse({ status: 200, body: item.data });
      })
    );
  }

  public postAttachment(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const reqArray = request.url.split("/");
    const punchId = parseInt(reqArray[reqArray.length - 2], 10);
    let attachments: PunchAttachment[];

    const data = this.offlineData.punch.get({ punchId }).then(async punch => {
      const created = new Date().getTime();
      attachments = await this.offlineData.punchAttachment.where({ punchId }).toArray();
      attachments = attachments.filter(attachment => attachment.isPushed === false);
      await this.offlineData.changeLog.put({
        attachments,
        objectId: punchId,
        csaId: punch.csaId,
        method: request.method,
        params: request.params,
        url: request.url,
        offlineCreatedType: ChangeEventType.punchAttachment,
        status: ChangeLogStatus.pending,
        created,
        payload: [],
        updated: created
      });
      for (const attachment of attachments) {
        await this.offlineData.punchAttachment.update(attachment.id, { isInSync: true });
      }
    });
    this.changeEventsChanged.emit();

    return from(data).pipe(
      map(() => {
        return new HttpResponse({ status: 204, body: attachments });
      })
    );
  }

  public getProjectAccess(): Observable<HttpResponse<ProjectAccess>> {
    const data = this.offlineData.projectAccess.get(this.currentProjectId);
    return from(data).pipe(
      map(item => {
        return new HttpResponse({ status: 200, body: item.data });
      })
    );
  }

  public deleteAttachment(request: HttpRequest<any>): Observable<HttpEvent<any>> {

    this.saveChangeEvent(request, HttpRequestMethod.DELETE);

    const reqArray = request.url.split("/");
    const id = parseInt(reqArray[reqArray.length - 1], 10);
    const data = this.offlineData.punchAttachment.where({ attachmentId: id }).delete();
    return from(data).pipe(
      map(() => {
        return new HttpResponse({ status: 204 });
      })
    );
  }

  public fetchPackageData(isCheckedTags: boolean, isMcp: boolean, id: number): Observable<HttpResponse<any>> {
    let data: any;
    if (isCheckedTags) {
      const queryObj = isMcp ? { mcpId: id, type: this.PackageType.mcp } : { cpId: id, type: this.PackageType.cp };
      data = this.offlineData.csa.where(queryObj).toArray();
      return from(data).pipe(
        map((items: CSA[]) => {
          if (!items) {
            return new HttpResponse({ status: 200, body: [] });
          }
          const csas = items.map(csa => csa.csa);
          return new HttpResponse({ status: 200, body: csas });
        })
      );
    }
    if (isMcp) {
      data = this.offlineData.mcp.get(id);
    } else {
      data = this.offlineData.cp.get(id);
    }
    return from(data).pipe(map((item: any) => new HttpResponse({ status: 200, body: item.packageData })));
  }

  public readonly getTree = () =>
    zip(this.offlineData.cp.toArray(), this.offlineData.mcp.toArray()).pipe(
      map((items: any) => {
        const treeData = this.buildTree(items[0], items[1]);
        return new HttpResponse({ status: 200, body: treeData });
      })
    );

  public getTasks(): Observable<HttpResponse<Task[]>> {
    const data = this.offlineData.cp.toArray();
    return from(data).pipe(
      map((items: any) => {
        if (!items) {
          return new HttpResponse({ status: 200, body: [] });
        }
        const tasks: Task[] = [];
        items.forEach((element: CP) => {
          tasks.push(element.packageData.task);
        });
        return new HttpResponse({ status: 200, body: tasks });
      })
    );
  }

  public getCps(): Observable<HttpResponse<CommissioningPackage[]>> {
    const data = this.offlineData.cp.toArray();
    return from(data).pipe(
      map((items: any) => {
        const cps: Array<CommissioningPackage> = [];
        items.forEach(item => {
          cps.push(item.packageData);
        });
        return new HttpResponse({ status: 200, body: cps });
      })
    );
  }

  public fetchCSA(id: number): Observable<HttpEvent<any>> {
    const data = this.offlineData.csa.get(id);
    return from(data).pipe(map((item: any) => new HttpResponse({ status: 200, body: item.csa })));
  }

  public getProjects(): Observable<HttpResponse<Project[]>> {
    const data = this.offlineData.project.toArray();
    return from(data).pipe(
      map((item: any) => {
        if (item.length === 0) {
          this.store.dispatch(new ProjectTreeLoadSuccess([]));
          this.store.dispatch(new TasksLoadSuccess([]));
        }
        return new HttpResponse({ status: 200, body: item });
      })
    );
  }

  private buildTree(items: any, mcps: any): Array<TreeNode> {
    const tree: Array<TreeNode> = [];
    items.forEach(element => {
      if (!tree.some(elem => elem.id === element.packageData.task.id)) {
        const cp = this.addTaskIfNotExists(tree, element);
        this.injectMcpIfPresent(cp, mcps);
      } else {
        const subSystem: TreeNode = this.searchTreeArray(tree, element.packageData.subSystem.id, TreeNodeType.SUBSYSTEM);
        if (subSystem) {
          const cp = this.addNode(subSystem.children, subSystem.id, element.packageData, TreeNodeType.CP);
          this.injectMcpIfPresent(cp, mcps);
        } else {
          const system: TreeNode = this.searchTreeArray(tree, element.packageData.system.id, TreeNodeType.SYSTEM);
          if (system) {
            const subSys = this.addNode(system.children, system.id, element.packageData.subSystem, TreeNodeType.SUBSYSTEM);
            const cp = this.addNode(subSys.children, subSys.id, element.packageData, TreeNodeType.CP);
            this.injectMcpIfPresent(cp, mcps);
          } else {
            const task = this.searchTreeArray(tree, element.packageData.task.id, TreeNodeType.TASK);
            const sys = this.addNode(task.children, task.id, element.packageData.system, TreeNodeType.SYSTEM);
            const subSys = this.addNode(sys.children, sys.id, element.packageData.subSystem, TreeNodeType.SUBSYSTEM);
            const cp = this.addNode(subSys.children, subSys.id, element.packageData, TreeNodeType.CP);
            this.injectMcpIfPresent(cp, mcps);
          }
        }
      }
    });
    return tree;
  }

  private injectMcpIfPresent(node: TreeNode, mcps: Array<any>) {
    mcps
      .filter(mcp => mcp.cpId === node.id)
      .forEach(item => {
        this.addNode(node.children, node.id, item.packageData, TreeNodeType.MCP);
      });
  }

  private searchTreeArray(nodes: Array<TreeNode>, compare: number, nodeType: TreeNodeType): TreeNode {
    let searchNode: TreeNode = null;
    for (const node of nodes) {
      searchNode = this.searchTree(node, compare, nodeType);
      if (searchNode) {
        return searchNode;
      }
    }
    return searchNode;
  }

  private searchTree(node: TreeNode, compare: number, nodeType: TreeNodeType): any {
    if (node.id === compare && node.nodeType === nodeType) {
      return node;
    } else if (node.children != null) {
      let result = null;
      for (let i = 0; result == null && i < node.children.length; i++) {
        result = this.searchTree(node.children[i], compare, nodeType);
      }
      return result;
    }
    return null;
  }

  private addTaskIfNotExists(tree: Array<TreeNode>, element: any): TreeNode {
    let node = this.addNode(tree, null, element.packageData.task, TreeNodeType.TASK);
    node = this.addNode(node.children, node.id, element.packageData.system, TreeNodeType.SYSTEM);
    node = this.addNode(node.children, node.id, element.packageData.subSystem, TreeNodeType.SUBSYSTEM);
    node = this.addNode(node.children, node.id, element.packageData, TreeNodeType.CP);
    return node;
  }

  private addNode(children: Array<TreeNode>, parentId: number, node: any, nodeType: TreeNodeType): TreeNode {
    let length;
    if (nodeType === TreeNodeType.CP) {
      length = children.push({
        id: node.id,
        parentId,
        nodeType,
        name: node.description,
        number: node.cpNumber,
        children: []
      } as TreeNode);
    } else if (nodeType === TreeNodeType.MCP) {
      length = children.push({
        id: node.id,
        parentId,
        nodeType,
        name: node.description,
        number: node.mcpNumber,
        children: []
      } as TreeNode);
    } else {
      length = children.push({
        id: node.id,
        parentId,
        nodeType,
        name: node.name,
        number: node.number,
        children: []
      } as TreeNode);
    }
    const index = length - 1;
    return children[index];
  }

  fetchCsasByProject(projectId: number): Promise<Array<CSA>> {
    return this.offlineData.csa.where({ projectId }).toArray();
  }
}
