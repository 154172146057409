import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { OfflineDownloadScope } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineDownloadsService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.OfflineGetDownloads)
  public getOfflineDownloads(projectId: number) : Observable<OfflineDownloadScope> {
    return this.http.get<OfflineDownloadScope>(`${this.baseUrl}/users/current/offline-scopes/projects/${projectId}`);
  }

  @Resource(ResourceType.OfflineReleaseDownloads)
  releaseOfflineDownloads(projectId: number, pkgId: number, pkgType: string, csaIds: number[]): Observable<any> {
    if(pkgType === "mcp") {
      return this.http.put(`${this.baseUrl}/projects/${projectId}/mcps/${pkgId}/offline/disable`, csaIds);
    } else {
      return this.http.put(`${this.baseUrl}/projects/${projectId}/cps/${pkgId}/offline/disable`, csaIds);
    }
  }

}
