import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  LoadCheckSheetAssignmentForReportByProject,
  LoadCheckSheetAssignmentForReportByTask,
  LoadCheckSheetAssignmentForReportSuccess,
  LoadPunchesForBurnDownSuccess, LoadPunchesForReportByProject,
  ReportsActionTypes


} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentTask, getCurrentProject } from '@completion/selectors';

import { ResourceEffect } from './resource.effect';
import { ToastrService } from "ngx-toastr";
import { ReportService } from "../../services/report.service";
import { LoadPunchesForReportByTask } from "@completion/actions";


@Injectable()
export class ReportEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly punchService: ReportService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, punchService, store, toastr);
  }

  @Effect()
  loadReportPunchesByTask$: Observable<Action> = this.actions$.pipe(
    ofType<LoadPunchesForReportByTask>(ReportsActionTypes.LoadPunchesForReportByTask),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentTask)),
    filter(([_, currentProject, task]) => !!(currentProject && task)),
    this.fetchResource('getProjectPunchesByTask', LoadPunchesForBurnDownSuccess, ([_, currentProject, task]) => [currentProject.id, task.id])
  );

  @Effect()
  loadReportPunchesByProject$: Observable<Action> = this.actions$.pipe(
    ofType<LoadPunchesForReportByProject>(ReportsActionTypes.LoadPunchesForReportByProject),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!(currentProject )),
    this.fetchResource('getProjectPunches', LoadPunchesForBurnDownSuccess, ([_, currentProject, task]) => [currentProject.id])
  );

  @Effect()
  loadCheckSheetAssignmentByTask$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCheckSheetAssignmentForReportByTask>(ReportsActionTypes.LoadCheckSheetAssignmentForReportByTask),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentTask)),
    filter(([_, currentProject, task]) => !!(currentProject && task)),
    this.fetchResource('getCheckSheetAssignmentByTask', LoadCheckSheetAssignmentForReportSuccess, ([_, currentProject, task]) => [currentProject.id, task.id])
  );

  @Effect()
  loadCheckSheetAssignmentByProject$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCheckSheetAssignmentForReportByProject>(ReportsActionTypes.LoadCheckSheetAssignmentForReportByProject),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!(currentProject )),
    this.fetchResource('getCheckSheetAssignmentByProject', LoadCheckSheetAssignmentForReportSuccess, ([_, currentProject]) => [currentProject.id])
  );

}
