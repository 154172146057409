import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Sort } from "@angular/material/sort";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import Chart from 'chart.js/auto'
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoadDashboardCheckSheetAssignmentStatus, LoadDashboardCps, LoadDashboardMcps, LoadUnacceptedPunches, LoadUserPunches, LoadUserSignatureAssignments } from "@completion/actions";
import { ApplicationMode, CompletionStatus, ResourceStatus, ResourceType } from '@completion/enums';
import { getCurrentProject, getDashboardCheckSheetAssignmentStatus, getOfflineState, getProjects, getResourceState } from '@completion/selectors';
import { ContextTool, ContextToolbarService, TooledComponent } from '../context-toolbar/context-toolbar.service';
import { getDashBoardCps, getDashBoardMcps } from "../core/store/selectors/dashboard-package.selector";
import { DashboardCheckSheetAssignmentStatus } from "../shared/models/dashboard-checksheet-status";
import { DashboardPackage } from "../shared/models/dashboard-package";
import { Project } from '../shared/models/project';
import { U } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, TooledComponent {

  readonly tools: ContextTool[];
  displayedColumns: string[] = ['description', 'status'];
  filterOnCp: boolean;
  projectProgress: string;
  mcpDashboardPackages: DashboardPackage[];
  cpDashboardPackages: DashboardPackage[];
  pieChart: Chart;
  isOffline: boolean = false;
  accepted = 'accepted';
  assignmentCount = 0;
  badgeHidden = true;

  private readonly destroy$: Subject<void> = new Subject();
  constructor(
    private readonly router: Router,
    private elRef: ElementRef,
    private readonly store: Store<Project>,
    private readonly contextToolbarService: ContextToolbarService,
    private readonly toastr: ToastrService) {
    this.tools = [
      // new ContextTool('Create CP', this.gotoCreateCp.bind(this), this)
    ];
    this.store.select(getResourceState, ResourceType.GetProjects)
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        if (this.isOffline) {
          return;
        }
        this.projectProgress = state.status;
        switch (state.status) {
          case ResourceStatus.Idle:
            this.projectProgress = "";
            break;
          case ResourceStatus.InProgress:
            this.projectProgress = "Fetching projects and tasks";
            this.toastr.info(this.projectProgress, '', { positionClass: 'toast-top-center' });
            break;
          case ResourceStatus.Success:
            this.projectProgress = "Fetching projects and tasks success!";
            this.toastr.success(this.projectProgress +
              " \n" + "Start using the application by navigating in the left menu.", '', { positionClass: 'toast-top-center' });
            break;
          case ResourceStatus.Failure:
            this.projectProgress = "Failed to fetch projects. " + state.lastError.split(":")[0];
            break;
        }
      });

    this.store.select(getOfflineState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(offlineState => this.isOffline = offlineState === ApplicationMode.OFFLINE);
  }

  sortDataCp(sort: Sort) {
    const data = this.cpDashboardPackages.slice();
    if (!sort.active || sort.direction === '') {
      this.cpDashboardPackages = data;
      return;
    }

    this.cpDashboardPackages = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'status':
          return compare(a.status.completionState.toString(), b.status.completionState.toString(), isAsc);
        default:
          return 0;
      }
    });
  }

  sortDataMcp(sort: Sort) {

    const data = this.mcpDashboardPackages.slice();
    if (!sort.active || sort.direction === '') {
      this.mcpDashboardPackages = data;
      return;
    }

    this.mcpDashboardPackages = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'status':
          return compare(a.status.completionState.toString(), b.status.completionState.toString(), isAsc);
        default:
          return 0;
      }
    });
  }

  togglePackages(): void {
    this.filterOnCp = !this.filterOnCp;
  }

  async ngOnInit(): Promise<void> {
    this.contextToolbarService.addToolsFor(this);
    this.filterOnCp = true;
    this.renderDashboard();
  }

  addCount(count: number): void {
    if (count > 0) {
      this.toastr.info('You have ' + count + ' new assignments', 'New assignments', { positionClass: 'toast-top-right' });
      this.assignmentCount = count;

      this.badgeHidden = this.assignmentCount === 0;
    }
  }


  ngOnDestroy(): void {
    this.contextToolbarService.removeToolsFor(this);
    this.mcpDashboardPackages = [];
    this.cpDashboardPackages = [];
    this.pieChart?.destroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  drawPieChart(statusSummary: DashboardCheckSheetAssignmentStatus): void {

    var canvas = this.elRef.nativeElement.querySelector('#csaStatus');
    if (!canvas) {
      return;
    }
    try {
      if (this.pieChart) {
        this.pieChart.destroy();
      }
      if (statusSummary !== null) {
        const ctx = 'csaStatus';
        const csaData = {
          labels: [
            CompletionStatus.OK,
            CompletionStatus.OS,
            CompletionStatus.PA,
            CompletionStatus.PB
          ],
          datasets: [{
            label: 'Check Sheet Assignment Statuses',
            data: [statusSummary.OKCount, statusSummary.OSCount, statusSummary.PACount, statusSummary.PBCount],
            backgroundColor: [
              'rgb(13, 135, 98)',
              'rgb(178, 183, 186)',
              'rgb(215, 35, 17)',
              'rgb(255, 128, 00)',
            ],
            hoverOffset: 5
          }]
        };
        this.pieChart = new Chart(ctx, { type: 'pie', data: csaData });
        this.pieChart.render();
      }
    }
    catch (e) {
      console.log(e);
    }

  }

  renderDashboard(): void {
    if (this.isOffline) {
      return;
    }
    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(p => {
      if (p !== null && !this.isOffline) {
        this.store.dispatch(new LoadUserPunches());
        this.store.dispatch(new LoadDashboardCps());
        this.store.dispatch(new LoadDashboardMcps());
        this.store.dispatch(new LoadDashboardCheckSheetAssignmentStatus());
        this.store.dispatch(new LoadUserSignatureAssignments());
        this.store.dispatch(new LoadUnacceptedPunches());

        this.store.select(getDashBoardCps).pipe(takeUntil(this.destroy$)).subscribe(
          packages => this.cpDashboardPackages = packages);
        this.store.select(getDashBoardMcps).pipe(takeUntil(this.destroy$)).subscribe(
          packages => this.mcpDashboardPackages = packages);
        this.store.select(getDashboardCheckSheetAssignmentStatus).pipe(takeUntil(this.destroy$)).subscribe(
          statusSummary => {
            this.drawPieChart(statusSummary);
          });
      }
    });
  }
  getRouterLink(dashboardPackage: DashboardPackage) {
    if (dashboardPackage.type === 'CP') {
      return `/cp/${dashboardPackage.cpId}`;
    }
    return `/cp/${dashboardPackage.cpId}/mcp/${dashboardPackage.mcpId}`;
  }

  goToPackage(dashboardPackage: DashboardPackage) {
    this.router.navigate([this.getRouterLink(dashboardPackage)]);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
