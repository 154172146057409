import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from '@angular/material/tooltip';

import { TaskSelectorComponent } from './task-selector.component';

@NgModule({
  imports: [CommonModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatButtonModule, FormsModule, MatTooltipModule],
  declarations: [TaskSelectorComponent],
  exports: [TaskSelectorComponent]
})
export class TaskSelectorModule { }
