import { Action } from '@ngrx/store';

import { CheckSheetAssignment, CommissioningPackage, McPackage, MoveCheckSheetAssignement, SignatureState } from '@completion/models';
import {ItemAction, ResetLevel} from "@completion/enums";
import {Reset} from "../../../shared/models/reset";




export enum CpActionTypes {
  CpsLoad = 'Cps Load',
  CpsLoadSuccess = 'Cps Load Success',
  ChangeCurrentCpId = 'Change Current CP Id',
  CreateMcps = 'Create MCP',
  CreateMcpsSuccess = 'Create MCP Success',
  LoadMcpsForCp = 'Load Mcps For Cp',
  LoadMcpsForCpSuccess = 'Load Mcps For Cp Success',
  LoadCurrentCp = 'Load Current CP',
  LoadCurrentCpSuccess = 'Load Current CP Success',
  ClearCurrentCp = 'Clear Current Cp',
  CpUpdate = 'Cp Update',
  CpUpdateSuccess = 'Cp Update Success',
  CpUpdateFailure = 'Cp Update Failure',
  ClearLatestError = 'Clear Latest Error',
  CpAddCheckedTags = 'Cp Add Checked Tags',
  CpDeleteCSA = 'Cp Delete CSA',
  CpMoveCSA = 'Cp Move CSA',
  DeleteCurrentCp = 'Delete Current CP',
  CpResetData = 'Cp Reset Data',
  SignCpCsaSigntureEntry = "SignCpCsaSigntureEntry",
  InsertAndSignCpCsaSigntureEntry = "InsertAndSignCpCsaSigntureEntry",
  ResetCpItems = "ResetCpItems",
}

export class LoadCurrentCp implements Action {
  readonly type = CpActionTypes.LoadCurrentCp;
}

export class DeleteCurrentCp implements Action {
  readonly type = CpActionTypes.DeleteCurrentCp;
}

export class ChangeCurrentCpId implements Action {
  readonly type = CpActionTypes.ChangeCurrentCpId;

  constructor(public cpId: number) { }
}

export class LoadCurrentCpSuccess implements Action {
  readonly type = CpActionTypes.LoadCurrentCpSuccess;

  constructor(public cp: CommissioningPackage) { }
}

export class CpsLoad implements Action {
  readonly type = CpActionTypes.CpsLoad;

  constructor(public taskId?: number) { }
}

export class ClearCurrentCp implements Action {
  readonly type = CpActionTypes.ClearCurrentCp;
}

export class CpUpdate implements Action {
  readonly type = CpActionTypes.CpUpdate;

  constructor(public cp: CommissioningPackage) { }
}

export class CpUpdateSuccess implements Action {
  readonly type = CpActionTypes.CpUpdateSuccess;

  constructor(public cp: CommissioningPackage) { }
}

export class CpUpdateFailure implements Action {
  readonly type = CpActionTypes.CpUpdateFailure;

  constructor(public errorMessage: string) { }
}
export class CpsLoadSuccess implements Action {
  readonly type = CpActionTypes.CpsLoadSuccess;

  constructor(public commissioningPackages: CommissioningPackage[]) { }
}

export class LoadMcpsForCp implements Action {
  readonly type = CpActionTypes.LoadMcpsForCp;
}

export class LoadMcpsForCpSuccess implements Action {
  readonly type = CpActionTypes.LoadMcpsForCpSuccess;

  constructor(public mcps: McPackage[]) { }
}

export class CreateMcps implements Action {
  readonly type = CpActionTypes.CreateMcps;

  constructor(public mcps: McPackage[]) { }
}

export class CreateMcpsSuccess implements Action {
  readonly type = CpActionTypes.CreateMcpsSuccess;

  constructor(public mcps: McPackage[], public cpId: number) { }
}

export class ClearLatestError implements Action {
  readonly type = CpActionTypes.ClearLatestError;
}

export class CpAddCheckedTags implements Action {
  readonly type = CpActionTypes.CpAddCheckedTags;

  constructor(public checkSheetAssignment: CheckSheetAssignment[]) { }
}

export class CpDeleteCSA implements Action {
  readonly type = CpActionTypes.CpDeleteCSA;

  constructor(public ids: number[]) { }
}

export class CpMoveCSA implements Action {
  readonly type = CpActionTypes.CpMoveCSA;

  constructor(public csas: MoveCheckSheetAssignement) { }
}

export class CpResetData implements Action {
  readonly type = CpActionTypes.CpResetData;
}

export class SignCpCsaSigntureEntry implements Action {
  readonly type = CpActionTypes.SignCpCsaSigntureEntry;
  constructor(public entry: SignatureState) {
  }
}

export class InsertAndSignCpCsaSigntureEntry implements Action {
  readonly type = CpActionTypes.InsertAndSignCpCsaSigntureEntry;
  constructor(public entry: SignatureState) {
  }
}

export class ResetCpItems implements Action {
  readonly type = CpActionTypes.ResetCpItems;
  constructor(public reset:Reset) { }
}

export type CpAction =
  | LoadMcpsForCp
  | LoadMcpsForCpSuccess
  | LoadCurrentCp
  | LoadCurrentCpSuccess
  | ChangeCurrentCpId
  | CpsLoad
  | CpsLoadSuccess
  | ClearCurrentCp
  | CpUpdate
  | CpUpdateSuccess
  | CpUpdateFailure
  | CreateMcps
  | CreateMcpsSuccess
  | ClearLatestError
  | CpAddCheckedTags
  | CpDeleteCSA
  | CpMoveCSA
  | DeleteCurrentCp
  | CpResetData
  | SignCpCsaSigntureEntry
  | InsertAndSignCpCsaSigntureEntry
  | ResetCpItems;
