export enum CertificateType {
  MCC = 'MCC',
  RFCC = 'RFCC',
  RFOC = 'RFOC'
}

export const getCertificateTypeDescription = (type: CertificateType) => {
  switch (type) {
    case CertificateType.MCC:
      return 'Mechanical Completion Certificate';
    case CertificateType.RFCC:
      return 'Ready For Commissioning Certificate';
    case CertificateType.RFOC:
      return 'Ready For Operation Certificate';
    default:
      throw new Error(`The provided certificate type is not supported. ${type}`);
  }
};
