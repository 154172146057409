import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CertificateOverviewReport} from "@completion/models";
import {Store} from "@ngrx/store";
import {Subject} from "rxjs";
import {getCertificateOverviewReport, getResourceState} from "@completion/selectors";
import {takeUntil} from "rxjs/operators";
import {LoadCertificateReport} from "@completion/actions";
import {ResourceStatus, ResourceType} from "@completion/enums";


@Component({
  selector: 'app-certificate-overview-report',
  templateUrl: './certificate-overview-report.component.html',
  styleUrls: ['./certificate-overview-report.component.scss']
})
export class CertificateOverviewReportComponent  implements OnDestroy, OnInit {
  reportData: CertificateOverviewReport[] = [];

  filteredData: CertificateOverviewReport[] = [];
  destroy$ = new Subject<void>();
  displayedColumns = ['taskNumber','systemNo','subsystemNo','commissioningPackageNo','signedMcc', 'signedRfcc', 'signedRfoc' ];

  filterValues = {
    taskNumber: '',
    systemNo: '',
    subsystemNo: '',
    commissioningPackageNo: ''
  };

  loading = false;

  constructor(private store: Store) {
    this.store.select(getCertificateOverviewReport).pipe(takeUntil(this.destroy$)).subscribe((reportData: CertificateOverviewReport[]) => {
      if(reportData) {
        console.log(reportData);
        this.reportData = reportData;
        this.applyFilter();
      }

    });

    this.store.select(getResourceState, ResourceType.GetCertificateReport ).pipe(takeUntil(this.destroy$)).subscribe( resourceState => {
        if(resourceState){
          if(resourceState.status === ResourceStatus.InProgress){
            this.loading = true;
          }
          else {
            this.loading = false;
          }
        }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadCertificateReport());
  }

 readinessClass(signedAmount, totalAmount) {
    if(totalAmount === 0) return 'not-started';
    return signedAmount === totalAmount ? 'ready' : 'in-progress';
  }

  applyFilter() {
    this.filteredData = this.reportData.filter(item => {
      return (!this.filterValues.taskNumber || item.taskNumber.includes(this.filterValues.taskNumber)) &&
        (!this.filterValues.systemNo || item.systemNo.includes(this.filterValues.systemNo)) &&
        (!this.filterValues.subsystemNo || item.subsystemNo.includes(this.filterValues.subsystemNo)) &&
        (!this.filterValues.commissioningPackageNo || item.commissioningPackageNo.includes(this.filterValues.commissioningPackageNo));
    });
  }

  rowColor(element:CertificateOverviewReport ) {
    if( element.totalMcc > 0 && element.totalRfcc > 0 && element.totalRfoc > 0 && element.signedMcc === element.totalMcc && element.signedRfcc === element.totalRfcc && element.signedRfoc === element.totalRfoc){
      return 'rgba(60, 150, 0, 0.1)';
    }
    else if(element.totalMcc > 0){
      console.log(element.totalMcc);
      return 'rgba(255, 204, 0, 0.1)';
    }
    else {
      return 'rgba(0,0, 0, 0.1)';
    }

  }

  onFilterChange() {
    this.applyFilter();
  }

  loadCertificateReportData() {
    this.store.dispatch(new LoadCertificateReport());
  }
}
