import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { RouteParamsResolverService } from './core/services/route-params-resolver.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OfflineDownloadsOverviewComponent } from './side-navigation/offline-downloads-overview/offline-downloads-overview.component';
import { MsalGuard } from '@azure/msal-angular';
import { HeaderComponent } from "./header";
import { HealthComponent } from './health/health.component';
import { ReportsComponent } from "./reports/reports.component";


const routes: Routes = [
  {
    path: 'health',
    component: HealthComponent
  },
  {
    path: 'callback',
    component: HeaderComponent
  },
  {
    path: '',
    canActivate: [MsalGuard],
    component: DashboardComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [MsalGuard],
    component: DashboardComponent,
    data: {
      title: 'Dashboard'
    }
  },
  {
    path: 'offline',
    canActivate: [MsalGuard],
    component: DashboardComponent,
    data: {
      title: 'Offline'
    }
  },
  {
    path: 'downloads',
    canActivate: [MsalGuard],
    component: OfflineDownloadsOverviewComponent,
    data: {
      title: 'Downloads'
    }
  },
  {
    path: 'reports',
    canActivate: [MsalGuard],
    component: ReportsComponent,
    data: {
      title: 'Downloads'
    }
  },
  {
    path: 'cp',
    canActivate: [MsalGuard],
    loadChildren: () => import('./commissioning-package/commissioning-package.module').then(m => m.CommissioningPackageModule)
  },
  {
    path: 'cp/:id',
    loadChildren: () => import('./cp-overview/cp-overview.module').then(m => m.CpOverviewModule),
    resolve: {
      cp: RouteParamsResolverService
    }
  },
  {
    path: 'cp/:id/mcp/:mcpId',
    loadChildren: () => import('./mc-overview/mc-overview.module').then(m => m.McOverviewModule),
    resolve: {
      cp: RouteParamsResolverService
    }
  },
  {
    path: 'cp/:id/checksheet/:csId',
    loadChildren: () => import('./check-sheet/check-sheet.module').then(m => m.CheckSheetModule),
    resolve: {
      cs: RouteParamsResolverService
    },
    data: {
      title: 'CP Checksheet'
    }
  },
  {
    path: 'cp/:id/mcp/:mcpId/checksheet/:csId',
    loadChildren: () => import('./check-sheet/check-sheet.module').then(m => m.CheckSheetModule),
    resolve: {
      cs: RouteParamsResolverService
    },
    data: {
      title: 'MCP Checksheet'
    }
  },
  {
    path: 'cp/:id/certificates',
    loadChildren: () => import('./certificates/certificates.module').then(m => m.CertificatesModule),
    resolve: {
      cs: RouteParamsResolverService
    }
  },
  {
    path: 'cp/:id/handover-docs',
    loadChildren: () => import('./handover-docs/handover-docs.module').then(m => m.HandoverDocsModule),
    resolve: {
      cs: RouteParamsResolverService
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
    }),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
}) export class AppRoutingModule {
}
