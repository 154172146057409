import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { User } from '@completion/models';
import { getCurrentUser } from '@completion/selectors';
import {MatDialog} from "@angular/material/dialog";
import {UserPreferenceDialogComponent} from "../user-preference-dialog/user-preference-dialog.component";

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit, AfterViewChecked {
  public user: User;
  public usersFirstLetter: string;
  public isOpen = false;

  constructor(private store: Store, private changeRef: ChangeDetectorRef, private msalService: MsalService, private dialog: MatDialog) { }
  ngAfterViewChecked(): void {
    this.changeRef.detectChanges();
  }

  ngOnInit(): void {
    this.store.select(getCurrentUser).subscribe((user: User) => {
      this.user = user;
    });
  }

  clickOnUserInfo(event): void {
    event.stopPropagation();
  }

  signOut(): void {
    this.msalService.logout();
  }

  openUserPreference(): void {
    this.dialog.open(UserPreferenceDialogComponent, {
      width: '700px' // Adjust the width as needed
    });
  }
}
