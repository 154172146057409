import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinct, filter, takeUntil } from 'rxjs/operators';

import { BreadCrumb, CheckSheetAssignment, CommissioningPackage, McPackage } from '@completion/models';
import { State } from '@completion/reducers';
import { getCurrentCp, getCurrentCsa, getCurrentMcp, getCurrentMcpId } from '@completion/selectors';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() breadcrumbs?: Observable<BreadCrumb[]>;
  @Input() isParentIncluded = true;
  breadCrumbList: BreadCrumb[] = [];
  cp$: Observable<CommissioningPackage>;
  mcp$: Observable<McPackage>;
  mcpId: number;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly store: Store<State>) {
    this.cp$ = this.store.select(getCurrentCp);
    this.mcp$ = this.store.select(getCurrentMcp);
    this.store
    .select(getCurrentMcpId)
    .pipe(takeUntil(this.destroy$))
    .subscribe(mcpId => {
      this.mcpId = mcpId;
    });
  }

  ngOnInit() {
    this.cp$
    .pipe(
      filter(cp => !!cp),
      distinct(cp => cp.cpNumber),
      takeUntil(this.destroy$)
    )
    .subscribe(cp => {
      this.breadCrumbList = [];
      if (this.isParentIncluded) {
        this.breadCrumbList.push(this.getObjectValue(cp.task));
        this.breadCrumbList.push(this.getObjectValue(cp.system));
        this.breadCrumbList.push(this.getObjectValue(cp.subSystem));
        const cpBreadCrumb: BreadCrumb = {
          number: cp.cpNumber,
          name: cp.description,
          url: `/cp/${cp.id}`
        };
        this.breadCrumbList.push(cpBreadCrumb);
      }
      if (this.mcpId && this.isParentIncluded) {
        this.mcp$
        .pipe(
          filter(mcp => !!mcp),
          distinct(mcp => mcp.mcpNumber),
          takeUntil(this.destroy$)
        )
        .subscribe(mcp => {
          const mcpBreadCrumb: BreadCrumb = {
            number: mcp.mcpNumber,
            name: mcp.description,
            url: `/cp/${cp.id}/mcp/${mcp.id}`
          };
          this.breadCrumbList.push(mcpBreadCrumb);
          this.buildChildBreadCrumbs();
        });
      } else {
        this.buildChildBreadCrumbs();
      }
    });
    this.store
    .pipe(
      select(getCurrentCsa),
      takeUntil(this.destroy$)
    )
    .subscribe((currentCsa: CheckSheetAssignment) => {
      if (currentCsa) {
        // If this.breadCrumbList does not have current csa
        const csa = {...currentCsa};
        const tagBreadCrumb: BreadCrumb = {
          number: csa.tag.tagNumber,
          name: csa.tag.name
        };
        const csCrumb: BreadCrumb = {
          number: csa.checkSheet.csNumber,
          name: csa.checkSheet.name
        };

        const foundCsCrumb = this.breadCrumbList.find(element => element.name === csCrumb.name && element.number === csCrumb.number);
        if (foundCsCrumb == null) {
          this.breadCrumbList.push(csCrumb);
        }
        const foundTagBreadCrumb = this.breadCrumbList.find(element => element.name === csCrumb.name && element.number === csCrumb.number);
        if (foundTagBreadCrumb == null) {
          this.breadCrumbList.push(tagBreadCrumb);
        }
      }
    });
  }

  getObjectValue(object: any): BreadCrumb {
    if (object) {
      return {
        number: object.number,
        name: object.name
      };
    } else {
      return {
        number: 'object.number',
        name: 'object.name'
      };
    }
  }

  buildChildBreadCrumbs() {
    if (this.breadcrumbs) {
      this.breadcrumbs
      .pipe(
        filter(bc => !!bc),
        distinct(bc => bc),
        takeUntil(this.destroy$)
      )
      .subscribe(bc => {
        bc.forEach(bcValue => {
          this.breadCrumbList.push(bcValue);
        });
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
