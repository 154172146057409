import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DossierState } from '../reducers/dossier-status';

const getDossierFeatureState = createFeatureSelector<DossierState>('dossier');

export const getDossierUrl = createSelector(
  getDossierFeatureState,
  (state: DossierState) => state.dossierUrl
);

export const getPreviewUrl = createSelector(
  getDossierFeatureState,
  (state: DossierState) => state.previewUrl
);

export const getDossierStatus = createSelector(
  getDossierFeatureState,
  (state: DossierState) => state.dossierStatus
);

export const getPreviewDossierStatus = createSelector(
  getDossierFeatureState,
  (state: DossierState) => state.dossierPreviewStatus
);
