<mat-tab-group>
  <mat-tab label="Regular Signature Matrix Templates">
    <div *ngFor="let property of getSignatureTypes(); let idx = index">
      <app-signature-matrix-template-list [signatureType]="property" [idx]="idx"></app-signature-matrix-template-list>
    </div>
  </mat-tab>
  <mat-tab label="Company Templates">
    <div *ngFor="let property of getCompanySignatureTypes(); let idx = index">
      <app-signature-matrix-template-list [signatureType]="SignatureType.COMPANY" [customTypeName]="property" [customType]="true" [idx]="idx"></app-signature-matrix-template-list>
    </div>
  </mat-tab>
  <mat-tab label="Check Sheet Templates">
    <div *ngFor="let property of getCheckSheetSignatureTypes(); let idx = index">
      <app-signature-matrix-template-list [signatureType]="SignatureType.CHECK_SHEET" [customTypeName]="property" [customType]="true" [idx]="idx"></app-signature-matrix-template-list>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="signature-role-container">
  <app-signature-role></app-signature-role>
</div>
