import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ResourceType } from '@completion/enums';
import { CheckListItem, CheckRecordValue, CheckSheetAssignment, McPackage, MoveCheckSheetAssignement } from '@completion/models';
import { getSignVerifyValue } from '@completion/utils';
import { BaseService, Resource } from './base.service';
import { SignatureMatrixState } from '../store/reducers/signature-matrix';

@Injectable({
  providedIn: 'root'
})
export class McpService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.GetCheckedTags)
  getCheckedTags(projectId: number, mcpId: number): Observable<CheckSheetAssignment[]> {
    return this.http.get<CheckSheetAssignment[]>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags`);
  }

  @Resource(ResourceType.GetCheckSheetAssignment)
  getCheckSheetAssignment(projectId: number, mcpId: number, csaId: number): Observable<CheckSheetAssignment> {
    return this.http.get<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}`);
  }

  @Resource(ResourceType.GetMcp)
  getMcp(projectId: number, mcpId: number): Observable<McPackage> {
    return this.http.get<McPackage>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}`);
  }

  @Resource(ResourceType.UpdateMcp)
  updateMcp(projectId: number, updatedMcp: McPackage): Observable<McPackage> {
    return this.http.put<McPackage>(`${this.baseUrl}/projects/${projectId}/mcps/${updatedMcp.id}`, updatedMcp);
  }

  @Resource(ResourceType.AddCheckedTags)
  addCheckedTags(projectId: number, mcpId: number, checkSheetAssignment: CheckSheetAssignment[]) {
    return this.http.post(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags`, checkSheetAssignment, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.UpdateCheckListItem)
  updateCheckListItem(projectId: number, mcpId: number, csaId: number, changedItem: CheckListItem): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/csItems/${changedItem.number}`,
      changedItem
    );
  }

  @Resource(ResourceType.UpdateCheckSheetAssignment)
  updateCheckSheetAssignment(
    projectId: number,
    mcpId: number,
    assignment: CheckSheetAssignment,
    doSignOrVerify?: string
  ): Observable<CheckSheetAssignment> {
    const value = getSignVerifyValue(doSignOrVerify, assignment);
    const params = doSignOrVerify ? { params: new HttpParams().set(doSignOrVerify, value.toString()) } : {};

    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${assignment.id}`,
      assignment,
      params
    );
  }

  @Resource(ResourceType.UpdateRecordValue)
  updateMcpRecordValue(
    projectId: number,
    mcpId: number,
    csaId: number,
    csiNum: number,
    recordValue: CheckRecordValue
  ): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/csItems/${csiNum}` +
      `/recordValues/${recordValue.sequenceNumber}`,
      recordValue
    );
  }

  @Resource(ResourceType.AddRecordValue)
  addMcpRecordValue(
    projectId: number,
    mcpId: number,
    csaId: number,
    csiNum: number,
    recordValue: CheckRecordValue
  ): Observable<CheckSheetAssignment> {
    return this.http.post<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/csItems/${csiNum}` +
      `/recordValues/${recordValue.sequenceNumber}`,
      recordValue
    );
  }

  @Resource(ResourceType.DeleteRecordValue)
  deleteMcpRecordValue(
    projectId: number,
    mcpId: number,
    csaId: number,
    csiNum: number,
    recordValue: CheckRecordValue
  ): Observable<CheckSheetAssignment> {
    return this.http.delete<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/csItems/${csiNum}` +
      `/recordValues/${recordValue.id}`
    );
  }

  @Resource(ResourceType.DuplicateTags)
  duplicateTags(projectId: number, mcpId: number, checkSheetAssignment: CheckSheetAssignment[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/duplicateTags`, checkSheetAssignment, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.MoveCheckSheetAssignment)
  moveTags(projectId: number, mcpId: number, csas: MoveCheckSheetAssignement): Observable<HttpResponse<object>> {
    return this.http.post(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/moveTags`, csas, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.DeleteCheckSheetAssignment)
  deleteMcpCheckSheetAssignment(projectId: number, mcpId: number, ids: number[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags`, options);
  }

  @Resource(ResourceType.FetchSignaturesFromHistory)
  fetchMcpSignaturesFromHistory(projectId: number, mcpId: number, csaId: number): Observable<CheckSheetAssignment> {
    return this.http.get<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/signatures`);
  }

  @Resource(ResourceType.FetchMcItemStatusesFromHistory)
  fetchMcItemStatusesFromHistory(projectId: number, mcpId: number, csaId: number): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/recoverItemStatuses`,null);
  }

  @Resource(ResourceType.SignMcpCsaSigntureEntry)
  signMcpCsaSigntureEntry(projectId: number, mcpId: number, csaId: number, signatureEntryId: number): Observable<CheckSheetAssignment> {
    return this.http.put<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/signatures/${signatureEntryId}`,
      null
    );
  }

  @Resource(ResourceType.InsertAndSignMcpCsaSigntureEntry)
  insertAndSignMcpCsaSigntureEntry(
    projectId: number,
    mcpId: number,
    csaId: number,
    sign: boolean,
    entry: SignatureMatrixState[]
  ): Observable<CheckSheetAssignment> {
    return this.http.post<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/signatures/add/${sign ? 'sign' : 'unsign'}`,
      entry
    );
  }

  @Resource(ResourceType.UploadMcpCsaFile)
  uploadMcpCsaFile(projectId: number,mcpId:number, csaId: number, file: File): Observable<CheckSheetAssignment> {
    const formData = new FormData();
    formData.append('attachmentFiles', file, file.name);

    return this.http.post<CheckSheetAssignment>(`${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/files`, formData);
  }

  @Resource(ResourceType.DeleteMcpCsaAttachment)
  deleteMcpCsaAttachment(projectId: number, mcpId: number, csaId: number, attachmentId: number): Observable<CheckSheetAssignment> {
    return this.http.delete<CheckSheetAssignment>(
      `${this.baseUrl}/projects/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/files/${attachmentId}`
    );
  }

}

