import { Action } from '@ngrx/store';

import { Cppl, Cpsi, Mcpl, Mcsi } from '@completion/models';

export enum HandoverDocsActionTypes {
  CpplLoad = 'Request CPPL Load',
  CpplLoadSuccess = 'Handover CPPL Load Success',

  McsiLoad = 'Request MCSI Load',
  McsiLoadSuccess = 'Handover MCSI Load Success',

  CpsiLoad = 'Request CPSI Load',
  CpsiLoadSuccess = 'Handover CPSI Load Success',

  McplLoad = 'Request MCPL Load',
  McplLoadSuccess = 'Handover MCPL Load Success'
}

export class CpplLoad implements Action {
  readonly type = HandoverDocsActionTypes.CpplLoad;
  constructor(public handoverId: string) { }
}

export class CpplLoadSuccess implements Action {
  readonly type = HandoverDocsActionTypes.CpplLoadSuccess;
  constructor(public cppl: Cppl) { }
}

export class McsiLoad implements Action {
  readonly type = HandoverDocsActionTypes.McsiLoad;
  constructor(public handoverId: string, public mccId: string) { }
}

export class McsiLoadSuccess implements Action {
  readonly type = HandoverDocsActionTypes.McsiLoadSuccess;
  constructor(public mcsi: Mcsi) { }
}

export class CpsiLoad implements Action {
  readonly type = HandoverDocsActionTypes.CpsiLoad;
  constructor(public handoverId: string) { }
}

export class CpsiLoadSuccess implements Action {
  readonly type = HandoverDocsActionTypes.CpsiLoadSuccess;
  constructor(public cpsi: Cpsi) { }
}

export class McplLoad implements Action {
  readonly type = HandoverDocsActionTypes.McplLoad;
  constructor(public handoverId: string, public mccId: string) { }
}

export class McplLoadSuccess implements Action {
  readonly type = HandoverDocsActionTypes.McplLoadSuccess;
  constructor(public mcpl: Mcpl) { }
}

export type HandoverDocsAction =
  | CpplLoad
  | CpplLoadSuccess
  | McsiLoad
  | McsiLoadSuccess
  | CpsiLoad
  | CpsiLoadSuccess
  | McplLoad
  | McplLoadSuccess;
