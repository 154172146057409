<h2>
  {{ getTitle() }}
  <span
    >( {{ countPuncesIncollection() }} <span *ngIf="filter.length > 0"> filtered</span> ,
    <span class="category-download" (click)="download('pa')"> PA: {{ countPAInCollection() }}, </span>
    <span class="category-download" (click)="download('pb')">PB: {{ countPBInCollection() }} </span>
    )</span
  >
</h2>
<div *ngIf="displayFilter()">
  <mat-form-field class="filter-form-field" appearance="fill">
    <mat-label>Search</mat-label>
    <input matInput [(ngModel)]="filter" (keyup)="applyFilter()" type="text" name="filter" />
    <button *ngIf="filter.length > 0" matSuffix mat-icon-button aria-label="Clear" color="primary" (click)="clearFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button mat-icon-button (click)="downloadSelection()" class="download-button"><mat-icon>download</mat-icon></button>
</div>
<div *ngIf="loading; else loaded">
  <app-spinner> </app-spinner>
</div>
<ng-template #loaded>
  <div class="punch-list">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z2">
      <ng-container matColumnDef="punchNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#No</th>
        <td mat-cell *matCellDef="let punch">#{{ punch.punchNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell class="description" *matCellDef="let punch">{{ getDescriptionString(punch) }}</td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
        <td mat-cell class="assigned-to-company" *matCellDef="let punch">
          <button
            *ngIf="punch.assignedCompany && displayFilter()"
            class="copy-button"
            mat-icon-button
            (click)="copyToFilter($event, punch.assignedCompany?.compNumber)"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
          {{ punch.assignedCompany ? punch.assignedCompany.compNumber : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="phase" *ngIf="displayPhase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase</th>
        <td mat-cell *matCellDef="let punch">{{ punch.relatedPhase }}</td>
      </ng-container>
      <ng-container matColumnDef="assigned-user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Responsible</th>
        <td mat-cell *matCellDef="let punch">
          <button
            *ngIf="punch.username && displayFilter()"
            class="copy-button"
            mat-icon-button
            (click)="copyToFilter($event, punch.assignedUser?.username)"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
          {{ punch.responsible ? punch.assignedUser.username : '' }}
        </td>
        </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let punch">
          <app-status-circle [status]="punch.category"></app-status-circle>
        </td>
      </ng-container>
      <ng-container matColumnDef="attachment">
        <th mat-header-cell *matHeaderCellDef><mat-icon>attach_file</mat-icon></th>
        <td mat-cell *matCellDef="let punch">
          {{ punch.attachments?.length }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CommPkg</th>
        <td mat-cell *matCellDef="let punch">
          <button
            *ngIf="punch.relatedTag.commissioningPackage && displayFilter()"
            class="copy-button"
            mat-icon-button
            (click)="copyToFilter($event, punch.relatedTag.commissioningPackage?.cpNumber)"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
          {{ punch.relatedTag.commissioningPackage?.cpNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="mcp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>McPackage</th>
        <td mat-cell *matCellDef="let punch">
          <button
            *ngIf="punch.relatedTag.mcPackage && displayFilter()"
            class="copy-button"
            mat-icon-button
            (click)="copyToFilter($event, punch.relatedTag.mcPackage?.mcpNumber)"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
          {{ punch.relatedTag.mcPackage?.mcpNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
        <td mat-cell *matCellDef="let punch">
          <button
            *ngIf="punch.relatedTag.tagNumber && displayFilter()"
            class="copy-button"
            mat-icon-button
            (click)="copyToFilter($event, punch.relatedTag.tagNumber)"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
          {{ punch.relatedTag.tagNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Progress</th>
        <td mat-cell *matCellDef="let punch">
          <div *ngIf="!punch.clearedBy && !punch.acceptedBy">
            <div class="status-indicator-bar status-entered"><div>&nbsp;</div></div>
            ENTERED
          </div>
          <div *ngIf="punch.clearedBy && !punch.acceptedBy">
            <div class="status-indicator-bar status-cleared"><div>&nbsp;</div></div>
            CLEARED
          </div>
        </td>
      </ng-container>
      <tr mat-header-row cdkMonitorElementFocus *matHeaderRowDef="displayedColumns"></tr>
      <tr class="punch-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="goToPunch(row)"></tr>
    </table>

    <div class="empty-list">
      <p *ngIf="countPuncesIncollection() === 0">{{ getEmptyMessage() }}</p>
    </div>
  </div>
</ng-template>
