import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OfflineDataService } from '@completion/services';
import { OfflineDownloadScopeCp, OfflineDownloadScopeMcp } from '@completion/models';

import { OfflineScopesLoad, OfflineScopesRelease } from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentProject, getOfflineDownloads } from '@completion/selectors';
import { animationExpandContent } from '../../shared/animations';
import { OfflineDownloadsOverviewComponent } from '../offline-downloads-overview/offline-downloads-overview.component';

@Component({
  selector: 'app-offline-downloads',
  templateUrl: './offline-downloads.component.html',
  styleUrls: ['./offline-downloads.component.scss'],
  animations: [animationExpandContent]
})
export class OfflineDownloadsComponent implements OnInit, OnDestroy {
  activeTabIndex: number;
  isMcPkg = false;
  displayedColumns: string[] = ['tagNo', 'checkSheetNo', 'mcPkg', 'phase', 'company', 'signed', 'verified', 'releaseScope'];
  scopes: (OfflineDownloadScopeCp | OfflineDownloadScopeMcp)[] = [];
  openPackages: boolean[] = [];
  toggleTagName = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly overview: OfflineDownloadsOverviewComponent,
    private readonly offlineDataService: OfflineDataService,
    private readonly store: Store<State>
  ) {
    this.activeTabIndex = overview.activeTabIndex;
    this.store
      .select(getOfflineDownloads)
      .pipe(takeUntil(this.destroy$))
      .subscribe(offlineDownloads => {
        this.scopes = offlineDownloads.cps;
        if (this.activeTabIndex === 1) {
          this.scopes = offlineDownloads.mcps;
          this.displayedColumns = this.displayedColumns.filter(value => value !== 'mcPkg');
        }
        this.openPackages = new Array(this.scopes.length).fill(true);
      });
  }

  ngOnInit(): void {
    this.store
      .select(getCurrentProject)
      .pipe(takeUntil(this.destroy$))
      .subscribe(project => {
        if (project) {
          this.store.dispatch(new OfflineScopesLoad());
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Input()
  set mcPackage(isMcp: boolean) {
    this.isMcPkg = isMcp;
  }

  get mcPackage() {
    return this.isMcPkg;
  }

  handleToggle(index: number) {
    this.openPackages[index] = !this.openPackages[index];
  }

  handleToggleAll(): void {
    const openAll = this.openPackages.some(this.isOpen);
    this.openPackages = new Array(this.scopes.length).fill(openAll);
  }

  isOpen(pkgOpen: boolean): boolean {
    return !pkgOpen;
  }

  toggleTag(toggle: boolean): void {
    this.toggleTagName = toggle;
  }

  loadCheckSheetAssignment(cpId: number, mcpId: number, csaId: number) {
    if (this.mcPackage) {
      return `/cp/${cpId}/mcp/${mcpId}/checksheet/${csaId}`;
    }
    return `/cp/${cpId}/checksheet/${csaId}`;
  }

  releaseScope(cpId: number, mcpId: number, csaId: number) {
    return new Promise(resolve => {
      resolve(
        this.offlineDataService
          .deleteScope(csaId)
          .catch(reason => {
            console.log(`Unable to delete scope with CP: ${cpId}, MCP: ${mcpId}, CSA: ${csaId} from local storage.\n${reason}`);
            alert(`Unable to delete scope from local storage (CSA = ${csaId}).`);
          })
          .finally(() => {
            const csaIds = [];
            csaIds.push(csaId);
            if (this.mcPackage) {
              this.store.dispatch(new OfflineScopesRelease(mcpId, 'mcp', csaIds));
            } else {
              this.store.dispatch(new OfflineScopesRelease(cpId, 'cp', csaIds));
            }
          })
      );
    });
  }

  async releaseAllScopes(pkgId: number) {
    const csas = this.scopes
      .reduce((previous, current) => previous.concat(current.assignments), [])
      .filter(assignment => (this.mcPackage ? assignment.mcpId === pkgId : assignment.cpId === pkgId));

    for (const csa of csas) {
      await this.releaseScope(csa.cpId, csa.mcpId, csa.id);
    }
  }
}
