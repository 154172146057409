import { Action } from '@ngrx/store';

import { User } from '@completion/models';

export enum UserActionTypes {
  CurrentUserLoad = 'User Load',
  CurrentUserLoadSuccess = 'User Load Success',
  ProjectUsers = 'Project Users',
  ProjectUsersSuccess = 'Project Users Success',
  ClearProjectUsers = 'Clear Project Users',
  CompanyUsers = 'Company Users',
  CompanyUsersSuccess = 'Company Users Success'
}

export class CurrentUserLoad implements Action {
  readonly type = UserActionTypes.CurrentUserLoad;
}

export class CurrentUserLoadSuccess implements Action {
  readonly type = UserActionTypes.CurrentUserLoadSuccess;

  constructor(public currentUser: User) {}
}

export class ProjectUsers implements Action {
  readonly type = UserActionTypes.ProjectUsers;
}

export class ProjectUsersSuccess implements Action {
  readonly type = UserActionTypes.ProjectUsersSuccess;

  constructor(public users: User[]) {}
}

export class CompanyUsers implements Action {
  readonly type = UserActionTypes.CompanyUsers;

  constructor(public companyId: number) {}
}

export class CompanyUsersSuccess implements Action {
  readonly type = UserActionTypes.CompanyUsersSuccess;

  constructor(public users: User[]) {}
}

export class ClearProjectUsers implements Action {
  readonly type = UserActionTypes.ClearProjectUsers;
}

export type UserActions =
  | CurrentUserLoad
  | CurrentUserLoadSuccess
  | ProjectUsers
  | ProjectUsersSuccess
  | ClearProjectUsers
  | CompanyUsers
  | CompanyUsersSuccess;
