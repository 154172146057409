import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() diameter = 100;
  wrapperPadding: number;

  ngOnInit() {
    this.wrapperPadding = Math.ceil((this.diameter * Math.sqrt(2) - this.diameter) / 2);
  }
}
