import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PackageCommonState } from '../reducers/package-common';

const getPackageCommonFeatureState = createFeatureSelector<PackageCommonState>('packageCommon');

export const getCpScopeOfWork = createSelector(
  getPackageCommonFeatureState,
  (state: PackageCommonState) => state.cpScopeOfWork
);

export const getMcpScopeOfWork = createSelector(
  getPackageCommonFeatureState,
  (state: PackageCommonState) => state.mcpScopeOfWork
);

export const getSelectedCheckSheetAssignments = createSelector(
  getPackageCommonFeatureState,
  (state: PackageCommonState) => state.checkSheetAssignments.selectedIds
);

export const getCheckSheetAssignmentsResponseStatus = createSelector(
  getPackageCommonFeatureState,
  (state: PackageCommonState) => state.checkSheetAssignments.responseStatus
);

export const getCheckSheetAssignmentsErrors = createSelector(
  getPackageCommonFeatureState,
  (state: PackageCommonState) => state.checkSheetAssignments.duplicationErrors
);

export const getPackageCommonLoadFailure = createSelector(
  getPackageCommonFeatureState,
  (state: PackageCommonState) => state.latestError
);
