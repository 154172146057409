import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { UserAssignment } from '@completion/models';

@Component({
  selector: 'app-user-with-date',
  templateUrl: './user-with-date.component.html',
  styleUrls: ['./user-with-date.component.scss']
})
export class UserWithDateComponent implements OnChanges {

  @Input() label: string;
  @Input() required: boolean;
  @Input() assignmentCandidates: UserAssignment[] = [];
  @Input() control: AbstractControl;

  filteredOptions: Observable<UserAssignment[]>;

  get selectedAssignmentDate(): Date {
    const assignment = this.getSelectedAssignment();
    return assignment ? assignment.timestamp : null;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.assignmentCandidates && this.assignmentCandidates) {
      this.filteredOptions = this.control.valueChanges
        .pipe(
          startWith(''),
          map(value => value instanceof UserAssignment ? value.user.userName : value),
          map(name => this.filterOptions(name))
        );
    }
  }

  displayAssignment(assignment: UserAssignment): string {
    return assignment && assignment.user ? assignment.user.userName : '';
  }

  private getSelectedAssignment(): UserAssignment {
    const assignment = this.control.value;
    return assignment instanceof UserAssignment ? assignment : null;
  }

  private filterOptions(value: string): UserAssignment[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.assignmentCandidates.filter(assignment => assignment.user.userName.toLowerCase().includes(filterValue));
  }
}
