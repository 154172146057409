import { Action } from '@ngrx/store';

import { Delivery } from '@completion/models';

export enum DeliveryActionTypes {
  DeliveriesLoad = 'Deliveries Load',
  DeliveriesLoadSuccess = 'Deliveries Load Success'
}

export class DeliveriesLoad implements Action {
  readonly type = DeliveryActionTypes.DeliveriesLoad;
}

export class DeliveriesLoadSuccess implements Action {
  readonly type = DeliveryActionTypes.DeliveriesLoadSuccess;

  constructor(public deliveries: Delivery[]) {}
}

export type DeliveryAction = DeliveriesLoad | DeliveriesLoadSuccess;
