import update from 'immutability-helper';

import { HandoverAction, HandoverActionTypes } from '@completion/actions';
import { HandoverPart, HandoverSection, HandoverSectionTemplate } from '@completion/models';

// The BE sends a spurious "ordinal" value that is unnecessary/confusing, so we remove it here
function stripOrdinal(section): HandoverSection | HandoverSectionTemplate {
  const cleanSection = { ...section };
  delete cleanSection.ordinal;
  return cleanSection;
}

function getHandoverSectionAndRowIndices(state: HandoverState, handoverId: number, sectionId: number, rowId: number) {
  let handoverIdx = null;
  let sectionIdx = null;
  let rowIdx = null;

  const foundHandoverIdx = state.parts.findIndex(h => h.id === handoverId);

  if (foundHandoverIdx !== -1) {
    handoverIdx = foundHandoverIdx;
    const foundSectionIdx = state.parts[handoverIdx].sections.findIndex(s => s.id === sectionId);

    if (foundSectionIdx !== -1) {
      sectionIdx = foundSectionIdx;
      const foundRowIdx = state.parts[handoverIdx].sections[sectionIdx].rows.findIndex(r => r.id === rowId);

      if (foundRowIdx !== -1) {
        rowIdx = foundRowIdx;
      }
    }
  }

  return { handoverIdx, sectionIdx, rowIdx };
}

export const initialState: HandoverState = {
  selectedHandoverPart: null,
  parts: [],
  sectionTemplates: []
};

export interface HandoverState {
  selectedHandoverPart: number;
  parts: HandoverPart[];
  sectionTemplates: HandoverSectionTemplate[];
}

export function handoverReducer(state: HandoverState = initialState, action: HandoverAction): HandoverState {
  switch (action.type) {
    case HandoverActionTypes.HandoverLoadSuccess: {
      const handoverParts = [...action.handoverParts].map(part => ({
        ...part,
        sections: part.sections
      })) as HandoverPart[];

      // Handover only has partNumber if there are multiple parts
      // but for FE its easier to work with it if we always have a part number
      if (handoverParts.length === 1) {
        handoverParts[0].partNumber = 1;
      }

      return {
        ...state,
        parts: [...handoverParts],
        selectedHandoverPart: state.selectedHandoverPart || 1
      };
    }

    case HandoverActionTypes.HandoverUnload:
      return initialState;

    case HandoverActionTypes.HandoverPatch:
      const handoverUpdatedParts = { ...state.parts.find(part => part.partNumber === state.selectedHandoverPart), ...action.data };
      const index = state.parts.findIndex(part => part.partNumber === state.selectedHandoverPart);
      return {
        ...state,
        parts: Object.assign([...state.parts], { [index]: handoverUpdatedParts })
      };

    case HandoverActionTypes.HandoverSectionTemplatesLoadSuccess:
    case HandoverActionTypes.HandoverCreateSectionSuccess:
    case HandoverActionTypes.HandoverUpdateSectionsSuccess: {
      const strippedSectionTemplates = action.sections as HandoverSectionTemplate[];

      return {
        ...state,
        sectionTemplates: strippedSectionTemplates
      };
    }

    case HandoverActionTypes.HandoverUpdateRowSuccess: {
      const { handoverId, sectionId, rowId } = action.update;
      const { handoverIdx, sectionIdx, rowIdx } = getHandoverSectionAndRowIndices(state, handoverId, sectionId, rowId);

      if (handoverIdx === null || sectionIdx === null || rowIdx === null) {
        return state;
      }

      return update(state, {
        parts: { [handoverIdx]: { sections: { [sectionIdx]: { rows: { [rowIdx]: { $set: action.update.row } } } } } }
      });
    }

    case HandoverActionTypes.HandoverChangeSelectedPart:
      return {
        ...state,
        selectedHandoverPart: action.handoverPart
      };

    default:
      return state;
  }
}
