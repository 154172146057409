<ng-container [ngSwitch]="status">
  <div class="loading" data-testid="resource-loading" *ngSwitchCase="ResourceStatus.InProgress">
    <app-spinner></app-spinner>
  </div>

  <div class="error" data-testid="resource-error" *ngSwitchCase="ResourceStatus.Failure">
    <app-error-message [errorMessage]="error"></app-error-message>
  </div>

  <div data-testid="resource-success" *ngSwitchCase="ResourceStatus.Success">
    <ng-container *ngIf="hasData; else noResults" [ngTemplateOutlet]="templateRef"></ng-container>
  </div>
</ng-container>

<ng-template #noResults>
  <div data-testid="no-results" class="no-results">
    <img src="assets/img/empty-list.svg" alt="No results" />
    <div class="message">
      <ng-container *ngIf="customNoDataComponent; else defaultNoResults" [ngTemplateOutlet]="customNoDataComponent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #defaultNoResults>
  <span>No results found</span>
</ng-template>
