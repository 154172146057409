import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from "@angular/material/menu";

import { ProjectSetupModule } from '../project/project-setup.module';
import { ContextToolbarComponent } from './context-toolbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { SearchResultDialogComponent } from './search-result-dialog/search-result-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { StatusCircleModule } from '../shared/status-circle/status-circle.module';

@NgModule({
  declarations: [ContextToolbarComponent, SearchResultDialogComponent],
  imports:
    [CommonModule,
      StatusCircleModule,
      MatIconModule,
      MatButtonModule,
      MatMenuModule,
      ProjectSetupModule,
      MatFormFieldModule,
      MatInputModule,
      FormsModule,
      MatButtonModule,
      MatIconModule,
      MatListModule,
      MatDialogModule],
  exports: [ContextToolbarComponent]
})
export class ContextToolbarModule { }
