import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Punch} from '../models/punch';
import {Store} from '@ngrx/store';
import {first, Subject, takeUntil} from 'rxjs';
import {
  getCurrentTask,
  getProjectTreeNodes,
  getResourceState,
  getUnacceptedPunches,
  getUserPunches
} from '@completion/selectors';
import {PunchCreateEditMode} from '../enums/punch-edit-mode';
import {MatDialog} from '@angular/material/dialog';
import {PunchDialogComponent} from '../punch-dialog/punch-dialog.component';
import {LoadUserPunches} from '@completion/actions';
import {compare} from '../utils/sorting';
import {ResourceType} from '../enums/resource-type';
import {ResourceStatus} from '../enums/resource-status';
import {Task} from '../models/task';
import {ExcelService} from '@completion/services';
import {ProjectConfigService} from "../../core/services/project-config.service";
import {ProjectConfigs} from "@completion/enums";



@Component({
  selector: 'app-user-punch',
  templateUrl: './user-punch.component.html',
  styleUrls: ['./user-punch.component.scss']
})
export class UserPunchComponent implements OnInit {
  displayedColumns: string[] = ['punchNo', 'cp', 'mcp', 'tag', 'description','company', 'category', 'attachment', 'status'];
  dataSource$: Subject<MatTableDataSource<Punch>> = new Subject();
  dataSource: MatTableDataSource<Punch>;
  editMode: PunchCreateEditMode = PunchCreateEditMode.EditPunch;
  filteredData: Punch[] = [];
  unsortedData: Punch[] = [];
  filter = new Map<string, Array<string>>();
  filterString:string = ""
  loading = false;
  currentTask: Task = null;
  displayPhase = false;



  @Input() listType = ''; // 'unaccepted' | 'assigned

  private readonly destroy$ = new Subject<boolean>();
  constructor(private readonly store: Store, private readonly dialog: MatDialog, private readonly excelService: ExcelService, private config:ProjectConfigService) {
    this.config.isInitialized.subscribe((value) => {
      if(value){
        this.displayPhase = this.config.getConfig(ProjectConfigs.DISPLAY_PHASE_ON_OPEN_PUNCHES_TABLE);
        if(this.displayPhase){
          this.displayedColumns.splice(5, 0, 'phase');
        }
      }
    });
  }

  ngOnInit(): void {

    this.dataSource$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.dataSource = data;
    });
    if (this.listType === 'unaccepted') {
      this.store.select(getResourceState, ResourceType.LoadUnacceptedPunches).pipe(takeUntil(this.destroy$)).subscribe(state => {
        this.loading = state.status === ResourceStatus.InProgress
      });

      this.store.select(getCurrentTask).pipe(takeUntil(this.destroy$)).subscribe(task => {
        if (task) {
          this.currentTask = task;
          this.applyFilter();
        }
        else {

          this.currentTask = null;
          this.applyFilter();
        }
      });

      this.store.select(getUnacceptedPunches).pipe(takeUntil(this.destroy$)).subscribe(punches => {
        this.dataSource$.next(punches ? new MatTableDataSource(punches) : null);
        this.unsortedData = [...punches];
        this.filteredData = [...punches];
      });
    }
    else {
      this.store.select(getUserPunches).pipe(takeUntil(this.destroy$)).subscribe(punches => {
        this.dataSource$.next(punches ? new MatTableDataSource(punches) : null);
        this.unsortedData = [...punches];
        this.filteredData = [...punches];
      });
    }
  }

  goToPunch(punch: Punch): void {
    const dialog = this.dialog.open(PunchDialogComponent, { data: { punch: { ...punch }, editMode: this.editMode } });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        this.store.dispatch(new LoadUserPunches);
      });
  }

  getTitle(): string {
    return this.listType === 'unaccepted' ? 'Open Punches' : 'My assigned Punches';
  }

  getEmptyMessage(): string {
    return this.listType === 'unaccepted' ? 'No unaccepted punches' : 'No assigned punches';
  }

  displayFilter(): boolean {
    return this.listType === 'unaccepted';
  }

  sortData(sort: any) {
    const data = this.unsortedData.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource$.next(new MatTableDataSource(this.applyTaskFilter(this.filteredData)));
      return;
    }

    let tempData = this.filteredData.sort((a, b) => {
      switch (sort.active) {
        case 'cp':
          return compare(a.relatedTag.commissioningPackage.cpNumber, b.relatedTag.commissioningPackage.cpNumber, sort.direction === 'asc');
        case 'mcp':
          return compare(a.relatedTag.mcPackage.mcpNumber, b.relatedTag.mcPackage.mcpNumber, sort.direction === 'asc');
        case 'tag':
          return compare(a.relatedTag.tagNumber, b.relatedTag.tagNumber, sort.direction === 'asc');
        case 'category':
          return compare(a.category, b.category, sort.direction === 'asc');
        case 'status':
          return compare(a.clearedBy ? 'cleared' : 'notCleared', b.clearedBy ? 'cleared' : 'notCleared', sort.direction === 'asc');
        case 'company':
          return compare(a.assignedCompany.compNumber, b.assignedCompany.compNumber, sort.direction === 'asc');
        case 'phase':
          return compare(a.relatedPhase, b.relatedPhase, sort.direction === 'asc');
        default:
          return 0;
      }
    });
    this.dataSource$.next(new MatTableDataSource(tempData));
  }

  applyFilter(): void {
    let filteredData = this.unsortedData.filter((punch) => {

      if (this.filter.size === 0 && this.filterString.trim() !== '') {
        // Perform free text search
        const searchText = this.filterString.toLowerCase();
        return Object.values(punch).some(value =>
          value && value.toString().toLowerCase().includes(searchText)
        )
          || punch.relatedTag?.commissioningPackage?.cpNumber.toLowerCase().includes(searchText)
          || punch.relatedTag?.mcPackage?.mcpNumber.toLowerCase().includes(searchText)
          || punch.relatedTag?.tagNumber.toLowerCase().includes(searchText)
          || punch.category?.toLowerCase().includes(searchText)
          || punch.assignedCompany?.compNumber?.toLowerCase().includes(searchText)
          || punch.relatedPhase?.toUpperCase().includes(searchText)
          || punch.enteredBy?.user?.name?.toLowerCase().includes(searchText);

      } else {
        return Array.from(this.filter.entries()).every(([key, values]) => {
          switch (key) {
            case 'description':
              return values.some(value => punch.description.toLowerCase().includes(value.toLowerCase()));
            case 'cp':
              return values.some(value => punch.relatedTag.commissioningPackage?.cpNumber.toLowerCase().includes(value.toLowerCase()));
            case 'mcp':
              return values.some(value => punch.relatedTag.mcPackage?.mcpNumber.toLowerCase().includes(value.toLowerCase()));
            case 'tag':
              return values.some(value => punch.relatedTag.tagNumber.toLowerCase().includes(value.toLowerCase()));
            case 'category':
              return values.some(value => punch.category.toLowerCase().includes(value.toLowerCase()));
            case 'company':
              return values.some(value => punch.assignedCompany.compNumber.toLowerCase().includes(value.toLowerCase()));
            case 'phase':
              return values.some(value => punch.relatedPhase.toUpperCase().includes(value.toUpperCase()));
            default:
              return true;
          }
        });
      }
    });
    this.filteredData = this.applyTaskFilter(filteredData);
    this.dataSource$.next(new MatTableDataSource(this.filteredData));
  }

  clearFilter(): void {
    this.filter.clear();
    this.filterString = '';
    this.dataSource$.next(new MatTableDataSource(this.applyTaskFilter(this.unsortedData)));
  }

  copyToFilter(event: any, filterString: any, filterKey): void {
    event.stopPropagation();
    this.filter.set(filterKey,[filterString]);
    this.filterString = this.serializeFilter();
    this.applyFilter();
  }

  serializeFilter(): string {
    let serializedFilter = '';
    this.filter.forEach((values, key) => {
      serializedFilter += `${key}:${values.join(',')}; `;
    });
    return serializedFilter.slice(0, -2); // Remove the trailing semicolon and space
  }

  deserializeFilter(): void {
    const regex = /(\w+):([^;]+)(;|$)/g;
    let match;
    while ((match = regex.exec(this.filterString)) !== null) {
      const key = match[1];
      const values = match[2].split(',');
      console.log(this.filterString);
      this.filter.set(key, values);
    }
    this.applyFilter();
  }

  countPuncesIncollection(): number {
    if (!this.dataSource) {
      return 0;
    }
    return this.dataSource.data.length;
  }

  countPAInCollection(): number {
    if (!this.dataSource) {
      return 0;
    }
    return this.dataSource.data.filter(punch => punch.category === 'PA').length;
  }

  countPBInCollection(): number {
    if (!this.dataSource) {
      return 0;
    }
    return this.dataSource.data.filter(punch => punch.category === 'PB').length;
  }

  download(filterString: string): void {
    let excelData: any[] = this.dataSource.data.filter(p => p.category.toLowerCase() === filterString.toLowerCase()).map(punch => {
      return {
        PunchNo: punch.punchNumber,
        CP: punch.relatedTag.commissioningPackage.cpNumber,
        MCP: punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.mcpNumber : '',
        Tag: punch.relatedTag.tagNumber,
        Description: punch.description,
        Category: punch.category,
        AssignedTo: punch.assignedUser ? punch.assignedUser.name : '',
        EnteredBy: punch.enteredBy ? punch.enteredBy.user.name : '',
        ClearedBy: punch.clearedBy ? punch.clearedBy.user.name : '',

      };
    });
    let date = Date.now();
    this.excelService.generateExcel(excelData, 'punches_' + filterString + '_' + date.toString());
  }

  applyTaskFilter(data: Punch[]): Punch[] {
    if (!this.currentTask) {
      return data;
    }
    let cpNumbers: string[];
    this.store.select(getProjectTreeNodes).subscribe((data) => {
      cpNumbers = data
        .filter(node => node.number === this.currentTask.number)
        .flatMap(node => node.children)
        .flatMap(node => node.children)
        .flatMap(node => node.children).map(node => node.number);

    });
    return data.filter(punch => {
      return cpNumbers.includes(punch.relatedTag?.commissioningPackage?.cpNumber);
    });
  }

  downloadSelection(): void {
    let excelData: any[] = this.dataSource.data.map(punch => {
      return {
        PunchNo: punch.punchNumber,
        CP: punch.relatedTag.commissioningPackage.cpNumber,
        MCP: punch.relatedTag.mcPackage ? punch.relatedTag.mcPackage.mcpNumber : '',
        Tag: punch.relatedTag.tagNumber,
        CheckSheet: punch.relatedCheckSheet ? punch.relatedCheckSheet.name : '',
        Item: punch.relatedItem ? punch.relatedItem.name : '',
        Description: punch.description,
        Notes: punch.notes ? punch.notes : '',
        DueDate: punch.due ? punch.due : '',
        Category: punch.category,
        RaisedBy: punch.raisedBy ? punch.raisedBy.name : '',
        Company: punch.assignedCompany ? punch.assignedCompany.compNumber : '',
        AssignedTo: punch.assignedUser ? punch.assignedUser.name : '',
        EnteredBy: punch.enteredBy ? punch.enteredBy.user.name : '',
        EnteredByDate: punch.enteredBy ? punch.enteredBy.timestamp : '',
        ClearedBy: punch.clearedBy ? punch.clearedBy.user.name : '',
        ClearedByDate: punch.clearedBy ? punch.clearedBy.timestamp : '',
      };
    });

    this.excelService.generateExcel(excelData, 'punches' + Date.now().toString());
  }

  getDescriptionString(punch: Punch): string {
    return punch.description.length > 20 ? punch.description.substring(0, 20) + '...' : punch.description;
  }

  getPunchNumner(punch) {
    return punch.sequenceNumber ? punch.sequenceNumber : punch.punchNumber;
  }
}

