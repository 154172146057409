import { Action } from '@ngrx/store';

import { CheckSheetAssignment, McPackage, MoveCheckSheetAssignement, SignatureState } from '@completion/models';








export enum McpActionTypes {
  CurrentMcp = 'Current Mcp',
  ClearCurrentMcp = 'Clear Current Mcp',
  ChangeCurrentMcpId = 'Change Current MCP Id',
  LoadCurrentMcp = 'Load Current MCP',
  LoadCurrentMcpSuccess = 'Load Current MCP Success',
  McpUpdate = 'Mcp Update',
  McpUpdateFailure = 'Mcp Update Failure',
  McpAddCheckedTag = 'Mcp Add CheckedTag',
  ClearMCPError = 'Clear MCP Error',
  McpDeleteCSA = 'Mcp Delete CSA',
  McpMoveCSA = 'Mcp Move CSA',
  SignMcpCsaSigntureEntry = "SignMcpCsaSigntureEntry",
  InsertAndSignMcpCsaSigntureEntry = "InsertAndSignMcpCsaSigntureEntry"
}

export class LoadCurrentMcp implements Action {
  readonly type = McpActionTypes.LoadCurrentMcp;
}

export class ChangeCurrentMcpId implements Action {
  readonly type = McpActionTypes.ChangeCurrentMcpId;

  constructor(public mcpId: number) { }
}

export class LoadCurrentMcpSuccess implements Action {
  readonly type = McpActionTypes.LoadCurrentMcpSuccess;

  constructor(public mcp: McPackage) { }
}

export class ClearCurrentMcp implements Action {
  readonly type = McpActionTypes.ClearCurrentMcp;
}

export class ClearMCPError implements Action {
  readonly type = McpActionTypes.ClearMCPError;
}

export class McpUpdate implements Action {
  readonly type = McpActionTypes.McpUpdate;

  constructor(public mcp: McPackage) { }
}

export class McpUpdateFailure implements Action {
  readonly type = McpActionTypes.McpUpdateFailure;

  constructor(public errorMessage: string) { }
}

export class McpAddCheckedTag implements Action {
  readonly type = McpActionTypes.McpAddCheckedTag;

  constructor(public checkSheetAssignment: CheckSheetAssignment[]) { }
}

export class McpDeleteCSA implements Action {
  readonly type = McpActionTypes.McpDeleteCSA;

  constructor(public ids: number[]) { }
}

export class McpMoveCSA implements Action {
  readonly type = McpActionTypes.McpMoveCSA;

  constructor(public csas: MoveCheckSheetAssignement) { }
}

export class SignMcpCsaSigntureEntry implements Action {
  readonly type = McpActionTypes.SignMcpCsaSigntureEntry;
  constructor(public entry: SignatureState) {
  }
}

export class InsertAndSignMcpCsaSigntureEntry implements Action {
  readonly type = McpActionTypes.InsertAndSignMcpCsaSigntureEntry;
  constructor(public entry: SignatureState) {
  }
}

export type McpAction =
  | ClearCurrentMcp
  | LoadCurrentMcp
  | LoadCurrentMcpSuccess
  | ChangeCurrentMcpId
  | McpUpdate
  | McpUpdateFailure
  | McpAddCheckedTag
  | ClearMCPError
  | McpDeleteCSA
  | McpMoveCSA
  | SignMcpCsaSigntureEntry
  | InsertAndSignMcpCsaSigntureEntry;
