import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule, Routes } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { FeatureFlagGuard } from '../core/services/feature-flag.guard';
import { CommitButtonModule } from '../shared/commit-button/commit-button.module';
import { ErrorMessageModule } from '../shared/error-message/error-message.module';
import { FileUploaderModule } from '../shared/file-uploader/file-uploader.module';
import { SpinnerModule } from '../shared/spinner/spinner.module';
import { ProjectTemplateComponent } from './setup-page/project-template/project-template.component';
import { SetupPageComponent } from './setup-page/setup-page.component';
import { UserEditDialogComponent } from './setup-page/user-admin/user-edit-dialog/user-edit-dialog.component';
import { UserListComponent } from './setup-page/user-admin/user-list/user-list.component';
import { ProjectConfigComponent } from './setup-page/project-config/project-config.component';
import { DataDialogComponent } from './setup-page/data-dialog/data-dialog.component';
import { SignatureMatrixTemplateComponent } from './setup-page/signature-matrix-template/signature-matrix-template.component';
import { SignatureMatrixTemplateListComponent } from './setup-page/signature-matrix-template/signature-matrix-template-list/signature-matrix-template-list.component';
import { MatrixEditDialogComponent } from './setup-page/signature-matrix-template/matrix-template-edit-dialog/matrix-template-edit-dialog.component';
import { SignatureRoleComponent } from './setup-page/signature-matrix-template/signature-role/signature-role.component';
import { SignatureRoleEditDialogComponent } from './setup-page/signature-matrix-template/signature-role-edit-dialog/signature-role-edit-dialog.component';


export const routes: Routes = [
  {
    path: 'project-setup',
    component: SetupPageComponent,
    data: {
      title: 'Project Setup'
    },
    canActivate: [FeatureFlagGuard]
  }
];

@NgModule({
  declarations: [SetupPageComponent, ProjectTemplateComponent, UserListComponent, UserEditDialogComponent, ProjectConfigComponent, DataDialogComponent, SignatureMatrixTemplateComponent, SignatureMatrixTemplateListComponent, MatrixEditDialogComponent, SignatureRoleComponent, SignatureRoleEditDialogComponent],
  imports: [
    CommonModule,
    CommitButtonModule,
    DragDropModule,
    FormsModule,
    ErrorMessageModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatInputModule,
    SpinnerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    FileUploaderModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})
export class ProjectSetupModule { }
