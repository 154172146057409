import {OptionsActions, OptionsActionTypes, ResetAction, ResetActionTypes} from '@completion/actions';
import {
  byCsNumber,
  byPhaseNumber,
  bySiteNumber,
  CheckSheet, CheckSheetAssignment,
  Company, MCCDetails, MCCertificate,
  Phase, RFCCDetails, RFCCertificate, RFOCDetails,
  RFOCertificate,
  SignatureRole,
  Site
} from '@completion/models';
import { byCompanyNumber } from '../../../../shared/models/company';

export interface ResetState {
  rfoc: RFOCDetails[];
  rfcc: RFCCDetails[];
  mcc: MCCDetails[];
  cpCsa: CheckSheetAssignment[];
  mcCsa: CheckSheetAssignment[];
}

export const initialState: ResetState = {
  rfoc: [],
  rfcc: [],
  mcc: [],
  cpCsa: [],
  mcCsa: [],
};

export function resetReducer(state: ResetState = initialState, action: ResetAction): ResetState {
  switch (action.type) {
    case ResetActionTypes.LoadRfocSuccess:
      return {
        ...state,
        rfoc: [...action.rfoc]
      };
    case ResetActionTypes.LoadRfccSuccess:
      return {
        ...state,
        rfcc: [...action.rfcc]
      };
case ResetActionTypes.LoadMccSuccess:
      return {
        ...state,
        mcc: [...action.mcc]
      };
case ResetActionTypes.LoadCpCsasSuccess:
      return {
        ...state,
        cpCsa: [...action.cpCsa]
      };
case ResetActionTypes.LoadMcCsasSuccess:
      return {
        ...state,
        mcCsa: [...action.mcCsa]
      };
    default:
      return state;
  }
}
