import { NamedEntity } from './named-entity';

export interface System extends NamedEntity {
  id: number;
  number: string;
}

export interface SubSystem extends System {
  parentId: number;
}
