<div class="grey-title">
  <span>&nbsp;</span>
  <h2 mat-dialog-title>Edit Signature Matrix</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSave()" id="ngForm" autocomplete="off">
    <div>
      <div class="cell user-name">Signature Type: {{ signatureMatrixTemplate.signatureType }}</div>
    </div>
    <div class="table">
      <div class="row">
        <div class="cell">
          <mat-form-field appearance="fill" class="no-validation ordinal">
            <mat-label>Ordinal:</mat-label>
            <input matInput formControlName="ordinal" type="number" name="ordinal" [(ngModel)]="signatureMatrixTemplate.ordinal" />
          </mat-form-field>
        </div>
        <div class="cell">
          <mat-form-field appearance="fill" class="no-validation role">
            <mat-label>Role:</mat-label>
            <input matInput formControlName="role" type="text" name="role" [(ngModel)]="signatureMatrixTemplate.role" />
          </mat-form-field>
        </div>
        <div class="cell">
          <mat-form-field appearance="fill" class="no-validation verified">
            <mat-label>Verified:</mat-label>
            <input matInput formControlName="verified" type="text" name="verified" [(ngModel)]="signatureMatrixTemplate.verified" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="cell"></div>
        <div class="cell site-name">
          <mat-form-field appearance="fill" class="no-validation">
            <mat-label>Signee:</mat-label>
            <mat-select formControlName="signee" name="signee" [(value)]="signatureMatrixTemplate.signee" [compareWith]="hasSameIds">
              <mat-option *ngFor="let signee of signees$ | async" [value]="signee">{{ signee.userName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="cell company-name">
          <mat-form-field appearance="fill" class="no-validation">
            <mat-label>Signature Role:</mat-label>
            <mat-select
              formControlName="signatureRole"
              name="signatureRole"
              [(value)]="signatureMatrixTemplate.signatureRole"
              [compareWith]="hasSameIds"
            >
              <mat-option *ngFor="let signatureRole of signatureRoles$ | async" [value]="signatureRole">{{
                signatureRole.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="error-message">
    <app-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-error-message>
  </div>

  <div>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <app-commit-button
      [isRoundButton]="true"
      [buttonStatus]="buttonStatus"
      buttonTestId="userEditDialogSave"
      (commitButtonClicked)="onSave()"
      idleLabel="Save"
    ></app-commit-button>
  </div>
</mat-dialog-actions>
