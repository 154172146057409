import {formatDate} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatRadioChange} from "@angular/material/radio";
import {MatSelectChange} from "@angular/material/select";
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';

import {
  ClearError,
  ClearPunch,
  ClearSelectedAttachment,
  CompanyLoad,
  CompanyUsers,
  CpScopeOfWork,
  CreateCPPunch,
  CreateMCPPunch,
  DeleteAttachment,
  DeleteAttachmentSuccess,
  DeletePunch,
  LoadCPPunches,
  LoadCurrentCp,
  LoadCurrentCpCsa,
  LoadCurrentMcp,
  LoadCurrentMcpCsa,
  LoadMCPPunches,
  LoadSelectedAttachment,
  McpScopeOfWork,
  ResourceCancel,
  ResourceReset,
  UpdatePunch,
  UploadAttachments
} from '@completion/actions';
import {ButtonStatus, CompanyCategory, HttpStatus, ResourceStatus, ResourceType} from '@completion/enums';
import {
  Attachment,
  BreadCrumb,
  CommissioningPackage,
  Company,
  Confirmation,
  McPackage,
  Punch,
  User,
  UserAssignment
} from '@completion/models';
import {State} from '@completion/reducers';
import {
  getCompanies,
  getCompanyUser,
  getCurrentCp,
  getCurrentCpId,
  getCurrentMcp,
  getCurrentMcpId,
  getDeleteStatus,
  getError,
  getLatestAttachments,
  getLatestPunches,
  getOfflineState,
  getResourceState,
  getSelectedAttachment
} from '@completion/selectors';
import {OfflineDataService, ProjectAccessService} from '@completion/services';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {APP_DATE_FORMATS, AppDateAdapter} from '../date-format';
import {ApplicationMode} from '../enums/application-mode';
import {PunchCreateEditMode} from '../enums/punch-edit-mode';
import {Router} from '@angular/router';

@Component({
  selector: 'app-punch-dialog',
  templateUrl: './punch-dialog.component.html',
  styleUrls: ['./punch-dialog.component.scss'],
  providers: [{ provide: DateAdapter, useClass: AppDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }]
})
export class PunchDialogComponent implements OnInit, OnDestroy {
  package$: Observable<CommissioningPackage | McPackage>;
  breadcrumbs$: Observable<BreadCrumb[]>;
  companies$: Observable<Company[]>;
  errorMsg$: Observable<string>;
  assignedUsers: UserAssignment[] = [];
  editMode: PunchCreateEditMode;
  form: FormGroup;
  mcpId: number;
  showAlternativeContent: boolean;
  uploadedFiles: File[] = [];
  errorMsg: string;
  cameraMode = false;
  disabled = false;
  breadcrumbsParentInclude = false;
  buttonStatus = ButtonStatus.IDLE;
  applicationMode = ApplicationMode.ONLINE;

  private punchInternal: Punch;
  private readonly subscription = new Subscription();
  private readonly destroy$ = new Subject<boolean>();
  public readonly hasSameIds: ((o1: any, o2: any) => boolean) | null = (o1, o2) => o1 && o2 && o1.id === o2.id;

  get punch() {
    return this.punchInternal;
  }

  set punch(value: Punch) {
    this.punchInternal = { ...value };
    this.populateFormGroup();
    if (this.punch.assignedCompany) {
      this.loadUsers(this.punch.assignedCompany.id);
    }
    this.store.dispatch(new ClearPunch());
    this.store.dispatch(new ClearError());
  }

  constructor(
    private readonly store: Store<State>,
    private readonly fb: FormBuilder,
    private readonly offlineDataService: OfflineDataService,
    private readonly projectAccessService: ProjectAccessService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PunchDialogComponent>,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) readonly data = null
  ) {
    this.punch = this.data.punch;
    this.editMode = this.data.editMode;
    this.loadCPAndMCP();
    this.loadCompanies();

    if (this.editMode === PunchCreateEditMode.Create) {
      this.store
        .pipe(
          select(getResourceState, ResourceType.CreateCpPunch),
          takeUntil(this.destroy$)
        )
        .subscribe(state => {
          if (state.status === ResourceStatus.InProgress) {
            this.buttonStatus = ButtonStatus.IN_PROGRESS;
          }

          if (state.status === ResourceStatus.Failure) {
            this.buttonStatus = ButtonStatus.IDLE;
          }

          this.errorMsg = state.lastError;

          if (state.status === ResourceStatus.Success) {
            this.buttonStatus = ButtonStatus.COMPLETED;
          }
        });
    }
    else {
      this.store
        .pipe(
          select(getResourceState, ResourceType.UpdatePunch),
          takeUntil(this.destroy$)
        )
        .subscribe(state => {
          if (state.status === ResourceStatus.InProgress) {
            this.buttonStatus = ButtonStatus.IN_PROGRESS;
          }

          if (state.status === ResourceStatus.Failure) {
            this.buttonStatus = ButtonStatus.IDLE;
          }

          this.errorMsg = state.lastError;

          if (state.status === ResourceStatus.Success) {
            if (this.buttonStatus === ButtonStatus.IN_PROGRESS) {
              this.buttonStatus = ButtonStatus.COMPLETED;
            }
            else {
              this.buttonStatus = ButtonStatus.IDLE;
            }
          }
        });
    }

    this.store.pipe(
      select(getResourceState, ResourceType.AdminRevokeAccepted),
      takeUntil(this.destroy$))
      .subscribe(state => {
        let path = '/dashboard';
        if (state.status === ResourceStatus.Success) {
          this.store.select(getCurrentCp).subscribe(cp => {
            if (cp) {
              path = '/cps/' + cp.id;
            }
          });
          this.store.dispatch(new ResourceReset(ResourceType.AdminRevokeAccepted));
          this.router.navigateByUrl(path);
          this.dialogRef.close();
        }
      });

    this.store.pipe(
      select(getOfflineState),
      takeUntil(this.destroy$))
      .subscribe(mode => this.applicationMode = mode);
  }

  ngOnInit(): void {
    this.breadcrumbs$ = !!this.punch
      ? of([
        {
          number: this.punch.relatedTag.tagNumber,
          name: this.punch.relatedTag.name
        },
        {
          number: this.punch.relatedCheckSheet.csNumber,
          name: this.punch.relatedCheckSheet.name
        },
        {
          number: 'Check Sheet Item ' + Math.round(this.punch.relatedItem.number),
          name: this.punch.relatedItem.name
        }
      ])
      : of([]);

    const cp$ = this.store.select(getCurrentCp);
    const mcp$ = this.store.select(getCurrentMcp);

    this.package$ = combineLatest([cp$, mcp$]).pipe(
      map(([cp, mcp]) => {
        if (cp || mcp) {
          return !!mcp ? mcp : cp;
        }
      })
    );
    this.form.valueChanges.subscribe((_) => this.buttonStatus = ButtonStatus.IDLE);

    this.errorMsg$ = this.store.pipe(select(getError));

    this.store.pipe(
      select(getResourceState, ResourceType.AdminRevokeAccepted),
      takeUntil(this.destroy$))
      .subscribe(state => {
        if (state.status === ResourceStatus.Success) {
          let path = '/dashboard';
          this.store.select(getCurrentCp).subscribe(cp => {
            if (cp) {
              path = '/cp/' + cp.id;
            }
          });
          this.router.navigateByUrl(path);
          this.dialogRef.close();
        }
      });
  }

  displayPunchActions(): boolean {
    return this.editMode === PunchCreateEditMode.EditPunch;
  }
  displayNotesField(): boolean {
    return this.editMode === PunchCreateEditMode.EditPunch || this.editMode === PunchCreateEditMode.OfflineEditPunch;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
    this.store.dispatch(new ResourceCancel(ResourceType.DeleteAttachment));
  }

  removeAttachment(i: number, attachment: Attachment): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: this.confirmationMessage(attachment.name)
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const clonedAttachments = [...this.punch.attachments];
        clonedAttachments.splice(i, 1);
        this.punch = { ...this.punch, attachments: clonedAttachments };
        this.store.dispatch(new DeleteAttachment(this.punch.id, attachment.id));
        this.subscripeDeleteAttachementStatus(attachment.id);
      }
    });
  }

  isAdmin(): boolean {
    return this.projectAccessService.isAdmin();
  }

  subscripeDeleteAttachementStatus(attachmentId: number) {
    this.store
      .pipe(
        select(getResourceState, ResourceType.DeleteAttachment),
        takeUntil(this.destroy$)
      )
      .subscribe(state => {
        if (state.status === ResourceStatus.Success) {
          this.store.dispatch(new DeleteAttachmentSuccess(this.punch.id, attachmentId));
        }
      });
  }

  onChangeUploadedFiles(files: File[]): void {
    this.uploadedFiles = files;
  }

  changeCategory(event: MatRadioChange): void {
    this.punch.category = event.value;
  }

  assignedCompanyChange(event: MatSelectChange): void {
    this.form.controls.assignedUser.setValue(null);
    this.form.controls.assignedUser.enable();
    this.loadUsers(event.value.id);
  }

  onSave(): void {
    this.form.markAllAsTouched();
    this.errorMsg = null;
    this.store.dispatch(new ClearError());

    if (this.form.valid) {
      this.disabled = true;
      this.buttonStatus = ButtonStatus.IN_PROGRESS;
      const updatedPunch = this.getUpdatedPunch();
      if (this.editMode) {
        this.updatePunch(updatedPunch);
      } else {
        this.createPunch(updatedPunch);
      }

      this.subscription.add(
        this.store.pipe(select(getLatestPunches)).subscribe(punch => {
          if (punch) {
            this.uploadAttachments(punch.id);
            if (this.editMode) {
              this.loadPunches();
            } else {
              this.loadCurrentCSA();
            }
          } else {
            this.subscription.add(
              this.errorMsg$.subscribe(error => {
                this.disabled = false;
                this.errorMsg = error;
                this.buttonStatus = ButtonStatus.IDLE;
              })
            );
          }
        })
      );
      if (!this.errorMsg || this.errorMsg.length === 0) {
        this.buttonStatus = ButtonStatus.COMPLETED;
        setTimeout(() => this.buttonStatus = ButtonStatus.IDLE, 2000);
      }
    }
  }

  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: this.confirmationMessage(`Punch ${this.punch.punchNumber}`)
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletePunch();
      }
    });
  }

  private confirmationMessage(title: string): Confirmation {
    return {
      title: `Do you really want to delete ${title}?`,
      message: 'This is a permanent delete and you cannot revert it.',
      isCancelDisplay: true,
      confirmationTile: 'DELETE'
    };
  }

  openFile(attachment: Attachment): void {
    this.store.dispatch(new ClearSelectedAttachment());
    this.subscription.add(
      this.store
        .pipe(
          select(getSelectedAttachment),
          filter(file => file != null),
          take(1)
        )
        .subscribe(file => {
          window.open(URL.createObjectURL(file));
        })
    );
    this.store.dispatch(new LoadSelectedAttachment(this.punch.id, attachment.id));
  }

  private deletePunch(): void {
    this.errorMsg = null;

    this.store.dispatch(new DeletePunch(this.punch.id));

    this.subscription.add(
      this.store.pipe(select(getDeleteStatus)).subscribe(status => {
        if (status === HttpStatus.NO_CONTENT) {
          this.loadSOW();
          this.loadPunches();
          this.dialogRef.close();
        } else {
          this.subscription.add(
            this.errorMsg$.subscribe(error => {
              this.errorMsg = error;
            })
          );
        }
      })
    );
  }

  private async uploadAttachments(punchId: number): Promise<void> {
    if (this.uploadedFiles.length && punchId) {
      if (this.applicationMode === ApplicationMode.OFFLINE) {
        // we need to store the files when we have them.
        await this.offlineDataService.storeOfflineFiles(punchId, this.uploadedFiles);
      }
      this.store.dispatch(new UploadAttachments(punchId, this.uploadedFiles));
      this.subscription.add(
        this.store.pipe(select(getLatestAttachments)).subscribe(attachments => {
          if (attachments) {
            if (this.editMode === PunchCreateEditMode.EditPunch || this.editMode === PunchCreateEditMode.OfflineEditPunch) {
              this.loadPunchs();
            }
            this.loadSOW();
            this.dialogRef.close();
          }
        })
      );
    } else {
      this.loadSOW();
      this.dialogRef.close();
    }
  }

  private populateFormGroup(): void {
    this.form = this.fb.group({
      category: [this.punch.category],
      description: [{ value: this.punch.description, disabled: !!this.punch.acceptedBy }, Validators.required],
      raisedBy: [{ value: this.punch.raisedBy, disabled: !!this.punch.acceptedBy }, Validators.required],
      due: [
        {
          value: this.punch.due || null,
          disabled: !!this.punch.acceptedBy
        }
      ],
      assignedCompany: [{ value: this.punch.assignedCompany, disabled: !!this.punch.acceptedBy }, Validators.required],
      assignedUser: [
        {
          value: new UserAssignment(this.punch.assignedUser),
          disabled: !this.punch.assignedCompany || !!this.punch.acceptedBy
        }
      ],
      notes: [{ value: this.punch.notes, disabled: !!this.punch.acceptedBy }]
    });
  }

  private loadCPAndMCP(): void {
    this.subscription.add(
      this.store.select(getCurrentCpId).subscribe(id => {
        if (id) {
          this.store.dispatch(new LoadCurrentCp());
        }
      })
    );

    this.subscription.add(
      this.store.select(getCurrentMcpId).subscribe(id => {
        if (id) {
          this.mcpId = id;
          this.store.dispatch(new LoadCurrentMcp());
        }
      })
    );
  }

  private loadCompanies(): void {
    this.store.dispatch(new CompanyLoad(CompanyCategory.M));
    this.companies$ = this.store.select(getCompanies);
  }

  private loadUsers(companyId: number): void {
    this.store.dispatch(new CompanyUsers(companyId));
    this.subscription.add(
      this.store.pipe(select(getCompanyUser)).subscribe(users => {
        this.assignedUsers = users.map(user => new UserAssignment(user));
      })
    );
  }

  private getUpdatedPunch(): Punch {
    const updatedPunch: Punch = { ...this.punch, ...this.form.getRawValue() };
    this.updateAssignedUser(updatedPunch);
    if (this.form.controls.due.value) {
      updatedPunch.due = formatDate(this.form.controls.due.value, 'yyyy-MM-dd', 'en-US') as any;
    }
    return updatedPunch;
  }

  private updateAssignedUser(updatedPunch: Punch): void {
    const assignedUserValue = this.form.controls.assignedUser.value;
    if (!assignedUserValue) {
      updatedPunch.assignedUser = null;
      return;
    }
    updatedPunch.assignedUser = this.findUser(assignedUserValue);
  }

  private findUser(value: UserAssignment | string): User {
    if (typeof value === 'string') {
      const userName = value.trim();
      const userAssignment = this.assignedUsers.find(user => user.user.userName === userName);
      return userAssignment ? userAssignment.user : null;
    }
    return value.user;
  }

  private updatePunch(updatedPunch: Punch): void {
    this.store.dispatch(new UpdatePunch(updatedPunch));
  }

  private createPunch(newPunch: Punch): void {
    if (this.mcpId) {
      this.store.dispatch(new CreateMCPPunch(newPunch.relatedItem.number, newPunch));
    } else {
      this.store.dispatch(new CreateCPPunch(newPunch.relatedItem.number, newPunch));
    }
  }

  private loadPunchs(): void {
    if (this.mcpId) {
      this.store.dispatch(new LoadMCPPunches());
    } else {
      this.store.dispatch(new LoadCPPunches());
    }
  }

  private loadSOW(): void {
    if (this.mcpId) {
      this.store.dispatch(new McpScopeOfWork());
    } else {
      this.store.dispatch(new CpScopeOfWork());
    }
  }

  private loadCurrentCSA(): void {
    if (this.mcpId) {
      this.store.dispatch(new LoadCurrentMcpCsa());
    } else {
      this.store.dispatch(new LoadCurrentCpCsa());
    }
  }

  private loadPunches(): void {
    if (this.mcpId) {
      this.store.dispatch(new LoadMCPPunches());
    } else {
      this.store.dispatch(new LoadCPPunches());
    }
  }
}
