<div class="context-tools">
  <button mat-button *ngFor="let tool of tools$ | async" (click)="tool.clickHandler()" [disabled]="tool.disabled$ | async">
    {{ tool.caption }}
  </button>
</div>

<div class="global-tools">
  <button mat-button *ngIf="showTestingUtilities && isOnline" [matMenuTriggerFor]="menu" [class.active]="areTestingToolsActive">
    Testing Utilities
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="addAdminPermissions()" [class.active]="areTestingToolsActive">
      Browse with admin permissions
    </button>
  </mat-menu>

  <button *ngIf="isOnline && isAdmin" mat-button (click)="goToProjectSetup()">Setup</button>

  <div *ngIf="isOnline" class="search">
    <mat-form-field class="search-field">
      <mat-label>Search</mat-label>
      <input matInput type="text" [(ngModel)]="value" (keyup.enter)="search()" />

      <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</div>
