import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {ToastrService} from "ngx-toastr";
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { DeliveriesLoad, DeliveriesLoadSuccess, DeliveryActionTypes } from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentProject } from '@completion/selectors';
import { ProjectService } from '@completion/services';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class DeliveryEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly service: ProjectService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, service, store, toastr);
  }

  @Effect()
  loadDeliveries$: Observable<Action> = this.actions$.pipe(
    ofType<DeliveriesLoad>(DeliveryActionTypes.DeliveriesLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getDeliveries', DeliveriesLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );
}
