import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import { getCurrentProject, getProjects } from '../store/selectors/project.selector';
import { ProjectConfigs } from '@completion/enums';

@Injectable({
  providedIn: 'root'
})
export class ProjectConfigService implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<boolean>();
  private readonly projectConfig = new Map<ProjectConfigs, boolean>();
  public  isInitialized  = new BehaviorSubject(false)

  constructor(private readonly store: Store) {
    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(project => {
      if (project) {
        project.config.forEach(p => {
          if (!this.projectConfig.has(ProjectConfigs[p.name])) {
            this.projectConfig.set(ProjectConfigs[p.name], p.value === 'true');
          }
        });

        for (let config in ProjectConfigs) {
          if (!this.projectConfig.has(ProjectConfigs[config])) {
            this.projectConfig.set(ProjectConfigs[config], false);
          }
        }
        this.isInitialized.next(true)
      }
    });
  }
  ngOnInit(): void {

  }



  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  getConfig(config: ProjectConfigs): boolean {

    let value = this.projectConfig.get(config);
    return value ? value : false;
  }

}
