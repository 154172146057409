<mat-tab-group>
  <mat-tab label="CheckSheet Editor">
    <ng-template matTabContent>
      <div class="container">
        <h3>CheckSheet Editor</h3>
        <app-check-sheet-editor></app-check-sheet-editor>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Company Editor">
    <ng-template matTabContent>
      <div class="container">
        <h3>Company Editor</h3>
        <app-company-editor></app-company-editor>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
