import { CompletionStatus } from '@completion/enums';
import { CheckSheet } from './check-sheet';
import { Company } from './company';
import { Phase } from './phase';
import { Site } from './site';
import { Tag } from './tag';
import { UserAssignment } from './user-assignment';
import { SignatureMatrix } from './signature-matrix';
import {Attachment} from "./attachment";
import {MCCertificate, RFCCertificate, RFOCertificate} from "./certificate";

export class PunchSummary {
  constructor(public countA: number, public countB: number) { }
}

export class CheckRecordValue {
  id: number;
  sequenceNumber: number;
  name: string;
  value: string;
  createdInternal: boolean;
  valueReference: string;
  ok: boolean;
  comment: string;
  enteredBy: UserAssignment;
}

export class CheckListItem {
  name: string;
  number: number;
  status: CompletionStatus;
  recordValues?: CheckRecordValue[];
  punches?: PunchSummary;
  note?: string;
  id: number;
}

export class CheckSheetAssignment {
  constructor(tag: Tag, checkSheet: CheckSheet, site: Site, company: Company, phase: Phase) {
    this.id = null;
    this.tag = tag;
    this.status = CompletionStatus.OS;
    this.comments = null;
    this.checkSheet = checkSheet;
    this.csItems = null;
    this.site = site;
    this.company = company;
    this.phase = phase;
    this.signedBy = null;
    this.verifiedBy = null;
    this.useLegacySignature = false;
    this.mcCertificateIssued = false;
    this.mcCertificateSigned = false;
    this.mcCertificateAccepted = false;
    this.rfcCertificateSigned = false;
    this.rfcCertificateIssued = false;
    this.rfoCertificateSigned = false;
    this.rfoCertificateIssued = false;

  }

  id?: number;
  tag: Tag;
  status?: CompletionStatus;
  comments: string;
  checkSheet: CheckSheet;
  csItems: CheckListItem[];
  site: Site;
  company: Company;
  phase: Phase;
  offlineBy?: UserAssignment;
  signedBy?: UserAssignment;
  verifiedBy?: UserAssignment;
  certified: boolean;
  signatureMatrix?: SignatureMatrix;
  useLegacySignature: boolean;
  attachments?: Attachment[];
  mcCertificateSigned: boolean;
  mcCertificateIssued :boolean;
  mcCertificateAccepted: boolean;
  rfcCertificateSigned: boolean;
  rfcCertificateIssued: boolean;
  rfoCertificateSigned: boolean;
  rfoCertificateIssued: boolean;
}

export interface DuplicationErrors {
  message: string;
  unsuccessfulAssignments: CheckSheetAssignment[];
}

export interface MoveCheckSheetAssignement {
  tagIds: number[];
  cpId: number;
  mcpId?: number;
}

export interface MoveCSAData {
  ids: number[];
  isMcPkg: boolean;
  currentId: number;
  csasToBeMoved: CheckSheetAssignment[];
}
