import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { User } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.UserGetCurrentUser)
  public getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/users/current`);
  }

  @Resource(ResourceType.UserGetUsers)
  public getUsers(projectId: number, companyId?: number): Observable<User[]> {
    const params = companyId ? { params: new HttpParams().set('companyId', companyId.toString()) } : {};
    return this.http.get<User[]>(`${this.baseUrl}/projects/${projectId}/users`, params);
  }
}
