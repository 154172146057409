import { SiteAction, SiteActionTypes } from '@completion/actions';
import { Site } from '@completion/models';

export interface SiteState {
  currentSite: Site;
}

export const initialState: SiteState = {
  currentSite: null,
};

export function siteReducer(state: SiteState = initialState, action: SiteAction): SiteState {
  switch (action.type) {
    case SiteActionTypes.SetCurrentSite:
      return {
        ...state,
        currentSite: action.site
      };
    case SiteActionTypes.ClearCurrentSite:
      return {
        ...state,
        currentSite: null
      };
    default:
      return state;
  }
}
