import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import {
  AddSignatureMatrixTemplate,
  AddUnsignedSignatureMatrices, CertificateActionTypes,
  CertificateDetailsPatch,
  CertificateSignatureMatrixSave,
  DeleteSignatureMatrix,
  DeleteSignatureMatrixTemplate,
  FetchDefaultSignatureMatrices,
  FetchDefaultSignatureMatricesSuccess,
  HandoverPatch,
  HandoverSignatureMatrixSave,
  SignatureMatrixActionTypes, InsertAndSignSignatureMatrix,
  SigntureMatrixTemplateLoadSuccess, ToggleSignSignatureMatrix
} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentCpId, getCurrentProject } from '@completion/selectors';
import { SignatureMatrixService } from '@completion/services';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class SignatureMatrixEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly signatureMatrixService: SignatureMatrixService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, signatureMatrixService, store, toastr);
  }

  @Effect()
  certificateSaveSignatureMatrix$: Observable<Action> = this.actions$.pipe(
    ofType<CertificateSignatureMatrixSave>(SignatureMatrixActionTypes.CertificateSignatureMatrixSave),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('certificateSaveSignatureMatrix', CertificateDetailsPatch, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.certType,
      action.certId,
      action.matrix
    ])
  );

  @Effect()
  handoverSaveSignatureMatrix$: Observable<Action> = this.actions$.pipe(
    ofType<HandoverSignatureMatrixSave>(SignatureMatrixActionTypes.HandoverSignatureMatrixSave),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    filter(([_, currentProject, cpId]) => !!(currentProject && cpId)),
    this.fetchResource('handoverSaveSignatureMatrix', HandoverPatch, ([action, currentProject, cpId]) => [
      currentProject.id,
      cpId,
      action.handoverId,
      action.matrix
    ])
  );

  @Effect()
  fetchDefaultSignatureMatrices$: Observable<Action> = this.actions$.pipe(
    ofType<FetchDefaultSignatureMatrices>(SignatureMatrixActionTypes.FetchDefaultSignatureMatrices),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('fetchDefaultSignatureMatrices', FetchDefaultSignatureMatricesSuccess, ([_, currentProject]) => [currentProject.projectId]));

  @Effect()
  deleteSignatureMatrixTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteSignatureMatrixTemplate>(SignatureMatrixActionTypes.DeleteSignatureMatrixTemplate),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('deleteSignatureMatrixTemplate', SigntureMatrixTemplateLoadSuccess, ([action, currentProject]) => [currentProject.id, action.matrix.id])
  );

  @Effect()
  updateSignatureMatrixTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteSignatureMatrixTemplate>(SignatureMatrixActionTypes.UpdateSignatureMatrixTemplate),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('updateSignatureMatrixTemplate', SigntureMatrixTemplateLoadSuccess, ([action, currentProject]) => [currentProject.id, action.matrix])
  );

  @Effect()
  addSignatureMatrixTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<AddSignatureMatrixTemplate>(SignatureMatrixActionTypes.AddSignatureMatrixTemplate),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('addSignatureMatrixTemplate', SigntureMatrixTemplateLoadSuccess, ([action, currentProject]) => [currentProject.id, action.matrix])
  );

  @Effect()
  addUnsignedSignatureMatrices$: Observable<Action> = this.actions$.pipe(
    ofType<AddUnsignedSignatureMatrices>(SignatureMatrixActionTypes.AddUnsignedSignatureMatrices),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('addUnsignedSignatureMatrices', null, ([action, currentProject]) => [currentProject.id, action.matrix])
  );

  @Effect()
  saveSignatureMatrix$: Observable<Action> = this.actions$.pipe(
    ofType<AddUnsignedSignatureMatrices>(SignatureMatrixActionTypes.SaveSignatureMatrix),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('saveSignatureMatrix', null, ([action, currentProject]) => [currentProject.id, action.matrix])
  );

  @Effect()
  deleteSignatureMatrix$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteSignatureMatrix>(SignatureMatrixActionTypes.DeleteSignatureMatrix),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('deleteSignatureMatrix', null, ([action, currentProject]) => [currentProject.id, action.matrixId])
  );

  @Effect()
  insertAnSignCertificateSignatureMatrix$: Observable<Action> = this.actions$.pipe(
    ofType<InsertAndSignSignatureMatrix>(CertificateActionTypes.InsertAndSignSignatureMatrix),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('insertAndSignSignatureMatrix', null, ([action, currentProject]) => [currentProject.id, action.parentId, [action.entry]])
  );

  @Effect()
  toggleSignSignatureMatrix$: Observable<Action> = this.actions$.pipe(
    ofType<ToggleSignSignatureMatrix>(SignatureMatrixActionTypes.ToggleSignSignatureMatrix),
    withLatestFrom(this.store.select(getCurrentProject)),
    filter(([_, currentProject]) => !!currentProject),
    this.fetchResource('toggleSignSignatureMatrix', null, ([action, currentProject]) => [currentProject.id, action.matrixId])
  );

}
