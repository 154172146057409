import { ProjectAction, ProjectActionTypes, SignatureMatrixActionTypes } from '@completion/actions';
import { byName, Project, ProjectConfig, TreeNode, TreeNodeType } from '@completion/models';
import { PackageType } from '@completion/services';

export const initialState: ProjectState = {
  projects: [],
  currentProject: null,
  treeNodes: [],
  currentProjectConfigs: []
};

export interface ProjectState {
  projects: Project[];
  currentProject: Project;
  treeNodes: TreeNode[];
  currentProjectConfigs: ProjectConfig[];
}

export function projectReducer(state: ProjectState = initialState, action: ProjectAction): ProjectState {
  switch (action.type) {
    case ProjectActionTypes.ProjectsLoadSuccess:
      return {
        ...state,
        projects: [...action.projects].sort(byName)
      };
    case ProjectActionTypes.ChangeProject:
      return {
        ...state,
        currentProject: action.project,
        currentProjectConfigs: action.project.config
      };
    case ProjectActionTypes.ProjectTreeLoadSuccess:
      return {
        ...state,
        treeNodes: action.treeNodes
      };
    case ProjectActionTypes.ProjectConfigLoadSuccess:
      return {
        ...state,
        currentProjectConfigs: action.config
      }
    case ProjectActionTypes.SignatureMatrixTemplateLoadSuccess:
      let project = deepCopy(state.currentProject);
      project.signatureMatrixTemplates = action.signatureMatrices;
      return {
        ...state,
        currentProject: project
      }

    case ProjectActionTypes.ProjectTreeUpdateNode:
      let tree = deepCopy(state.treeNodes);
      let isDirty = false;
      tree.forEach(node => {
        let n = searchTreeByNo(node, action.update.packageNumber, action.update.packageType === PackageType.MCP ? TreeNodeType.MCP : TreeNodeType.CP);
        if (n && n.status !== action.update.status) {
          n.status = action.update.status;
          isDirty = true;
        }
      });
      if (!isDirty) {
        return {
          ...state,
          treeNodes: tree
        };
      }
    default:
      return state;
  }
}

export function searchTreeByNo(node: TreeNode, compare: string, nodeType: TreeNodeType): any {
  if (node.number === compare && node.nodeType === nodeType) {
    return node;
  } else if (node.children != null) {
    let result = null;
    for (let i = 0; result == null && i < node.children.length; i++) {
      result = searchTreeByNo(node.children[i], compare, nodeType);
    }
    return result;
  }
  return null;
}

export function deepCopy(obj): any {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}
