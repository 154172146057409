import { UserAssignment } from './user-assignment';

export interface OfflineDownloadScope {
  cps: OfflineDownloadScopeCp[];
  mcps: OfflineDownloadScopeMcp[];
}

export interface OfflineDownloadScopeCp {
  cpId: number;
  cpNumber: string;
  assignments: OfflineDownloadScopeItem[];
}

export interface OfflineDownloadScopeMcp {
  cpId: number;
  mcpId: number;
  mcpNumber: string;
  assignments: OfflineDownloadScopeItem[];
}

export interface OfflineDownloadScopeItem {
  id: number;
  cpId?: number;
  mcpId?: number;
  status: string;
  tagNumber: string;
  tagName: string;
  checkSheetNumber: string;
  phaseNumber: string;
  companyNumber: string;
  mcpNumber?: string;
  signedBy?: UserAssignment;
  verifiedBy?: UserAssignment;
}
