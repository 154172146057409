import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ResourceStatus, ResourceType } from '@completion/enums';
import { ResourceDescriptor, ResourceState } from '@completion/models';
import { getResourceName, initialState, ResourceMapState } from '../reducers/resource';

const getResourceFeatureState = createFeatureSelector<ResourceMapState>('resource');

export const getResourceState = createSelector(
  getResourceFeatureState,
  (state: ResourceMapState, resourceDescriptor: ResourceDescriptor | string): ResourceState => {
    const resourceName = getResourceName(resourceDescriptor);
    return state[resourceName] || initialState; // default to `initialState` since keys aren't pre-populated for resources
  }
);

function resourceHasStatus(state: ResourceMapState, resourceDescriptor: ResourceDescriptor | string, testStatus: ResourceStatus) {
  const resourceName = getResourceName(resourceDescriptor);

  return state[resourceName] && state[resourceName].status === testStatus;
}

export const getCompoundResourceStatus = createSelector(
  getResourceFeatureState,
  (state: ResourceMapState, resourceDescriptors: ResourceDescriptor[] | string[]): ResourceState => {
    // We need this filter to be able to feature flag resources
    const filteredResourceNames = [...resourceDescriptors].filter(descriptor => !!descriptor);

    if (filteredResourceNames.some(descriptor => resourceHasStatus(state, descriptor, ResourceStatus.InProgress))) {
      return {
        status: ResourceStatus.InProgress,
        lastError: ''
      };
    }

    if (filteredResourceNames.every(descriptor => resourceHasStatus(state, descriptor, ResourceStatus.Success))) {
      return {
        status: ResourceStatus.Success,
        lastError: ''
      };
    }

    const failedResource = filteredResourceNames.find(descriptor => resourceHasStatus(state, descriptor, ResourceStatus.Failure));

    if (failedResource !== undefined) {
      // A bit of a cop-out to get the lastError from the first failed resource
      // Should we concatenate the lastErrors from all failed resources instead?
      return state[getResourceName(failedResource)];
    }

    return {
      status: ResourceStatus.Idle,
      lastError: ''
    };
  }
);

/** Returns if any child of a ResourceType is loading  */
export const getResourceTypeChildInProgress = createSelector(
  getResourceFeatureState,
  (state: ResourceMapState, resourceDescriptor: ResourceType) => {
    const childResources = Object.entries(state)
      .filter(([key, _]) => key.startsWith(`${resourceDescriptor}/`))
      .map(([_, value]) => value);

    return childResources.some(s => s.status === ResourceStatus.InProgress);
  }
);
