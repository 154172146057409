import { OptionsActions, OptionsActionTypes } from '@completion/actions';
import { byCsNumber, byPhaseNumber, bySiteNumber, CheckSheet, Company, Phase, SignatureRole, Site } from '@completion/models';
import { byCompanyNumber } from '../../../../shared/models/company';

export interface OptionsState {
  sites: Site[];
  companies: Company[];
  phases: Phase[];
  checkSheets: CheckSheet[];
  signatureRoles: SignatureRole[];
}

export const initialState: OptionsState = {
  sites: [],
  companies: [],
  phases: [],
  checkSheets: [],
  signatureRoles: []
};

export function optionsReducer(state: OptionsState = initialState, action: OptionsActions): OptionsState {
  switch (action.type) {
    case OptionsActionTypes.SiteLoadSuccess:
      return {
        ...state,
        sites: [...action.sites].sort(bySiteNumber)
      };
    case OptionsActionTypes.CompanyLoadSuccess:
      return {
        ...state,
        companies: [...action.companies].sort(byCompanyNumber)
      };
    case OptionsActionTypes.PhaseLoadSuccess:
      return {
        ...state,
        phases: [...action.phases].sort(byPhaseNumber)
      };
    case OptionsActionTypes.CheckSheetLoadSuccess:
      return {
        ...state,
        checkSheets: [...action.checkSheets].sort(byCsNumber)
      };
    case OptionsActionTypes.SignatureRoleLoadSuccess:
      return {
        ...state,
        signatureRoles: [...action.signatureRoles]
      };
    default:
      return state;
  }
}
