import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { CurrentUserLoad, SetOffline } from '@completion/actions';
import { ResourceStatus, ResourceType } from '@completion/enums';
import { Confirmation, ProjectAccessRoles } from '@completion/models';
import { getCurrentProject, getResourceState, getRole } from '@completion/selectors';
import { getOfflineState } from '@completion/selectors';
import { OfflineDataService } from '@completion/services';
import { FeatureFlagService } from './core/services/feature-flag.service';
import { NetworkStatusService } from './core/services/network-status.service';
import { OfflineValidityService } from './core/services/offline-validity-service';
import { WorkBoxService } from './core/services/workbox.service';
import * as projectAction from './core/store/actions/project.action';
import { ProjectState } from './core/store/reducers/project';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { ApplicationMode } from './shared/enums/application-mode';
import { OfflineDialogComponent } from './shared/offline-dialog/offline-dialog.component';
import { env } from 'src/environments/env';
import { Subject, filter, first, map, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Completion';
  private readonly destroy$ = new Subject<void>();
  offlineMode = false;
  isAdmin = false;
  private timer: number = null;
  errorMessage: string = null;

  constructor(
    private readonly store: Store<ProjectState>,
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly domTitle: Title,
    private readonly workBoxService: WorkBoxService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly networkStatusService: NetworkStatusService,
    private readonly offlineDataService: OfflineDataService,
    private readonly offlineValidityService: OfflineValidityService,
    private readonly featureFlag: FeatureFlagService
  ) {

    this.workBoxService.setSnackBar(snackBar);
    this.title += ' - ' + env.get().name;

    this.updateDOMTitle();

    this.store.select(getRole).pipe(takeUntil(this.destroy$)).subscribe(role => {
      this.isAdmin = role === ProjectAccessRoles.ADMIN;
    });

    this.store
      .pipe(
        select(getOfflineState),
        takeUntil(this.destroy$)
      )
      .subscribe(mode => {
        this.offlineMode = mode === ApplicationMode.OFFLINE;
        if (this.offlineMode) {
          this.store.dispatch(new projectAction.ProjectsLoad());
          this.store.dispatch(new CurrentUserLoad());
          this
        }
      });

    this.offlineDataService.changeLogError.subscribe(e => {
      console.log('Error', e);
      this.displaySyncErrorDialog(e);
    });

    //this.store.dispatch(new CurrentUserLoad());

    this.store
      .pipe(
        select(getCurrentProject),
        takeUntil(this.destroy$)
      )
      .subscribe(project => {
        if (project && env.get().name !== 'e2e') {
          this.offlineValidityService.checkValidityOfOfflineScopes(project.id);
        }
      });

    this.store.select(getResourceState, ResourceType.GetProjects)
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        if (state.status === ResourceStatus.Failure) {
          this.showProjectErrorDialog();
        }
        this.errorMessage = state.lastError;
      });
  }

  async ngOnInit(): Promise<void> {
    if ('serviceWorker' in navigator && env.get().serviceWorker) {
      await this.workBoxService.register();
      await navigator.serviceWorker.ready;
      await this.workBoxService.getServiceWorkerVersion();
      console.log('Service worker ready');
    }

    if (this.featureFlag.isEnabled('offline')) {
      this.networkStatusService.isOnline().subscribe(online => {
        if (online) {
          this.hideOfflineMessageDialog();

          this.offlineDataService.isOfflineChangesPending().then(isPending => {
            if (isPending) {
              this.offlineDataService.syncPendingChanges();
              this.timer = window.setInterval(() => this.syncOfflineChanges(), 60000);
            }
          });
        } else {
          this.showOfflineMessageDialog();
          clearInterval(this.timer);
          this.timer = null;
        }
      });
    }
  }

  private async syncOfflineChanges() {
    this.offlineDataService.isOfflineChangesPending().then(isPending => {
      if (isPending) {
        this.offlineDataService.syncPendingChanges();
      } else {
        clearInterval(this.timer);
        this.timer = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateDOMTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          // Finding the last activated route
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        takeUntil(this.destroy$)
      )
      .subscribe(activatedRoute => {
        const params = Object.values(activatedRoute.snapshot.params);
        const routeId = params[params.length - 1];
        const { title } = activatedRoute.snapshot.data;

        const routeIdTitle = routeId ? ` ${routeId}` : '';
        const pageDomTitle = title ? `${title + routeIdTitle} — ` : '';

        this.domTitle.setTitle(`${pageDomTitle}Completion`);
      });
  }

  hideOfflineMessageDialog(): void {
    this.dialog.closeAll();
  }

  displaySyncErrorDialog(error: HttpErrorResponse) {
    const title = 'A sync error occured';
    let confirmationMessage: string;
    switch (error.status) {
      case 400:
      case 403:
      case 404:
      case 405:
      case 422:
      case 500: {
        confirmationMessage = error.message;
        break;
      }
      default:
        confirmationMessage = 'A network issue caused the sync to fail. The applicaiton will retry later if network is available.';
    }

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        confirmationMessage,
        isCancelDisplay: false,
        confirmationTile: 'Got it'
      } as Confirmation
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
      });
  }

  showOfflineMessageDialog(): void {
    if (this.offlineMode) {
      return;
    }

    const confirmMessage =
      'You will be switched to ' +
      '<strong>Offline mode.</strong><br/>' +
      " You'll still be able to access your " +
      '<br/>downloaded <strong>Scopes</strong>.';

    const dialog = this.dialog.open(OfflineDialogComponent, {
      data: {
        title: 'Internet connection lost',
        icon: 'warning',
        warning: confirmMessage,
        isCancelDisplay: true,
        confirmationTile: 'Got it'
      } as Confirmation
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }

        // redirect to offline mode
        this.store.dispatch(new SetOffline());
        this.router.navigateByUrl('/offline');
      });
  }

  showProjectErrorDialog(): void {

    const title = "Project access error";
    const confirmationMessage = "Error fecthing projects, contact service desk if error persists";
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        confirmationMessage,
        isCancelDisplay: false,
        confirmationTile: 'Got it'
      } as Confirmation
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
      });
  }
}
