import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TreeStatusService } from '@completion/services';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit, OnDestroy {

  @Input() status: string;
  @Input() packageNumber: string;
  @Input() packageType: string;

  displayStatus: string;

  private readonly destroy$ = new Subject<void>();

  constructor(private treeStatusService: TreeStatusService, private cdr: ChangeDetectorRef) { }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.displayStatus = this.status;
    this.treeStatusService.pagckageStatusUpdate$.pipe(takeUntil(this.destroy$)).subscribe(update => {
      if (update.packageNumber === this.packageNumber && update.packageType.toUpperCase() === this.packageType.toUpperCase() && this.status !== update.status) {
        this.displayStatus = update.status;
        this.cdr.detectChanges();
      }
    });
  }

  getStatusClass(): string {
    switch (this.displayStatus) {
      case 'OK':
        return 'status-icon ok-status';
      case 'PA':
        return 'status-icon pa-status';
      case 'PB':
        return 'status-icon pb-status';
      case 'OS':
      default:
        return 'status-icon os-status';
    }
  }

  getStatusText(): string {
    switch (this.displayStatus) {
      case 'OK':
        return 'OK';
      case 'PA':
        return 'A';
      case 'PB':
        return 'B';
      case 'OS':
      default:
        return '';
    }
  }

}
