import { CompletionStatus } from '@completion/enums';
import { CheckListItem } from '@completion/models';
import { Attachment } from './attachment';
import { CheckSheet } from './check-sheet';
import { Company } from './company';
import { Tag } from './tag';
import { User } from './user';
import { UserAssignment } from './user-assignment';

export class Punch {
  id: number;
  punchNumber: number;
  description: string;
  notes?: string;
  category: CompletionStatus = CompletionStatus.PB;
  due?: Date;
  relatedTag: Tag;
  relatedCheckSheet: CheckSheet;
  relatedPhase?: string;
  relatedItem: CheckListItem;
  raisedBy: Company;
  attachments: Attachment[];
  assignedCompany?: Company;
  assignedUser?: User;
  enteredBy: UserAssignment;
  clearedBy?: UserAssignment;
  clearedByExecutor?: UserAssignment;
  acceptedBy?: UserAssignment;
  offlineBy?: UserAssignment;
  relatedCheckSheetAssignmentId?: number;
}

export function punchItemSort(a: Punch, b: Punch) {
  const compareStatus = a.category.localeCompare(b.category);
  const compareTagNumber = a.relatedTag.tagNumber.localeCompare(b.relatedTag.tagNumber);
  const comparePunchNumber = a.punchNumber - b.punchNumber;
  return compareStatus || compareTagNumber || comparePunchNumber;
}
