<div class="dialog-container">
  <div mat-dialog-content>
    <h1>{{ confirmation.title }}</h1>
    <h4 *ngIf="confirmation.message" [innerHtml]="confirmation.message | safeHtml"></h4>
    <h4>
      <strong>{{ confirmation.warning }}</strong>
    </h4>
    <P *ngIf="confirmation.confirmationMessage" [innerHtml]="confirmation.confirmationMessage | safeHtml"></P>
  </div>
  <div mat-dialog-actions>
    <button
      class="dialog-action-button"
      mat-button
      mat-stroked-button
      *ngIf="confirmation.isCancelDisplay"
      mat-dialog-close
      data-testid="dialog-cancel"
    >
      Cancel
    </button>
    <button
      class="dialog-action-button"
      mat-raised-button
      [mat-dialog-close]="true"
      name="confirm-btn"
      color="primary"
      cdkFocusInitial
      data-testid="dialog-confirm"
    >
      {{ confirmation.confirmationTile }}
    </button>
  </div>
</div>
