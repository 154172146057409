import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ProjectState } from '../core/store/reducers/project';
import { getCurrentProject } from '../core/store/selectors/project.selector';
import { getOfflineState } from '../core/store/selectors/offline.selector';

@Component({
  selector: 'app-side-navigation',
  templateUrl: 'side-navigation.component.html',
  styleUrls: ['side-navigation.component.scss']
})
export class SideNavigationComponent {
  isOpen: boolean;

  @Input()
  isOnline: boolean;
  displaySiteFilter = false;
  displayTaskFilter = true; // defalut to true, as this is the default for all projects
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private readonly store: Store<ProjectState>, private readonly router: Router) {
    if (window.localStorage.getItem('sidebarOpen') === undefined) {
      window.localStorage.setItem('sidebarOpen', this.isOpen.toString());
    }
    else {
      this.isOpen = window.localStorage.getItem('sidebarOpen') === "true";
    }


    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(p => {
      if (p && this.isOnline) {
        const projectConfig = p.config.find(c => c.name === 'DISPLAY_FILTER_TREE_ON_SITE');
        this.displaySiteFilter = projectConfig?.value === 'true';

        const projectConfigMcSite = p.config.find(c => c.name === 'DISPLAY_FILTER_TREE_ON_MC_SITE');
        this.displaySiteFilter = projectConfigMcSite ? projectConfigMcSite.value === 'true' : this.displaySiteFilter;

        const projectConfigTask = p.config.find(c => c.name === 'DISPLAY_FILTER_TREE_ON_TASK');
        this.displayTaskFilter = projectConfigTask ? projectConfigTask.value === 'true' : true;
      }
    });
  }

  loadDownloads() {
    this.router.navigate(['downloads']);
    return true;
  }

  loadReports() {
    this.router.navigate(['reports']);
    return true;
  }

  loadDashboard(): boolean {
    this.router.navigate(['dashboard']);
    return true;
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
    window.localStorage.setItem('sidebarOpen', this.isOpen.toString());
  }
}
