import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface TooledComponent {
  tools: ContextTool[];
}

export class ContextTool {
  constructor(
    public caption: string,
    public clickHandler: () => void,
    public componentRef: any = null,
    public disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class ContextToolbarService {
  currentTools: BehaviorSubject<ContextTool[]> = new BehaviorSubject([]);
  private toolList: ContextTool[] = [];
  private readonly offlineAvailableToolList = ["Back"];

  public reset(): void {
    this.toolList = [];
    this.pushTools();
  }

  public addToolsFor(componentRef: TooledComponent): void {
    if(navigator.onLine) {
      this.toolList.push(...componentRef.tools);
    }else {
      componentRef.tools.forEach(tool => {
        const contextTools = [];
        if(this.offlineAvailableToolList.includes(tool.caption)) {
          contextTools.push(tool);
        }
        this.toolList.push(...contextTools);
      })
    }
    this.pushTools();
  }

  public removeToolsFor(componentRef: TooledComponent): void {
    let toolIdx = this.toolList.findIndex(tool => tool.componentRef === componentRef);
    if (toolIdx >= 0) {
      while (toolIdx >= 0) {
        this.toolList.splice(toolIdx, 1);
        toolIdx = this.toolList.findIndex(tool => tool.componentRef === componentRef);
      }

      this.pushTools();
    }
  }

  private pushTools(): void {
    this.currentTools.next(this.toolList);
  }
}
