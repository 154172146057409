import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Workbox } from 'workbox-window';

@Injectable({
  providedIn: 'root'
})
export class WorkBoxService {
  private wb: Workbox;
  private readonly version = new BehaviorSubject('');
  private snackbar: MatSnackBar;

  async getServiceWorkerVersion() {
    this.version.next(await this.wb.messageSW({ type: 'GET_VERSION' }));
  }

  async register() {
    this.wb = new Workbox('/sw.js');
    this.installed();
    await this.wb.register();
  }

  setSnackBar(snackbar: MatSnackBar) {
    this.snackbar = snackbar;
  }

  private installed() {
    this.wb.addEventListener('installed', event => {
      if (!event.isUpdate) {
        return;
      }

      const infoText = 'Update is available';
      const confirmText = 'Install';

      const snack = this.snackbar.open(infoText, confirmText);
      snack
        .onAction()
        .pipe(first())
        .subscribe(() => {
          window.location.reload();
        });
    });
  }
}
