import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import {
  AddSignatureRole,
  CheckSheetLoad,
  CheckSheetLoadSuccess,
  CompanyLoad,
  CompanyLoadSuccess,
  DeleteSignatureRole,
  LoadCpCsas,
  LoadCpCsasSuccess, LoadMcCsas,
  LoadMcCsasSuccess,
  LoadMccsSuccess,
  LoadRfccsSuccess,
  LoadRfocSuccess,
  OptionsActionTypes,
  PhaseLoad,
  PhaseLoadSuccess,
  ResetActionTypes,
  SignatureRoleLoad,
  SignatureRoleLoadSuccess,
  SiteLoad,
  SiteLoadSuccess,
  UpdateSignatureRole
} from '@completion/actions';
import { State } from '@completion/reducers';
import {getCurrentCpId, getCurrentProject} from '@completion/selectors';
import {OptionsService, ResetService} from '@completion/services';
import { ResourceEffect } from './resource.effect';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ResetEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly resetService: ResetService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, resetService, store, toastr);
  }

  @Effect()
  loadResetRfocs$: Observable<Action> = this.actions$.pipe(
    ofType<SiteLoad>(ResetActionTypes.LoadRfocs),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('getResetRfocs', LoadRfocSuccess, ([_, currentProject, currentCpId]) => [currentProject.id, currentCpId])
  );

  @Effect()
  loadResetRfccs$: Observable<Action> = this.actions$.pipe(
    ofType<SiteLoad>(ResetActionTypes.LoadRfccs),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('getResetRfccs', LoadRfccsSuccess, ([_, currentProject, currentCpId]) => [currentProject.id, currentCpId])
  );

  @Effect()
  loadResetMccs$: Observable<Action> = this.actions$.pipe(
    ofType<SiteLoad>(ResetActionTypes.LoadMccs),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('getResetMccs', LoadMccsSuccess, ([_, currentProject, currentCpId]) => [currentProject.id, currentCpId])
  );

  @Effect()
  loadResetCpCsas$: Observable<Action> = this.actions$.pipe(
    ofType<LoadCpCsas>(ResetActionTypes.LoadCpCsas),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('getResetCpCsas', LoadCpCsasSuccess, ([_, currentProject, currentCpId]) => [currentProject.id, currentCpId])
  );

  @Effect()
  loadResetMcCsas$: Observable<Action> = this.actions$.pipe(
    ofType<LoadMcCsas>(ResetActionTypes.LoadMcCsas),
    withLatestFrom(this.store.select(getCurrentProject), this.store.select(getCurrentCpId)),
    this.fetchResource('getResetMcCsas', LoadMcCsasSuccess, ([_, currentProject, currentCpId]) => [currentProject.id, currentCpId])
  );
}
