import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ApplicationMode, ResourceType } from '@completion/enums';
import { ProjectAccess, ProjectAccessRoles, SignatureRole } from '@completion/models';
import { getOfflineState, getRole, getProjectAccess } from '@completion/selectors';
import { ProjectAccessState } from '../store/reducers/project-access';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectAccessService extends BaseService {

  private role = ProjectAccessRoles.READ_ONLY;
  private isOffline = false;
  private projectAccess: ProjectAccess = null;
  constructor(readonly http: HttpClient, private readonly store: Store<ProjectAccessState>) {
    super(http);
    this.store.select(getRole).subscribe(role => this.role = role);
    this.store.select(getProjectAccess).subscribe(access => this.projectAccess = access);
    this.store.select(getOfflineState).subscribe(state => {
      this.isOffline = state === ApplicationMode.OFFLINE;
    })
  }

  @Resource(ResourceType.GetProjectAccess)
  public getProjectAccess(projectId: number): Observable<ProjectAccess> {
    return this.http.get<ProjectAccess>(`${this.baseUrl}/projects/${projectId}/project-access`);
  }

  @Resource(ResourceType.GetProjectAccessList)
  public getProjectAccessList(projectId: number): Observable<Array<ProjectAccess>> {
    return this.http.get<Array<ProjectAccess>>(`${this.baseUrl}/projects/${projectId}/project-access-list`);
  }

  @Resource(ResourceType.UpdateProjectAccess)
  public updateProjectAccess(projectId: number, projectAccess: ProjectAccess): Observable<Array<ProjectAccess>> {
    return this.http.put<Array<ProjectAccess>>(`${this.baseUrl}/projects/${projectId}/project-access`, projectAccess);
  }

  public isOperatorMCP(): boolean {
    if (this.isOffline) {
      return true;
    }
    return this.role === ProjectAccessRoles.ADMIN
      || this.role === ProjectAccessRoles.EDITOR
      || this.role === ProjectAccessRoles.OPERATOR_CP
      || this.role === ProjectAccessRoles.OPERATOR_MCP;
  }

  public isOperatorCP(): boolean {
    if (this.isOffline) {
      return true;
    }
    return this.role === ProjectAccessRoles.ADMIN
      || this.role === ProjectAccessRoles.EDITOR
      || this.role === ProjectAccessRoles.OPERATOR_CP;
  }

  public isEditor(): boolean {
    return this.role === ProjectAccessRoles.ADMIN
      || this.role === ProjectAccessRoles.EDITOR;
  }

  public canSignCertificates(): boolean {
    return this.role === ProjectAccessRoles.COMPANY_SIGN_CERT;
  }

  public canAcceptPunches(): boolean {
    return this.isOperatorMCP() || this.canSignCertificates();
  }

  public canClearPunches(): boolean {
    return this.isOperatorMCP();
  }

  public isAdmin(): boolean {
    return this.role === ProjectAccessRoles.ADMIN;
  }

  canCreateCSAs(): boolean {
    if (this.isEditor()) {
      return true;
    }
    else if ((this.isOperatorCP || this.isOperatorMCP)) {
      return this.projectAccess.companies.length == 0;
    }
    else
      return false;
  }

  hasSignatureRole(signatureRole: SignatureRole): boolean {
    if (signatureRole) {
      return this.projectAccess.signatureRoles.some(role => role.id === signatureRole.id);
    }
    return false;
  }

}
