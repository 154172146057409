import { HandoverDocument, PackageCheckSheetAssignment } from './handover-document';

export interface Cpsi extends HandoverDocument {
  assignments: PackageCheckSheetAssignment[];
  cpCheckStatus: CheckSheetStatus;
  mcpCheckStatus: CheckSheetStatus;
}

export interface CheckSheetStatus {
  totalAssignments: number;
  totalOK: number;
  totalPB: number;
  totalPA: number;
  status: string;
}
