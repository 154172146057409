import { HandoverDocument, PackageCheckSheetAssignment } from './handover-document';

export interface Mcsi extends HandoverDocument {
  mcPackages: McsiMcPackage[];
}

export interface McsiMcPackage {
  mcpNumber: string;
  assignments: PackageCheckSheetAssignment[];
}
