import { Attachment, Punch, UserAssignment } from '@completion/models';
import { CompletionStatus } from '../enums/completion-status';

export interface HandoverDocument {
  cpDescription: string;
  cpNumber: string;
  documentNumber: string;
  id: number;
  partNumber: number;
  title: string;
}

export interface PackageCheckSheetAssignment {
  areaName: string;
  checkSheetNumber: string;
  companyNumber: string;
  phaseNumber: string;
  siteNumber: string;
  status: CompletionStatus;
  tagName: string;
  tagNumber: string;
  tagObjectType: string;
}

export interface HandoverDocumentPunch {
  acceptedBy?: UserAssignment;
  areaName: string;
  attachments: Attachment[];
  checkSheetNumber: string;
  checkSheetType: string;
  clearedBy?: UserAssignment;
  companyNumber: string;
  enteredBy: UserAssignment;
  fullPunch: Punch;
  id: number;
  phaseNumber: string;
  punchItemNumber: string;
  punchNumber: number;
  siteNumber: string;
  status: CompletionStatus;
  tagName: string;
  tagNumber: string;
  tagObjectType: string;
}
