/**
 * To add a new config param, simply just add another item to the enum. This defaults
 * false, hence it has to be an OPT IN param, and not OPT OUT, so we don't force a change on projects.
 */

export enum ProjectConfigs {
  DISPLAY_MERGED_SOF = "Display merged scope of work",
  DISPLAY_FILTER_TREE_ON_SITE = "Filter left tree menu on site",
  DISPLAY_FILTER_TREE_ON_MC_SITE = "Filter left tree menu on mc site",
  DISPLAY_FILTER_TREE_ON_TASK = "Filter left tree menu on task",
  DISPLAY_CHECK_SHEET_ITEMS_IN_HOD = "Display check sheet items in HOD",
  USE_ACCEPTANCE_ON_CERTIFICATE_MCC = "Use acceptance on mc certificates",
  RESTRICT_ISSUING_RFCC_ON_OS_PRE_COMM = "Restrict issuing RFCC on pending pre-comm",
  DISPLAY_STATUS_ICONS = "Display status icons in navigation tree",
  USE_SIGNATURE_MATRIX_ON_CSA = "Use signature matrix on Check Sheets",
  USE_SIGNATURE_MATRIX_ON_MCC = "Use signature matrix on MC Certificates",
  USE_VENDOR_SIGNATURE_MATRIX_ON_CSA = "Use vendor signature matrix on Check Sheets",
  USE_LEAGACY_SIGNATURE_ON_HANDOVER = "Use legacy signature on handover",
  SEND_EMAIL_ON_PUNCH_UPDATE = "Send email on punch update",
  SEND_EMAIL_ON_PUNCH_CREATE = "Send email on punch create",
  SEND_EMAIL_ON_PUNCH_DELETE = "Send email on punch delete",
  DISPLAY_CHECKSHEET_DESCRIPTION = "Display Checksheet Description",
  DISPLAY_PHASE_ON_OPEN_PUNCHES_TABLE = "Display phase on open punches table",
}
