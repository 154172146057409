import { Action } from '@ngrx/store';

import { CertificateType, SignatureType } from '@completion/enums';
import { SignatureEntryMatrix, SignatureMatrixTemplate, SignatureMatrixTemplates, SignatureState } from '@completion/models';


export enum SignatureMatrixActionTypes {
  CertificateSignatureMatrixSave = 'Save Certificate signature matrix',
  HandoverSignatureMatrixSave = 'Save handover signature matrix',
  FetchDefaultSignatureMatrices = "FetchDefaultSignatureMatrix",
  FetchDefaultSignatureMatricesSuccess = "FetchDefaultSignatureMAtricesSuccess",
  DeleteSignatureMatrixTemplate = "DeleteSignatureMatrixTemplate",
  AddSignatureMatrixTemplate = "AddSignatureMatrixTemplate",
  SaveSignatureMatrix = "SaveSignatureMatrix",
  UpdateSignatureMatrixTemplate = "UpdateSignatureMatrixTemplate",
  SetMatrixType = "SetMatrixType",
  ClearCurrentType = "ClearCurrentType",
  AddUnsignedSignatureMatrices = "AddUnsignedSignatureMatrices",
  DeleteSignatureMatrix = "DeleteSignatureMatrix",
  ToggleSignSignatureMatrix = "ToggleSignSignatureMatrix",
  SetCustomMatrixType = "SetCustomMatrixType",
}

export class CertificateSignatureMatrixSave implements Action {
  readonly type = SignatureMatrixActionTypes.CertificateSignatureMatrixSave;

  constructor(public certType: CertificateType, public certId: number, public matrix: SignatureEntryMatrix) { }
}

export class HandoverSignatureMatrixSave implements Action {
  readonly type = SignatureMatrixActionTypes.HandoverSignatureMatrixSave;

  constructor(public handoverId: number, public matrix: SignatureEntryMatrix) { }
}

export class FetchDefaultSignatureMatrices implements Action {
  readonly type = SignatureMatrixActionTypes.FetchDefaultSignatureMatrices;
}

export class FetchDefaultSignatureMatricesSuccess implements Action {
  readonly type = SignatureMatrixActionTypes.FetchDefaultSignatureMatricesSuccess;
  constructor(public matrix: SignatureEntryMatrix[]) { }
}

export class DeleteSignatureMatrixTemplate implements Action {
  readonly type = SignatureMatrixActionTypes.DeleteSignatureMatrixTemplate;
  constructor(public matrix: SignatureMatrixTemplate) { }
}

export class UpdateSignatureMatrixTemplate implements Action {
  readonly type = SignatureMatrixActionTypes.UpdateSignatureMatrixTemplate;
  constructor(public matrix: SignatureMatrixTemplate) { }
}

export class AddSignatureMatrixTemplate implements Action {
  readonly type = SignatureMatrixActionTypes.AddSignatureMatrixTemplate;
  constructor(public matrix: SignatureMatrixTemplate) { }
}

export class SaveSignatureMatrix implements Action {
  readonly type = SignatureMatrixActionTypes.SaveSignatureMatrix;
  constructor(public matrix: SignatureState[]) { }
}

export class SetMatrixType implements Action {
  readonly type = SignatureMatrixActionTypes.SetMatrixType;
  constructor(public matrixType: SignatureType) {
  }
}

export class SetCustomMatrixType implements Action {
  readonly type = SignatureMatrixActionTypes.SetCustomMatrixType;
  constructor(public matrixType: string) {
  }
}


export class ClearCurrentType implements Action {
  readonly type = SignatureMatrixActionTypes.ClearCurrentType;
}

export class AddUnsignedSignatureMatrices implements Action {
  readonly type = SignatureMatrixActionTypes.AddUnsignedSignatureMatrices;
  constructor(public matrix: SignatureState[]) { }
}

export class DeleteSignatureMatrix implements Action {
  readonly type = SignatureMatrixActionTypes.DeleteSignatureMatrix;
  constructor(public matrixId: number) { }
}

export class ToggleSignSignatureMatrix implements Action {
  readonly type = SignatureMatrixActionTypes.ToggleSignSignatureMatrix;
  constructor(public matrixId: number) { }
}

export type SignatureMatrixAction = CertificateSignatureMatrixSave
  | HandoverSignatureMatrixSave
  | FetchDefaultSignatureMatrices
  | FetchDefaultSignatureMatricesSuccess
  | DeleteSignatureMatrixTemplate
  | UpdateSignatureMatrixTemplate
  | AddSignatureMatrixTemplate
  | SaveSignatureMatrix
  | SetMatrixType
  | SetCustomMatrixType
  | ClearCurrentType
  | AddUnsignedSignatureMatrices
  | DeleteSignatureMatrix;
