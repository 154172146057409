import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

import { ButtonStatus } from '@completion/enums';
import { Confirmation } from '@completion/models';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Input() processingImage: boolean;
  @Input() acceptFileFormat = 'image/*';
  @Input() height: string;
  filePreviewURL?: string | ArrayBuffer;

  @Input() set image(image: string) {
    if (image) {
      this.filePreviewURL = image;
    }
  }

  @Output() fileUpload = new EventEmitter<File>();
  @Output() fileDelete = new EventEmitter();

  errorMessage: string;
  buttonStatus = ButtonStatus.IDLE;

  constructor(private readonly dialog: MatDialog) {}

  onSelectedFile(files: File[]) {
    this.errorMessage = '';
    if (files.length > 0) {
      this.buttonStatus = ButtonStatus.IN_PROGRESS;
      const file = files[0];
      if (file.type.includes('image/')) {
        this.onPreview(file);
        this.fileUpload.emit(file);
        this.buttonStatus = ButtonStatus.COMPLETED;
        setTimeout(() => { this.buttonStatus = ButtonStatus.IDLE }, 2000);
      } else {
        this.errorMessage = 'Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed';
      }
    }
  }

  noImage() {
    this.filePreviewURL = '';
    this.processingImage = false;
  }

  loadingImage() {
    this.processingImage = false;
  }

  onPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      this.filePreviewURL = reader.result;
    };
  }

  remove() {
    this.showConfirmation();
  }

  showConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: this.confirmationMessage()
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filePreviewURL = '';
        this.fileDelete.emit();
      }
    });
  }

  private confirmationMessage(): Confirmation {
    return {
      title: 'Are you sure you want to remove the image?',
      message: 'This is a permanent action that cannot be undone.',
      isCancelDisplay: true,
      confirmationTile: 'REMOVE'
    };
  }
}
