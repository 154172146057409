export enum SystemDocumentType {
  SOW = 'SOW',
  MCSI = 'MCSI',
  CPSI = 'CPSI',
  CPPL = 'CPPL',
  MCPL = 'MCPL',
  RFOC = 'RFOC',
  RFCC = 'RFCC',
  MCC = 'MCC'
}

export enum FileDocumentType {
  Procedure = 'PROCEDURE',
  Drawing = 'DRAWING',
  Other = 'OTHER'
}

export const getSystemDocumentTypeDescription = (type: SystemDocumentType) => {
  switch (type) {
    case SystemDocumentType.MCC:
      return 'MCC - Mechanical Completion Certificate';
    case SystemDocumentType.RFCC:
      return 'RFCC - Ready for Commissioning Certificate';
    case SystemDocumentType.RFOC:
      return 'RFOC - Ready for Operation Certificate';
    case SystemDocumentType.MCPL:
      return 'MCPL - Mechanical Completion Punch List';
    case SystemDocumentType.CPPL:
      return 'CPPL - Commissioning Preparation Punch List';
    case SystemDocumentType.CPSI:
      return 'CPSI - Commissioning Preparation Status Index';
    case SystemDocumentType.MCSI:
      return 'MCSI - Mechanical Completion Status Index';
    case SystemDocumentType.SOW:
      return 'Scope of Work';
    default:
      throw new Error(`The provided handover system document is not supported. ${type}`);
  }
};
