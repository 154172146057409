import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompletionStatus } from '@completion/enums';
import { CheckSheetAssignment } from '@completion/models';
import { TagSearchDialog } from 'src/app/shared/models/tag-search-dialog';

@Component({
  selector: 'app-search-result-dialog',
  templateUrl: './search-result-dialog.component.html',
  styleUrls: ['./search-result-dialog.component.scss']
})
export class SearchResultDialogComponent implements OnInit {

  constructor(private readonly router: Router, public dialogRef: MatDialogRef<SearchResultDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: TagSearchDialog) { }

  ngOnInit(): void {
  }

  getCompletionStatus(status: string): CompletionStatus {
    return CompletionStatus[status];
  }

  navToCp(csa: CheckSheetAssignment) {
    this.router.navigate([`/cp/${csa.tag.commissioningPackage.id}`]);
    this.dialogRef.close();
  }

  navToMcp(csa: CheckSheetAssignment) {
    this.router.navigate([`/cp/${csa.tag.commissioningPackage.id}/mcp/${csa.tag.mcPackage.id}`]);
    this.dialogRef.close();
  }

  navToCsa(csa: CheckSheetAssignment) {
    this.router.navigate([`/cp/${csa.tag.commissioningPackage.id}/mcp/${csa.tag.mcPackage.id}/checksheet/${csa.id}`]);
    this.dialogRef.close();
  }



}
