import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ErrorMessageModule } from '../../shared/error-message/error-message.module';
import { ResourceStatusModule } from '../../shared/resource-status/resource-status.module';
import { SpinnerModule } from '../../shared/spinner/spinner.module';
import { TreeComponent } from './tree.component';
import { StatusIconComponent } from './status-icon/status-icon.component';

@NgModule({
  declarations: [TreeComponent, StatusIconComponent],
  imports: [
    CommonModule,
    CdkTreeModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    ResourceStatusModule,
    SpinnerModule,
    ErrorMessageModule,
    MatToolbarModule
  ],
  exports: [TreeComponent]
})
export class TreeModule {}
