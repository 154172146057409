import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

import { Confirmation } from '@completion/models';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  openCamera: boolean;
  selectedFile: File;
  uploadedFiles: File[] = [];
  errorMsg: string;
  @Output() toggleCamera = new EventEmitter<boolean>();
  @Output() files = new EventEmitter<File[]>();

  @ViewChild('fileInput', { read: ElementRef, static: false })
  public fileInputElm: ElementRef;

  constructor(public dialog: MatDialog) { }

  onOpenCamera(): void {
    this.openCamera = true;
    this.toggleCamera.emit(this.openCamera);
  }

  onCameraClosed(canvas: HTMLCanvasElement): void {
    this.openCamera = false;
    this.toggleCamera.emit(this.openCamera);
    if (canvas) {
      canvas.toBlob(blob => {
        this.selectedFile = new File([blob], this.generateFileName(), { type: 'image/png' });
      }, 'image/png');
    }
  }

  onSelectedFile(e): void {
    this.selectedFile = e.target.files[0];
  }

  onUpload(): void {
    this.errorMsg = '';
    if (this.selectedFile.type.includes('image/') || this.selectedFile.type.includes('application/pdf')) {
      this.uploadedFiles = this.uploadedFiles.concat(this.selectedFile);
      this.files.emit(this.uploadedFiles);
      this.selectedFile = null;
      this.fileInputElm.nativeElement.value = null;
    } else {
      this.errorMsg = `${this.selectedFile.type} is not an accepted attachment type`;
    }
  }

  onOpenUploadedFile(file: File): void {
    window.open(URL.createObjectURL(file));
  }

  onChangeFileName(fileName: string): void {
    this.selectedFile = new File([this.selectedFile], `${fileName}.${this.getFileType(this.selectedFile.name)}`, { type: 'image/png' });
  }

  getFileType(name: string): string {
    const nameArray = name.split('.');
    return nameArray[nameArray.length - 1];
  }

  removeFile(index: number, attachment: File): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: this.confirmationMessage(attachment.name)
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.uploadedFiles.splice(index, 1);
        this.files.emit(this.uploadedFiles);
      }
    });
  }

  private generateFileName(): string {
    return `photo${this.uploadedFiles.length}.png`;
  }

  private confirmationMessage(title: string): Confirmation {
    return {
      title: `Do you really want to delete ${title}?`,
      message: 'This is a permanent delete and you cannot revert it.',
      isCancelDisplay: true,
      confirmationTile: 'DELETE'
    };
  }
}
