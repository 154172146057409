import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClearError, ProjectInjectsCheckSheetItemValues } from '@completion/actions';
import { ButtonStatus } from '@completion/enums';
import { MipsCheckSheetItemValue } from '@completion/models';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-data-dialog',
  templateUrl: './data-dialog.component.html',
  styleUrls: ['./data-dialog.component.scss']
})
export class DataDialogComponent implements OnInit {

  jsondata: string = '';
  buttonStatus = ButtonStatus.IDLE;
  errorMsg: string | null;
  dataForm: FormGroup;
  constructor(private readonly store: Store, private readonly formBuilder: FormBuilder, public dialogRef: MatDialogRef<DataDialogComponent>, @Inject(MAT_DIALOG_DATA) readonly data = null) { }

  ngOnInit(): void {
    this.dataForm = this.formBuilder.group({
      jsonData: [this.jsondata]
    });
  }

  onSave(): void {
    this.dataForm.markAllAsTouched();
    this.errorMsg = null;
    this.store.dispatch(new ClearError());

    let data: MipsCheckSheetItemValue[] = JSON.parse(this.dataForm.value.jsonData);
    if (this.dataForm.valid) {
      this.buttonStatus = ButtonStatus.IN_PROGRESS;
      this.store.dispatch(new ProjectInjectsCheckSheetItemValues(data));

      if (!this.errorMsg || this.errorMsg.length === 0) {
        this.buttonStatus = ButtonStatus.COMPLETED;
        setTimeout(() => this.buttonStatus = ButtonStatus.IDLE, 2000);
      }

      this.dialogRef.close();
    }
  }

}
