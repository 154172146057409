import { Discipline } from './discipline';
import { PackageCommon } from './package-common';
import { Phase } from './phase';

export class McPackage extends PackageCommon {
  discipline: Discipline;
  phase: Phase;
  allPhases?: boolean;
  mcpNumber?: string;
  mcpLocked: boolean;
  mcpId: number;
}

// MIPS guard
// These fields are nullable in the database, but is actually required for the user to work on the MCP
const requiredFields = [];

export function isMcPackage(mcp: PackageCommon): mcp is McPackage {
  return (mcp as McPackage).hasOwnProperty('phase');
}

export function hasRequiredFields(pkg: McPackage): boolean {
  return getMissingFields(pkg).length === 0;
}

export function getMissingFields(mcp: McPackage): string[] {
  if (mcp) {
    return Object.entries(mcp)
      .filter(([key, _]) => requiredFields.includes(key))
      .filter(([_, value]) => !value)
      .map(([key, _]) => key);
  }
}
