import {Component, OnDestroy, OnInit} from '@angular/core';
import { SignatureType } from '@completion/enums';
import { SignatureMatrixTemplates } from '@completion/models';
import {
  getCheckSheetMatrixTypes,
  getCompanyMatrixTypes, getCSMatrixTypes,
  getCurrentProject,
  getDefaultSignatureMatrices
} from '@completion/selectors';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import {
  CheckSheetLoad,
  CheckSheetSignatureTypeLoad,
  CompanySignatureTypeLoad,
  GetCheckSheetMatrixTypes
} from "@completion/actions";



@Component({
  selector: 'app-signature-matrix-template',
  templateUrl: './signature-matrix-template.component.html',
  styleUrls: ['./signature-matrix-template.component.scss']
})
export class SignatureMatrixTemplateComponent implements  OnInit, OnDestroy{

  checkSheetMatrixTypes: string[] = [];
  companyMatrixTypes: string[] = [];
  destroy$: Subject<void> = new Subject();
  constructor(private store: Store) {
    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe((project) => {
      if(project) {
        console.log(project);
        this.store.dispatch(new CompanySignatureTypeLoad());
        this.store.dispatch(new GetCheckSheetMatrixTypes(project.id));
      }
    });


    this.store.select(getCompanyMatrixTypes).pipe(takeUntil(this.destroy$)).subscribe((companyMatrixTypes) => {
      console.log(companyMatrixTypes);
      if(companyMatrixTypes && companyMatrixTypes.length > 0) {

        console.log(companyMatrixTypes);
        this.companyMatrixTypes = companyMatrixTypes;
      }
    });
    this.store.select(getDefaultSignatureMatrices).pipe(takeUntil(this.destroy$)).subscribe((signatureMatrixTemplates: SignatureMatrixTemplates) => {
      if(signatureMatrixTemplates) {
        console.log(signatureMatrixTemplates);
      }
    });

    this.store.select(getCSMatrixTypes).pipe(takeUntil(this.destroy$)).subscribe((checkSheetMatrixTypes) => {
      console.log(checkSheetMatrixTypes);
      if(checkSheetMatrixTypes && checkSheetMatrixTypes.length > 0) {
        console.log(checkSheetMatrixTypes);

        this.checkSheetMatrixTypes = checkSheetMatrixTypes;
      }
    });
  }
  getSignatureTypes() {
    const signatureTypes = Object.keys(SignatureType);
    return signatureTypes.slice(0, -2);
  }

  getCompanySignatureTypes(): string[] {
      return  this.companyMatrixTypes;
  }
  getCheckSheetSignatureTypes():string[]{
    return this.checkSheetMatrixTypes;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {

  }

  protected readonly SignatureType = SignatureType;
}
