import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-user-preference-dialog',
  templateUrl: './user-preference-dialog.component.html',
  styleUrls: ['./user-preference-dialog.component.scss']
})
export class UserPreferenceDialogComponent {
  constructor(private dialogRef: MatDialogRef<UserPreferenceDialogComponent>, private store:Store) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
