import { Action } from '@ngrx/store';

import { CheckSheetAssignment, DuplicationErrors } from '@completion/models';

export enum PackageCommonActionTypes {
  CpScopeOfWork = 'Cp Scope of Work',
  CpScopeOfWorkSuccess = 'Cp Scope of Work Success',
  McpScopeOfWorkSuccess = 'Mcp Scope of Work Success',
  McpScopeOfWork = 'MCP Scope of Work',
  ClearScopeOfWork = 'Clear Scope of Work',
  CpDuplicateTags = 'Cp Duplicate Tags',
  McpDuplicateTags = 'MCP Duplicate Tags',
  DuplicateTagsSuccess = 'Duplicate Tags Success',
  DuplicateTagsFailure = 'Duplicate Tags Failure',
  DuplicateTagsClearState = 'Duplicate Tags Clear State',
  PCLoadFailure = 'PC Load Failure',
  SelectCheckSheetAssignmentIds = 'Select Check Sheet Assignemnt Ids'
}

export class CpScopeOfWork implements Action {
  readonly type = PackageCommonActionTypes.CpScopeOfWork;
}

export class McpScopeOfWorkSuccess implements Action {
  readonly type = PackageCommonActionTypes.McpScopeOfWorkSuccess;

  constructor(public sow: CheckSheetAssignment[]) { }
}

export class CpScopeOfWorkSuccess implements Action {
  readonly type = PackageCommonActionTypes.CpScopeOfWorkSuccess;

  constructor(public sow: CheckSheetAssignment[]) { }
}

export class McpScopeOfWork implements Action {
  readonly type = PackageCommonActionTypes.McpScopeOfWork;
}

export class ClearScopeOfWork implements Action {
  readonly type = PackageCommonActionTypes.ClearScopeOfWork;
}

export class CpDuplicateTags implements Action {
  readonly type = PackageCommonActionTypes.CpDuplicateTags;

  constructor(public checkSheetAssignment: CheckSheetAssignment[]) { }
}

export class McpDuplicateTags implements Action {
  readonly type = PackageCommonActionTypes.McpDuplicateTags;

  constructor(public checkSheetAssignment: CheckSheetAssignment[]) {
  }
}

export class DuplicateTagsSuccess implements Action {
  readonly type = PackageCommonActionTypes.DuplicateTagsSuccess;

  constructor(public responseStatus: number) { }
}

export class DuplicateTagsFailure implements Action {
  readonly type = PackageCommonActionTypes.DuplicateTagsFailure;

  constructor(public errors: DuplicationErrors) { }
}

export class DuplicateTagsClearState implements Action {
  readonly type = PackageCommonActionTypes.DuplicateTagsClearState;
}

export class PCLoadFailure implements Action {
  readonly type = PackageCommonActionTypes.PCLoadFailure;

  constructor(public error: string) { }
}

export class SelectCheckSheetAssignmentIds implements Action {
  readonly type = PackageCommonActionTypes.SelectCheckSheetAssignmentIds;

  constructor(public selectedIds: number[]) { }
}

export type PackageCommonAction =
  | McpScopeOfWork
  | CpScopeOfWork
  | McpScopeOfWorkSuccess
  | CpScopeOfWorkSuccess
  | ClearScopeOfWork
  | CpDuplicateTags
  | McpDuplicateTags
  | DuplicateTagsSuccess
  | DuplicateTagsFailure
  | DuplicateTagsClearState
  | PCLoadFailure
  | SelectCheckSheetAssignmentIds;
