<div class="dialog-container">
  <i *ngIf="confirmation.icon" class="material-icons">{{ confirmation.icon }}</i>
  <div mat-dialog-content>
    <h1>{{ confirmation.title }}</h1>
    <p [innerHTML]="confirmation.warning | safeHtml"></p>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-stroked-button *ngIf="confirmation.isCancelDisplay" mat-dialog-close data-testid="dialog-cancel">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="true" name="confirm-btn" color="primary" cdkFocusInitial data-testid="dialog-confirm">
      {{ confirmation.confirmationTile }}
    </button>
  </div>
</div>
