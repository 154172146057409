import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offline-downloads-overview',
  templateUrl: './offline-downloads-overview.component.html',
  styleUrls: ['./offline-downloads-overview.component.scss']
})
export class OfflineDownloadsOverviewComponent{
  activeTabIndex = 0;
  tabs = ['cp', 'mcp'];

  constructor(private readonly router: Router) { }

  setUrlFragment(tabIndex: number): void {
    if (tabIndex !== this.activeTabIndex) {
      const tab = this.tabs[tabIndex];
      this.router.navigate([], { fragment: tab });
      this.activeTabIndex = tabIndex;
    }
  }
}
