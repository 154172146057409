import { NamedEntity } from './named-entity';

export interface Phase extends NamedEntity {
  id: string;
  phaseNumber: string;
}

export function byPhaseNumber(a: Phase, b: Phase) {
  return a.phaseNumber.localeCompare(b.phaseNumber);
}
