import { Action } from '@ngrx/store';

import { CheckSheetAssignment } from '@completion/models';

export enum OfflineActionTypes {
  SetOffline = 'Set application status to offline',
  SetOnline = 'Set application status to online',
  DownloadMcpSOW = 'Download mcp scope of work',
  DownloadCpSOW = 'Download cp scope of work',
  DownloadSOWSuccess = 'Scope of work successfully downloaded'
}

export class SetOffline implements Action {
  readonly type = OfflineActionTypes.SetOffline;
}

export class SetOnline implements Action {
  readonly type = OfflineActionTypes.SetOnline;
}

export class DownloadMcpSOW implements Action {
  readonly type = OfflineActionTypes.DownloadMcpSOW;
  constructor(public sowToDownload: CheckSheetAssignment[]) {}
}
export class DownloadCpSOW implements Action {
  readonly type = OfflineActionTypes.DownloadCpSOW;
  constructor(public sowToDownload: CheckSheetAssignment[]) {}
}

export class DownloadSOWSuccess implements Action {
  readonly type = OfflineActionTypes.DownloadSOWSuccess;
  constructor(public downloadedSows: CheckSheetAssignment[]) {}
}

export type OfflineAction = SetOffline | SetOnline | DownloadMcpSOW | DownloadCpSOW | DownloadSOWSuccess;
