import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserAssignmentComponent } from './user-assignment.component';

@NgModule({
  declarations: [UserAssignmentComponent],
  imports: [CommonModule, OverlayModule],
  exports: [UserAssignmentComponent]
})
export class UserAssignmentModule { }
