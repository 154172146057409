import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

import { Site, Task } from '@completion/models';
import { getCurrentProject, getSites, getTasks } from '@completion/selectors';
import * as taskAction from '../../core/store/actions/task.action';
import { TaskState } from '../../core/store/reducers/task';
import { ClearCurrentSite, ClearCurrentTask, CurrentTask, McSiteLoad, ProjectTreeLoad, SetCurrentSite, SiteLoad } from '@completion/actions';
import { ProjectConfigs } from '@completion/enums';


@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss']
})
export class SiteSelectorComponent {
  filteredTasks: Observable<Task[]>;
  filteredSites: Observable<Site[]>;
  currentTask = '';
  currentSite = '';
  displaySiteFilter = false;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  readonly siteList: Observable<Site[]>

  constructor(private readonly store: Store<TaskState>, private readonly router: Router) {
    this.siteList = this.store.pipe(select(getSites), takeUntil(this.destroy$));

    this.store.select(getCurrentProject).pipe(takeUntil(this.destroy$)).subscribe(p => {
      if (p) {
        const projectConfigMcSite = p.config.find(c => c.name === 'DISPLAY_FILTER_TREE_ON_MC_SITE');
        if (projectConfigMcSite.value === 'true') {
          this.store.dispatch(new McSiteLoad());
        }
        else {
          this.store.dispatch(new SiteLoad());
        }
      }
    });
  }

  onSelectedSite(site: Site): void {
    if (site) {
      this.store.dispatch(new SetCurrentSite(site));
    }
    else {
      this.store.dispatch(new ClearCurrentSite());
    }
    this.store.dispatch(new ProjectTreeLoad());
    this.router.navigate(['dashboard']);
  }

  displayValue(option: Site): string {
    return option ? `${option.name}` : '';
  }

  clearSiteHandler(event): void {
    this.onSelectedSite(null);
    this.currentSite = '';
    event.stopPropagation();
  }
}
