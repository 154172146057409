import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContextToolbarModule } from './context-toolbar/context-toolbar.module';
import { CoreModule } from './core/core.module';
import { HttpInterceptorClient } from './core/HttpClient/HttpInterceptorClient';
import { DashboardModule } from './dashboard/dashboard.module';
import { ProjectSetupModule } from "./project/project-setup.module";
import { OfflineDialogModule } from './shared/offline-dialog/offline-dialog.module';
import { OfflineToolbarModule } from './shared/offline-toolbar/offline-toolbar.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { OfflineDownloadsOverview } from './side-navigation/offline-downloads-overview/offline-downloads-overview.module';
import {
  MsalGuard,
  MsalInterceptor,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalModule
} from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { HeaderModule } from './header/header.module';
import { ReportsModule } from "./reports/reports.module";
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { env } from 'src/environments/env';
import { HealthComponent } from './health/health.component';

export const MSALGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ["user.read"],
  },
});


export const MSALInstanceFactory = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: {
      clientId: env.get().auth.clientId,
      authority: "https://login.microsoftonline.com/26b749f6-8c72-44e3-bbde-ae3de07b4206",
      redirectUri: "/callback",
    },
    cache: {
      cacheLocation: "localStorage",
    },
  });

export const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

  protectedResourceMap.set(env.get().auth.protectedResource, [env.get().auth.apiScope]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

// @ts-ignore
@NgModule({
  declarations: [AppComponent, HealthComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ContextToolbarModule,
    CoreModule,
    LayoutModule,
    HeaderModule,
    HttpClientModule,
    MatSnackBarModule,
    MsalModule,
    OfflineDialogModule,
    OfflineToolbarModule,
    ToastrModule.forRoot({
      timeOut: 10000
    }),
    PipesModule,
    DashboardModule,
    ProjectSetupModule,
    OfflineDownloadsOverview,
    ReportsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClient,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    NgxExtendedPdfViewerService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
