import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { StatusCircleComponent } from './status-circle.component';

@NgModule({
  declarations: [StatusCircleComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [StatusCircleComponent]
})
export class StatusCircleModule { }
