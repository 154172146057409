<app-camera [videoVisible]="openCamera" (closeCamera)="onCameraClosed($event)"></app-camera>
<ng-container *ngIf="!openCamera">
  <div class="upload-container">
    <button mat-icon-button (click)="fileInput.click()"><mat-icon>file_upload</mat-icon></button>
    <input type="file" class="hidden" (change)="onSelectedFile($event)" accept="image/*,.pdf" #fileInput />
    <button mat-icon-button (click)="onOpenCamera()" name="camera-btn"><mat-icon>add_a_photo</mat-icon></button>
    <mat-form-field appearance="fill" *ngIf="selectedFile">
      <input
        #fileName
        matInput
        autocomplete="off"
        name="file-name"
        onClick="select()"
        [value]="
          selectedFile.name
            .split('.')
            .slice(0, -1)
            .join('.')
        "
        (change)="onChangeFileName(fileName.value)"
      />
      <p matSuffix>.{{ getFileType(selectedFile.name) }}</p>
    </mat-form-field>
    <button mat-button name="upload-btn" *ngIf="selectedFile" (click)="onUpload()">Upload</button>
  </div>
  <div *ngFor="let attachment of uploadedFiles; let i = index" class="attachment-buttons">
    <button mat-button name="attachment" (click)="onOpenUploadedFile(attachment)">{{ attachment.name }}</button>
    <button mat-icon-button class="remove-attachments" (click)="removeFile(i, attachment)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-error-message [errorMessage]="errorMsg" *ngIf="errorMsg"></app-error-message>
</ng-container>
