<div class="check-sheet-list-container">
  <div class="check-sheet-list-actions mat-elevation-z3">
    <mat-button-toggle-group [(ngModel)]="isMcCheckSheets" (change)="toggleCheckSheetType($event.value)">
      <mat-button-toggle [value]="true">MC Check Sheets</mat-button-toggle>
      <mat-button-toggle [value]="false">CP Check Sheets</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter()" [(ngModel)]="filterString" placeholder="Filter check sheets">
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z3">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let checkSheet"> {{checkSheet.name}} </td>
    </ng-container>
    <ng-container matColumnDef="csNumber">
      <th mat-header-cell *matHeaderCellDef> CS Number </th>
      <td mat-cell *matCellDef="let checkSheet"> {{checkSheet.csNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let checkSheet"> {{checkSheet.description}} </td>
    </ng-container>
    <ng-container matColumnDef="signatureMatrixType">
      <th mat-header-cell *matHeaderCellDef> Signature Matrix Type </th>
      <td mat-cell *matCellDef="let checkSheet"> {{checkSheet.signatureMatrixType}} </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let checkSheet">
        <button mat-icon-button (click)="openEditDialog(checkSheet)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="50"></mat-paginator>
</div>
