import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { getCurrentUser, getUserSignatureAssignments } from '@completion/selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { RFCCertificate, RFOCertificate } from '../models/certificate';
import { User } from '../models/user';
import { SignatureMatrix, SignatureState } from '../models/signature-matrix';
import { Router } from '@angular/router';
import { HandoverPart } from '../models/handover';
import { CheckSheetAssignment } from '../models/check-sheet-assignment';
import { SignatureType } from '../enums/signature-type';
import { ProjectAccessService } from '@completion/services';


@Component({
  selector: 'app-signature-assignments',
  templateUrl: './signature-assignments.component.html',
  styleUrls: ['./signature-assignments.component.scss']
})
export class SignatureAssignmentsComponent implements OnInit, OnDestroy {

  @Output() assignmentCount = new EventEmitter<number>();
  count = 0;
  private user: User;
  hasRfccsToSign: boolean = false;
  hasRfocsToSign: boolean = false;
  hasHandoversToSign: boolean = false;
  hasCheckSheetsToSign: boolean = false;
  private readonly destroy$ = new Subject<boolean>();
  rfccList$ = new BehaviorSubject<RFCCertificate[]>([]);
  rfocList$ = new BehaviorSubject<RFOCertificate[]>([]);
  handoverList$ = new BehaviorSubject<HandoverPart[]>([]);
  checkSheetAssignmentList$ = new BehaviorSubject<CheckSheetAssignment[]>([]);
  constructor(private readonly store: Store, private readonly router: Router, private access: ProjectAccessService) { }

  ngOnInit(): void {
    this.store.select(getUserSignatureAssignments).pipe(takeUntil(this.destroy$)).subscribe(signatureAssignments => {
      if (signatureAssignments) {
        this.count = 0;
        if (signatureAssignments.rfcCertificateDtos.length > 0) {
          this.rfccList$.next(signatureAssignments.rfcCertificateDtos);
          this.hasRfccsToSign = true;
          this.count += signatureAssignments.rfcCertificateDtos.length;
        }
        else {
          this.hasRfccsToSign = false;
        }
        if (signatureAssignments.handoverDtos.length > 0) {
          this.handoverList$.next(signatureAssignments.handoverDtos);
          this.hasHandoversToSign = true;
          this.count += signatureAssignments.handoverDtos.length;
        }
        else {
          this.hasHandoversToSign = false;
        }

        if (signatureAssignments.checkSheetAssignmentDtos.length > 0) {
          this.checkSheetAssignmentList$.next(signatureAssignments.checkSheetAssignmentDtos);
          this.hasCheckSheetsToSign = true;
          this.count += signatureAssignments.checkSheetAssignmentDtos.length;
        }
        else {
          this.checkSheetAssignmentList$.next([]);
          this.hasCheckSheetsToSign = false;
        }

        if (signatureAssignments.rfoCertificateDtos.length > 0) {
          this.rfocList$.next(signatureAssignments.rfoCertificateDtos);
          this.hasRfocsToSign = true;
          this.count += signatureAssignments.rfoCertificateDtos.length;
        }
        else {
          this.hasRfocsToSign = false;
        }
        this.assignmentCount.emit(this.count);
      }
    });


    this.store.select(getCurrentUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.user = user);
  }

  isMyTurn(matrix: SignatureMatrix): boolean {
    if (matrix[0].signatureType) {

      for (let i = 0; i < matrix.length; i++) {
        if (!matrix[i].signedDate) {
          if (matrix[i].signee && matrix[i].signee.id === this.user.id
            || matrix[i].signatureRole && this.access.hasSignatureRole(matrix[i].signatureRole)) {
            return true;
          }
          return false;
        }
      }
    }
    let inTurn: User = this.getNextToSign(matrix);
    if (inTurn === null) {
      return false;
    }
    let isMyTurn = inTurn.id === this.user.id;
    return inTurn.id === this.user.id;
  }

  getNextToSign(matrix: SignatureMatrix): User {
    let nextInLine: User = null;
    for (let i = 0; i < matrix.length; i++) {
      if (matrix[i].signed === false) {
        nextInLine = matrix[i].signee;
        break;
      }
    }
    return nextInLine;
  }

  getNextToSignAsString(matrix: SignatureMatrix): string {
    if (matrix[0].signatureType) {
      let nextInLine = matrix.find(m => !m.signedDate);
      if (nextInLine && nextInLine.signee) {
        return nextInLine.signee.name;
      }
      else if (nextInLine && nextInLine.signatureRole) {
        return nextInLine.signatureRole.name;
      }
      return 'No one';
    }
    let nextInLine: User = this.getNextToSign(matrix);
    if (nextInLine === null) {
      return 'No one';
    }
    return nextInLine.name;
  }
  getCurrentToSign(matrix: SignatureMatrix): SignatureState {
    let current: SignatureState = null;
    for (let i = 0; i < matrix.length; i++) {
      if (matrix[i].signed === false) {
        current = matrix[i];
        break;
      }
    }
    return current;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  navigateToRfccCertificate(rfcCertificate: any): void {
    this.router.navigateByUrl(`cp/${rfcCertificate.commPackage.id}/certificates/sign/rfcc/${rfcCertificate.id}`)
  }

  navigateToRfoCertificate(rfoCertificate: any): void {
    this.router.navigateByUrl(`cp/${rfoCertificate.commissioningPackage.id}/certificates/sign/rfoc/${rfoCertificate.id}`)

  }

  navigateToCsa(checkSheetAssignment: CheckSheetAssignment): void {
    let isMcp = checkSheetAssignment.signatureMatrix[0].signatureType === SignatureType.CSA_MCP;
    const cpId = checkSheetAssignment.tag.commissioningPackage.id;

    if (isMcp) {
      const mcpId = checkSheetAssignment.tag.mcPackage.mcpId;
      this.router.navigateByUrl(`cp/${cpId}/mcp/${mcpId}/checksheet/${checkSheetAssignment.id}`)
    }
    else {
      this.router.navigateByUrl(`cp/${cpId}/checksheet/${checkSheetAssignment.id}`)
    }
  }

  signedFraction(matrix: SignatureMatrix): string {
    let signed = matrix.filter(m => m.signed).length;
    let total = matrix.length;
    return `${signed}/${total}`;
  }
}


