import { HandoverDocumentPunch } from './handover-document';

export interface Cppl {
  cpDescription: string;
  cpNumber: string;
  documentNumber: string;
  id: number;
  mcPackages: CpplMcPackage[];
  partNumber: number;
  punches: HandoverDocumentPunch[];
  title: string;
}

export interface CpplMcPackage {
  mcpNumber: string;
  punches: HandoverDocumentPunch[];
}
