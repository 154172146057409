import { Inject, Injectable, Optional } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { LoaderConfig, defaultLoaderConfig } from './loader.config';
import { PatternMatchingService } from './pattern-matching.service';


export interface RequestIdent {
  id: number;
  time: number;
  url: string;
}

@Injectable()
export class LoaderService {
  public countStream: ReplaySubject<number> = new ReplaySubject(1);
  public isLoadingStream: ReplaySubject<boolean> = new ReplaySubject(1);
  private requests = new Map();
  private loaderConfig: LoaderConfig = defaultLoaderConfig;

  public get count() {
    return this.requests.size;
  }
  public get isLoading() {
    return this.requests.size > 0;
  }

  constructor(
    private patternMatchingService: PatternMatchingService
  ) {
    this.countStream.next(this.count);
    this.isLoadingStream.next(this.isLoading);
  }

  public addRequest(request: RequestIdent) {
    this.requests.set(request.id, request);
    this.countStream.next(this.count);
    if (this.count === 1) {
      this.isLoadingStream.next(true);
    }
  }
  public removeRequest(request: any) {
    const r = this.requests.get(request.id);
    if (r) {
      this.requests.delete(r.id);
      this.countStream.next(this.count);
      if (this.count === 0) {
        this.isLoadingStream.next(false);
      }
    }
  }

  public ignoreResource(url: string): boolean {
    if (!this.loaderConfig) {
      return false;
    }

    return this.patternMatchingService.matches(this.loaderConfig.ignorePatterns, url);
  }
}
