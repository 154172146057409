import { CompletionStatus } from '@completion/enums';
import { Area } from './area';
import { Company } from './company';
import { Delivery } from './delivery';
import { Site } from './site';
import { UserAssignment } from './user-assignment';

export class PackageCommon {
  id?: number;
  description: string;
  descriptionOfWork?: string;
  comments?: string;
  status: Status;
  company: Company;
  site: Site;
  area: Area;
  delivery?: Delivery;
  createdBy: UserAssignment;
  updatedBy: UserAssignment;
  responsible: UserAssignment;
  revision?: string;
  certificateLock: boolean;
  completionState?: CompletionStatus;
}

export class Status {
  completionState = CompletionStatus.OS;
  completionProgress: CompletionProgress;
}

export class CompletionProgress {
  stateOKCount: number;
  stateOSCount: number;
  statePACount: number;
  statePBCount: number;
  stateNACount: number;
}
