import { TaskAction, TaskActionTypes } from '@completion/actions';
import { byTaskNumber, Task } from '@completion/models';

export interface TaskState {
  tasks: Task[];
  currentTask: Task;
}

export const initialState: TaskState = {
  tasks: [],
  currentTask: null
};

export function taskReducer(state: TaskState = initialState, action: TaskAction): TaskState {
  switch (action.type) {
    case TaskActionTypes.TasksLoadSuccess:
      return {
        ...state,
        tasks: [...action.tasks].sort(byTaskNumber)
      };
    case TaskActionTypes.CurrentTask:
      return {
        ...state,
        currentTask: action.task
      };
    case TaskActionTypes.ClearCurrentTask:
      return {
        ...state,
        currentTask: null
      };
    default:
      return state;
  }
}
