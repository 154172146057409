import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ResourceType } from '@completion/enums';
import {
  HandoverFile,
  HandoverFileUploadRequest,
  HandoverPart,
  HandoverRow,
  HandoverRowUpdate,
  HandoverSectionTemplate,
  HandoverTemplate,
  SignatureMatrix
} from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class HandoverTemplateService extends BaseService {
  constructor(readonly http: HttpClient) {
    super(http);
  }

  @Resource(ResourceType.HandoverTemplateGetHandoverTemplate)
  getHandoverTemplate(projectId: number): Observable<HandoverTemplate> {
    return this.http.get<HandoverTemplate>(`${this.baseUrl}/projects/${projectId}/handoverTemplate`);
  }

  @Resource(ResourceType.HandoverTemplateUpdateOrdinals)
  updateHandoverTemplateOrdinals(projectId: number, sections: HandoverTemplate[]): Observable<HandoverTemplate> {
    return this.http.patch<HandoverTemplate>(`${this.baseUrl}/projects/${projectId}/handoverTemplate/update`, sections);
  }

  @Resource(ResourceType.HandoverTemplateDeleteHandoverTemplate)
  deleteHandoverTemplate(projectId: number, handoverTemplateId: number): Observable<HandoverTemplate> {
    return this.http.delete<HandoverTemplate>(`${this.baseUrl}/projects/${projectId}/handoverTemplate/item/${handoverTemplateId}`);
  }

  @Resource(ResourceType.HandoverTemplateAddHandoverTemplate)
  addHandoverTemplate(projectId: number, template: HandoverTemplate): Observable<HandoverTemplate> {
    return this.http.post<HandoverTemplate>(`${this.baseUrl}/projects/${projectId}/handoverTemplate/addHandoverTemplate/`, template);
  }

  @Resource(ResourceType.HandoverTemplateUpdateHandoverTemplate)
  updateHandoverTemplate(projectId: number, template: HandoverTemplate[]): Observable<HandoverTemplate> {
    return this.http.patch<HandoverTemplate>(`${this.baseUrl}/projects/${projectId}/handoverTemplate/update`, template);
  }
}
