import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Project } from '@completion/models';
import { getCurrentProject, getProjects } from '@completion/selectors';
import * as projectAction from '../../core/store/actions/project.action';
import { ProjectState } from '../../core/store/reducers/project';

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss']
})
export class ProjectSelectorComponent {
  selectedProject$: Observable<Project>;
  projects$: Observable<Project[]>;

  constructor(private readonly store: Store<ProjectState>, private readonly router: Router) {
    this.selectedProject$ = this.store.pipe(select(getCurrentProject));
    this.projects$ = this.store.pipe(select(getProjects));
  }

  public onSelectedProject(project: Project): void {
    this.router.navigate(['dashboard']);
    this.store.dispatch(new projectAction.ChangeProject(project));
    window.localStorage.setItem('projectId', project.id.toString());
  }
}
