import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Confirmation } from '@completion/models';

@Component({
  selector: 'app-offline-dialog',
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.scss']
})
export class OfflineDialogComponent {
  constructor(public dialogRef: MatDialogRef<OfflineDialogComponent>, @Inject(MAT_DIALOG_DATA) public confirmation: Confirmation) {}
}
