import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResourceType } from '@completion/enums';
import { Attachment, Punch } from '@completion/models';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PunchService extends BaseService {
  private projectUrl = '/projects';

  constructor(readonly http: HttpClient) {
    super(http);
    this.projectUrl = this.baseUrl + this.projectUrl;
  }

  @Resource(ResourceType.GetMcpPunches)
  getMcpPunches(projectId: number, mcpId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.projectUrl}/${projectId}/mcps/${mcpId}/punches`);
  }

  @Resource(ResourceType.GetCpPunches)
  getCpPunches(projectId: number, cpId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.projectUrl}/${projectId}/cps/${cpId}/punches`);
  }

  @Resource(ResourceType.GetCsaPunches)
  getCsaPunches(projectId: number, csaId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.projectUrl}/${projectId}/csas/${csaId}/punches`);
  }

  @Resource(ResourceType.CreateCpPunch)
  createCpPunch(projectId: number, cpId: number, csaId: number, csiNum: number, punchItem: Punch): Observable<Punch> {
    return this.http.post<Punch>(`${this.projectUrl}/${projectId}/cps/${cpId}/checkedTags/${csaId}/csItems/${csiNum}/punches`, punchItem);
  }

  @Resource(ResourceType.CreateMcpPunch)
  createMcpPunch(projectId: number, mcpId: number, csaId: number, csiNum: number, punchItem: Punch): Observable<Punch> {
    return this.http.post<Punch>(`${this.projectUrl}/${projectId}/mcps/${mcpId}/checkedTags/${csaId}/csItems/${csiNum}/punches`, punchItem);
  }

  @Resource(ResourceType.UpdatePunch)
  updatePunch(projectId: number, punch: Punch): Observable<Punch> {
    return this.http.put<Punch>(`${this.projectUrl}/${projectId}/punches/${punch.id}`, punch);
  }

  @Resource(ResourceType.DeletePunch)
  deletePunch(projectId: number, punchId: number) {
    return this.http.delete(`${this.projectUrl}/${projectId}/punches/${punchId}`, {
      observe: 'response'
    });
  }

  @Resource(ResourceType.UploadAttachments)
  uploadAttachments(projectId: number, punchId: number, filesToUpload: File[]): Observable<Attachment[]> {
    const url = `${this.projectUrl}/${projectId}/punches/${punchId}/attachments`;
    const formData: FormData = new FormData();
    filesToUpload.forEach(file => {
      formData.append('attachmentFiles', file, file.name);
    });
    return this.http.post<Attachment[]>(url, formData);
  }

  @Resource(ResourceType.GetAttachment)
  getAttachment(projectId: number, punchId: number, attachmentId: number): Observable<Blob> {
    return this.http.get(`${this.projectUrl}/${projectId}/punches/${punchId}/attachments/${attachmentId}`, {
      responseType: 'blob'
    });
  }

  @Resource(ResourceType.DeleteAttachment)
  deleteAttachment(projectId: number, punchId: number, attachmentId: number): Observable<any> {
    return this.http.delete(`${this.projectUrl}/${projectId}/punches/${punchId}/attachments/${attachmentId}`);
  }

  @Resource(ResourceType.LoadUnacceptedPunches)
  getUnacceptedPunches(projectId: number): Observable<Punch[]> {
    return this.http.get<Punch[]>(`${this.projectUrl}/${projectId}/dashboard/unacceptedPunches`);
  }

  @Resource(ResourceType.AdminRevokeAccepted)
  adminRevokeAccepted(projectId: number, punchId: number): Observable<any> {
    return this.http.put(`${this.projectUrl}/${projectId}/punches/${punchId}/adminRevokeAccepted`, {});
  }

  @Resource(ResourceType.AdminRevokeAccepted)
  adminRevokeCleared(projectId: number, punchId: number): Observable<any> {
    return this.http.put(`${this.projectUrl}/${projectId}/punches/${punchId}/adminRevokeCleared`, {});
  }

  @Resource(ResourceType.PublishPunchesToMips)
  publishPunchesToMips(projectId: number, cpId: number): Observable<any> {
    return this.http.get(`${this.projectUrl}/${projectId}/cps/${cpId}/publishPunchesToMips`);
  }

  @Resource(ResourceType.PublishPunchToMips)
  publishPunchToMips(projectId: number, cpId: number, punchId:number): Observable<any> {
    return this.http.get(`${this.projectUrl}/${projectId}/cps/${cpId}/publishPunchToMips/${punchId}`);
  }
}
