<div class="company-list-container">
  <div class="company-list-actions mat-elevation-z3">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter()" [(ngModel)]="filterString" placeholder="Filter companies">
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z3">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let company"> {{company.name}} </td>
    </ng-container>
    <ng-container matColumnDef="companyNumber">
      <th mat-header-cell *matHeaderCellDef> Company Number </th>
      <td mat-cell *matCellDef="let company"> {{company.compNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="signatureMatrixType">
      <th mat-header-cell *matHeaderCellDef> Signature Matrix Type </th>
      <td mat-cell *matCellDef="let company"> {{company.signatureMatrixType}} </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let company">
        <button mat-icon-button (click)="openEditDialog(company)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="50"></mat-paginator>
</div>
