import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CpState } from '../reducers/cp';

const getCpFeatureState = createFeatureSelector<CpState>('cp');

export const getCurrentCpId = createSelector(
  getCpFeatureState,
  (state: CpState) => state.currentCpId
);

export const getCps = createSelector(
  getCpFeatureState,
  (state: CpState) => state.cps
);

export const getCurrentCp = createSelector(
  getCpFeatureState,
  (state: CpState) => state.currentCp
);

export const getLatestError = createSelector(
  getCpFeatureState,
  (state: CpState) => state.latestError
);

export const getMcps = createSelector(
  getCpFeatureState,
  (state: CpState) => state.mcps
);

export const getCpStatus = createSelector(
  getCpFeatureState,
  (state: CpState) => state.responseStatus
);
