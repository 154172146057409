export interface Stepper {
  steps: Step[];
  state: StepperState;
}

export interface Step {
  title: string;
  state: StepperState;
}

export enum StepperState {
  DONE = 'Done',
  IN_PROGRESS = 'In Progress',
  NOT_STARTED = 'Not Started'
}
