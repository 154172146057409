import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {ToastrService} from "ngx-toastr";
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { OfflineDownloadsActionTypes, OfflineScopesLoad, OfflineScopesLoadSuccess, OfflineScopesRelease } from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentProject } from '@completion/selectors';
import { OfflineDownloadsService } from '../../services/offline-downloads.service';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class OfflineDownloadsEffects extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly service: OfflineDownloadsService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, service, store, toastr);
  }

  @Effect()
  loadOfflineDownloads$: Observable<Action> = this.actions$.pipe(
    ofType<OfflineScopesLoad>(OfflineDownloadsActionTypes.OfflineScopesLoad),
    withLatestFrom(...this.multiSelect(getCurrentProject)),
    this.fetchResource('getOfflineDownloads', OfflineScopesLoadSuccess, ([_, currentProject]) => [currentProject.id])
  );

  @Effect({ dispatch: true })
  releaseOfflineDownloads$: Observable<Action> = this.actions$.pipe(
    ofType<OfflineScopesRelease>(OfflineDownloadsActionTypes.OfflineScopesRelease),
    withLatestFrom(...this.multiSelect(getCurrentProject)),
    this.fetchResource('releaseOfflineDownloads', OfflineScopesLoad, ([action, currentProject]) => [currentProject.id, action.pkgId, action.pkgType, action.csaIds])
  );
}
