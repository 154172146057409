import { createFeatureSelector } from '@ngrx/store';

import { Cppl, Cpsi, Mcpl, Mcsi } from '@completion/models';

export const getMcsi = createFeatureSelector<Mcsi>('mcsi');

export const getCppl = createFeatureSelector<Cppl>('cppl');

export const getMcpl = createFeatureSelector<Mcpl>('mcpl');

export const getCpsi = createFeatureSelector<Cpsi>('cpsi');
