import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { OfflineToolbarComponent } from './offline-toolbar.component';

@NgModule({
  declarations: [OfflineToolbarComponent],
  exports: [OfflineToolbarComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule]
})
export class OfflineToolbarModule {}
