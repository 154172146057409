import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {ToastrService} from "ngx-toastr";
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import {
  ProjectAccessActionTypes,
  ProjectAccessListLoad,
  ProjectAccessListLoadSuccess,
  ProjectAccessLoad,
  ProjectAccessLoadSuccess,
  UpdateProjectAccess,

} from '@completion/actions';
import { State } from '@completion/reducers';
import { getCurrentProject } from '@completion/selectors';
import { ProjectAccessService } from '../../services';
import { ResourceEffect } from './resource.effect';

@Injectable()
export class ProjectAccessEffect extends ResourceEffect {
  constructor(readonly actions$: Actions, readonly projectAccessService: ProjectAccessService, readonly store: Store<State>, readonly toastr: ToastrService) {
    super(actions$, projectAccessService, store, toastr);
  }

  @Effect()
  loadProjectAccess$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectAccessLoad>(ProjectAccessActionTypes.ProjectAccessLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getProjectAccess', ProjectAccessLoadSuccess, ([__, currentProject]) => [currentProject.id])
  );

  @Effect()
  loadProjectAccessList$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectAccessListLoad>(ProjectAccessActionTypes.ProjectAccessListLoad),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('getProjectAccessList', ProjectAccessListLoadSuccess, ([__, currentProject]) => [currentProject.id])
  );

  @Effect()
  updateProjectAccess$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateProjectAccess>(ProjectAccessActionTypes.UpdateProjectAccess),
    withLatestFrom(this.store.select(getCurrentProject)),
    this.fetchResource('updateProjectAccess', ProjectAccessListLoadSuccess, ([action, currentProject]) => [currentProject.id, action.projectAccess])
  );
}
