import { Injectable } from '@angular/core';
import { env } from 'src/environments/env';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private readonly features: Record<Feature, boolean> = env.get().featureFlags;

  isEnabled(feature: Feature) {
    return this.features[feature];
  }
}

type Feature = 'rfoc' | 'handover' | 'testingUtils' | 'offline';
