import { Action } from '@ngrx/store';

import { Project, ProjectAccess, } from '@completion/models';


export enum ProjectAccessActionTypes {
  ProjectAccessLoad = 'Project Access Load',
  ProjectAccessLoadSuccess = 'Projects Access Load Success',
  ProjectAccessListLoad = 'Project Access List Load',
  ProjectAccessListLoadSuccess = "Project Access ListLoad Success",
  UpdateProjectAccess = "Update Project Access",
}

export class ProjectAccessLoad implements Action {
  readonly type = ProjectAccessActionTypes.ProjectAccessLoad;
  constructor(public project: Project) {
  }
}

export class ProjectAccessLoadSuccess implements Action {
  readonly type = ProjectAccessActionTypes.ProjectAccessLoadSuccess;

  constructor(public projectsAccess: ProjectAccess) { }
}

export class ProjectAccessListLoad implements Action {
  readonly type = ProjectAccessActionTypes.ProjectAccessListLoad;
  constructor(public project: Project) { }
}

export class ProjectAccessListLoadSuccess implements Action {
  readonly type = ProjectAccessActionTypes.ProjectAccessListLoadSuccess;

  constructor(public projectsAccessList: Array<ProjectAccess>) { }
}

export class UpdateProjectAccess implements Action {
  readonly type = ProjectAccessActionTypes.UpdateProjectAccess;

  constructor(public projectAccess: ProjectAccess) { }
}


export type ProjectAccessAction =
  | ProjectAccessLoad
  | ProjectAccessLoadSuccess
  | ProjectAccessListLoad
  | ProjectAccessListLoadSuccess
  | UpdateProjectAccess;
