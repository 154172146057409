import { Action } from '@ngrx/store';

import { ResourceType } from '@completion/enums';
import { ResourceDescriptor } from '@completion/models';

export enum ResourceActionTypes {
  Start = 'Resource Retrieval Started',
  Success = 'Resource Retrieval Success',
  Failure = 'Resource Retrieval Failure',
  Cancel = 'Resource Retrieval Cancelled',
  Reset = 'Resource Reset'
}

abstract class ResourceBaseAction implements Action {
  abstract readonly type: ResourceActionTypes;
  public resourceDescriptor: ResourceDescriptor | ResourceType;

  constructor(rd: ResourceDescriptor | ResourceType) {
    // Normalise the state of the object regardless of it being instantiated with a
    // full ResourceDescriptor or a simple ResourceType string
    this.resourceDescriptor = typeof rd === 'string' ? { type: rd, identifiers: {} } : rd;
  }
}

export class ResourceStart extends ResourceBaseAction {
  readonly type = ResourceActionTypes.Start;
}

export class ResourceSuccess extends ResourceBaseAction {
  readonly type = ResourceActionTypes.Success;
}

export class ResourceFailed extends ResourceBaseAction {
  readonly type = ResourceActionTypes.Failure;
  constructor(rd: ResourceDescriptor | ResourceType, public error: string) {
    super(rd);
  }
}

export class ResourceReset extends ResourceBaseAction {
  readonly type = ResourceActionTypes.Reset;
}

export class ResourceCancel extends ResourceBaseAction {
  readonly type = ResourceActionTypes.Cancel;
}

export type ResourceAction = ResourceStart | ResourceSuccess | ResourceFailed | ResourceReset | ResourceCancel;
