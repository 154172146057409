<nav class="sidenav-container">
  <div class="main-menu-container" [class.expanded]="isOpen">
    <div *ngIf="isOpen; else showIcon">
      <div class="project-selector">
        <app-project-selector *ngIf="isOnline"></app-project-selector>
      </div>
      <app-task-selector *ngIf="displayTaskFilter"></app-task-selector>
      <app-site-selector *ngIf="displaySiteFilter"></app-site-selector>
      <div class="tree-container">
        <app-tree></app-tree>
      </div>
      <div class="download-scope" *ngIf="isOnline">
        <!--<img draggable="false" src="assets/icon/Download-White.svg" alt="Download" />  -->
        <i class="material-icons menu-item-icon">download</i>
        <button class="mat-icon-button" (click)="loadDownloads()">Downloads</button>
      </div>
      <div class="download-scope" *ngIf="isOnline">
        <i class="material-icons menu-item-icon">stacked_line_chart</i>
        <button class="mat-icon-button" (click)="loadReports()">Reports</button>
      </div>
    </div>
    <ng-template #showIcon>
      <div class="project-selector">
        <a
          title="Select project"
          class="menu-item"
          (click)="toggleOpen()"
          aria-label="Dashboard"
          cdkMonitorElementFocus
          [routerLink]="['', 'dashboard']"
        >
          <i class="material-icons menu-item-icon">dashboard</i>
        </a>
      </div>
      <a title="Expand tree" (click)="toggleOpen() && loadDashboard()" class="menu-item" cdkMonitorElementFocus>
        <i class="material-icons menu-item-icon">tune</i>
      </a>
      <a title="Offline downloads" *ngIf="isOnline" (click)="toggleOpen() && loadDownloads()" class="menu-item" cdkMonitorElementFocus>
        <img
          class="material-icons menu-item-icon download-scope-icon"
          draggable="false"
          src="assets/icon/Download-White.svg"
          alt="Download"
        />
      </a>
      <a title="Reports" *ngIf="isOnline" (click)="toggleOpen() && loadReports()" class="menu-item" cdkMonitorElementFocus>
        <i class="material-icons menu-item-icon">stacked_line_chart</i>
      </a>
    </ng-template>
    <div class="footer">
      <button title="Expand menu" class="expand-button" cdkMonitorElementFocus (click)="toggleOpen()" [class.flipped]="isOpen">
        <i class="material-icons">arrow_forward</i>
      </button>
    </div>
  </div>
</nav>
