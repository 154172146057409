import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnDestroy {
  @Input('videoVisible') set videoVisible(visible: boolean) {
    this.visible = visible;
    if (this.visible && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          this.availableDevices = devices.filter(device => device.kind === 'videoinput');
          this.activeDevice = this.availableDevices[this.availableDevices.length - 1];
          if (this.activeDevice) {
            const constraints = { deviceId: { exact: this.activeDevice.deviceId } };
            return navigator.mediaDevices.getUserMedia({ video: constraints, audio: false });
          }
          return null;
        })
        .then(stream => {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
        })
        .catch(() => {
          // Happy path life 🔥
        });
    }
  }

  @Output() closeCamera = new EventEmitter<any>();

  @ViewChild('video', { static: true })
  public video: ElementRef;

  @ViewChild('canvas', { static: true })
  public canvas: ElementRef;

  visible: boolean;
  activeDevice: MediaDeviceInfo;
  private availableDevices: MediaDeviceInfo[];

  ngOnDestroy(): void {
    this.stopVideo();
  }

  onCapture(): void {
    this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 640, 450);
    this.closeCamera.emit(this.canvas.nativeElement);
    this.stopVideo();
  }

  onFlipCamera(): void {
    if (this.availableDevices.length > 1) {
      this.stopVideo();
      const newIndex =
        this.availableDevices.indexOf(this.activeDevice) < this.availableDevices.length - 1
          ? this.availableDevices.indexOf(this.activeDevice) + 1
          : 0;
      this.activeDevice = this.availableDevices[newIndex];
      navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: this.activeDevice.deviceId } }, audio: false }).then(stream => {
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
      });
    }
  }

  onCancel(): void {
    this.closeCamera.emit(null);
    this.stopVideo();
  }

  private stopVideo(): void {
    if (this.video.nativeElement.srcObject) {
      this.video.nativeElement.srcObject.getTracks().forEach(track => track.stop());
    }
  }
}
