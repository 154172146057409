import { Injectable } from "@angular/core";
import { CompletionStatus } from "@completion/enums";
import { Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class TreeStatusService {

    public pagckageStatusUpdate$ = new Subject<PackageStatusUpdate>();

    public updatePackageStatus(packageNumber: string, status: CompletionStatus, packageType: PackageType): void {
        this.pagckageStatusUpdate$.next({ packageNumber, status, packageType });
    }
}

export enum PackageType {
    MCP = 'MCP',
    CP = 'CP',
}

export interface PackageStatusUpdate {
    packageNumber: string;
    status: CompletionStatus;
    packageType: PackageType;
}

