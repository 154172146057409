import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  private readonly online$: BehaviorSubject<boolean> = new BehaviorSubject(navigator.onLine);

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        this.online$.next(true);
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.online$.next(false);
      })
    );
  }

  isOnline(): Observable<boolean> {
    return this.online$;
  }
}
