import { Action } from "@ngrx/store";

import { DashboardPackage, UserSignatureAssignments } from "@completion/models";
import { DashboardCheckSheetAssignmentStatus } from "../../../shared/models/dashboard-checksheet-status";








export enum DashBoardActionTypes {
  LoadDashboardCps = 'Load Cps Dashboard',
  LoadDashboardMcps = 'Load Mpcs Dashboard',
  LoadDashboardCheckSheetAssignmentStatus = 'Load Dashboard CheckSheet Assignment Status',
  CpsDashBoardPackageLoadSuccess = 'Cps Dashboard Package Load Success',
  McpsDashBoardPackageLoadSuccess = 'Mcps Dashboard Package Load Success',
  DashboardCheckSheetAssignmentStatusLoadSuccess = 'Dashboard Check Sheet Assignment Status Load Success',
  LoadUserSignatureAssignments = "LoadUserSignatureAssignments",
  LoadUserSignatureAssignmentsSuccess = "LoadUserSignatureAssignmentsSuccess"
}

export class LoadDashboardCps implements Action {
  readonly type = DashBoardActionTypes.LoadDashboardCps;
}

export class LoadDashboardMcps implements Action {
  readonly type = DashBoardActionTypes.LoadDashboardMcps;
}
export class LoadDashboardCheckSheetAssignmentStatus implements Action {
  readonly type = DashBoardActionTypes.LoadDashboardCheckSheetAssignmentStatus;
}

export class CpsDashBoardPackageLoadSuccess implements Action {
  readonly type = DashBoardActionTypes.CpsDashBoardPackageLoadSuccess;

  constructor(public cps: DashboardPackage[]) {
  }
}

export class McpsDashBoardPackageLoadSuccess implements Action {
  readonly type = DashBoardActionTypes.McpsDashBoardPackageLoadSuccess;

  constructor(public mcps: DashboardPackage[]) { }
}

export class DashboardCheckSheetAssignmentStatusLoadSuccess implements Action {
  readonly type = DashBoardActionTypes.DashboardCheckSheetAssignmentStatusLoadSuccess;
  constructor(public csaStatus: DashboardCheckSheetAssignmentStatus) {
  }
}

export class LoadUserSignatureAssignments implements Action {
  readonly type = DashBoardActionTypes.LoadUserSignatureAssignments;
}

export class UserSignatureAssignmentsLoadSuccess implements Action {
  readonly type = DashBoardActionTypes.LoadUserSignatureAssignmentsSuccess;
  constructor(public userSignatureAssignments: UserSignatureAssignments) {
  }
}

export type DashBoardAction =
  | LoadDashboardMcps
  | LoadDashboardCps
  | LoadDashboardCheckSheetAssignmentStatus
  | CpsDashBoardPackageLoadSuccess
  | McpsDashBoardPackageLoadSuccess
  | DashboardCheckSheetAssignmentStatusLoadSuccess
  | LoadUserSignatureAssignments
  | UserSignatureAssignmentsLoadSuccess;

