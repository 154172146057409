import { Action } from '@ngrx/store';

import { Task } from '@completion/models';

export enum TaskActionTypes {
  TasksLoad = 'Tasks Load',
  TasksLoadSuccess = 'Tasks Load Success',
  CurrentTask = 'Current Task',
  ClearCurrentTask = 'Clear Current Task'
}

export class TasksLoad implements Action {
  readonly type = TaskActionTypes.TasksLoad;

  constructor(public projectId: number) {}
}

export class CurrentTask implements Action {
  readonly type = TaskActionTypes.CurrentTask;

  constructor(public task: Task) {}
}

export class ClearCurrentTask implements Action {
  readonly type = TaskActionTypes.ClearCurrentTask;
}

export class TasksLoadSuccess implements Action {
  readonly type = TaskActionTypes.TasksLoadSuccess;

  constructor(public tasks: Task[]) {}
}

export type TaskAction = TasksLoad | TasksLoadSuccess | CurrentTask | ClearCurrentTask;
