<div class="offline-toolbar apd-dark-theme">
  <div class="title">{{ 'Offline mode' }}</div>
  <div class="description" *ngIf="online">- {{ 'Internet connected' }}</div>
  <div class="description" *ngIf="!online">- {{ 'No connection to the internet' }}</div>
  <div class="status">
    <i class="material-icons">{{ online ? 'wifi' : 'wifi_off' }}</i>
  </div>
  <div class="description">{{ 'Offline changes' }}: {{ offlineChanges }}</div>
  <div class="description">
    <i class="material-icons" (click)="fetchPendingCount()">{{ 'autorenew' }}</i>
  </div>
  <div class="switchMode" *ngIf="online">
    <button mat-flat-button color="primary" (click)="switchToOnline()">
      {{ 'Switch to online' }}
    </button>
  </div>
</div>
