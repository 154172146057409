import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProjectAccessState } from '@completion/reducers';

import { ProjectAccessListLoad, UpdateProjectAccess } from '@completion/actions';
import { ProjectAccess } from '@completion/models';
import { getProjectAccessList } from '@completion/selectors';

@Injectable({
  providedIn: 'root'
})
export class UserListService implements OnDestroy {

  filter = "";
  sortDirection: string | undefined;
  projectAccessList: ProjectAccess[] = [];
  public projectAccessList$: BehaviorSubject<ProjectAccess[]> = new BehaviorSubject<ProjectAccess[]>(this.projectAccessList);
  currentIndex: number;
  public loading = true;
  private readonly destroy$: Subject<void> = new Subject();

  constructor(private readonly store: Store<ProjectAccessState>) {
    this.store.select(getProjectAccessList).pipe(takeUntil(this.destroy$)).subscribe(pl => {
      if (pl) {
        this.loading = false;
        this.projectAccessList = [...pl];
        this.projectAccessList$.next(this.getFilteredList());
      }
    });
  }

  public setFilter(newFilter: string) {
    this.filter = newFilter;
    this.projectAccessList$.next(this.getFilteredList());
  }

  getFilteredList(): ProjectAccess[] {
    let filteredResult = this.projectAccessList.filter(pa => pa.user.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0);
    filteredResult = filteredResult.concat(this.projectAccessList.filter(pa => pa.user.userName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0));
    return filteredResult.filter((user, pos) => filteredResult.indexOf(user) === pos);
  }

  onSaveProjectAccess(projectAccess: ProjectAccess) {
    this.store.dispatch(new UpdateProjectAccess(projectAccess));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
