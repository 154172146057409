export enum HandoverDocsObjectTypeNames {
  AREA = 'Area',
  ASSEMBLY = 'Steel assembly',
  BOLT = 'Bolted Connection',
  CABLE = 'Cable Index Electrical / Instrument',
  COMMDUMMY = 'MC/Comm Job Cards',
  COMMPKG = 'Commisioning Package',
  COMPLDOSSIER = 'Compl.Dossier Job Cards',
  COMPLETION = 'Completion Job Cards',
  CONTRCODE = 'Contractor code',
  DISCIPLINE = 'Discipline',
  DUMMY = 'Dummy Object',
  EIDUMMY = 'Electrical & Instrument dummy tag',
  EIMTOQTY = 'Early MTO Electrical / Instrument',
  EIPRQTY = 'Electrical / Instrument PRQTY',
  EISTAAL = 'Cable Tray Electrical / Instrument',
  EITCADMTO = 'DEIT - Designed Qty for Purchase',
  EITRANSIT = 'Transit Electrical / Instrument',
  FA = 'FA Fabrication Assembly (FAS)',
  FU = 'FU Fabricated unit',
  GROUPOBJECT = 'Group Object',
  HCADMTO = 'PDMS Bulk MTO HVAC',
  HOOKUP = 'Hoop Up Item',
  HPRQTY = 'Production Quantity HVAC',
  HVAC = 'HVAC Line',
  HVACAREA = 'HVAC Area',
  HVACSPOOL = 'Hvac Spool',
  HWEIQTY = 'Weight Quantity HVAC',
  INDEX = 'Electrical / Instrument Index',
  INSULATION = 'Insulation (ISS)',
  LCADMTO = 'PDMS Bulk MTO Piping',
  LCADSUP = 'PDMS Bulk MTO Support',
  LINE = 'Piping Line',
  LINEAREA = 'Line Area',
  LOOP = 'Electrical / Instrument Loop',
  'LP&IDMTO' = 'P&ID Material Take off Piping',
  LPRQTY = 'Production Quantity Piping',
  LSUPMTO = 'Total Support MTO',
  LTPRQTY = 'Production Quantity Piping Test',
  LWEIQTY = 'Weight Quantity Piping',
  MCDUMMY = 'MC check of Job Cards',
  MCPKG = 'Mechanical Completion package',
  MNPRQTY = 'Surface Production Quantity Steel',
  MODPKG = 'Modpakke',
  MTOREQ = 'MTO Requisition Maintenance',
  NCADMTO = 'Structural - Designed Qty for Purchase',
  NMTOQTY = 'Early MTO Quantity Steel',
  NPRQTY = 'Production Quantity Steel',
  NWEI = 'NWEIQTY - CAD mto steel - PDMS detail',
  NWEIQTY = 'Weight Quantity Steel',
  PAINTING = 'Painting (ISS)',
  PIPESIZE = 'Pipe size',
  PIPESPEC = 'Pipe spec',
  PRODFASE = 'Production Phase',
  PS = 'Pipe support (PS)',
  PSLP = 'Pipesupport Linepoint',
  PSPOS = 'Pipesupport Pos Number',
  PSSPOOL = 'Pipesupport Spool',
  RFQITEM = 'RFQ Item',
  RMTOQTY = 'Total Mechanical MTO',
  ROUTING = 'Cable routing',
  RPRQTY = 'Production Quantity MEL',
  SCAFFOLDING = 'Stillas',
  SEKSJON = 'Steel Section',
  SHIPLOOSE = 'Shiploose from Vendor',
  SIGNAL = 'Instrument Signal',
  SPOOL = 'Piping Spool',
  SUBSYS = 'Sub. System',
  SYSTEM = 'System',
  TAG = 'TAG E&I, Telecom Mechanical, Piping',
  TASK = 'Task',
  TEST = 'Piping Test',
  'TIE-IN' = 'Tie in no.',
  TRACE = 'TRACEABILITY',
  TRANSIT = 'Multi discipline transit',
  VOLUMREF = 'Volum referanse',
  WELDPIPE = 'Weld Pipe',
  WELDSTEEL = 'Weld Steel',
  XARTRULE = 'Insulation Article Definition Rules',
  XMTOQTY = 'MTO Quantity Insulation',
  XPRQTY = 'Production Quantity Insulation',
  ZCADMTO = 'PDMS Bulk MTO Support'
}
