import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {
  constructor(private readonly featureFlag: FeatureFlagService, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { flag } = route.data;

    if (flag) {
      return this.featureFlag.isEnabled(flag) ? true : this.router.parseUrl('/');
    }

    return true;
  }
}
