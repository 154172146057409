import { ResourceStatus, ResourceType } from '@completion/enums';

export interface ResourceState {
  status: ResourceStatus;
  lastError: string;
}

export interface ResourceDescriptor {
  type: ResourceType;
  identifiers?: Record<string, string | number>;
}
