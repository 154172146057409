import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PatternMatchingService {

  matches(patterns: (string | RegExp)[], testCase: string): boolean {
    return patterns.some(ignorePattern => {
      if (ignorePattern instanceof RegExp) {
        return ignorePattern.test(testCase);
      } else {
        const wildCardPos = ignorePattern.indexOf('*');
        if (wildCardPos > 0) {
          return testCase.startsWith(ignorePattern.substring(0, wildCardPos));
        } else if (wildCardPos === 0) {
          return testCase.endsWith(ignorePattern.substring(wildCardPos + 1, ignorePattern.length));
        } else {
          return testCase === ignorePattern;
        }
      }
    });
  }
}
