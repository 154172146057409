import { CommissioningPackage } from '@completion/models';
import { Delivery } from './delivery';
import { Discipline } from './discipline';
import { McPackage } from './mc-package';
import { NamedEntity } from './named-entity';

export class BaseTag implements NamedEntity {
  id: number;
  tagNumber: string;
  name: string;
  objectType?: string;
  mcpId?: number;
  mcpNumber?: string;
}

export class Tag extends BaseTag {
  delivery: Partial<Delivery>;
  discipline: Partial<Discipline>;
  commissioningPackage: Partial<CommissioningPackage>;
  mcPackage?: Partial<McPackage>;
  childReferenceTags?: BaseTag[];
  tagReferenceType?: string;
  serialNumber: string;
}

export function byTagNumber(a: Tag, b: Tag) {
  return a.tagNumber.localeCompare(b.tagNumber);
}
