<h1 mat-dialog-title>Edit Company</h1>
<div mat-dialog-content>
  <form [formGroup]="companyForm">
    <mat-form-field>
      <mat-label>Signature Matrix Type</mat-label>
      <input type="text" matInput formControlName="signatureMatrixType" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let type of signatureMatrixTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSave()" [disabled]="!companyForm.valid">Save</button>
</div>
