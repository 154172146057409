<div class="user-signmature-assignments">
  <div class="rfcc-container sign-container" *ngIf="hasRfccsToSign">
    <h2>My assigned RFCCs</h2>
    <div class="header">
      <span class="doc-number"> Document Number</span>
      <span class="doc-number">Issued date</span>
      <span class="fraction"> Progress</span>
      <span class="fraction">&nbsp;</span>
      <span class="avaliable"> Next Signee</span>
    </div>
    <div class="item-row" *ngFor="let item of rfccList$ | async" (click)="navigateToRfccCertificate(item)">
      <span class="doc-number"> {{ item.documentNumber }}</span>
      <span class="doc-number"> {{ item.issuedDate }} </span>
      <span class="fraction"> {{ signedFraction(item.signatureMatrix) }}</span>

      <span class="icon ready" *ngIf="isMyTurn(item.signatureMatrix)">
        <mat-icon>assignment_add</mat-icon>
      </span>
      <span class="avaliable" *ngIf="isMyTurn(item.signatureMatrix)">Ready to signed</span>

      <span class="icon blocked" *ngIf="!isMyTurn(item.signatureMatrix)"><mat-icon>block</mat-icon> </span>
      <span class="avaliable" *ngIf="!isMyTurn(item.signatureMatrix)">Blocked by: {{ getNextToSignAsString(item.signatureMatrix) }}</span>
    </div>
  </div>

  <div class="rfoc-container sign-container" *ngIf="hasRfocsToSign">
    <h2>My assigned RFOCs</h2>
    <div class="header">
      <span class="doc-number"> Document Number</span>
      <span class="doc-number">Issued date</span>
      <span class="fraction"> Progress</span>
      <span class="fraction">&nbsp;</span>
      <span class="avaliable"> Next Signee</span>
    </div>
    <div class="item-row" *ngFor="let item of rfocList$ | async" (click)="navigateToRfoCertificate(item)">
      <span class="doc-number"> {{ item.documentNumber }}</span>
      <span class="doc-number"> {{ item.issuedDate }} </span>
      <span class="fraction"> {{ signedFraction(item.signatureMatrix) }}</span>

      <span class="icon ready" *ngIf="isMyTurn(item.signatureMatrix)">
        <mat-icon>assignment_add</mat-icon>
      </span>
      <span class="avaliable" *ngIf="isMyTurn(item.signatureMatrix)">Ready to be signed</span>

      <span class="icon blocked" *ngIf="!isMyTurn(item.signatureMatrix)"><mat-icon>block</mat-icon> </span>
      <span class="avaliable" *ngIf="!isMyTurn(item.signatureMatrix)">Blocked by: {{ getNextToSignAsString(item.signatureMatrix) }}</span>
    </div>
  </div>

  <div class="csa-container sign-container" *ngIf="hasCheckSheetsToSign">
    <h2>My assigned Checksheets</h2>
    <div class="header">
      <span class="fraction"> Progress</span>
      <span class="fraction">&nbsp;</span>
      <span class="avaliable"> Next Signee</span>
    </div>
    <div class="item-row" *ngFor="let item of checkSheetAssignmentList$ | async" (click)="navigateToCsa(item)">
      <span class="doc-number"> {{ item.tagNumber }}</span>
      <span class="fraction"> {{ signedFraction(item.signatureMatrix) }}</span>

      <span class="icon ready" *ngIf="isMyTurn(item.signatureMatrix)">
        <mat-icon>assignment_add</mat-icon>
      </span>
      <span class="avaliable" *ngIf="isMyTurn(item.signatureMatrix)">Ready to be signed</span>

      <span class="icon blocked" *ngIf="!isMyTurn(item.signatureMatrix)"><mat-icon>block</mat-icon> </span>
      <span class="avaliable" *ngIf="!isMyTurn(item.signatureMatrix)">Blocked by: {{ getNextToSignAsString(item.signatureMatrix) }}</span>
    </div>
  </div>

  <div class="handover-container sign-container" *ngIf="hasHandoversToSign">
    <h2>My assigned RFOCs</h2>
    <div class="header">
      <span class="doc-number"> Document Number</span>
      <span class="doc-number">Issued date</span>
      <span class="fraction"> Progress</span>
      <span class="fraction">&nbsp;</span>
      <span class="avaliable"> Next Signee</span>
    </div>
    <div class="item-row" *ngFor="let item of handoverList$ | async" (click)="navigateToRfccCertificate(item)">
      <span class="doc-number"> {{ item.documentNumber }}</span>
      <span class="doc-number"> {{ item.issuedDate }} </span>
      <span class="fraction"> {{ signedFraction(item.signatureMatrix) }}</span>

      <span class="icon ready" *ngIf="isMyTurn(item.signatureMatrix)">
        <mat-icon>assignment_add</mat-icon>
      </span>
      <span class="avaliable" *ngIf="isMyTurn(item.signatureMatrix)">Ready to signed</span>

      <span class="icon blocked" *ngIf="!isMyTurn(item.signatureMatrix)"><mat-icon>block</mat-icon> </span>
      <span class="avaliable" *ngIf="!isMyTurn(item.signatureMatrix)">Blocked by: {{ getNextToSignAsString(item.signatureMatrix) }}</span>
    </div>
  </div>
</div>
