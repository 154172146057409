import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckSheet } from '@completion/models';

@Component({
  selector: 'app-edit-signature-matrix-dialog',
  templateUrl: './edit-signature-matrix-dialog.component.html',
  styleUrls: ['./edit-signature-matrix-dialog.component.scss']
})
export class EditSignatureMatrixDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditSignatureMatrixDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { checkSheet: CheckSheet, signatureMatrixTypes: string[] }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.data.checkSheet);
  }
}
