import { CompletionStatus } from '../enums/completion-status';
import { McPackage } from './mc-package';
import { PackageCommon } from './package-common';
import { SubSystem, System } from './system';
import { Task } from './task';

export class CommissioningPackage extends PackageCommon {
  task: Task;
  system: System;
  subSystem: SubSystem;
  decommissioning: boolean;
  mcPackages: McPackage[];
  cpNumber?: string;
}

export function cpSort(a: CommissioningPackage, b: CommissioningPackage) {
  const compareSystemNumber = a.system.number.localeCompare(b.system.number);
  const compareSubSystemNumber = a.subSystem.number.localeCompare(b.subSystem.number);
  const comparecpNumber = a.cpNumber.localeCompare(b.cpNumber);
  return compareSystemNumber || compareSubSystemNumber || comparecpNumber;
}

export function hasMcPackage(cp: CommissioningPackage): boolean {
  return cp.mcPackages && cp.mcPackages.length > 0;
}
